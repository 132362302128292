import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {WalletService} from '../../services/wallet.service';
import {CoinModel} from '@core/models/connect/coin-model';
import {AccountBrief} from '@core/models/connect/account-brief';
import {CoinMarketService} from '../../services/coin-market.service';
import {Helpers} from '../../services/helpers';
import {UiEvent} from '@core/models/connect/ui-event.enum';
import {WalletDeviceService} from "../../services/wallet-device.service";

enum SidebarMode {
  Disconnected = 'Disconnected',
  LazyLoading = 'LazyLoading',
  CompleteDiscovery = 'CompleteDiscovery',
}

@Component({
  selector: 'app-wallet-sidebar',
  templateUrl: './wallet-sidebar-layout.component.html'
})
export class WalletSidebarLayoutComponent implements OnInit, OnDestroy {

  coin: CoinModel;
  accountList: AccountBrief[] = [];
  tempList: AccountBrief[] = [];
  selectedAccount: AccountBrief = <AccountBrief>{};
  newAccount: AccountBrief = <AccountBrief>{};

  changeWalletSubscription: Subscription;
  newAccountDiscoveredSubscription: Subscription;
  discoveryFinishedSubscription: Subscription;
  uiAlertSubscription: Subscription;

  sidebarMode: SidebarMode = SidebarMode.Disconnected;
  completeDiscovery: boolean = false;
  canAddAccount: boolean = false;
  currency: string;

  constructor(private _deviceService: WalletDeviceService,
              private _walletService: WalletService,
              private _coinMarketService: CoinMarketService) {
  }

  ngOnInit() {
    this.currency = this._coinMarketService.getCurrentCurrency();
    this._uiAlertSubscribe();
    this._changeWalletSubscribe();
    this._newAccountDiscoveredSubscribe();
    this._discoveryFinishedSubscribe();
  }

  private _changeWalletSubscribe() {
    this.changeWalletSubscription = this._walletService.onCurrentWalletChange.subscribe(() => {
      if (this._walletService.CurrentWalletCoin) {
        this.coin = this._walletService.CurrentWalletCoin;
        this.accountList = [];
        this.completeDiscovery = false;
        this.sidebarMode = SidebarMode.LazyLoading;
      }
    });
  }

  private _newAccountDiscoveredSubscribe() {
    this.newAccountDiscoveredSubscription = this._walletService.onNewAccountDiscovered.subscribe(accountIndex => {
      try {

        if (accountIndex < 0) {
          return;
        }

        const nextAccount = this._walletService.CurrentWallet.getAccountBrief(accountIndex);
        this.accountList.push(nextAccount);

        if (accountIndex === 0) {
          this.selectedAccount = nextAccount;
          this._walletService.setCurrentAccountOfCurrentWallet(accountIndex);
          Helpers.WalletLoading(false);
        }
      } catch (error) {
        console.warn('on discover new account error. ', error);
        Helpers.WalletLoading(false);
        Helpers.canSelectCoin(true);
        this._deviceService.setUiAlertSource(UiEvent.Blockchain_Disconnected);
      }
    });
  }

  private _discoveryFinishedSubscribe() {
    this.discoveryFinishedSubscription = this._walletService.onCurrentWalletDiscoveryFinished.subscribe(isFinished => {
      if (isFinished) {
        this.completeDiscovery = true;
        this.sidebarMode = SidebarMode.CompleteDiscovery;
        this.canAddAccount = this._walletService.CurrentWallet.canAddNewAccount();
        this._deviceService.setUiAlertSource(UiEvent.Blockchain_Synchronized);
        Helpers.canSelectCoin(true);

        //! Set market price
        this._coinMarketService.setCoinMarket(this._walletService.CurrentWalletCoin).then(() => {
          this.accountList.forEach(x => this._coinMarketService.setBalancePrice(x));
        });
      }
    });
  }

  private _uiAlertSubscribe() {
    this.uiAlertSubscription = this._deviceService.uiAlert$.subscribe(uiEvent => {
      if (uiEvent === UiEvent.Blockchain_Disconnected) {
        this.sidebarMode = SidebarMode.Disconnected;
      }
    });
  }

  changeAccount_OnClick(account: AccountBrief) {
    this.selectedAccount = account;
    this._walletService.setCurrentAccountOfCurrentWallet(account.id);
  }

  addAccount_OnClick() {
    if (!this.canAddAccount) {
      return;
    }
    this._walletService.AddNewAccount();
    this.canAddAccount = false;
  }

  ngOnDestroy() {
    this.changeWalletSubscription.unsubscribe();
    this.newAccountDiscoveredSubscription.unsubscribe();
    this.discoveryFinishedSubscription.unsubscribe();
    this.uiAlertSubscription.unsubscribe();
    this._walletService.onNewAccountDiscovered.unsubscribe();
  }
}
