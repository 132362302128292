import {Component, OnDestroy, OnInit} from '@angular/core';
import {WalletService} from '../../services/wallet.service';
import {CoinModel} from '@core/models/connect/coin-model';
import {Subscription} from 'rxjs';
import {UiEvent} from '@core/models/connect/ui-event.enum';
import {Helpers} from '../../services/helpers';
import {WindowService} from '../../services/window.service';
import {CoinBaseType, CoinInfo} from '@webcore/coins/CoinInfo';
import {WalletAddCoinComponent} from "../wallet-add-coin/wallet-add-coin.component";

import {ProkeySettingsService} from "../../services/prokey-settings.service";
import {ProkeySettingsKey} from "@core/models/prokey-settings-key.enum";
import {WalletDeviceService} from "../../services/wallet-device.service";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import { Router } from '@angular/router';
import { LayoutMode } from '@core/models/layout-mode';
import { LayoutService } from '@core/services/layout.service';
import {StorageService} from "@core/services/storage/storage.service";
import {BaseRepository} from "@core/services/storage/repository/base-repository";
import {first} from "rxjs/operators";

enum SyncMode {
  Synchronized = 'Synchronized',
  Synchronizing = 'Synchronizing',
  Disconnected = 'Disconnected',
}

@Component({
  selector: 'app-wallet-header',
  templateUrl: './wallet-header-layout.component.html'
})
export class WalletHeaderLayoutComponent implements OnInit, OnDestroy {

  isInitialWallet: boolean = false;
  headerMode: LayoutMode;
  syncMode: SyncMode = SyncMode.Synchronizing;
  storageImagePath: string = '';
  storageRepresentName: string = '';

  coins: CoinModel[];
  selectedCoin: CoinModel;

  myFamousCoinsSubscription: Subscription;
  uiAlertSubscription: Subscription;
  selectedCoinSubscription: Subscription;
  changeLayoutSubscription: Subscription;

  private _currentRoute = '/wallet/device';
  private _currentRepository: BaseRepository;

  constructor(private _deviceService: WalletDeviceService,
              private _walletService: WalletService,
              private _layoutService: LayoutService,
              private windowService: WindowService,
              private _prokeySettingsService: ProkeySettingsService,
              private _dialogService: DialogService,
              private _route: Router,
              private _storageService: StorageService) {
  }

  async ngOnInit() {
    this._changeLayoutSubscribe();
    this.initialWallet();
    this.myFamousCoinsSubscribe();
    this.uiAlertSubscribe();
    this.selectedCoinSubscribe();
    await this._setStorage();
  }

  private async _setStorage() {
    let device = await this._deviceService.device$.pipe(first()).toPromise();
    this._currentRepository = this._storageService.getCurrentStorage(device.features.device_id);
    this.storageImagePath = this._currentRepository.getRepositoryImagePath();
    this.storageRepresentName = this._currentRepository.getRepositoryRepresentName();
  }

  async initialWallet() {
    if (this.isInitialWallet) {
      return;
    }

    if (this._walletService.WalletCoins.count > 0) {
      this.setCoins();
      this.selectedCoin = this._walletService.WalletCoins.defaultCoin;
      this.isInitialWallet = true;
    } else {
      this.isInitialWallet = false;
      console.warn('initial wallet failed: no any coin available');
      this.windowService.nativeWindow.location.href = '/start';
    }
  }

  get SelectedCoinIsToken(): boolean {
    if (this.selectedCoin == null || this.selectedCoin.coinInfo == null) {
      return false;
    }
    
    return this.selectedCoin.coinInfo.coinBaseType == CoinBaseType.ERC20 || this.selectedCoin.coinInfo.coinBaseType == CoinBaseType.OMNI;
  }

  IsThisCoinDuplicated(coinName: string): boolean {
    let numberOfRepeated = 0;
    for(let i=0; i<this.coins.length; i++){
      if(this.coins[i].coinInfo.name.toLowerCase() == coinName.toLowerCase())
      {
        numberOfRepeated++;
      }
    }

    return (numberOfRepeated > 1);
  }

  /**
   * This function is called from view when user selects a coin from drop-down list
   * @param coin Selected Coin
   */
  async coinsDropDownItem_OnClick(coin: CoinModel) {
      Helpers.WalletLoading(true);
      this._deviceService.setUiAlertSource(UiEvent.Blockchain_Synchronizing);
      const changeResult = await this._walletService.changeCurrentWallet(coin);
      if (changeResult) {
        this.selectedCoin = coin;
      }
  }

  setCoins() {
    //! Load selected coins from local storage
    let myFamousCoins = this._prokeySettingsService.get(ProkeySettingsKey.FAMOUS_COINS);
    this.coins = this._walletService.WalletCoins.getMyCoins(myFamousCoins);
  }

  myFamousCoinsSubscribe() {
    this.myFamousCoinsSubscription = this._walletService.onMyFavCoinsChange.subscribe(isChange => {
      if (isChange) {
        this.setCoins();
      }
    });
  }

  /**
   * This function will be called when WalletService selects a new coin
   */
  selectedCoinSubscribe(){
    this.selectedCoinSubscription = this._walletService.onCurrentWalletChange.subscribe( () => {
      //! Usually the coin selection happens from this component and from drop down list, but in some cases like (AddNewCoin), selection
      //! Can be done from other place of code, so we need to update the selected coin here
      this.selectedCoin = this._walletService.CurrentWalletCoin;
      
      if (this._route.url != this._currentRoute && this._route.url != '/wallet/portfolio') {
        this._route.navigate(['/wallet/device']);
      }
    });
  }

  uiAlertSubscribe() {
    this.uiAlertSubscription = this._deviceService.uiAlert$.subscribe(uiEvent => {
      switch (uiEvent) {
        case UiEvent.Blockchain_Synchronizing:
          this.setSyncMode(SyncMode.Synchronizing);
          break;
        case UiEvent.Blockchain_Synchronized:
          this.setSyncMode(SyncMode.Synchronized);
          break;
        case UiEvent.Blockchain_Disconnected:
          this.setSyncMode(SyncMode.Disconnected);
          break;
        default:
          break;
      }
    });
  }

  setSyncMode(mode: SyncMode) {
    this.syncMode = mode;
  }

  reDiscovery() {
    this._walletService.reDiscovery(this.headerMode, this.selectedCoin);
  }

  openModal() {
    this._dialogService.open(WalletAddCoinComponent, {width: '100%'});
  }

  private _changeLayoutSubscribe() {
    this.changeLayoutSubscription = this._layoutService.onLayoutChange.subscribe((layout: LayoutMode) => {
      this.headerMode = layout;
    });
  }

  ngOnDestroy() {
    this.changeLayoutSubscription.unsubscribe();
    this.myFamousCoinsSubscription.unsubscribe();
    this.uiAlertSubscription.unsubscribe();
    this.selectedCoinSubscription.unsubscribe();
  }
}
