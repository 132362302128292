<div *ngIf="!isAvailableChart" class="text-center">
  <img src="/assets/img/no-balance.svg" width="320" height="auto" class="rounded mx-auto d-block mt-4">
  <h5 class="no-bal-title mt-4">Load assets to get started</h5>
  <p class="mx-auto w-75">Add an asset by clicking on add/remove asset, choose any asset you want and add funds to activate portfolio. </p>
</div>

<div [class.no-display]="!isAvailableChart" class="chart">
  <div class="chart-header">
    <div class="row p-3">
      <div class="col-lg-4 col-md-4 b-r align-self-center" tourAnchor="chart.totalBalance">
        <h4 class="d-block m-b-3"> {{currency}} {{assetsTotalBalance | number:'1.0-2'}} </h4>
        <h6 class="total-balance d-block"> Total Balance </h6>
      </div>
      <div class="col-lg-2 col-md-4 align-self-center">
        <h6 class="d-block m-b-3">{{currentPricePercentage | number:'1.0-2'}}% <i [class]="currentPercentageTypeClass"></i></h6>
        <h6 class="total-balance d-block">{{currnetPeriodTime}}</h6>
      </div>
      <div class="col-lg-6 col-md-4">
          <div class="dropdown">
            <button 
              type="button" 
              class="btn btn-outline-new-final text-dark dropdown-toggle float-right waves waves-effect" 
              data-toggle="dropdown" 
              tourAnchor="chart.periodTime">
              {{currnetPeriodTime}}
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.305" height="7" viewBox="0 0 10.305 6.183">
                <image id="left33_copy_2" data-name="left33 copy 2" width="10.305" height="6.183" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAABHNCSVQICAgIfAhkiAAAAIxJREFUCJldzTFqAlEUQNEzI6ZwBcE1uA8rSxsLhQexFUQEN2ATSafdvMJiOpu4ANfkAuSnGUVy6wO3appmgRG2EVG8lZk9nHCr8YkNDv9QjTOWGNb4Rot1Zu47VOGIGU4R8VOVUmRmHxdMsOsuKzT4iohSlVKeqw/8YtzdW8wj4gEv2OEBrrhj+kTwB4oYMVxk5ZZTAAAAAElFTkSuQmCC"/>
              </svg>
            </button>
            <div class="dropdown-menu dropdown-menu-right" style="width:220px;"> 
              <a *ngFor="let periodTime of chartPeriodTiems" href="javascript:;" (click)="updateChart_OnClick(periodTime)" class="dropdown-item py-2 waves waves-effect">{{periodTime.type}}</a>
            </div>
          </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
  <div class="chart-body">
    <div id="timeline-chart"></div>
  </div>
</div>

<div class="clear"></div>
<div class="mt-4">
  <h3 class="body-title float-left col-lg-8 col-md-6 col-sm-6 col-12" tourAnchor="assets">Assets</h3>
  <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end pt-2 px-0">
    <a href="javascript:void(0)" class="list-view active">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 27.231 22.125">
        <path id="Icon_awesome-list" data-name="Icon awesome-list" d="M4.255,20.394H.851A.851.851,0,0,0,0,21.245v3.4a.851.851,0,0,0,.851.851h3.4a.851.851,0,0,0,.851-.851v-3.4A.851.851,0,0,0,4.255,20.394Zm0-17.019H.851A.851.851,0,0,0,0,4.226v3.4a.851.851,0,0,0,.851.851h3.4a.851.851,0,0,0,.851-.851v-3.4A.851.851,0,0,0,4.255,3.375Zm0,8.51H.851A.851.851,0,0,0,0,12.736v3.4a.851.851,0,0,0,.851.851h3.4a.851.851,0,0,0,.851-.851v-3.4A.851.851,0,0,0,4.255,11.885ZM26.38,21.245H9.361a.851.851,0,0,0-.851.851v1.7a.851.851,0,0,0,.851.851H26.38a.851.851,0,0,0,.851-.851V22.1A.851.851,0,0,0,26.38,21.245Zm0-17.019H9.361a.851.851,0,0,0-.851.851v1.7a.851.851,0,0,0,.851.851H26.38a.851.851,0,0,0,.851-.851v-1.7A.851.851,0,0,0,26.38,4.226Zm0,8.51H9.361a.851.851,0,0,0-.851.851v1.7a.851.851,0,0,0,.851.851H26.38a.851.851,0,0,0,.851-.851v-1.7A.851.851,0,0,0,26.38,12.736Z" transform="translate(0 -3.375)" fill="#333437"/>
      </svg>								  
    </a>
    <a href="javascript:void(0)" class="ml-2 grid-view" tourAnchor="gridView">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22.125 22.125">
        <path id="Icon_open-grid-three-up" data-name="Icon open-grid-three-up" d="M0,0V5.531H5.531V0ZM8.3,0V5.531h5.531V0Zm8.3,0V5.531h5.531V0ZM0,8.3v5.531H5.531V8.3Zm8.3,0v5.531h5.531V8.3Zm8.3,0v5.531h5.531V8.3ZM0,16.594v5.531H5.531V16.594Zm8.3,0v5.531h5.531V16.594Zm8.3,0v5.531h5.531V16.594Z" fill="#7e8087"/>
      </svg>
    </a>
  </div>
</div>

<!-- LIST VIEW -->
<div style="min-height:245px;" class="assetsView assetsView-list">
  <table class="table w-100 table-assets">
    <thead>
      <tr>
        <th>Crypto</th>
        <th tourAnchor="balance">Balance</th>
        <th class="text-center">Price</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let asset of assets" (click)="asset_OnClick(asset)">
        <td>
          <div class="coin-name d-flex align-items-center">
            <img src="{{asset.coin.getImageUrl()}}" alt="{{asset.coin.coinInfo.shortcut}}" loading="lazy" class="coinmenuimg"
             onerror="if (this.src != './assets/img/coins/tk.png') { this.src = './assets/img/coins/tk.png'; } return;" />
            <span class="mx-2 font-weight-bold">{{asset.coin.coinInfo.name}}</span>
            <strong class="font-weight-light">{{asset.coin.coinInfo.shortcut}}</strong>
          </div>
        </td>
        <td>
          <ng-container *ngIf="asset.isDisconnected then DisconnectedListTemplate else ConnectedListTemplate"></ng-container>
          <ng-template #ConnectedListTemplate>
            <ng-container *ngIf="asset.isDiscoveryFinished then BalanceTemplate else LoadingBalanceTemplate"></ng-container>
            <ng-template #BalanceTemplate>
              <b>{{asset.totalBalance | unit: asset.coin.coinInfo.decimals | number: '1.6-8'}} {{asset.coin.coinInfo.shortcut}}</b> <ng-container *ngIf="hasBalancePrice(asset)"> ≈ </ng-container> {{asset.totalBalancePrice | currency: currency:'symbol'}}
            </ng-template>
            <ng-template #LoadingBalanceTemplate>
              <i class="fa fa-spinner fa-spin text-muted"></i>
            </ng-template>
          </ng-template>
          <ng-template #DisconnectedListTemplate>
            <small class="faildblockchain">Failed to connect blockchain</small>
          </ng-template>
        </td>
        <td class="text-center">
          <div class="d-flex align-items-center justify-content-center">
            <svg *ngIf="asset.coinMarketChangeStatus === 'Negative'" xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 18 9" class="mr-1">
              <path id="Icon_ionic-md-arrow-dropup" data-name="Icon ionic-md-arrow-dropup" d="M9,22.5l9-9,9,9Z" transform="translate(27 22.5) rotate(180)" fill="#e12f41"/>
            </svg>
            <svg *ngIf="asset.coinMarketChangeStatus === 'Positive'" xmlns="http://www.w3.org/2000/svg" width="18" height="9" viewBox="0 0 18 9" class="mr-1">
              <path id="Icon_ionic-md-arrow-dropup" data-name="Icon ionic-md-arrow-dropup" d="M9,22.5l9-9,9,9Z" transform="translate(-9 -13.5)" fill="#11c464"/>
            </svg>																					
            <span>{{asset.coinPrice | currency: currency:'symbol'}}</span>
          </div>
        </td>
      </tr>
      <tr class="cursour-pointer">
        <td colspan="3" style="padding:0px !important">
          <div class="d-flex justify-content-center align-items-center waves waves-effect" style="padding: 12px 12px" (click)="openModal()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 18.733 20.775" class="mr-2">
              <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-7 -7)">
                <path id="Path_9" data-name="Path 9" d="M18,7.5V27.275" transform="translate(-1.633 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <path id="Path_10" data-name="Path 10" d="M7.5,18H25.233" transform="translate(0 -0.613)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
              </g>
            </svg>											  
            Add/Remove coins
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- /LIST VIEW -->

<!-- GRID VIEW -->
<div style="min-height:245px;display:none;" class="assetsView assetsView-grid">
  <div class="assets-items d-flex w-100">
    <a *ngFor="let asset of assets" class="assets-item waves waves-effect" href="javascript:void(0)" (click)="asset_OnClick(asset)">
      <div class="assets-item-header d-flex">
        <div class="item-icon">
          <img src="{{asset.coin.getImageUrl()}}" alt="{{asset.coin.coinInfo.shortcut}}" loading="lazy" class="coinmenuimg"
             onerror="if (this.src != './assets/img/coins/tk.png') { this.src = './assets/img/coins/tk.png'; } return;" />										  
        </div>
        <div class="item-name">
          <h5 class="d-inline-block">{{asset.coin.coinInfo.name}}</h5>
          <h6 class="d-inline-block ml-2">{{asset.coin.coinInfo.shortcut}}</h6>
        </div>
      </div>
      <div class="assets-item-body">
        <ng-container *ngIf="asset.isDisconnected then DisconnectedGridTemplate else ConnectedGridTemplate"></ng-container>
        <ng-template #ConnectedGridTemplate>
          <div><b>{{asset.totalBalance | unit: asset.coin.coinInfo.decimals | number: '1.6-8'}} {{asset.coin.coinInfo.shortcut}}</b></div>
          <div>
            <span><ng-container *ngIf="hasBalancePrice(asset)"> ≈ </ng-container> {{asset.totalBalancePrice | currency: currency:'symbol'}}</span>
            <strong class="font-weight-normal" [className]="asset.coinMarketChangeStatus === 'Negative' ? 'text-danger ml-2 mr-1 font-weight-normal' : 'text-success ml-2 mr-1 font-weight-normal'">{{asset.priceChangePercentage | number:'1.0-2'}}%</strong>
            <svg *ngIf="asset.coinMarketChangeStatus === 'Negative'" xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 18 9">
              <path id="Icon_ionic-md-arrow-dropup" data-name="Icon ionic-md-arrow-dropup" d="M9,22.5l9-9,9,9Z" transform="translate(27 22.5) rotate(180)" fill="#e12f41"/>
            </svg>
            <svg *ngIf="asset.coinMarketChangeStatus === 'Positive'" xmlns="http://www.w3.org/2000/svg" width="18" height="9" viewBox="0 0 18 9" class="mr-1">
              <path id="Icon_ionic-md-arrow-dropup" data-name="Icon ionic-md-arrow-dropup" d="M9,22.5l9-9,9,9Z" transform="translate(-9 -13.5)" fill="#11c464"/>
            </svg>
          </div>
        </ng-template>
        <ng-template #DisconnectedGridTemplate>
          <small class="faildblockchain">Failed to connect blockchain</small>
        </ng-template>
      </div>
    </a>
    <div class="assets-item add-more-assets waves waves-effect" (click)="openModal()">
      <div class="d-flex justify-content-center align-items-center" tourAnchor="addOrRemoveAsset">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 18.733 20.775" class="mr-2">
          <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-7 -7)">
            <path id="Path_9" data-name="Path 9" d="M18,7.5V27.275" transform="translate(-1.633 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <path id="Path_10" data-name="Path 10" d="M7.5,18H25.233" transform="translate(0 -0.613)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          </g>
        </svg>											  
        Add/Remove coins
      </div>
    </div>
  </div>
</div>
<!-- /GRID VIEW -->
