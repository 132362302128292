import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {NgForm} from '@angular/forms';
import {Helpers} from '../../core/services/helpers';
import {ButtonRequestType} from '@core/models/connect/button-request-type.enum';
import {WalletDeviceService} from "../../core/services/wallet-device.service";
import { DialogService } from '@shared/dialog/_services/dialog.service';


enum WizardStep {
  AcceptTerms = 'AcceptTerms',
  CreateBackup = 'CreateBackup',
  SetDeviceName = 'SetDeviceName',
  SetPIN = 'SetPIN',
}

enum RecoverySeedMode {
  WriteSeed = 'WriteSeed',
  CheckSeed = 'CheckSeed',
  SuccessfullyBackup = 'SuccessfullyBackup',
  Unsuccessfull = 'Unsuccessfull',
}

enum SetLabelMode {
  Info = 'Info',
  SetLabel = 'SetLabel',
  ConfirmAction = 'ConfirmAction',
  Successfully = 'Successfully',
}

enum SetPinMode {
  EnterPIN = 'EnterPIN',
  Successfully = 'Successfully',
  Unsuccessfull = 'Unsuccessfull',
  Finished = 'Finished',
}

enum FinishMode {
  FollowUs = 'FollowUs',
  Finished = 'Finished'
}

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.css']
})
export class BackupComponent implements OnInit, OnDestroy {

  uiButtonSubscription: Subscription;

  readonly wizardElement: string = 'backup-wizard';
  iAgree: boolean = false;
  wordNumber: number = 0;
  errorMessage: string = '';

  WizardStep = WizardStep;
  wizardStep: WizardStep = WizardStep.AcceptTerms;
  recoverySeedMode: RecoverySeedMode = RecoverySeedMode.WriteSeed;
  setLabelMode: SetLabelMode = SetLabelMode.Info;
  setPinMode: SetPinMode = SetPinMode.EnterPIN;
  finishMode: FinishMode = FinishMode.FollowUs;

  labelModel: any = {label: ''};

  constructor(
    private router: Router, 
    private _deviceService: WalletDeviceService,
    private _dialogService: DialogService) {
  }

  ngOnInit() {
    //TODO: check needs backup.
    this.wizardStep = WizardStep.AcceptTerms;
    this.buttonSubscribe();
  }

  buttonSubscribe() {
    this.uiButtonSubscription = this._deviceService.uiButton$.subscribe(code => {
      if (ButtonRequestType.ButtonRequest_ConfirmWord == code) {
        this.setWordNumber();
      }
    });
  }

  nextTo(step: WizardStep) {
    this.setWizardStep(step);
    switch (step) {
      case WizardStep.CreateBackup:
        Helpers.setCurrentStep('step-1', 'step-2');
        this.backup();
        break;
      case WizardStep.SetDeviceName:
        Helpers.setCurrentStep('step-2', 'step-3');
        break;
      case WizardStep.SetPIN:
        Helpers.setCurrentStep('step-3', 'step-4');
        this.setPIN();
        break;
      default:
        break;
    }
  }

  setWizardStep(step: WizardStep) {
    this.wizardStep = step;
  }

  setCurrentStep(currentStep: string, nextStep: string) {
    Helpers.setCurrentStep(currentStep, nextStep);
  }

  setWordNumber() {
    if (this.wordNumber < 24) {
      this.wordNumber = this.wordNumber + 1;
    } else {
      this.wordNumber = 1;
      this.recoverySeedMode = RecoverySeedMode.CheckSeed;
    }
  }

  labelConfirmAction() {
    if (this.setLabelMode == SetLabelMode.SetLabel) {
      this.setLabelMode = SetLabelMode.ConfirmAction;
    }
  }

  async backup() {
    try {
      const backupResult = await this._deviceService.backupDevice();
      if (backupResult) {
        this.recoverySeedMode = RecoverySeedMode.SuccessfullyBackup;
      } else {
        this.recoverySeedMode = RecoverySeedMode.Unsuccessfull;
        this.errorMessage = 'Backup unsuccessfully.';
      }
    } catch (error) {
      console.log(error);
      this.recoverySeedMode = RecoverySeedMode.Unsuccessfull;
      this.errorMessage = 'Backup unsuccessfully.';
    }
  }

  showSetLabel() {
    this.setLabelMode = SetLabelMode.SetLabel;
  }

  async setLabel(form: NgForm) {
    try {
      const result = await this._deviceService.changeLabel(this.labelModel.label);
      this._dialogService.closeActiveModal();

      if (result) {
        this.setLabelMode = SetLabelMode.Successfully;
        Helpers.showToastrNotification('success', 'Set device label successful.', {timeout: 5000});
      } else {
        Helpers.showToastrNotification('warning', 'Try agin to change label.', {timeout: 5000});
      }
    } catch (e) {
      console.log(e);
      this._dialogService.closeActiveModal();
      Helpers.showToastrNotification('warning', 'Try agin to change label.', {timeout: 5000});
    }
  }

  async setPIN() {
    try {
      const result = await this._deviceService.changePin(false);

      if (result) {
        this.setPinMode = SetPinMode.Successfully;
        Helpers.showToastrNotification('success', 'Set device PIN successful.', {timeout: 5000});
      } else {
        this.setPinMode = SetPinMode.Unsuccessfull;
        Helpers.showToastrNotification('warning', 'Somethings went wrong, Please try again later.', {timeout: 5000});
      }
    } catch (e) {
      console.log(e);
      this.setPinMode = SetPinMode.Unsuccessfull;
      Helpers.showToastrNotification('warning', 'Somethings went wrong, Please try again later.', {timeout: 5000});
    }
  }

  showFinished() {
    this.setPinMode = SetPinMode.Finished;
    Helpers.setFinishStep('step-4');
  }

  finish() {
    this._deviceService.initializeDevice();
    this.router.navigate(['/wallet/device']);
  }

  ngOnDestroy() {
    if (this.uiButtonSubscription) {
      this.uiButtonSubscription.unsubscribe();
    }
  }

}
