import {
  ChangeDetectionStrategy,
  ContentChild,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import {DialogService} from "./_services/dialog.service";
import {ModalFooterDirective} from "./_directive/dialog-footer-directive";

/** a dialog component for create bootstrap dialog window content */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent {
  /** dialog header title */
  @Input() headerTitle: string;
  /** fire when submit button clicked emit new event */
  @Output() submitModal: EventEmitter<void> = new EventEmitter<void>();
  /** fire when close button clicked emit new event */
  @Output() closedModal: EventEmitter<void> = new EventEmitter<void>();
  /** card footer content */
  @ContentChild(ModalFooterDirective) _footer: ModalFooterDirective;

  constructor(public _modal: DialogService) {
  }

  /**
   * closed selected dialog when click on close button
   * emit new onclose event for user
   */
  closeModal(): void {
    this._modal.closeActiveModal();
    this.closedModal.emit();
  }

  /**
   * emit new onclose event for user when click on submit button
   */
  submit(): void {
    this.submitModal.emit();
  }

}

