import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectFeeLevel} from '@core/models/connect/select-fee-level';
import {CoinModel} from '@core/models/connect/coin-model';
import {BitcoinFeeSelectionModel} from '@webcore/models/FeeSelectionModel';

@Component({
  selector: 'app-coin-fee',
  templateUrl: './coin-fee.component.html',
  styleUrls: ['./coin-fee.component.css']
})
export class CoinFeeComponent implements OnChanges {

  @Input() coin: CoinModel;
  @Input() fees: BitcoinFeeSelectionModel;
  @Input() isFeeMoreThanBalanceErrorView = false;
  @Output() selectedFeeEvent = new EventEmitter<SelectFeeLevel>();
  selectedFee: string;
  feeLevels: SelectFeeLevel[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fees?.currentValue) {
      this.convertToFeeLevel(changes.fees.currentValue);
    }
  }

  onChangeFee(fee: string) {
    this.selectedFee = fee;
    this.selectedFeeEvent.emit(this.feeLevels.find(feeLevel => feeLevel.name === fee));
  }

  convertToFeeLevel(fees: BitcoinFeeSelectionModel): SelectFeeLevel[] {
    if (fees) {
      const economyFee = {
        name: 'Economy',
        fee: fees.economy,
        unit: fees.unit,
        decimal: fees.decimal,
      };
      if (fees.economy === fees.normal && fees.normal === fees.priority) {
        this.feeLevels = [economyFee];
      } else {
        this.feeLevels = [
          economyFee,
          {
            name: 'Normal',
            fee: fees.normal,
            unit: fees.unit,
            decimal: fees.decimal,
          },
          {
            name: 'Priority',
            fee: fees.priority,
            unit: fees.unit,
            decimal: fees.decimal,
          },
        ];
      }

      // !Select default fee to Economy
      if (!this.selectedFee) {
        this.onChangeFee(economyFee.name);
      }
    } else { // ! NO FEE
      this.feeLevels = [];
    }
    return this.feeLevels;
  }
}
