import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutMode } from '@core/models/layout-mode';
import { LayoutService } from '@core/services/layout.service';
import {GoogleOath2ProviderService} from "@core/services/oauth2/google-oath2-provider.service";
import {GoogleStorageRepositoryService} from "@core/services/storage/repository/google-drive/google-storage-repository.service";
import { TourService } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'app';
  tourService: TourService;

  constructor(
    private _router: Router,
    private _layoutService: LayoutService,
    private _tourService: TourService,
    private _googleOath2ProviderService: GoogleOath2ProviderService) {
      this.tourService = _tourService;
  }

  ngOnInit() {
   this._onRouteChange();
    this._googleOath2ProviderService.fetchToken();
  }

  private _onRouteChange(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url?.includes('exchange-receive') || event.url?.includes('exchange-status')) {
          this._layoutService.Layout = LayoutMode.Exchange;
        } else if (event.url?.includes('portfolio')) {
          this._layoutService.Layout = LayoutMode.Portfolio;
        } else {
          this._layoutService.Layout = LayoutMode.Default;
        }
      }
    });
  }
}
