import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WalletAddCoinComponent } from '@core/component/wallet-add-coin/wallet-add-coin.component';
import { AssetInfo, CoinMarketStatus } from '@core/models/connect/asset-info';
import { CoinModel } from '@core/models/connect/coin-model';
import { UiEvent } from '@core/models/connect/ui-event.enum';
import { ProkeySettingsKey } from '@core/models/prokey-settings-key.enum';
import { CoinMarketService } from '@core/services/coin-market.service';
import { Helpers } from '@core/services/helpers';
import { ProkeySettingsService } from '@core/services/prokey-settings.service';
import { WalletDeviceService } from '@core/services/wallet-device.service';
import { WalletService } from '@core/services/wallet.service';
import { DialogService } from '@shared/dialog/_services/dialog.service';
import { Subscription } from 'rxjs';
import { WalletTourService } from '@core/services/wallet-tour.service';
import { Wallet } from '@core/services/coins/wallet';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit, OnDestroy {

  assetCoins: string[] = [];
  assets: AssetInfo[] = [];
  currency: string;
  assetsTotalBalance: number = 0;

  readonly maxDays: number = 30;
  isAvailableChart: boolean = false;
  minFrom: number;
  maxTo: number;
  currentPricePercentage: number;
  currentPercentageTypeClass: string;
  defaultPeriodTime: string = 'Month';
  currnetPeriodTime: string = 'Month';
  chartPeriodTiems: any = [
    { type: 'Month', days: 30 },
    { type: 'Week', days: 7 },
    { type: 'Day', days: 1 },
  ];
  chart = {
    totalPrices: [],
    minFrom: null,
    maxTo: null,
  };

  private _coinCollection: CoinModel[] = [];
  private _isRenderedChart: boolean = false;
  private _isViewdTour: boolean = false;
  private _startedTour: boolean = false;

  changeAssetSubscription: Subscription;
  discoveryFinishedSubscription: Subscription;
  reDiscoverySubscription: Subscription;

  constructor(
    private _settingService: ProkeySettingsService,
    private _walletService: WalletService,
    private _dialogService: DialogService,
    private _coinMarketService: CoinMarketService,
    private _router: Router,
    private _deviceService: WalletDeviceService,
    private _tourService: WalletTourService
  ) { }

  ngOnInit() {
    this._initialSettings();
    this._subscribeDiscoveryFinished();
    this._subscribeChangeAsset();
    this._subscribeReDiscovery();
    this._initialAssets();
  }

  openModal() {
    this._dialogService.open(WalletAddCoinComponent, {width: '100%'});
  }

  asset_OnClick(assetInfo: AssetInfo) {
    if (!assetInfo.isDisconnected && assetInfo.isDiscoveryFinished) {
      this._walletService.changeCurrentWallet(assetInfo.coin);
      this._router.navigate(['/wallet/device']);
    }
  }

  updateChart_OnClick(periodTime: any) {
    this.currnetPeriodTime = periodTime.type;
    let maxToDate = new Date(this.chart.maxTo);
    let newFrom: number;

    if (periodTime.days == this.maxDays) {
      newFrom = this.minFrom;
    } else {
      newFrom = maxToDate.setDate(maxToDate.getDate() - periodTime.days);
    }

    Helpers.updateMarketPriceChart(newFrom, this.maxTo);
    this._setPricePercentage(true);
  }

  private _subscribeChangeAsset() {
    this.changeAssetSubscription = this._walletService.WalletCoins.onAssetCoinsChange.subscribe((isChange: boolean) => {
      if (isChange === true) {
        let newAssets: string[] = [];
        let removedAssets: string[] = [];
        let changes: string[] = this._settingService.get(ProkeySettingsKey.ASSET_COINS);

        changes.forEach(x =>  {
          if (!this.assetCoins.includes(x)) {
            newAssets.push(x);
          }
        });

        this.assetCoins.forEach(x => {
          if (!changes.includes(x)) {
            removedAssets.push(x);
          }
        });

        
        if (newAssets.length > 0) {
          this.assetCoins.push(...newAssets);
          newAssets.forEach(x => {
            let coin = this._coinCollection.find(c => c.coinInfo.id == x) || null;
            if (coin) {
              this.assets.push(<AssetInfo> 
                {
                  coin: coin,
                  coinId: coin.coinInfo.name.toCoinId(),
                  isDiscoveryFinished: false,
                  onChart: false,
                });
            }
          });

          this._coinMarketService.setAssetsMarketPrice(this.assets, this.currency);

          let nextAsset = this.assets.find(x => x.isDiscoveryFinished == false);
          if (nextAsset) {
            this._deviceService.setUiAlertSource(UiEvent.Blockchain_Synchronizing);
            this._discovery(nextAsset);
          }
        }

        if (removedAssets.length > 0) {

          let needToUpdateChart = false;
          removedAssets.forEach(x => {
            let removedAsset = this.assets.find(a => a.coin.coinInfo.id == x) || null;
            if (removedAsset) {
              if (removedAsset.onChart && this._isRenderedChart && this.chart.totalPrices.length > 0) {
                needToUpdateChart = true;
                for (let i = 0; i < removedAsset.marketPrices.length; i++) {
                  this.chart.totalPrices[i][1] = this.chart.totalPrices[i][1] - removedAsset.marketPrices[i][1];
                }
              }

              this.assetsTotalBalance = this.assetsTotalBalance - removedAsset.totalBalancePrice;
              this.assets.splice(this.assets.indexOf(removedAsset), 1);
              this.assetCoins.splice(this.assetCoins.indexOf(x), 1);
            }
          });

          if (needToUpdateChart) {
            Helpers.updateMarketPriceDataSeries(this.chart.minFrom, this.chart.maxTo, this.chart.totalPrices, 'Total balance');
          }
        }
      }
    });
  }

  private _subscribeDiscoveryFinished() {
    this.discoveryFinishedSubscription = this._walletService.onCurrentWalletDiscoveryFinished.subscribe(isFinished => {
      if (isFinished) {
        let asset = this.assets.find(x => x.isDiscoveryFinished == false) || null;
        if (asset) {
          this._discovery(asset);
        } else {
          this._deviceService.setUiAlertSource(UiEvent.Blockchain_Synchronized);
          this._renderChart().then((chartIsAvailable) => {
            if (!this._startedTour) {
              this._initialTour(chartIsAvailable);
              this._startTour();
            }
          });
        }
      }
    });
  }

  private _subscribeReDiscovery() {
    this.reDiscoverySubscription = this._walletService.onReDiscovery.subscribe(() => {
      this._reDeiscovery();
    });
  }

  private _reDeiscovery() {
    if (this.assets.length > 0) {

      this.assets.forEach(asset => {
        asset.totalBalance = null;
        asset.totalBalancePrice = null;
        asset.coinPrice = null;
        asset.coinMarketChangeStatus = null;
        asset.priceChangePercentage = null;
        asset.isDiscoveryFinished = false;
        asset.isLoadedPrice = false;
      });

      this._coinMarketService.setAssetsMarketPrice(this.assets, this.currency);
      this._discovery(this.assets[0]);
    }
  }

  private _initialAssets() {
    this.assetCoins = this._settingService.get(ProkeySettingsKey.ASSET_COINS);

    if (this.assetCoins === undefined || this.assetCoins.length === 0) {
      this.assetCoins = [this._walletService.WalletCoins.defaultCoin.coinInfo.id];
      this._settingService.save(ProkeySettingsKey.ASSET_COINS, this.assetCoins);
    }

    this._coinCollection = this._walletService.WalletCoins.getCoins();
    for(let coin of this._coinCollection) {
      if (this.assetCoins?.includes(coin.coinInfo.id)) {
        this.assets.push(<AssetInfo> 
          {
            coin: coin,
            coinId: coin.coinInfo.name.toCoinId(),
            isDiscoveryFinished: false,
            isDisconnected: false,
            isLoadedPrice: false,
            onChart: false,
            priceChangeMap: new Map(),
          });
      }
    }

    if (this.assets.length > 0) {
      this._coinMarketService.setAssetsMarketPrice(this.assets, this.currency)
      .then(changePercentageAverage => {
        if (changePercentageAverage) {
          this.currentPricePercentage = changePercentageAverage;
          this._setCurrentPercentageClass();
        }
      }).finally(() => {
        this._discovery(this.assets[0]);
      });
    }

    Helpers.WalletLoading(false);
  }

  private async _discovery(asset: AssetInfo) {

    try {
      const existingWallet = this._walletService.getWallet(asset.coin);
      const wallet = existingWallet ?? await this._walletService.CreateNewWallet(asset.coin);
      
      if (!wallet) {
        return;
      }
  
      if (wallet.IsAccountDiscoveryFinished === true) {
        this._setAfterDiscovery(wallet, asset);
      } else {
        wallet.StartAccountDiscovery((accountIndex) => {
          //console.log(`discovery account index ${accountIndex} on ${asset.coin.coinInfo.shortcut}`);
        }).then(() => {
          this._setAfterDiscovery(wallet, asset);
        })
        .catch(() => {
          asset.isDisconnected = true;
          this._walletService.nextDiscoveryFinished();
          console.warn('discovery failed for ', asset.coin.coinInfo.id);
        });      
      }
    } catch (error) {
      asset.isDisconnected = true;
      this._walletService.nextDiscoveryFinished();
      console.warn(`discovery error for ${asset.coin.coinInfo.name} : `, error);
    }
  }

  private _setAfterDiscovery(wallet: Wallet, asset: AssetInfo) {
    asset.totalBalance = wallet.TotalBalance;
    asset.isDiscoveryFinished = true;
    
    this._coinMarketService.setAssetTotalPrice(asset);
    if (asset.totalBalancePrice) {
      this.assetsTotalBalance += asset.totalBalancePrice;
    }
    
    if (asset.totalBalance > 0) {
      this._coinMarketService.setAssetMarketChart(asset, this.maxDays).then((success) => {

        this._walletService.nextDiscoveryFinished();

        if (success && !this.isAvailableChart) {
          this.isAvailableChart = true;
        }
      });
    } else {
      this._walletService.nextDiscoveryFinished();
    }
  }

  private _renderChart(): Promise<boolean> {

    return new Promise((resolve) => {
      let chartAssets = this.assets.filter(x => x.totalBalance > 0 && x.onChart == false && x.marketPrices != undefined);
      if (chartAssets.length == 0 && this.chart.totalPrices.length == 0) {
        this.isAvailableChart = false;
        return resolve(false);
      }
      
      this.isAvailableChart = true;
  
      for (let asset of chartAssets) {
        if (this.chart.totalPrices.length == 0) {
          this.chart.totalPrices = asset.marketPrices;
        } else {
          for (let i = 0; i < asset.marketPrices.length; i++) {
            if (this.chart.totalPrices[i]) {
              this.chart.totalPrices[i][1] = this.chart.totalPrices[i][1] + asset.marketPrices[i][1];
            }
          }
        }
  
        asset.onChart = true;
        this.chart.minFrom = this.chart.totalPrices[0][0];
        this.chart.maxTo = this.chart.totalPrices[this.chart.totalPrices.length - 1][0];
      }
  
      if (this._isRenderedChart) {
        Helpers.updateMarketPriceDataSeries(this.chart.minFrom, this.chart.maxTo, this.chart.totalPrices, 'Total balance');
      } else {
        this._isRenderedChart = true;
        Helpers.renderMarketPriceChart('timeline-chart', this.chart.totalPrices, this.chart.minFrom, this.chart.maxTo, this.currency, 'Total balance');
      }

      return resolve(true);
    });
  }

  private _setPricePercentage(updatePeriodTime: boolean) {

    if (updatePeriodTime) {
      for (let asset of this.assets) {
        if (asset.priceChangeMap) {
          asset.priceChangePercentage = asset.priceChangeMap.get(this.currnetPeriodTime);
          asset.coinMarketChangeStatus = asset.priceChangePercentage < 0.00000 ? CoinMarketStatus.Negative : CoinMarketStatus.Positive;
        }
      }
    }

    let changeArray = this.assets.filter(x => x.priceChangePercentage != null).map(x => x.priceChangePercentage);
    if (changeArray.length > 0) {
      this.currentPricePercentage = this._coinMarketService.getAverage(changeArray);
      this._setCurrentPercentageClass();
    }
  }

  private _setCurrentPercentageClass() {
    this.currentPercentageTypeClass = this.currentPricePercentage < 0.00000 ? 'fas fa-chevron-circle-down' : 'fas fa-chevron-circle-up';
  }

  private _initialSettings() {
    this.currency = this._coinMarketService.getCurrentCurrency();
    this._isViewdTour = this._settingService.get(ProkeySettingsKey.IS_VIEWED_TOUR);
  }

  private _initialTour(hasChart: boolean) {
    this._tourService.initialize(hasChart);
  }

  private _startTour() {
    if (!this._isViewdTour) {
      this._tourService.start();
      this._startedTour = true;
    }
  }

  hasBalancePrice(asset: AssetInfo) {
    if (asset.totalBalancePrice || asset.totalBalancePrice == 0) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.changeAssetSubscription.unsubscribe();
    this.reDiscoverySubscription.unsubscribe();
    this.discoveryFinishedSubscription.unsubscribe();
  }
}
