import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressPathIndex'
})
export class AddressPathIndexPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!value) {
      return '';
    }
    let arrPath: string[] = [];
    arrPath = value.split('/');
    if (arrPath.length > 0) {
      return arrPath[arrPath.length - 1];
    }
    return '';
  }

}
