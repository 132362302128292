import { CoinModel } from "./coin-model";

export enum CoinMarketStatus {
    Positive = 'Positive',
    Negative = 'Negative',
}

export interface AssetInfo {
    coin: CoinModel;
    coinId: string; // use for coin market
    totalBalance?: number;
    totalBalancePrice?: number;
    coinPrice?: number;
    coinMarketChangeStatus?: CoinMarketStatus;
    priceChangePercentage?: number;
    priceChangeMap: Map<string, number>;
    isDiscoveryFinished: boolean;
    marketPrices?: any[];
    onChart: boolean;
    isDisconnected: boolean;
    isLoadedPrice: boolean;
}
