import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WalletLayoutComponent} from '@core/component/wallet-layout/wallet-layout.component';
import {WalletSettingsComponent} from './settings/wallet-settings/wallet-settings.component';
import {DeviceComponent} from './device/device/device.component';
import {BackupComponent} from './backup/backup.component';
import {TransactionsComponent} from './device/transactions/transactions.component';
import {SendComponent} from './device/send/send.component';
import {ReceiveComponent} from './device/receive/receive.component';
import {SignVerifyComponent} from './device/sign-verify/sign-verify.component';
import {UserSettingsComponent} from './settings/user-settings/user-settings.component';
import {ExchangeComponent} from './device/exchange/components/compare-coin/exchange.component';
import {ReceiveExchangeComponent} from './device/exchange/components/receive-exchange/receive-exchange.component';
import {StatusExchangeComponent} from './device/exchange/components/status-exchange/status-exchange.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import {StorageGuard} from "./route-active/storage.guard";

const routes: Routes = [
  {
    path: 'wallet',
    component: WalletLayoutComponent,
    canActivate: [StorageGuard],
    children: [
      {
        path: "portfolio",
        component: PortfolioComponent,
      },
      {
        path: "device",
        component: DeviceComponent,
      },
      {
        path: "transactions",
        component: TransactionsComponent
      },
      {
        path: "send",
        component: SendComponent
      },
      {
        path: "receive",
        component: ReceiveComponent
      },
      {
        path: "sign-message",
        component: SignVerifyComponent
      },
      {
        path: "settings",
        component: WalletSettingsComponent,
      },
      {
        path: "user-settings",
        component: UserSettingsComponent,
      },
      {
        path: "backup",
        component: BackupComponent,
      },
      {
        path: "exchange",
        component: ExchangeComponent
      },
      {
        path: "exchange-receive",
        component: ReceiveExchangeComponent
      },
      {
        path: 'exchange-status',
        component: StatusExchangeComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WalletRoutingModule { }
