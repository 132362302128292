<div class="passphrase-request-container">
  <h5 class="body-title text-center">
    Set Prokey Passphrase
  </h5>
  <form #form="ngForm" (submit)="enterPassphrase()" novalidate>
    <div class="form-group text-left mb-1 mt-5">
      <label for="passphrase" class="mb-1 d-flex">
        Passphrase
        <ng-container *ngIf="passphrase.invalid">
          <span class="text-danger ml-auto small" *ngIf="passphrase.errors.maxlenght">
            <i class="fas fa-info-circle"></i> Maximum passphrase lenght is 50 characters.
          </span>
        </ng-container>
      </label>
      <div class="input-groupp relative">
        <input type="password" class="form-control passphrase" tabindex="1" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
               name="passphrase" [(ngModel)]="model.passphrase" #passphrase="ngModel"
               appValidateEqual compare-to="confirmPassphrase" maxlength="50" />
        <a href="javascript:void(0)" class="view-password" [class.text-primary]="showPass" (click)="changeType()">
          <i class="fa fa-eye"></i>
        </a>
      </div>
    </div>
    <div class="form-group text-left mb-1">
      <label for="confirm-passphrase" class="mb-1 d-flex">
        Confirm passphrase
        <ng-container *ngIf="confirmPassphrase.invalid">
          <span class="text-danger ml-auto small" *ngIf="confirmPassphrase.errors.appValidateEqual">
            <i class="fas fa-info-circle"></i> Passphrase mismatch.
          </span>
          <span class="text-danger ml-auto small" *ngIf="confirmPassphrase.errors.maxlenght">
            <i class="fas fa-info-circle"></i> Maximum passphrase lenght is 50 characters.
          </span>
        </ng-container>
      </label>
      <input type="password" class="form-control confirmPassphrase" tabindex="2" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
             name="confirmPassphrase" [(ngModel)]="model.confirmPassphrase" #confirmPassphrase="ngModel"
             appValidateEqual compare-to="passphrase" maxlength="50" />
    </div>
    <button type="submit" class="btn btn-gradinet-blue pnup waves-effect waves-dark d-block mt-3" style="width:100%" [disabled]="form.invalid">
      Enter
    </button>
  </form>
  <a href="https://support.prokey.io/help-center/articles/3/9/9/passphrases-your-extra-security-feature" target="_blank" class="text-right d-block text-muted" style="font-size:13px">What is Passphrase?</a>
  <span class="text-center text-success d-block mt-5">
    To access your default wallet, leave your passphrase blank.
  </span>
</div>
