import {Component, OnInit} from '@angular/core';
import {Exchange} from '../../../wallet/device/exchange/models/exchange';
import {ExchangeService} from '../../../wallet/device/exchange/services/exchange.service';
import {WalletService} from '../../services/wallet.service';
import {Subscription} from 'rxjs';
import {AccountInfo} from '../../models/connect/account-info';
import {Router} from '@angular/router';
import {WalletDeviceService} from "../../services/wallet-device.service";

@Component({
  selector: 'app-exchange-layout',
  templateUrl: './exchange-layout.component.html',
  styleUrls: ['./exchange-layout.component.css']
})
export class ExchangeLayoutComponent implements OnInit {
  accountSubscription: Subscription;
  exchange: Exchange;
  currentAccount: AccountInfo = <AccountInfo>{};

  constructor(
    private _walletService: WalletService,
    private _deviceService: WalletDeviceService,
    private exchangeService: ExchangeService,
    private route: Router
  ) {
  }

  async ngOnInit() {
    if (!this._deviceService.isInitial()) {
      this.route.navigate(['/wallet/device']);
    }
    this.exchangeSubscribe();
    this.subscribeAccount();
  }

  private async exchangeSubscribe() {
    this.exchangeService.currentOffer.subscribe(async exchange => {
      this.exchange = exchange;
    });
  }

  subscribeAccount() {
    this.accountSubscription = this._walletService.onCurrentAccountChange.subscribe(currentAccount => {
      this.currentAccount = currentAccount;
    });
  }
}
