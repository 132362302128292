<h6>Accounts</h6>
<ng-container *ngIf="sidebarMode !== 'Disconnected'">
  <ng-container *ngIf="accountList.length > 0">
    <ul class="accounts-menu">
      <li *ngFor="let account of accountList" class="sidebar-link-item">
        <a href="javascript:;" (click)="changeAccount_OnClick(account)" [class.active]="account.id === selectedAccount.id">
          <i class="crypto crypto-{{coin.coinInfo.shortcut | lowercase}}"></i>
          <h4>{{account.label}}</h4>
          <small>{{coin.coinInfo.shortcut}}&nbsp;&nbsp;{{account.balancePrice | currency: currency:'symbol'}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{account.balance | unit: coin.coinInfo.decimals | number: '1.6-8'}}</small>
        </a>
      </li>
    </ul>
    <ng-container *ngIf="sidebarMode === 'LazyLoading'">
      <i class="fa fa-spinner fa-spin text-muted"></i>
    </ng-container>
    <ng-container *ngIf="canAddAccount then enableAddTemplate else disableAddTemplate"></ng-container>
    <ng-template #enableAddTemplate>
      <a href="javascript:void(0)" (click)="addAccount_OnClick()" title="add account" class="btn btn-add waves-effect waves-dark">
        <i class="fa fa-plus-circle"></i>
        Add Account
      </a>
    </ng-template>
    <ng-template #disableAddTemplate>
      <ul>
        <li class="btnDisabled">
          <a href="javascript:void(0)" class="btn btn-add waves-effect waves-dark" title="To add a new account, the previous account must have some transactions.">
            <i class="fa fa-plus-circle"></i>
            Add Account
          </a>
        </li>
      </ul>
    </ng-template>
  </ng-container>
</ng-container>
