import {Injectable} from '@angular/core';
import {ProkeySettings} from "@core/models/prokey-settings";
import {ProkeySettingsKey} from "@core/models/prokey-settings-key.enum";
import {first} from "rxjs/operators";
import {WalletDeviceService} from "./wallet-device.service";
import {StorageService} from "@core/services/storage/storage.service";
import {BaseRepository} from "@core/services/storage/repository/base-repository";
import {LocalStorageService} from "@core/services/storage/repository/local-storage-service";
import {settings} from "cluster";

/** this service is created to handle prokey settings with localstorage. */
@Injectable({
  providedIn: 'root'
})
export class ProkeySettingsService {

  /** key for storing settings in localstorage. */
  private static readonly _prokeySettingsKey = 'PROKEY_SETTINGS';
  private static readonly _prokeySyncKey = 'PROKEY_SYNC';
  /** prokey settings */
  private _settings;
  /** the key where settings are stored in localstorage. */
  private _storageKey: string;
  private _deviceId: string;

  constructor(private _localStorage: LocalStorageService,
              private _storageService: StorageService,
              private _deviceService: WalletDeviceService) {}

  /** it will initial settings. */
  async initialize(): Promise<void> {
    let device = await this._deviceService.device$
      .pipe(first()).toPromise(); // it will unsubscribe device$ after first value is emitted.

    try {
      if (device.features) {
        this._deviceId = device.features.device_id;
      } else {
        window.location.href = '/start';
      }
    } catch {
      window.location.href = '/start';
    }

    this._storageKey = ProkeySettingsService._prokeySettingsKey + '_' + this._deviceId;
    let syncData = this.retrieveSync() ?? undefined;
    if (syncData) {
      this._settings = syncData;
      await this._getBaseRepository().save(this._storageKey, syncData);
      this.deleteSync();
    } else {
      let prokeySettings = await this._getBaseRepository().get<ProkeySettings>(this._storageKey);
      this._settings = prokeySettings ?? {};
    }
  }

  isInitialized() {
    return !!(this._storageKey && this._settings);
  }

  /** it gives you a certain field in settings. */
  get(key: ProkeySettingsKey) {
    return this._settings?.[key];
  }

  /** it will return you all settings. */
  getAll() {
    return {...this._settings};
  }

  /** it will change settings and store it in localstorage. */
  async save(key: ProkeySettingsKey, value: any): Promise<void> {
    if (key && value) {
      this._settings[key] = value;
      await this._getBaseRepository().save(this._storageKey, this._settings);
    } else {
      console.error('saving settings failed !')
    }
  }

  /** it will patch a part to settings.  */
  async patch(settings: Partial<ProkeySettings>): Promise<void> {
    if (this._settings && settings) {
      Object.assign(this._settings, settings);
      await this._getBaseRepository().save(this._storageKey, this._settings);
    }
  }

  /** it will delete a fields record. */
  delete(key: ProkeySettingsKey) {
    if (this._settings[key]) {
      this.save(key, undefined);
    }
  }

  async sync() {
    let localStorageSettings = this._localStorage.normalGet<ProkeySettings>(this._storageKey);
    await this._storageService.getCurrentStorage(this._deviceId).save<ProkeySettings>(this._storageKey, localStorageSettings);
    this._settings = localStorageSettings;
  }

  deleteSync() {
    this._localStorage.delete(ProkeySettingsService._prokeySyncKey + '_' + this._deviceId);
  }

  retrieveSync(): ProkeySettings {
    return this._localStorage.normalGet<ProkeySettings>(ProkeySettingsService._prokeySyncKey + '_' + this._deviceId);
  }

  private _getBaseRepository(): BaseRepository {
    return this._storageService.getCurrentStorage(this._deviceId);
  }
}

