import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ProkeySettingsService} from "@core/services/prokey-settings.service";
import {StorageService} from "@core/services/storage/storage.service";
import {Router} from "@angular/router";
import {DialogService} from "@shared/dialog/_services/dialog.service";

@Component({
  selector: 'app-storage-sync-dialog',
  templateUrl: './storage-sync-dialog.component.html',
  styleUrls: ['./storage-sync-dialog.component.css']
})
export class StorageSyncDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private _settingService: ProkeySettingsService,
              private _dialogService: DialogService,
              private _route: Router,
              private _storageService: StorageService) { }

  async sync() {
    // await this._settingService.sync();
    this._settingService.sync().then(() => this._route.navigate(['/']));
  }

  cancel() {
    this._dialogService.closeActiveModal();
  }
}
