import {Component, OnDestroy, OnInit} from '@angular/core';
import {WalletService} from '../../services/wallet.service';
import {Subject, Subscription} from 'rxjs';
import {CoinModel} from '@core/models/connect/coin-model';
import {debounceTime} from "rxjs/operators";
import {Erc20BaseCoinInfoModel} from "@webcore/models/CoinInfoModel";
import {ProkeySettingsService} from "../../services/prokey-settings.service";
import {ProkeySettingsKey} from "@core/models/prokey-settings-key.enum";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import { LayoutService } from '@core/services/layout.service';
import { LayoutMode } from '@core/models/layout-mode';

@Component({
  selector: 'app-wallet-add-coin',
  templateUrl: './wallet-add-coin.component.html',
  styleUrls: ['./wallet-add-coin.component.css']
})
export class WalletAddCoinComponent implements OnInit, OnDestroy {

  status: ModalStatus = ModalStatus.ADD_NEW_COIN;
  otherCoinSubscription: Subscription;
  coins: CoinModel[] = [];
  myFamousCoins: string[] = [];
  myAssetCoins: string[] = [];
  searchSubject: Subject<string> = new Subject<string>();
  searchCoins: Array<CoinModel>;
  searchText: string;

  constructor(private walletService: WalletService,
              private _prokeySettingsService: ProkeySettingsService,
              private _layoutService: LayoutService,
              private _dialogService: DialogService) {
  }

  ngOnInit() {

    if (this._layoutService.Layout === LayoutMode.Portfolio) {
      this.status = ModalStatus.ADD_ASSET_COIN;
    }

    switch (this.status) {
      case ModalStatus.ADD_NEW_COIN:
        this._initNewCoinStatus();
        break;
      case ModalStatus.ADD_ASSET_COIN:
        this._initAssetCoinStatus();
        break;
      default:
        break;
    }

    this.searchSubject.pipe(debounceTime(500)).subscribe(searchValue => {
      if (searchValue) {
        this.searchCoins = this.coins.filter(
          coin => coin.coinInfo.shortcut.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            coin.coinInfo.name.toLowerCase().startsWith(searchValue.toLowerCase())
        );
      } else {
        this.searchCoins = undefined;
      }
    });
  }

  subscribeOtherCoins() {
    this.otherCoinSubscription = this.walletService.WalletCoins.onOtherCoinsChange.subscribe(coins => {
      coins.forEach(coin => this.coins.push(coin));

      this.coins.forEach(coin => {
        coin.isSelected = this.myFamousCoins?.includes(coin.coinInfo.id) ?? false;
      });
    });
  }

  apply() {
    const selectedCoins = this.coins.filter(c => c.isSelected === true);
    const myCoins = selectedCoins.length > 0 ? selectedCoins.map(c => c.coinInfo.id) : [];

    switch (this.status) {
      case ModalStatus.ADD_NEW_COIN:
        this._addToWallet(myCoins);
        break;
      case ModalStatus.ADD_ASSET_COIN:
        this._addToAsset(myCoins);
        break;
      default:
        break;
    }

    this._dialogService.closeAll();
  }

  private _initNewCoinStatus() {
    this.myFamousCoins = this._prokeySettingsService.get(ProkeySettingsKey.FAMOUS_COINS);
    this.subscribeOtherCoins();
  }

  private _initAssetCoinStatus() {
    this.myAssetCoins = this._prokeySettingsService.get(ProkeySettingsKey.ASSET_COINS);
    this.coins = this.walletService.WalletCoins.getCoins();
    this.coins.forEach(coin => {
      coin.isSelected = this.myAssetCoins?.includes(coin.coinInfo.id) ?? false;
    });
  }

  private _addToWallet(myCoins: string[]) {
    this._prokeySettingsService.save(ProkeySettingsKey.FAMOUS_COINS, myCoins);
    this.walletService.changeMyFamousCoinsSource();
  }

  private _addToAsset(myCoins: string[]) {
    this._prokeySettingsService.save(ProkeySettingsKey.ASSET_COINS, myCoins);
    this._syncAssetToFamousCoin(myCoins);
    this.walletService.WalletCoins.changeAssetCoinsSource();
  }

  private _syncAssetToFamousCoin(assetCoins: string[]) {
    let fmCoins : string[] = this._prokeySettingsService.get(ProkeySettingsKey.FAMOUS_COINS);
    if (fmCoins == undefined) {
      fmCoins = [];
    }
    
    assetCoins.forEach(asset =>  {
      if (!fmCoins?.includes(asset)) {
        fmCoins.push(asset);
      }
    });

    this._addToWallet(fmCoins);    
  }

  ngOnDestroy() {
    if (this.otherCoinSubscription) {
      this.otherCoinSubscription.unsubscribe();
    }

    this.searchCoins = undefined;
    this.searchText = undefined;
  }

  addCustomToken() {
    this.status = ModalStatus.ADD_CUSTOM_TOKEN;
  }

  onSearchKeyUp() {
    this.searchSubject.next(this.searchText);
  }

  isSearchHasNotResult() {
    return this.searchCoins && this.searchCoins.length === 0;
  }

  close() {
    this._dialogService.closeAll();
    this.searchCoins = undefined;
    this.searchText = undefined;
    this.status = ModalStatus.ADD_NEW_COIN;
  }

  async addCustomTokenEvent(coin: Erc20BaseCoinInfoModel) {
    const addedCoinModel = new CoinModel(coin);
    this.walletService.WalletCoins.addOtherCoins([addedCoinModel], true);
    const selectedCoins = this.coins.filter(c => c.isSelected === true);
    const myCoins = selectedCoins.map(c => c.coinInfo.id);
    myCoins.push(addedCoinModel.coinInfo.id);
    this._prokeySettingsService.save(ProkeySettingsKey.FAMOUS_COINS, myCoins);
    this.walletService.changeMyFamousCoinsSource();
    this.close();
  }
}


enum ModalStatus {
  ADD_NEW_COIN,
  ADD_CUSTOM_TOKEN,
  ADD_ASSET_COIN,
}
