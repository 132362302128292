import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numToOrdinary'
})
export class NumToOrdinaryPipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if(value == 11 || value == 12)
      return `${value}th`;

    let firstDigit = value % 10;
    switch(firstDigit){
      case 1:
        return `${value}st`;
      case 2:
        return `${value}nd`;
      default:
        return `${value}th`;
    }
  }

}
