import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {Router} from '@angular/router';

import {Helpers} from '../../services/helpers';

import {DeviceMode} from '@core/models/connect/device-mode.enum';
import {WalletDeviceService} from "../../services/wallet-device.service";

@Component({
  selector: 'app-wallet-device-info',
  templateUrl: './wallet-device-info.component.html',
  styleUrls: ['./wallet-device-info.component.css']
})
export class WalletDeviceInfoComponent implements OnInit, OnDestroy {

  deviceSubscription: Subscription;
  isConnected: boolean = false;
  label: string = '';

  constructor(private router: Router, private _deviceService: WalletDeviceService) {
  }

  ngOnInit() {
    this.deviceSubscribe();
  }

  deviceSubscribe() {
    this.deviceSubscription = this._deviceService.device$.subscribe(device => {
      if (device.label !== undefined && device.label !== null && device.label.trim() !== '') {
        this.label = device.label;
      }
      if (device.isConnected === true) {
        this.isConnected = true;
      } else if (device.isConnected === false) {
        this.isConnected = false;
      } else if (device.mode === DeviceMode.Normal && this.isConnected == false) {
        this.isConnected = true;
      }

      if (device.mode === DeviceMode.Initialize) {
        let walletTimer = timer(5000);
        walletTimer.subscribe(() => {
          Helpers.hideModals();
          this.router.navigate(['/welcome']);
        });
      }
    });
  }

  ngOnDestroy() {
    this.deviceSubscription.unsubscribe();
  }

}
