import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPaginationModule } from 'ngx-pagination';

import { WalletRoutingModule } from './wallet-routing.module';
import { WalletSettingsComponent } from './settings/wallet-settings/wallet-settings.component';
import { BasicSettingsComponent } from './settings/basic-settings/basic-settings.component';
import { AdvanceSettingsComponent } from './settings/advance-settings/advance-settings.component';
import { TransactionsComponent } from './device/transactions/transactions.component';
import { SendComponent } from './device/send/send.component';
import { ReceiveComponent } from './device/receive/receive.component';
import { SignVerifyComponent } from './device/sign-verify/sign-verify.component';
import { DeviceComponent } from './device/device/device.component';
import { SharedModule } from '@shared/shared.module';
import { BackupComponent } from './backup/backup.component';
import { UserSettingsComponent } from './settings/user-settings/user-settings.component';
import { ExchangeComponent } from './device/exchange/components/compare-coin/exchange.component';
import { SendCoinComponent } from './device/exchange/components/send-coin/send-coin.component';
import { ReceiveCoinComponent } from './device/exchange/components/receive-coin/receive-coin.component';
import { OfferListComponent } from './device/exchange/components/offer-list/offer-list.component';
import { ReceiveExchangeComponent } from './device/exchange/components/receive-exchange/receive-exchange.component';
import { StatusExchangeComponent } from './device/exchange/components/status-exchange/status-exchange.component';
import {CoinFeeComponent} from './device/coin-fee/coin-fee.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {CoreModule} from '@core/core.module';
import { WipeDeviceDialogComponent } from './settings/wipe-device-dialog/wipe-device-dialog.component';
import { ResetPassphraseDialogComponent } from './settings/reset-passphrase-dialog/reset-passphrase-dialog.component';
import { ProcessingDialogComponent } from './device/processing-dialog/processing-dialog.component';
import {DialogModule} from "@shared/dialog/dialog.module";
import {MatButtonModule} from "@angular/material/button";
import { PortfolioComponent } from './portfolio/portfolio.component';
import { StorageAuthDialogComponent } from './settings/storage-auth-dialog/storage-auth-dialog.component';
import { StorageSyncDialogComponent } from './settings/storage-sync-dialog/storage-sync-dialog.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { GoogleAuthInsufficientScopeDialogComponent } from './settings/google-auth-insuffient-scope-dialig/google-auth-insufficient-scope-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WalletRoutingModule,
    NgxSkeletonLoaderModule.forRoot(),
    QRCodeModule,
    NgxPaginationModule,
    SharedModule,
    CoreModule,
    DialogModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatIconModule,
    TourMatMenuModule,
  ],
  declarations: [
    WalletSettingsComponent,
    BasicSettingsComponent,
    AdvanceSettingsComponent,
    TransactionsComponent,
    SendComponent,
    ReceiveComponent,
    SignVerifyComponent,
    DeviceComponent,
    BackupComponent,
    UserSettingsComponent,
    ExchangeComponent,
    SendCoinComponent,
    ReceiveCoinComponent,
    OfferListComponent,
    ReceiveExchangeComponent,
    StatusExchangeComponent,
    CoinFeeComponent,
    WipeDeviceDialogComponent,
    ResetPassphraseDialogComponent,
    ProcessingDialogComponent,
    PortfolioComponent,
    StorageAuthDialogComponent,
    StorageSyncDialogComponent,
    GoogleAuthInsufficientScopeDialogComponent
  ]
})
export class WalletModule { }
