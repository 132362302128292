import {CoinModel} from "@core/models/connect/coin-model";
import {ExtraId} from "@core/models/connect/extra-id";
import {Device} from "@webcore/device/Device";
import {AddressModel} from "@webcore/models/Prokey";
import {BaseWallet} from "@webcore/wallet/BaseWallet";
import * as PathUtil from "@webcore/utils/pathUtils";
import {AccountBrief} from "@core/models/connect/account-brief";
import {AccountInfo} from "@core/models/connect/account-info";
import {BaseOutput} from "@core/models/connect/base-output";
import {Transaction} from "@core/models/connect/transaction";
import * as GenericWalletModel from "@webcore/models/GenericWalletModel";

export abstract class Wallet {

  _device: Device;
  _coinModel: CoinModel;
  _webcoreBaseWallet : BaseWallet;
  _currentAccountIndex: number = 0;
  _isNewAccountAlreadyAdded = false;
  _isAccountDiscoveryFinished = false;
  protected _totalBalance?: number;
  protected _needToReSync: boolean = false;

  constructor(device: Device, coinModel: CoinModel) {
    this._device = device;
    this._coinModel = coinModel;
  }

  /**
   * Each wallet can have several accounts. This function will return the current account index
   */
  get CurrentAccountIndex(): number {
    return this._currentAccountIndex;
  }

  /**
   * To check if account discovery finished
   */
  get IsAccountDiscoveryFinished(): boolean {
    return this._isAccountDiscoveryFinished;
  }

  get CoinModel(): CoinModel {
    return this._coinModel;
  }

  get CoinInfo() {
    return this._webcoreBaseWallet.GetCoinInfo();
  }

  get TotalBalance(): number | null {
    return this._totalBalance;
  }

  get NeedToReSync(): boolean {
    return this._needToReSync;
  }

  set NeedToReSync(needToReSync: boolean) {
    this._needToReSync = needToReSync;
  }

  getCoinExtraIdTypes(): Array<ExtraId> {
    return null;
  }

  isAddressValid(address: string): boolean {
    return this._webcoreBaseWallet.IsAddressValid(address);
  }

  /**
   * This function will return the first path of the first account
   * @returns The path of Account 0 Path 0
   */
  abstract getPathOfFirstAddress(account: number): AddressModel;

  /**
   * To get Public Key
   * @param account Account number
   * @param showOnProkey True means show the public key on the device
   */
  abstract getPublicKey(account: number, showOnProkey: boolean): Promise<any>;

  /**
   * To get Address
   * @param path BIP 32 based path
   * @param showOnProkey True means show the address on the device
   */
  abstract getAddress(path: Array<number>, showOnProkey: boolean): Promise<any>;

  abstract getAddresses(accountInfo: AccountInfo): Promise<any>;

  /**
   * Get count of accounts, -1 means no account at all
   * @returns
   */
  abstract get AccountsCount(): number;

  /**
   * Delete previous account data and ...
   * This function should be called when user wants to refresh the wallet
   */
  abstract ClearCache(): void;

  /**
   * Start discovering wallet accounts
   * To get account and wallet info, you need to call getAccountInfo
   * If all accounts have been already discovered, this function will resolve using previous data immadiatly
   * To have a refresh account discovery, Call ClearCache first
   * @param updateCallBack Inform upper later that an account has discovered.
   * @returns Promise which will be resolved at the end of account discovery process
   */
  abstract StartAccountDiscovery(onNewAccountCallBack: (discoveredAccountNumber: number) => void): Promise<void>;

  /**
   * To ask if a new account can be added to list of current accounts
   * IMPORTANT: To get the currect response, Call this function after account discovery finished
   * @returns True means the "addNewAccount" func can be called in order to add a new (virtual) account
   */
  abstract canAddNewAccount(): boolean;

  /**
   * Add new account, Call "canAddNewAccount" first
   * DO NOT CALL THIS FUNCTION DIRECTLY IF YOU WANT EVENTS RISE AFTER ADDING NEW ACCOUNT, So call it through wallet service
   * @returns New account index. -1 means fail
   */
  abstract _addNewAccount(): Promise<number>;

  //TODO: instead of any, we better to return exact type
  abstract getWebcoreAccountInfo(accountIndex: number): any;

  abstract getAccountBrief(accountIndex: number): AccountBrief;

  abstract getAccountInfo(accountIndex?: number): AccountInfo;

  /**
   * This method will return all discovered accounts info.
   * @returns Array<AccountInfo> an array of accounts info.
   */
  abstract getAllAccountsInfo?(): AccountInfo[];

  /**
   * Set the current account
   * @param accountIndex
   */
  abstract setCurrentAccount(accountIndex: number): void;

  abstract signMessage(path: Array<number>, message: string): Promise<any>;

  abstract verifyMessage(address: string, message: string, signature: string): Promise<any>;

  abstract prepareSendModel(output: BaseOutput, ...params: any[]): any;

  abstract sendTransaction(model: any): Promise<GenericWalletModel.GenericSentTransactionResult>;

  abstract calculateTransactionFee(outputValue: BaseOutput, fromAccount: number): any;

  abstract getAccountTransactionsBy(accountNumber: number, startIndex: number, numberOfTransactions: number): Promise<Transaction[]>;
}
