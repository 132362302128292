import { Injectable } from '@angular/core';
import { BridgeService } from './bridge.service';
import { WebusbService } from './webusb.service';
import { ChallengeMessage } from '../../models/bootloader/ChallengeMessage';
import { TransportInterface } from '../../models/bootloader/transportInterface';
import { ChallengeErrorCode } from '../../models/bootloader/ChallengeErrors';

@Injectable()
export class TransportService {

  _transport: TransportInterface = null;
  //_instance: TransportService = null;

  constructor(private bridge: BridgeService, private webusb: WebusbService) {
  }

  public Get(): TransportInterface {
    return this._transport;
  }

  public async Init(): Promise<ChallengeMessage> {
    if (this._transport == null) {
      //! Checking the existance of Bridge
      //var res = await this.bridge.GetVersion();
      //if (res.success) {
        //this._transport = this.bridge;
        //return { success: true, payload: this.bridge };
      //}

      //! Checking the existance of WebUSB
      var res = await this.webusb.GetVersion();
      if (res.success) {
        this._transport = this.webusb;
        return { success: true, payload: this.webusb };
      }

      return { success: false, errorCode: ChallengeErrorCode.NO_TRANSPORT };
    }

    return { success: true, payload: this._transport };
  }
}
