<div class="pin-request-container">
  <div class="easy-numpad-frame easy-numpad-frame2" id="easy-numpad-frame">
    <div id="pin" style="width:100%" class="d-block">
      <div class="easy-numpad-container fade show">
        <div class="numpad-inner text-center">
          <h6>Enter Prokey PIN</h6>
          <span>The PIN layout is displayed on your device</span>
          <div class="easy-numpad-output-container">
            <input type="password" class="easy-numpad-output" autocomplete="off" max-length="9"
                   [(ngModel)]="model.pin" name="pin" #pin="ngModel" disabled />
            <a href="javascript:void(0)" class="numpad-del" (click)="backspacePin()"><i class="fa fa-times"></i></a>
          </div>
          <div class="easy-numpad-number-container">
            <table dir="ltr">
              <tbody>
                <tr>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(7)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(8)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(9)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                </tr>
                <tr>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(4)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(5)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(6)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                </tr>
                <tr>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(1)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(2)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                  <td><a class="waves-effect waves-light" href="javascript:void(0)" (click)="addPin(3)"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><g id="Ellipse_13" data-name="Ellipse 13" fill="#6c6767" stroke="#707070" stroke-width="1"><circle cx="6" cy="6" r="6" stroke="none"></circle></g></svg></a></td>
                </tr>
                <tr>
                  <td colspan="3"><button type="button" class="btn btn-block btn-gradinet-blue pnup waves-effect waves-dark" [disabled]="submitDisabled" (click)="submitPin()">Enter PIN</button></td>
                </tr>
                <tr>
                  <td colspan="3" class="text-right"><a href="https://youtu.be/BCWC586aXPI" target="_blank" class="how-pin">How PIN works?</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
