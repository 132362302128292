<div id="container">
  <div class="overlay"></div>
  <div class="logo404">
    <a href="https://prokey.io">
      <img src="https://statics.prokey.io/assets/img/logo-hr-blue.svg" title="Prokey" alt="Prokey Hardware Wallet" width="132px" height="60px">
    </a>
  </div>
  <div class="item-title">
    <div class="row">
      <div class="col-md-5">
        <div id="message">
          <p>NOT FOUND<br>ERROR 404</p>
          <p>You may have mis-typed the URL.</p>
          <p>Or the page has been removed.</p>
          <p>Actually, there is nothing to see here...</p>
          <p>Here are some helpful links instead:</p>
        </div>
        <div class="link-bottom">
          <a class="link-icon" href="https://wallet.prokey.io"><i class="icon ion-ios-home"></i> Wallet</a>
          <a class="link-icon" href="https://prokey.io/Shop"><i class="icon ion-ios-cart"></i> Shop</a>
          <a class="link-icon" href="https://support.prokey.io/"><i class="icon ion-ios-compose"></i> Support</a>
        </div>
      </div>
      <div class="col-md-7">
        <div class="illustration">
          <div class="boat"></div>
          <div class="water1"></div>
          <div class="water2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
