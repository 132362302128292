<div class="processing-dialog">
  <ng-container>
    <h5 class="body-title text-left">
      Sync from local storage
    </h5>
    <p class="pt-1 pb-1">
      Do you want to sync your data from local storage to your current storage?
    </p>
    <h5 class="alert alert-warning">
      Important: this action may cause you lost your configuration
    </h5>
    <button type="button" class="btn btn-gradinet-green waves-effect waves-dark mr-2" style="top:-5px;" (click)="sync()">Sync</button>
    <button type="button" class="btn btn-gradinet-blue waves-effect waves-dark" style="top:-5px;"(click)="cancel()">Cancel</button>
  </ng-container>
</div>
