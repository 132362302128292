import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {DeviceMode} from '@core/models/connect/device-mode.enum';
import {ButtonRequestType} from '@core/models/connect/button-request-type.enum';
import {UiEvent} from '@core/models/connect/ui-event.enum';
import {WalletDeviceService} from "../core/services/wallet-device.service";

enum welcomeMode {
  Initialize = 'Initialize',
  ConfirmAction = 'ConfirmAction',
  Preparing = 'Preparing',
  Recover = 'Recover',
}

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  deviceSubscription: Subscription;
  uiButtonSubscription: Subscription;
  uiAlertSubscription: Subscription;

  uiMode: welcomeMode = welcomeMode.Initialize;
  deviceMode: DeviceMode = DeviceMode.Initialize;
  isUnsuccessfulPreparing: boolean = false;
  errorMessage: string = '';

  constructor(private router: Router, private _deviceService: WalletDeviceService) {
  }

  async ngOnInit() {
    if (!this._deviceService.isInitial()) {
      await this._deviceService.init();
    }
    this.deviceSubscribe();
  }

  deviceSubscribe() {
    this.deviceSubscription = this._deviceService.device$.subscribe(device => {
      this.deviceMode = device.mode;
      if (device.isConnected === false) {
        this._deviceService.handleDisconnectedDevice();
      }
      if (this.deviceMode === DeviceMode.Normal) {
        this.router.navigate(['/wallet/portfolio']);
      }
    });
  }

  createWallet() {
    this.uiMode = welcomeMode.Preparing;
    this.uiButtonSubscribe();
    this.uiAlertSubscribe();
    this._deviceService.resetDevice().then(result => {
      if (result.message) {
        this.callDevice();
      } else {
        this.isUnsuccessfulPreparing = true;
        this.errorMessage = 'Please try a again.';
      }
    });
  }

  uiButtonSubscribe() {
    this._deviceService.DisableButtonRequestDialog = true;
    this.uiButtonSubscription = this._deviceService.uiButton$.subscribe(code => {
      if (ButtonRequestType.ButtonRequest_ProtectCall == code) {
        this.uiMode = welcomeMode.ConfirmAction;
      }
    });
  }

  uiAlertSubscribe() {
    this.uiAlertSubscription = this._deviceService.uiAlert$.subscribe(uiEvent => {
      if (uiEvent == UiEvent.Close_Window) {
        this.uiMode = welcomeMode.Preparing;
      }
    });
  }

  callDevice() {
    this._deviceService.initializeDevice();
  }

  ngOnDestroy() {
    this.deviceSubscription.unsubscribe();
    if (this.uiButtonSubscription) {
      this.uiButtonSubscription.unsubscribe();
    }
  }

}
