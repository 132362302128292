import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';
import {TourMatMenuModule} from 'ngx-ui-tour-md-menu';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {WalletModule} from './wallet/wallet.module';
import {StartComponent} from './start/start.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {WelcomeComponent} from './welcome/welcome.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OAuthModule, OAuthStorage} from "angular-oauth2-oidc";

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    PageNotFoundComponent,
    WelcomeComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    CoreModule,
    WalletModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    TourMatMenuModule.forRoot(),
    SharedModule.forRoot(),
    RouterModule.forRoot([
        {path: 'firmware', loadChildren: () => import('app/bootloader/bootloader.module').then(m => m.BootloaderModule)},
        {path: 'start', component: StartComponent},
        {path: 'welcome', component: WelcomeComponent},
        {path: '', component: StartComponent, pathMatch: 'full'},
        {path: '**', component: PageNotFoundComponent},
      ], {relativeLinkResolution: 'legacy'}),
    ReactiveFormsModule,
    OAuthModule.forRoot()
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
