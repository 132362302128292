import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ExchangeService} from '../../services/exchange.service';
import {Exchange} from '../../models/exchange';
import {WalletService} from '@core/services/wallet.service';
import {BaseOutput} from '@core/models/connect/base-output';
import {Helpers} from '@core/services/helpers';
import {Router} from '@angular/router';
import {ExchangeTransaction} from '../../models/exchange-transaction';
import {AccountInfo} from '@core/models/connect/account-info';
import {Subscription} from 'rxjs';
import {CoinModel} from '@core/models/connect/coin-model';
import {SelectFeeLevel} from '@core/models/connect/select-fee-level';
import {BitcoinFeeSelectionModel} from '@webcore/models/FeeSelectionModel';
import {ButtonRequestType} from '@core/models/connect/button-request-type.enum';
import {MyConsole} from '@webcore/utils/console';
import {SendStep} from '../../../models/send-step.enum';
import {WalletDeviceService} from "@core/services/wallet-device.service";
import {ProcessingDialogComponent} from "../../../processing-dialog/processing-dialog.component";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import {Wallet} from "@core/services/coins/wallet";

@Component({
  selector: 'app-receive-exchange',
  templateUrl: './receive-exchange.component.html',
  styleUrls: ['./receive-exchange.component.css']
})
export class ReceiveExchangeComponent implements OnInit, OnDestroy, AfterContentInit {
  coinSubscription: Subscription;
  accountSubscription: Subscription;
  uiButtonSubscription: Subscription;

  exchange: Exchange;
  receiverCoinFactory: Wallet;
  receiveAccounts: AccountInfo[];
  selectedReceiveAccount: AccountInfo;
  useOnlyProkeyAddressFlag = true;
  currentAccount: AccountInfo = <AccountInfo>{};
  currentCoin: CoinModel;
  currentFee: string;
  fees: BitcoinFeeSelectionModel;
  isAddressValid = false;
  loading = true;
  receiverAddress: string;
  hasError = false;
  errorMessage: string;
  processingData = {
    sendStep: SendStep.ProcessingStep
  }
  //@ViewChild('pop') pop: PopoverDirective;

  constructor(private _deviceService: WalletDeviceService,
              private exchangeService: ExchangeService,
              private _walletService: WalletService,
              private _dialogService: DialogService,
              private route: Router) {
  }

  ngOnInit() {
    this.exchangeSubscribe();
    this.subscribeCoin();
    this.subscribeAccount();
    this.subscribeButton();
  }

  ngAfterContentInit(): void {
    this.getFees({
      amount: this.exchange?.sendAmount?.toString(),
      address: ''
    });
    this.fetchReceiveAccounts();
  }

  subscribeCoin() {
    this.coinSubscription = this._walletService.onCurrentWalletChange.subscribe(() => {
      this.currentCoin = this._walletService.CurrentWalletCoin;
    });
  }

  subscribeAccount() {
    this.accountSubscription = this._walletService.onCurrentAccountChange.subscribe(currentAccount => {
      this.currentAccount = currentAccount;
    });
  }

  private async exchangeSubscribe() {
    this.exchangeService.currentOffer.subscribe(async exchange => {
      this.exchange = exchange;
    });
  }

  subscribeButton() {
    this.uiButtonSubscription = this._deviceService.uiButton$.subscribe((code) => {
      if (code === ButtonRequestType.ButtonRequest_Address) {
        this.loading = false;
        Helpers.showPopover('confirmAddress');
      } else if (code === ButtonRequestType.ButtonRequest_ConfirmOutput) {
        this.processingData.sendStep = SendStep.ConfirmOutputStep;
      } else if (code === ButtonRequestType.ButtonRequest_SignTx) {
        this.processingData.sendStep = SendStep.ConfirmActionStep;
      }
    });
  }

  async fetchReceiveAccounts() {
    await this.initializeReceiverCoinFactory();
    this.receiverAddress = "Loading from device, please wait...";
    this.receiveAccounts = this.receiverCoinFactory.getAllAccountsInfo();
    if (this.receiveAccounts?.[0]) {
      this.selectAccount(this.receiveAccounts[0]);
    }
  }

  async selectAccount(account: AccountInfo) {
    this.selectedReceiveAccount = account;
    this.receiverAddress = "Loading from device, please wait...";
    this.loading = true;
    this.loading = false;
    if (account?.lastUnusedAddress?.address) {
      this.receiverAddress = account.lastUnusedAddress.address;
    }
    await this.receiverCoinFactory.getAddress(account?.lastUnusedAddress?.path, true);
    Helpers.hidePopover('confirmAddress');
  }

  async changeUseProkeyFlag() {
    this.receiverAddress = null;
    this.useOnlyProkeyAddressFlag = !this.useOnlyProkeyAddressFlag;
    if (!this.receiveAccounts) {
      await this.fetchReceiveAccounts();
    } else {
      if (this.useOnlyProkeyAddressFlag) {
        this.selectAccount(this.selectedReceiveAccount);
      }
    }
  }

  async checkAddress() {
    await this.initializeReceiverCoinFactory();
    this.isAddressValid = this.receiverAddress && this.receiverCoinFactory.isAddressValid(this.receiverAddress);
  }

  async initializeReceiverCoinFactory() {
    if (!this.receiverCoinFactory) {
      this.receiverCoinFactory = this._walletService.getWallet(this.exchange.receiverCoin) ?? await this._walletService.CreateNewWallet(this.exchange.receiverCoin, true);
    }
  }

  async cancelTransaction() {
    await this._deviceService.init();
    this.route.navigate(['/wallet/device']);
  }

  async sendTransaction() {
    try {
      this._dialogService.open(ProcessingDialogComponent, {disableClose: true, minWidth: 500, data: this.processingData});
      this.clearError();
      const exchangeTransactionInfo = await this.exchangeService.getExchangeTransactionInfo(
        this.exchange, this.receiverAddress, this.currentAccount.lastUnusedAddress.address
      );
      const finalResult = await this.generateAndBroadcastTransaction(exchangeTransactionInfo);
      if (finalResult.isSuccess) {
        this.exchange.transactionId = finalResult.txid;
        this.exchangeService.changeSelectedExchange(this.exchange);
        this.route.navigate(['/wallet/exchange-status']);
      } else {
        this.showError('Error on sending transaction');
        MyConsole.Info('Error on sending transaction:', finalResult.error);
      }
      this.exchange.exchangeId = exchangeTransactionInfo.id;
      this.exchangeService.changeSelectedExchange(this.exchange);
    } catch (exception) {
      this.showError(exception);
      MyConsole.Info('Exception on sending transaction:', exception);
      // close pin-on-device dialog
      this._dialogService.closeAll();
    }
    this._dialogService.closeAll();
  }

  private async generateAndBroadcastTransaction(exchangeTransactionInfo: ExchangeTransaction) {
    const output: BaseOutput = {
      amount: this.exchange.sendAmount.toString(),
      address: exchangeTransactionInfo.payinAddress
    };
    this.setExtraId(exchangeTransactionInfo, output);
    MyConsole.Info('transaction id:', exchangeTransactionInfo.id);
    const sendModel = await this._walletService.CurrentWallet.prepareSendModel(output, this.currentAccount, this.currentFee);
    return await this._walletService.CurrentWallet.sendTransaction(sendModel);
  }

  async getFees(output: BaseOutput) {
    this.fees = await this._walletService.CurrentWallet.calculateTransactionFee(output, this.currentAccount.id);
  }

  selectFee(selectFee: SelectFeeLevel) {
    this.currentFee = selectFee.name;
  }

  private setExtraId(exchangeTransactionInfo: ExchangeTransaction, output: BaseOutput) {
    const extraIdTypes = this._walletService.CurrentWallet.getCoinExtraIdTypes();
    if (exchangeTransactionInfo.extraId && extraIdTypes) {
      const defaultExtraIdType = extraIdTypes.find(extraIdType => extraIdType.default);
      if (defaultExtraIdType) {
        output.extraIdType = defaultExtraIdType.type;
        output.extraIdValue = exchangeTransactionInfo.extraId;
      }
    }
  }

  private showError(message: string) {
    this.hasError = true;
    this.errorMessage = message;
  }

  private clearError() {
    this.hasError = false;
    this.errorMessage = '';
  }

  ngOnDestroy() {
    this.coinSubscription.unsubscribe();
    this.accountSubscription.unsubscribe();
    if (this.uiButtonSubscription) {
      this.uiButtonSubscription.unsubscribe();
    }
  }
}
