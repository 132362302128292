<div *ngIf="showNeedsBackup" class="alert alert-warning alerthead">
  <div class="container">
    <div class="float-left">
      <i class="fas fa-lock"></i>
      <div class="msghead">
        <strong>Your Prokey is not backed up.</strong><br />
        <span>Protect your coins from the unexpected. Start now.</span>
      </div>
    </div>
    <div class="float-right">
      <button type="button" class="btn btn-gradinet-yellow pnup waves-effect waves-dark" (click)="doBackup()">Create a backup</button>
    </div>
  </div>
</div>

<div *ngIf="showFirwareOutdated" class="alert alert-warning alerthead">
  <div class="container">
    <div class="float-left">
      <i class="fas fa-refresh"></i>
      <div class="msghead">
        <strong>A new PROKEY firmware update is available.</strong><br />
        <span>Upgrade to access the newest features.</span>
      </div>
    </div>
    <div class="float-right">
      <button type="button" class="btn btn-gradinet-yellow pnup waves-effect waves-dark" (click)="doUpdate()">Show details</button>
    </div>
  </div>
</div>

<div *ngIf="showIsTestCoin" class="alert alert-warning alerthead">
  <div class="container">
    <div class="float-left">
      <i class="fas fa-exclamation-triangle"></i>
      <div class="msghead">
        <strong>Testnet coin</strong><br />
        <span>The current coin is for testing porpuses.</span>
      </div>
    </div>
  </div>
</div>
