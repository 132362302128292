export enum ProkeySettingsKey {
  BITCOIN_SERVERS = 'bitcoreServers',
  DISPLAY_CURRENCY = 'displayCurrency',
  DISPLAY_COIN = 'displayCoin',
  EXPLORER_URL = 'explorerUrl',
  ENABLE_LOGS = 'enableLog',
  FAMOUS_COINS = 'famousCoins',
  ASSET_COINS = 'assetCoins',
  CUSTOM_TOKENS = 'customTokens',
  IS_VIEWED_TOUR = 'isViewedTour',
}
