import { WalletService } from '../../core/services/wallet.service';
import { Directive, Attribute, Inject } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
  selector: '[appAddressValidator][formControlName],[appAddressValidator][formControl],[appAddressValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: AddressValidatorDirective,
      multi: true
    }
  ]
})
export class AddressValidatorDirective implements Validator {

  constructor(@Attribute("coin-name") public coinName: string,
              @Inject(WalletService) private walletService: WalletService) { }

  validate(element: AbstractControl): { [key: string]: any } {

    if (!element.value || element.value == '') {
      return null;
    }

    let valid = true;
    try {
      valid = this.walletService.CurrentWallet.isAddressValid(element.value);
    } catch (e) {

    }
    if (valid) {
      return null;
    } else {
      return { appAddressValidator: true };
    }
  }
}
