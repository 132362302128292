<div>
  <button type="button" class="close" (click)="close()">&times;</button>
  <div class="clear"></div>
  <div *ngIf="status !== 1" class="col-lg-12 col-md-12 col-12 center">
    <div class="modal-search">
      <div class="input-group mb-3 col-lg-8 col-md-10 col-sm-10 col-12 p-0 table-search center">
        <div class="input-group-append">
          <button class="btn" type="button" style="z-index:1"><i class="fa fa-search"></i></button>
        </div>
        <input type="text" class="form-control" id="filter-search-asset" (keyup)="onSearchKeyUp()"
               [(ngModel)]="searchText" placeholder="SEARCH BY ASSET NAME OR SYMBOL">
      </div>
    </div>
    <div class="clear"></div>
    <div *ngIf="!searchCoins" class="row mt-5 labels-item" id="style-4">
      <div *ngFor="let coin of coins" class="col-lg-3 col-md-6 col-sm-6 col-12">
        <input id="{{coin.coinInfo.id}}" type="checkbox" class="checkbox-custom" [(ngModel)]="coin.isSelected">
        <label class="drinkcard-bb d-flex checkbox-custom-label" for="{{coin.coinInfo.id}}">
          <img src="{{coin.getImageUrl()}}" alt="{{coin.coinInfo.shortcut}}" loading="lazy"
               style="width:32px; height:32px; margin: 8px;"
               onerror="if (this.src !== './assets/img/coins/tk.png') this.src = './assets/img/coins/tk.png';"/>
          <span class="coin-nowrap">
                  {{coin.coinInfo.name}}<br>
                  <small class="font-weight-bold">{{coin.coinInfo.shortcut}}</small>
                  <small style="color:#7c828a;"> {{coin.coinInfo | tokenTypeToName }} </small>
                </span>
        </label>
      </div>
    </div>
    <div *ngIf="searchCoins" class="row mt-5 labels-item" id="style-4">
      <div *ngFor="let coin of searchCoins" class="col-lg-3 col-md-6 col-sm-6 col-12">
        <input id="{{coin.coinInfo.id}}" type="checkbox" class="checkbox-custom" [(ngModel)]="coin.isSelected">
        <label class="drinkcard-bb d-flex checkbox-custom-label" for="{{coin.coinInfo.id}}">
          <img src="{{coin.getImageUrl()}}" alt="{{coin.coinInfo.shortcut}}" loading="lazy"
               style="width:32px; height:32px; margin: 8px;"
               onerror="if (this.src !== './assets/img/coins/tk.png') this.src = './assets/img/coins/tk.png';"/>
          <span class="coin-nowrap">
                  {{coin.coinInfo.name}}<br>
                  <small class="font-weight-bold">{{coin.coinInfo.shortcut}}</small>
                  <small style="color:#7c828a;"> {{coin.coinInfo | tokenTypeToName }} </small>
                </span>
        </label>
      </div>
    </div>
    <div *ngIf="isSearchHasNotResult()">Can't find the specific token? You may add your custom token.</div>
    <div class="clear"></div>
    <div style="display: flex;flex-wrap: wrap;justify-content: center;margin-top: 2rem">
      <button class="add-coin-button btn-gradinet-blue waves-effect waves-dark text-center" type="button"
              *ngIf="!isSearchHasNotResult()" (click)="apply()">Apply
      </button>
      <button style="padding-right: 1rem!important;padding-left: 1rem !important;"
              class="add-coin-button btn-gradinet-blue waves-effect waves-dark text-center" type="button"
              *ngIf="isSearchHasNotResult()" (click)="addCustomToken()">
        <span class="fas fa-plus-circle" style="margin-right: 1rem"></span>
        Add Custom Token
      </button>
    </div>
  </div>
  <app-wallet-add-custom-token *ngIf="status === 1" class="col-lg-12 col-md-12 col-12 center" style="text-align: start"
                               (_cancelEvent)="close()" (_addedCustomTokenEvent)="addCustomTokenEvent($event)"
                               [_websiteCoinModels]="coins">
  </app-wallet-add-custom-token>
</div>
