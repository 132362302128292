import {Injectable} from '@angular/core';
import {GoogleOath2ProviderService} from "../../../oauth2/google-oath2-provider.service";
import {BaseRepository} from "../base-repository";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {GoogleDriveDetail} from "@core/services/storage/repository/google-drive/model/google-drive-detail";
import {GoogleDriveFileDetail} from "@core/services/storage/repository/google-drive/model/google-drive-file-detail";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import {StorageAuthDialogComponent} from "../../../../../wallet/settings/storage-auth-dialog/storage-auth-dialog.component";
import { GoogleAuthInsufficientScopeDialogComponent } from '../../../../../wallet/settings/google-auth-insuffient-scope-dialig/google-auth-insufficient-scope-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class GoogleStorageRepositoryService implements BaseRepository {
  private readonly _baseUrl = 'https://www.googleapis.com/drive/v3/files'

  constructor(private _googleOath2ProviderService: GoogleOath2ProviderService,
              private _httpClient: HttpClient,
              private _dialogService: DialogService) {
  }

  async initial() {
    try {
      await this._googleOath2ProviderService.logout();
      await this._googleOath2ProviderService.logIn();
    } catch (e) {
      console.log(e);
    }
  }

  getRepositoryImagePath(): string {
    return 'assets/img/google-drive.svg';
  }

  getRepositoryRepresentName(): string {
    return 'Google Drive Storage';
  }

  async init(): Promise<boolean> {
    return this._googleOath2ProviderService.isLoggedIn();
  }

  async delete(key: string): Promise<boolean> {
    if (!this._googleOath2ProviderService.isLoggedIn()) {
      this._dialogService.open(StorageAuthDialogComponent);
      return false;
    }
    if (!this._googleOath2ProviderService.haveSufficientScopes()) {
      this._dialogService.open(GoogleAuthInsufficientScopeDialogComponent);
      return false;
    }
    let googleDriveFile = await this.getProKeyFileDetail(key);
    if (googleDriveFile) {
      await this.deleteRequest(googleDriveFile);
      return true;
    }
    return false;
  }

  async get<T>(key: string): Promise<T> {
    if (!this._googleOath2ProviderService.isLoggedIn()) {
      this._dialogService.open(StorageAuthDialogComponent);
      return null;
    }
    if (!this._googleOath2ProviderService.haveSufficientScopes()) {
      this._dialogService.open(GoogleAuthInsufficientScopeDialogComponent);
      return null;
    }
    let googleDriveFile = await this.getProKeyFileDetail(key);
    if (googleDriveFile) {
      return await this.getProkeyFile<T>(googleDriveFile);
    }
    return null;
  }

  async save<T>(key: string, object: T): Promise<void> {
    if (!this._googleOath2ProviderService.isLoggedIn()) {
      this._dialogService.open(StorageAuthDialogComponent);
      return;
    }
    if (!this._googleOath2ProviderService.haveSufficientScopes()) {
      this._dialogService.open(GoogleAuthInsufficientScopeDialogComponent);
      return;
    }
    let googleDriveFile = await this.getProKeyFileDetail(key);
    if (googleDriveFile) {
      await this.deleteRequest(googleDriveFile);
    }
    this.saveFile<T>(key, object)
  }

  saveFile<T>(key: string, file: T, fileId?: string) {

    var accessToken = this._googleOath2ProviderService.getAccessToken();
    var form = new FormData();

    var xhr = new XMLHttpRequest();
    if (fileId) {
      var metadataUpdate = {
        'name': key, // Filename at Google Drive
        'mimeType': 'application/json' // mimeType at Google Drive
      };
      form.append('metadata', new Blob([JSON.stringify(metadataUpdate)], {type: 'application/json'}));
      form.append('file', new Blob([JSON.stringify(file)], {type: 'application/json'}));
      xhr.open('patch', `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart&fields=id`);
    } else {
      var metadataCreate = {
        'name': key, // Filename at Google Drive
        'mimeType': 'application/json', // mimeType at Google Drive
        'parents': ['appDataFolder'], // Folder ID at Google Drive
      };
      form.append('metadata', new Blob([JSON.stringify(metadataCreate)], {type: 'application/json'}));
      form.append('file', new Blob([JSON.stringify(file)], {type: 'application/json'}));
      xhr.open('post', 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id');
    }
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.responseType = 'json';
    xhr.onload = () => {
      console.log(xhr.response); // Retrieve uploaded file ID.
    };
    xhr.send(form);
  }

  private async getProKeyFileDetail(key: string): Promise<GoogleDriveFileDetail> {
    let driveFiles = await this._httpClient.get<GoogleDriveDetail>(
      this._baseUrl + '?spaces=appDataFolder',
      {
        headers: new HttpHeaders(
          {
            'Authorization': 'Bearer ' + this._googleOath2ProviderService.getAccessToken(),
            'Content-Type': 'application/json'
          }
        )
      }
    ).toPromise();
    console.log(driveFiles);
    return driveFiles.files.find(file => file.name === key);
  }

  private async getProkeyFile<T>(googleDriveFile: GoogleDriveFileDetail): Promise<T> {
    return await this._httpClient.get<T>(
      this._baseUrl + `/${googleDriveFile.id}?alt=media`,
      {
        headers: new HttpHeaders(
          {
            'Authorization': 'Bearer ' + this._googleOath2ProviderService.getAccessToken(),
            'Content-Type': 'application/json'
          }
        )
      }
    ).toPromise();
  }

  private async deleteRequest(googleDriveFile: GoogleDriveFileDetail) {
    await this._httpClient.delete<string>(
      `https://www.googleapis.com/drive/v3/files/${googleDriveFile.id}`,
      {
        headers: new HttpHeaders(
          {
            'Authorization': 'Bearer ' + this._googleOath2ProviderService.getAccessToken(),
            'Content-Type': 'application/json'
          }
        )
      }
    ).toPromise();
  }

  deleteAll(): Promise<void> {
    throw new Error('not implemented');
  }

  terminate() {
    this._googleOath2ProviderService.logout();
  }
}
