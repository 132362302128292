import {Component} from '@angular/core';
import {CoinModel} from '@core/models/connect/coin-model';
import {ExchangeService} from '../../services/exchange.service';
import {WindowService} from '@core/services/window.service';
import {Router} from '@angular/router';
import {Helpers} from '@core/services/helpers';
import {Exchange} from '../../models/exchange';
import {ExchangeException} from '../../errors/exchange.exception';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.css']
})
export class ExchangeComponent {
  selectedReceiveCoin: CoinModel;
  selectedSendCoin: CoinModel;
  sendAmount = 0;
  selectedOffer: Exchange;
  privacy = false;
  showSkeleton = false;
  isFixRateFlow = false;
  hasError = false;
  errorMessage: string;
  offers: Array<Exchange>;
  isOfferButtonDisabled = true;

  private static isTestCoin(coin: CoinModel): boolean {
    return coin.coinInfo.test;
  }

  constructor(
    private exchangeService: ExchangeService,
    private windowService: WindowService,
    private route: Router
  ) {}

  setSendCoin(coin: CoinModel) {
    this.selectedSendCoin = coin;
  }

  setReceiveCoin(coin: CoinModel) {
    this.clearError();
    this.showSkeleton = false;
    this.offers = null;
    this.selectedReceiveCoin = coin;
  }

  setAmount(amount: number) {
    this.sendAmount = amount;
    this.isOfferButtonDisabled = amount === 0;
  }

  setSelectedOffer(offer: Exchange) {
    this.clearError();
    this.selectedOffer = offer;
  }

  refreshOffersEvent(isTimerFinished: boolean) {
    if (isTimerFinished) {
      this.getOffers();
    }
  }

  checkPrivacy() {
    this.privacy = !this.privacy;
  }

  confirmExchange() {
    Helpers.hideModals();
    this.exchangeService.changeSelectedExchange(this.selectedOffer);
    this.route.navigate(['/wallet/exchange-receive']);
  }

  cancelExchange() {
    this.selectedOffer = null;
    this.privacy = false;
  }

  async getOffers() {
    if (this.selectedSendCoin.isCustomToken || this.selectedReceiveCoin.isCustomToken) {
      this.showError('you cant exchange custom token');
      return;
    }
    this.offers = null;
    if (this.sendAmount && this.sendAmount !== 0) {
      this.showSkeleton = true;
      this.clearError();
      try {
        this.checkCoinEquality();
        this.checkCoinIsNotTest();
        const receivedOffers = await this.exchangeService.getOffers(this.selectedSendCoin, this.selectedReceiveCoin, this.sendAmount);
        if (this.selectedReceiveCoin.coinInfo.shortcut === receivedOffers[0].receiverCoin.coinInfo.shortcut) {
          this.offers = receivedOffers;
        }
      } catch (error) {
        if (error instanceof ExchangeException) {
          this.showError(error.message);
        }
      }
      this.showSkeleton = false;
    }
  }

  private checkCoinIsNotTest() {
    if (ExchangeComponent.isTestCoin(this.selectedSendCoin) || ExchangeComponent.isTestCoin(this.selectedReceiveCoin)) {
      throw new ExchangeException('Exchange does not support test tokens');
    }
  }

  private checkCoinEquality() {
    if (this.selectedSendCoin.coinInfo.shortcut === this.selectedReceiveCoin.coinInfo.shortcut) {
      throw new ExchangeException('send and receive crypto assets must be different');
    }
  }

  private showError(message: string) {
    this.hasError = true;
    this.errorMessage = message;
  }

  private clearError() {
    this.hasError = false;
    this.errorMessage = '';
  }

  changePin() {
    this.isFixRateFlow = !this.isFixRateFlow;
  }
}
