import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CoinModel} from '@core/models/connect/coin-model';
import {AccountInfo} from '@core/models/connect/account-info';
import {Subscription} from 'rxjs';
import {WalletService} from '@core/services/wallet.service';
import {CoinMarketService} from '@core/services/coin-market.service';
import {UserSettings} from '@core/models/user-settings';
import {AbstractControl, NgForm, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ProkeySettingsKey} from "@core/models/prokey-settings-key.enum";
import {ProkeySettingsService} from "@core/services/prokey-settings.service";

@Component({
  selector: 'app-send-coin',
  templateUrl: './send-coin.component.html',
  styleUrls: ['./send-coin.component.css']
})
export class SendCoinComponent implements OnInit, OnDestroy {
  @Output() selectCoinEvent = new EventEmitter<CoinModel>();
  @Output() selectAmountEvent = new EventEmitter<number>();

  coinSubscription: Subscription;
  accountSubscription: Subscription;
  userSettingModel: UserSettings = <UserSettings>{};

  account: AccountInfo = <AccountInfo>{};
  selectedSendCoin: CoinModel;
  sendAmount = 0;
  sendUSDAmount: number;

  @ViewChild('sendCoinForm', { static: true }) public sendForm: NgForm;

  constructor(private walletService: WalletService,
              private coinMarketService: CoinMarketService,
              private _settingsService: ProkeySettingsService) {
  }

  ngOnInit() {
    this.setUserSetting();
    this.subscribeCoin();
    this.subscribeAccount();
  }

  ngOnDestroy() {
    this.coinSubscription.unsubscribe();
    this.accountSubscription.unsubscribe();
  }

  subscribeCoin() {
    this.coinSubscription = this.walletService.onCurrentWalletChange.subscribe(() => {
      const currentCoin = this.walletService.CurrentWalletCoin;
      this.selectedSendCoin = currentCoin;
      this.selectCoinEvent.emit(currentCoin);
      this.clear();
    });
  }

  subscribeAccount() {
    this.accountSubscription = this.walletService.onCurrentAccountChange.subscribe(currentAccount => {
      this.account = currentAccount;
      this.clear();
    });
  }

  clear() {
    this.sendAmount = 0;
    this.sendUSDAmount = 0;
  }

  setAmount() {
    if (this.sendForm.controls['amount'].valid) {
      this.selectAmountEvent.emit(this.sendAmount);
      this.calculateUSDCurrency();
    } else {
      this.selectAmountEvent.emit(0);
    }
  }

  calculateUSDCurrency() {
    let coin = this.selectedSendCoin.coinInfo.name.toLowerCase();
    if (coin === 'testnet') {
      coin = 'bitcoin';
    }
    const coinId = coin.toCoinId();
    this.coinMarketService.getSimplePrice(coinId, this.userSettingModel.displayCurrency.toLowerCase()).subscribe(result => {
      if (result) {
        const currentCoin = result[coinId];
        if (!currentCoin) {
          return;
        }
        const currentCurrency = currentCoin[this.userSettingModel.displayCurrency.toLowerCase()];
        if (currentCurrency) {
          this.sendUSDAmount = (currentCurrency * this.sendAmount);
        }
      }
    });
  }

  setUserSetting() {
    const settings = this._settingsService.getAll();
    this.userSettingModel = {
      displayCurrency: settings[ProkeySettingsKey.DISPLAY_CURRENCY],
      displayCoin: settings[ProkeySettingsKey.DISPLAY_COIN],
      bitcoreServers: settings[ProkeySettingsKey.BITCOIN_SERVERS],
      explorerUrl: settings[ProkeySettingsKey.EXPLORER_URL]
    }
  }

  validateAmountValidator(): ValidatorFn {
    return (amountControl: AbstractControl): ValidationErrors => {
      const amount = amountControl.value;
      if (amount >= this.account.balance) {
        return {invalidAmount: {message: 'your balance is not enough'}};
      } else {
        return;
      }
    };
  }
}
