import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {ProkeySettingsService} from "./prokey-settings.service";
import { AccountBrief } from '@core/models/connect/account-brief';
import { CoinModel } from '@core/models/connect/coin-model';
import { CoinUnitPipe } from '@shared/pipes/coin-unit.pipe';
import { AssetInfo, CoinMarketStatus } from '@core/models/connect/asset-info';

@Injectable()
export class CoinMarketService {


  //#region Private members 
  
  private _apiUrl = 'https://api.coingecko.com/api/v3';
  private _lastPrice: any;
  private _lastPriceFetchTime: Date = new Date();
  private _coin: CoinModel;
  
  //#endregion

  constructor(private http: HttpClient,
              private _unitPipe: CoinUnitPipe, 
              private _settingsService: ProkeySettingsService) {
    //! Initial time to yesterday
    this.ResetFetchTime();
  }

  //#region public methods

  ResetFetchTime(){
    this._lastPriceFetchTime.setDate(this._lastPriceFetchTime.getDate() - 1);
  }

  getCurrencies(): Observable<string[]> {
    return this.http.get<string[]>(`${this._apiUrl}/simple/supported_vs_currencies`);
  }

  getCurrentCurrency(): string {
    const settings = this._settingsService.getAll();
    if (settings?.displayCurrency) {
      return settings.displayCurrency;
    } else {
      this._settingsService.patch({displayCurrency: 'USD', displayCoin: 'BTC', bitcoreServers: '', explorerUrl: ''});
      return 'USD';
    }
  }

  getSimplePrice(coinId: string, currency: string): Observable<any> {

    const secondsPassedFromLastCall = (new Date().getTime() - this._lastPriceFetchTime.getTime()) / 1000;
    //! fetch/update the price rate every 3 minutes
    if(this._lastPrice != null && secondsPassedFromLastCall < 180 ){
        return this._lastPrice;
    }

    const params = new HttpParams()
      .set('ids', coinId)
      .set('vs_currencies', currency)
      .set('include_24hr_vol', 'false')
      .set('include_market_cap', 'false')
      .set('include_24hr_change', 'false')
      .set('include_last_updated_at', 'false');

    this._lastPrice = this.http.get<any>(`${this._apiUrl}/simple/price`, {
      params: params
    }).pipe(catchError((err: HttpErrorResponse) => {
      return EMPTY;
    }));

    this._lastPriceFetchTime = new Date();

    return this._lastPrice;
  }

  getMarketChart(coinId: string, days: number) {
    const params = new HttpParams()
      .set('vs_currency', this.getCurrentCurrency())
      .set('days', days.toString());
      //.set('interval', 'daily');
    return this.http.get<any>(`${this._apiUrl}/coins/${coinId}/market_chart`, {
      params: params
    }).pipe(catchError((err: HttpErrorResponse) => {
      return throwError("can't use market price");
    }));
  }

  setCoinMarket(currentCoin: CoinModel) : Promise<void> {

    return new Promise((resolve, reject) => {
      
      if (currentCoin == null) {
        return reject();
      }

      let currency = this.getCurrentCurrency();
      let coinId = currentCoin.coinInfo.name.toCoinId();
      let coinShortcut = currentCoin.coinInfo.shortcut.toLocaleLowerCase();

      if (currentCoin.coinInfo.shortcut === 'TEST' || currentCoin.coinInfo.name === 'Testnet') {
        coinId = 'bitcoin';
        coinShortcut = 'btc';
      }

      this._getMarketPrice(coinId, currency, '24h', '7d', '30d').subscribe({
        next: (result) => {
          if (result) {
            currentCoin.marketData = result.find(x => x.symbol === coinShortcut);
            currentCoin.isAvailableMarketData = true;
          } else {
            currentCoin.isAvailableMarketData = false;
          }

          this._coin = currentCoin;
          return resolve();
        },
        error: () => {
          return resolve();
        }
      });
    });
  }

  setBalancePrice(accountBreif: AccountBrief) {
    if(this._coin == null) {
      return;
    }

    if (this._coin.isAvailableMarketData) {
      accountBreif.balancePrice = this._getBalancePrice(accountBreif.balance);
    }
  }

  setAssetsMarketPrice(assets: AssetInfo[], currency: string): Promise<number> {
    return new Promise((resolve) => {
      let changePercentageAverage = 0;
      let marketResult = [];
      let coinIds = assets.map(x => x.coinId).join();
      
      this._getMarketPrice(coinIds, currency, '24h', '7d', '30d').subscribe({
        next: (result) => {
          marketResult = result;
          if (marketResult) {
            for (let asset of assets) {
              const marketPrice = marketResult.find(x => x.id == asset.coinId);
              if (marketPrice) {
                asset.coinPrice = marketPrice.current_price;
                asset.isLoadedPrice = true;
                
                asset.priceChangeMap = new Map();
                asset.priceChangeMap.set('Day', marketPrice.price_change_percentage_24h_in_currency);
                asset.priceChangeMap.set('Week', marketPrice.price_change_percentage_7d_in_currency);
                asset.priceChangeMap.set('Month', marketPrice.price_change_percentage_30d_in_currency);
    
                asset.priceChangePercentage = marketPrice.price_change_percentage_30d_in_currency;
                asset.coinMarketChangeStatus = asset.priceChangePercentage < 0.00000 ? CoinMarketStatus.Negative : CoinMarketStatus.Positive;  
              }
            }
    
            let changeArray = assets.filter(x => x.priceChangePercentage != null).map(x => x.priceChangePercentage);
            changePercentageAverage = this.getAverage(changeArray);
    
          }
          return resolve(changePercentageAverage);
        },
        error: () => {
          return resolve(changePercentageAverage);
        },
        complete: () => {
        },
      });
    });
  }

  setAssetTotalPrice(asset: AssetInfo) {
    if (asset.coinPrice) {
      const realBalance = this._unitPipe.transform(asset.totalBalance, asset.coin.coinInfo.decimals);
      asset.totalBalancePrice = realBalance * asset.coinPrice;
    }
    asset.isLoadedPrice = true;
  }
  
  setAssetMarketChart(asset: AssetInfo, maxDays: number): Promise<boolean> {
    return new Promise((resolve) => {
      let realBalance = this._unitPipe.transform(asset.totalBalance, asset.coin.coinInfo.decimals);
      
      this.getMarketChart(asset.coinId, maxDays).subscribe({ 
        next: (result) => {
          asset.marketPrices = result['prices'];

          for(let item of asset.marketPrices) {
            let currentCoinPrice = item[item.length - 1];
            item[item.length - 1] = realBalance * currentCoinPrice;
          }
          return resolve(true);
        },
        error: () => {
          return resolve(false);
        }
      });
    });
  }

  getAverage(array: number[]): number {
    let avg = null;
    const average = array => array.reduce((a, b) => a + b) / array.length;

    if (array.length > 0) {
      avg = average(array);
    }

    return avg;
  }

  //#endregion

  //#region Private methods

  private _getMarketPrice(coinId: string, currency: string, ...price_change_percentage: string[]) {
    let percentageParams = '';
    for (const day of price_change_percentage) {
      percentageParams += `${day},`;
    }
    percentageParams = percentageParams.substr(0, percentageParams.length - 1);
    const params = new HttpParams()
      .set('ids', coinId)
      .set('vs_currency', currency)
      .set('sparkline', 'false')
      .set('price_change_percentage', percentageParams);
    return this.http.get<any>(`${this._apiUrl}/coins/markets`, {
      params: params
    }).pipe(catchError((err: HttpErrorResponse) => {
      return throwError("can't use market price");
    }));
  }

  private _getBalancePrice(balance: number) {
    if (this._coin.marketData) {
      const coinPrice: number = this._coin.marketData.current_price;
      const realBalance = this._unitPipe.transform(balance, this._coin.coinInfo.decimals);
      return realBalance * coinPrice;
    }
    return null;
  }

  //#endregion

}