<div class="wipe-modal" data-keyboard="false">
  <div class="modal-content">
    <div class="modal-body text-center">
      <img class="prokey-device" src="./assets/img/device.svg" alt="prokey"/>
      <ng-container
        *ngIf="data.isSetPassphraseSuccessfully then setPassphraseCompletedTemplate else confirmSetPassphraseTemplate"></ng-container>
      <ng-template #confirmSetPassphraseTemplate>
        <ng-container *ngIf="data.passphraseAction === 'Enable'">
          <div><h6>Approve enabling passphrase on device</h6></div>
          <div class="modal-red-footer">
            <h6>Forgetting passphrase is equal to losing wallet.</h6>
            <span>Please make sure you never forget the passphrase because there is no way to recover fund if you loose it.</span>
          </div>
        </ng-container>
        <ng-container *ngIf="data.passphraseAction === 'Disable'">
          <h6>Approve disabling passphrase on device</h6>
          <div class="modal-red-footer">
            <h6>Disable Passphrase</h6>
            <span>If you disable passphrase encryption, your current funds will not be shown. You will have to enable passphrase encryption again to see your current wallet.</span>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #setPassphraseCompletedTemplate>
        <h6>Disconnect your Prokey now</h6>
        <span><span class="text-danger">disconnect</span> your Prokey to {{data.passphraseAction | lowercase}}
          passphrase.</span>
      </ng-template>
    </div>
  </div>
</div>
