<app-header class="header-white"></app-header>
<app-wallet-notify-layout></app-wallet-notify-layout>
<section class="home">
  <div class="container">
    <ng-container *ngIf="layoutMode === 'Default'">
      <app-wallet-header class="row home-header"></app-wallet-header>
      <div class="row home-body">
        <aside class="col-xl-3 col-lg-3 col-md-12 col-sm-12 float-left home-body-left">
          <div class="default-side">
            <app-wallet-device-info></app-wallet-device-info>
            <app-wallet-sidemenu></app-wallet-sidemenu>
            <hr>
            <app-wallet-sidebar></app-wallet-sidebar>
          </div>
        </aside>
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 float-right home-body-right">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="layoutMode === 'Exchange'">
      <div class="row home-body">
        <aside class="col-xl-3 col-lg-3 col-md-12 col-sm-12 float-left home-body-left">
          <div class="exchange-aside">
            <app-exchange-layout></app-exchange-layout>
          </div>
        </aside>
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 float-right home-body-right">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="layoutMode === 'Portfolio'">
      <app-wallet-header class="row home-header"></app-wallet-header>
      <div class="row home-body">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 float-right home-body-right">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-container>
  </div>
</section>
<div id="snackbar">Copied to Clipboard !</div>
<app-wallet-footer></app-wallet-footer>
