<div class="form-group mb-2">
  <label class="m-b-5">RECEIVE</label>
  <div class="dropdown exchange-coin-search-two">
    <button class="btn bitcoin-btn dropdown-toggle relative waves waves-effect"
            type="button" data-toggle="dropdown" aria-expanded="false">
      <div class="d-flex">
        <div class="col-button right-border">
          <img src="{{selectedReceiveCoin?.getImageUrl()}}" width="36px"
               height="36px" onerror="if (this.src != './assets/img/coins/tk.png') this.src = './assets/img/coins/tk.png';"/>
        </div>
        <div class="col-button ml-2 pl-2 border-left-exchange">
          <span class="coin-unit d-block">{{selectedReceiveCoin?.coinInfo.shortcut}}</span>
          <small class="account-number d-block">Select a crypto</small>
          <small class="balance d-block">to receive</small>
        </div>
      </div>
      <svg class="abs-dropdown" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="8"
           viewBox="0 0 14 8">
        <image id="left148" width="14" height="8"
               xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAABHNCSVQICAgIfAhkiAAAAL5JREFUGJWNzrEuRGEYhOFnj1rIJm5BIqJUke1UFEyjUahcwboCrS0p9LJRnL+SlRCJQlyDQqngIuQoHMnZjchO903m/WZ6dV2vYD3JkzlUStnHS4Ul3JVSduaAjnGNfpXkDcMfv2z+A+3hCodJXitIcoERJqWU1T+gbdzgJMkt9Jqm6QYusYutJO+tt4FnnCU5/83OggsYYw0DLLfQOMmwu6LqHkm+cIRPTHCPB5zOzp9q7DQv4hEfOGgfTukbk51GdPCtwzYAAAAASUVORK5CYII="/>
      </svg>
    </button>
    <div class="dropdown-menu" x-placement="bottom-start" style="width:100%">
      <div class="dropdown-search mb-2 p-2 d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-1" width="22px"
             height="22px" xmlns:xlink="http://www.w3.org/1999/xlink"
             version="1.1" id="Capa_1" x="0px" y="0px"
             viewBox="0 0 512.005 512.005"
             style="enable-background:new 0 0 512.005 512.005;"
             xml:space="preserve">
													<g>
														<g>
															<path
                                d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667    S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6    c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z     M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"/>
														</g>
													</g>
												</svg>
        <input type="text" placeholder="Type a currency" [(ngModel)]="searchText" (keyup)="onSearchKeyUp()"/>
        <input type="hidden" class="exchange-value" value="" name="receive"/>
      </div>
      <div class="search-category">
        <h6 class="hide-on-search p-2" *ngIf="!searchCoins">Popular Currencies</h6>
        <ul class="hide-on-search c-dropdown" *ngIf="!searchCoins">
          <li *ngFor="let coin of allCoins" (click)="setReceiveCoin(coin)">
            <a href="javascript:void(0)" class="waves waves-effect"
               data-coin="ltc" data-icon="assets/img/coins/color/ltc.svg">
              <div class="d-flex search-coin">
                <div class="col align-items-center coin-nowrap exchange-beforetag">
                  <img src="{{coin.getImageUrl()}}" loading="lazy" onerror="if (this.src != './assets/img/coins/tk.png') this.src = './assets/img/coins/tk.png';"
                       class="mr-2" width="24px" height="24px"/>
                  <span>{{coin.coinInfo.shortcut}}</span>
                </div>
                <div class="col taginlist">
                  <span *ngIf="coin.coinInfo.chain_id==1 && coin.coinInfo.shortcut != 'ETH'" class="alert-primary erc20tag"> {{coin.coinInfo | tokenTypeToName}} </span>
                  <span *ngIf="coin.coinInfo.chain_id==56 && coin.coinInfo.shortcut != 'BNB'" class="alert-primary bep20tag"> {{coin.coinInfo | tokenTypeToName}} </span>
                </div>
                <div class="col align-items-center">
                  <small>{{coin.coinInfo.name}}</small>
                </div>

              </div>
            </a>
          </li>
        </ul>

        <h6 *ngIf="searchCoins" class="p-2">Currencies</h6>
        <ul *ngIf="searchCoins">
          <li *ngFor="let coin of searchCoins" (click)="setReceiveCoin(coin)">
            <a href="javascript:void(0)" class="waves waves-effect"
               data-coin="ltc" data-icon="assets/img/coins/color/ltc.svg">
              <div class="d-flex search-coin">
                <div class="col align-items-center">
                  <img src="{{coin.getImageUrl()}}" loading="lazy" onerror="if (this.src != './assets/img/coins/tk.png') this.src = './assets/img/coins/tk.png';"
                       class="mr-2" width="24px" height="24px"/>
                  <span>{{coin.coinInfo.shortcut}}</span>
                </div>
                <div class="col align-items-center">
                  <small>{{coin.coinInfo.name}}</small>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
