import * as $ from "jquery";

declare var toastr: any;
declare var jQuery:any;
declare var $:any;
declare var ClipboardJS: any;
declare var ApexCharts: any;
declare var marketPriceChart: any;
declare var oTable: any;
declare var Waves: any;

declare global {
  export interface String {
    toCoinId: () => string;
    isNullOrEmpty: () => boolean;
  }
}

/*
  * Replace spaces with dash to coin id for coingecko api.
  * For example: replace 'Bitcoin Cash' to 'bitcoin-cash'
*/
String.prototype.toCoinId = function (): string {
  if (this.includes('Tether') || this.includes('tether')) {
    return 'tether';
  }
  if (this.includes('Binance Smart Chain')) {
    return 'binancecoin';
  }
  return this.toLowerCase().split(' ').join('-');
}

String.prototype.isNullOrEmpty = function (): boolean {
  if (this === undefined || this === null || this.trim() === '') {
    return true;
  }
  return false;
}

export class Helpers {

	static setLoading(loading) {
		let body = $('body');
		if (loading) {
			$('.preloader-backdrop').fadeIn(200);
		} else {
			$('.preloader-backdrop').fadeOut(200);
		}
	}

	static bodyClass(Class) {
		$('body').attr('class', Class);
	}

	static initLayout() {
	    // SIDEBAR ACTIVATE METISMENU
		$(".metismenu").metisMenu();

		// SIDEBAR TOGGLE ACTION
	    $('.js-sidebar-toggler').click(function() {
	        if( $('body').hasClass('drawer-sidebar') ) {
	            $('#sidebar').backdrop();
	        } else {
	            $('body').toggleClass('sidebar-mini');
	            if(! $('body').hasClass('sidebar-mini') ) {
	                $('#sidebar-collapse').hide();
	                setTimeout(function () {
	                    $('#sidebar-collapse').fadeIn(300);
	                }, 200);
	            }
	        }
	    });

	    // QUICK SIDEBAR TOGGLE ACTION
	    $('.quick-sidebar-toggler').click(function(){
	        $('.quick-sidebar').backdrop();
	    });

	    // SEARCH BAR ACTION
	    $('.js-search-toggler').click(function() {
	        $('.search-top-bar').backdrop().find('.search-input').focus();
	    });

	    // Session timeout

	    var idle_timer;
	    (function(){
	        $('#timeout-activate').click(function(){
	            if(+$('#timeout-count').val()) {
	                activate(+$('#timeout-count').val());
	            }
	        });

	        $('#timeout-reset').click(function(){
	            reset();
	        });

	        function reset(){
	            $( document ).idleTimer("destroy");
	            if(idle_timer) clearTimeout(idle_timer);
	            $('#session-dialog').modal('hide');
	            $('.timeout-toggler').removeClass('active');
	            $('#timeout-reset-box').hide();
	            $('#timeout-activate-box').show();
	        }

	        function activate(count){
	            $('#session-dialog').modal('hide');
	            $('#timeout-reset-box').show();
	            $('#timeout-activate-box').hide();
	            $( document ).idleTimer( count * 60000 );

	            setTimeout(function(){
	                $('.timeout-toggler').addClass('active');
	            },(count-1) * 60000);

	            $( document ).on( "idle.idleTimer", function(event, elem, obj){
	                // function you want to fire when the user goes idle
	                toastr.warning('Your session is about to expire. The page will redirect after 15 seconds with no activity.','Session Timeout Notification',{
	                    "progressBar": true,
	                    "timeOut": 5000,
	                });
	                idle_timer = setTimeout(timeOutHandler,5000);
	            });

	            $( document ).on( "active.idleTimer", function(event, elem, obj, triggerevent){
	                // function you want to fire when the user becomes active again
	                clearTimeout(idle_timer);
	                $( document ).idleTimer("reset");
	                toastr.clear();
	                toastr.success('You returned to the active mode.','You are back.');
	            });

	            function timeOutHandler() {
	                reset();
	                alert('Your session has expired. You can redirect this page or logout.');
	            }
	        }
	    })();
  }

  //TODO: Replace with initWalletEffects() method.
  static initWalletLayout() {
  }

	static initPage() {

	    // Activate bootstrap select
	    if($(".selectpicker").length>0) {
	        $('.selectpicker').selectpicker();
      }

      $('.select2').select2();

	    // Activate Tooltips
	    $('[data-toggle="tooltip"]').tooltip();

	    // Activate Popovers
	    $('[data-toggle="popover"]').popover();

	    // Activate slimscroll
	    $('.scroller').each(function(){
	        $(this).slimScroll({
	            height: $(this).attr('data-height') || '100%',
	            color: $(this).attr('data-color') || '#71808f',
	            railOpacity: '0.9',
	            size: '4px',
	        });
	    });

      $('.slimScrollBar').hide();

		// PANEL ACTIONS
	    // ======================

	    $('.ibox-collapse').click(function(){
	    	var ibox = $(this).closest('div.ibox');
	    	ibox.toggleClass('collapsed-mode').children('.ibox-body').slideToggle(200);
	    });
	    $('.ibox-remove').click(function(){
	    	$(this).closest('div.ibox').remove();
	    });
	    $('.fullscreen-link').click(function(){
	        if($('body').hasClass('fullscreen-mode')) {
	            $('body').removeClass('fullscreen-mode');
	            $(this).closest('div.ibox').removeClass('ibox-fullscreen');
	            $(window).off('keydown',toggleFullscreen);
	        } else {
	            $('body').addClass('fullscreen-mode');
	            $(this).closest('div.ibox').addClass('ibox-fullscreen');
	            $(window).on('keydown', toggleFullscreen);
	        }
	    });
	    function toggleFullscreen(e) {
	        // pressing the ESC key - KEY_ESC = 27
	        if(e.which == 27) {
	            $('body').removeClass('fullscreen-mode');
	            $('.ibox-fullscreen').removeClass('ibox-fullscreen');
	            $(window).off('keydown',toggleFullscreen);
	        }
	    }

  }

  static showCustomTooltip(show: boolean, elementId: string, placement?: string, title?: string) {
    if (show) {
      $(`#${elementId}`).tooltip({ trigger: 'manual', placement: placement, title: title });
      $(`#${elementId}`).tooltip('show');
    } else {
      $(`#${elementId}`).tooltip('hide');
    }
  }

  static showPopover(elementId: string) {
    $(`#${elementId}`).popover('toggle');
    $('body').addClass('popover-show');
  }

  static hidePopover(elementId: string) {
    $(`#${elementId}`).popover('toggle');
    $('body').removeClass('popover-show');
  }

  static showToastrNotification(type: string, text: string, options: any) {
    switch (type) {
      case 'success':
        toastr.success(text, options);
        break;
      case 'info':
        toastr.info(text, options);
        break;
      case 'warning':
        toastr.warning(text, options);
        break;
      case 'error':
        toastr.error(text, options);
        break;
      default:
        break;
    }
  }

  static hideModals() {
    $('.modal').modal('hide');
  }

  static setCurrentStep(currentStep: string, nextStep: string) {
    $(`.${currentStep}`).removeClass('current').addClass('done');
    $(`.${nextStep}`).removeClass('disabled').addClass('current');
  }

  static setFinishStep(lastStep: string) {
    $(`.${lastStep}`).addClass('done');
  }

  static changeInputType(type: string, ...elementClasses) {
    elementClasses.forEach(function (className) {
      $(`.${className}`).attr('type', type);
    });
  }

  static addClass(elementId: string, className: string) {
    $(`#${elementId}`).addClass(className);
  }

  static removeClass(elementId: string, className: string) {
    $(`#${elementId}`).removeClass(className);
  }

  static renderMarketPriceChart(elementId: string, dataSeries: any, fromTime: number, toTime, currencySymbol: string, coinName: string) {
    var options = {
      chart: {
        type: 'area',
        height: 400,
        toolbar: {
          show: false,
        },
      },
      colors: ['#14c2dc'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: coinName,
        data: dataSeries
      },

      ],
      markers: {
        size: 0,
        style: 'hollow',
      },
      xaxis: {
        type: 'datetime',
        min: fromTime,
        max: toTime,
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      },
      yaxis: {
        labels: {
          formatter: function (val, index) {
            return new Intl.NumberFormat('en', {
              style: 'currency',
              currency: currencySymbol,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(val);
          }
        }
      },
    };

    if (typeof ApexCharts === 'undefined') {
      console.warn('Apex chart is undefined.');
    } else {
      marketPriceChart = new ApexCharts(
        document.querySelector(`#${elementId}`),
        options
      );
      marketPriceChart.render();
    }
  }

  static updateMarketPriceChart(fromTime: number, toTime) {
    marketPriceChart.updateOptions({
      xaxis: {
        type: 'datetime',
        min: fromTime,
        max: toTime,
        tickAmount: 6,
      },
    });
  }

  static updateMarketPriceDataSeries(fromTime: number, toTime, dataSeries: any, coinName: string) {
    marketPriceChart.updateOptions({
      xaxis: {
        type: 'datetime',
        min: fromTime,
        max: toTime,
        tickAmount: 6,
      },
      series: [{
        name: coinName,
        data: dataSeries
      }],
    });
  }

  static destroyMarketPriceChart() {
    try {
      if (marketPriceChart) {
        marketPriceChart.destroy();
      }
    } catch (e) {
      console.warn(e);
    }
  }

  static WalletLoading(show: boolean) {
    if (show) {
      $('#loading').remove();
      $('body').append("<div id='loading' style='top:80px'><div class='dotiner'><div class='dot dot-1'></div><div class='dot dot-2'></div><div class='dot dot-3'></div></div><span>LOADING</span><svg xmlns='http://www.w3.org/2000/svg' version='1.1'><defs><filter id='goo'><feGaussianBlur in='SourceGraphic' stdDeviation='10' result='blur' /><feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7'/></filter></defs></svg></div>");
      $('.acc-balance').hide();
    } else {
      $('#loading').fadeOut(500);
      setTimeout(function () {
        $('#loading').remove();
      }, 1000);
      $('.syncing').remove();
      $('ul.home-links li:nth-child(3) a').show();
      $('.acc-balance').show();
    }
  }

  static StartLoading(show: boolean) {
    if (show) {
      $('#loading').remove();
      $('body').append("<div id='loading' style='top:80px'><div class='dotiner'><div class='dot dot-1'></div><div class='dot dot-2'></div><div class='dot dot-3'></div></div><span>LOADING</span><svg xmlns='http://www.w3.org/2000/svg' version='1.1'><defs><filter id='goo'><feGaussianBlur in='SourceGraphic' stdDeviation='10' result='blur' /><feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7'/></filter></defs></svg></div>");
    } else {
      $('#loading').fadeOut(500);
      setTimeout(function () {
        $('#loading').remove();
      }, 1000);
    }
  }

  static initLastTransactions(tableClassName) {
    $(function () {
      $("#filter-search").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $(`.${tableClassName} tr`).filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
      });
    });
  }

  static initTransactionsHistory(tableClassName) {
    oTable = $(`.${tableClassName}`).DataTable({
      "ordering": false,
      "info": false,
      "lengthChange": false,
      "fnDrawCallback": function (oSettings) {
        $(oSettings.nTHead).hide();
      },
      "language": {
        "paginate": {
          "previous": "<",
          "next": ">"
        }
      }
    });
    $('#filter-search').keyup(function () {
      oTable.search($(this).val()).draw();
    });
  }

  static initWalletEffects() {
    this.initCoppyToClipboard();
    Waves.displayEffect();
  }

  static initCoppyToClipboard() {
    $(function () {
      new ClipboardJS('[data-clipboard]').on('success', function (e) {
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
      });
    });
  }

  static toBtc(amountInSatoshi: number): string
  {
	return (amountInSatoshi / 100000000).toFixed(8);
  }

  static setSettingFormClass() {
    if ($('section.home').hasClass('setting-form')) {
      $('section.home').removeClass('setting-form');
    } else {
      $('section.home').addClass('setting-form');
    }
  }

  static canSelectCoin(enable: boolean) {
    $('#btn-select-coin').prop( 'disabled', !enable );
    if (enable) {
      $('#dv-select-coin').tooltip('disable');
    } else {
      $('#dv-select-coin').tooltip('enable');
    }
  }
}
