import {Device} from "@webcore/device/Device";
import {Helpers} from "./helpers";
import {BehaviorSubject, timer} from "rxjs";
import {Router} from "@angular/router";
import {DeviceMode} from "@core/models/connect/device-mode.enum";
import {DeviceFirmwareStatus} from "@core/models/connect/device-firmware-status.enum";
import {ProkeyDevice} from "@core/models/connect/prokey-device";
import {UiEvent} from "@core/models/connect/ui-event.enum";
import {FailureType} from "@core/models/connect/failure-type.enum";
import {PinRequest} from "@core/models/connect/pin-request";
import {PassphraseRequest} from "@core/models/connect/passphrase-request";
import {Features} from "@webcore/models/Prokey";
import {Injectable} from "@angular/core";
import {DialogService} from "@shared/dialog/_services/dialog.service";

/** this service is made for fulfilling device needs in the project. */
@Injectable({
  providedIn: 'root'
})
export class WalletDeviceService {
  constructor(private router: Router, private _dialogService: DialogService) {
    this.uiButton$ = this.uiButtonSource.asObservable();
    this.pinRequest$ = this.pinRequestSource.asObservable();
    this.passphraseRequest$ = this.passphraseRequestSource.asObservable();
    this.uiAlert$ = this.uiAlertSource.asObservable();
    this.failureCallBack$ = this.failureCallBackSource.asObservable();
    this.device$ = this.deviceSource.asObservable();
  }

  _webcoreDevice: Device;
  device$;
  uiButton$;
  pinRequest$;
  passphraseRequest$;
  uiAlert$;
  failureCallBack$;
  isForceDisconnect = false;

  private readonly DefaultProkeyLabel: string = 'My Prokey';
  private isInitialized: boolean = false;
  /** this variable prevents device disconnect subscribe. */
  private isDeviceDisconnectSubscribeWorks = true;
  private prokeyDevice: ProkeyDevice = <ProkeyDevice>{};
  private deviceSource = new BehaviorSubject<ProkeyDevice>(this.prokeyDevice);
  private uiButtonSource = new BehaviorSubject<string>('');
  private pinRequestSource = new BehaviorSubject<PinRequest>(<PinRequest>{});
  private passphraseRequestSource = new BehaviorSubject<PassphraseRequest>(<PassphraseRequest>{});
  private failureCallBackSource = new BehaviorSubject<any>(null);
  private uiAlertSource = new BehaviorSubject<UiEvent>(UiEvent.None);
  private _disableButtonRequestDialog: boolean = false;


  get deviceFeatures():Features{
    return this.prokeyDevice.features;
  }

  get WebcoreDevice(): Device{
    return this._webcoreDevice;
  }

  get DisableButtonRequestDialog(): boolean {
    return this._disableButtonRequestDialog;
  }

  set DisableButtonRequestDialog(disableButtonRequestDialog: boolean) {
    this._disableButtonRequestDialog = disableButtonRequestDialog;
  }

  setUiAlertSource(uiEvent: UiEvent) {
    this.uiAlertSource.next(uiEvent);
  }

  isInitial(): boolean {
    return this.isInitialized;
  }

  handleDisconnectedDevice(reload: boolean = false) {
    Helpers.hideModals();
    let disTimer$ = timer(1500);
    disTimer$.subscribe(() => {
      if (reload) {
        window.location.href = '/start';
      } else {
        this.router.navigate(['/start']);
      }
    });
  }

  convertToVersion(features: Features) {
    return `${features.major_version}.${features.minor_version}.${features.patch_version}`;
  }

  async init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this._webcoreDevice = new Device((success: boolean) => {
          if (success == true) {
            this.setUiAlertSource(UiEvent.Device_Initilaized);
            return resolve();
          } else {
            this.setUiAlertSource(UiEvent.Device_NotInitilaized);
            return reject('Device not initialized');
          }
        });

        this._webcoreDevice.AddOnButtonRequestCallBack((buttonRequestType: string): boolean => {
          this.uiButtonSource.next(buttonRequestType);
          return true;
        });

        this._webcoreDevice.AddOnFailureCallBack((failureType: any): void => {
          try {
            if (failureType) {
              let type: FailureType = failureType.code;
              switch (type) {
                case FailureType.Failure_PinInvalid:
                  Helpers.showToastrNotification('warning', 'PIN is invalid for your prokey.', {timeout: 5000});
                  break;
                default:
                  break;
              }
            }
          } catch (e) {
            console.log('Error on failure : ', e);
          }
          this.failureCallBackSource.next(failureType);
        });

        this._webcoreDevice.AddOnPinMatrixRequestCallBack((pinMatrixType: string): void => {
          this._dialogService.closeAll();
          this.pinRequestSource.next(<PinRequest>{showPopup: true, type: pinMatrixType, message: null});
        });

        this._webcoreDevice.AddOnPasspharaseRequestCallBack((): void => {
          this.passphraseRequestSource.next(<PassphraseRequest>{showPopup: true, message: null});
        });

        this._webcoreDevice.AddOnDeviceDisconnectCallBack((): void => {
          this.prokeyDevice.isConnected = false;
          if (this.isDeviceDisconnectSubscribeWorks) {
            this.deviceSource.next(this.prokeyDevice);
          }
        });

        this.isInitialized = true;
      } catch (e) {
        return reject(e);
      }
    });
  }

  async RebootDevice() {
    //! this is to prevent show Remember/Forget button
    this.isDeviceDisconnectSubscribeWorks = false;

    //! The wallet has to be reloaded
    this.handleDisconnectedDevice(true);

    //! Reboot the device
    return await this._webcoreDevice.RebootDevice();
  }

  async connect() {
    try {
      const res = await this._webcoreDevice.TransportConnect();
      if (res.success === true) {
        this.prokeyDevice.isConnected = true;
        this.initializeDevice();
      }
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  /** deivceFeathers is faster way to get features */
  async getFeatures() {
    return await this._webcoreDevice.GetFeatures();
  }

  async getVersion() {
    return this.convertToVersion(this.deviceFeatures);
  }

  async changeLabel(label: string) {
    return await this._webcoreDevice.ChangeLabel(label);
  }

  async changePin(remove: boolean) {
    return await this._webcoreDevice.ChangePin(remove);
  }

  async cancelAction() {
    return await this._webcoreDevice.Cancel();
  }

  async wipeDevice() {
    return await this._webcoreDevice.WipeDevice();
  }

  async resetDevice() {
    return await this._webcoreDevice.Reset(true, this.DefaultProkeyLabel, 24);
  }

  async backupDevice() {
    return await this._webcoreDevice.Backup();
  }

  async setPassphrase(enable: boolean) {
    if (enable) {
      return await this._webcoreDevice.EnablePassphrase();
    } else {
      return await this._webcoreDevice.DisablePassphrase();
    }
  }

  async receivePin(pin: string) {
    return await this._webcoreDevice.PinMatrixAck(pin);
  }

  async receivePassphrase(value: string) {
    return await this._webcoreDevice.PassphraseAck(value);
  }

  initializeDevice() {
    this._webcoreDevice.Initialize().then(
      (features: Features) => {
        this.prokeyDevice.features = features;
        this.prokeyDevice.label = features.label;
        if (features.bootloader_mode === true) {
          this.prokeyDevice.mode = DeviceMode.Bootloader;
          if (features.firmware_present === true) {
            this.prokeyDevice.firmware = DeviceFirmwareStatus.Valid;
          } else {
            this.prokeyDevice.firmware = DeviceFirmwareStatus.Required;
          }
        } else if (features.initialized === true) {
          this.prokeyDevice.mode = DeviceMode.Normal;
        } else {
          this.prokeyDevice.mode = DeviceMode.Initialize;
        }
        this.deviceSource.next(this.prokeyDevice);
      }).catch((reason) => {
      console.warn(reason);
    });
  }
}
