import { Injectable } from '@angular/core';
import {AuthConfig, OAuthService} from "angular-oauth2-oidc";
import { DialogService } from '@shared/dialog/_services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleOath2ProviderService {
  private readonly scopes = [
    {name: "openid", scope: "openid"},
    {name: "profile", scope: "profile"},
    {name: "email", scope: "email"},
    {name: "google drive", scope: "https://www.googleapis.com/auth/drive.appdata"},
  ];
  private readonly oAuthConfig: AuthConfig = {
    issuer: 'https://accounts.google.com',
    strictDiscoveryDocumentValidation: false,
    redirectUri: 'https://wallet.prokey.io',
    clientId: '559533547551-gha4dt8h6sd4l8foqd5gal1i6d6bo3jr.apps.googleusercontent.com',
    scope: this.scopes.map((value, index) => value.scope)
        .reduce((previousValue, currentValue) => previousValue + " " + currentValue)
  }

  constructor(private oAuthService: OAuthService, private dialogService: DialogService) {
    oAuthService.configure(this.oAuthConfig);
    oAuthService.setStorage(localStorage);
    oAuthService.setupAutomaticSilentRefresh();
  }

  async fetchToken() {
    // this.oAuthService.checkSession()
    this.oAuthService.loadDiscoveryDocument().then(() => {
      this.oAuthService.tryLoginImplicitFlow({
        // onTokenReceived: receivedTokens => {
        //
        // }
      }).then(() => {
      }).catch(error => {
        console.log(error);
      });
    }).catch(error => {
      console.log(error);
    });
  }

  isLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  haveSufficientScopes(): boolean {
    let grantedScopes = this.oAuthService.getGrantedScopes() as Array<string>;
    for (const scope of this.scopes) {
      if (!grantedScopes.find((grantedScope, index )=> scope.scope.toLowerCase() == grantedScope.toLowerCase())) {
        return false;
      }
    }
    return true;
  }

  async logIn() {
    let isLoggedIn = this.isLoggedIn();
    if (!isLoggedIn) {
      await this.oAuthService.initLoginFlow();
    } else {
      await this.oAuthService.loadUserProfile();
    }

  }

  logout() {
    this.oAuthService.logOut();
  }

  getAccessToken(): string {
    return this.oAuthService.getAccessToken();
  }
}
