import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgForm} from '@angular/forms';
import {WalletService} from '@core/services/wallet.service';
import {CoinModel} from '@core/models/connect/coin-model';
import {AccountInfo} from '@core/models/connect/account-info';
import {SignAndVerifyMessageModel} from '../models/sign-message';
import {AlertModel} from '@shared/models/alert-model';
import {AlertType} from '@shared/models/alert-type.enum';
import {FailureType} from '@core/models/connect/failure-type.enum';
import * as PathUtil from "@webcore/utils/pathUtils";
import {WalletDeviceService} from "@core/services/wallet-device.service";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import { AddressModel } from '@webcore/models/Prokey';

@Component({
  selector: 'app-sign-verify',
  templateUrl: './sign-verify.component.html',
  styleUrls: ['./sign-verify.component.css']
})
export class SignVerifyComponent implements OnInit, OnDestroy {

  coinSubscription: Subscription;
  accountSubscription: Subscription;
  failureSubscription: Subscription;

  coin: CoinModel;
  account: AccountInfo;
  signModel: SignAndVerifyMessageModel = <SignAndVerifyMessageModel>{};
  verifyModel: SignAndVerifyMessageModel = <SignAndVerifyMessageModel>{};
  alertModel: AlertModel = <AlertModel>{ show: false };
  currentPath: string = '';
  actionInForm: boolean = false;
  isSignAddressInvalid = false;
  isSignAddressFounded = false;
  signAddressPath: Array<number> = [];
  validAddresses: Array<AddressModel> = [];

  @ViewChild('signForm', { static: true }) public signForm: NgForm;
  @ViewChild('verifyForm', { static: true }) public verifyForm: NgForm;

  constructor(private _deviceService: WalletDeviceService,
              private _walletService: WalletService,
              private _dialogService: DialogService) {
  }

  ngOnInit() {
    this.subscribeCoin();
    this.subscribeAccount();
    this.subscribeFailure();
  }

  subscribeCoin() {
    this.coinSubscription = this._walletService.onCurrentWalletChange.subscribe(() => {
      this.coin = this._walletService.CurrentWalletCoin;
      this.refresh();
    });
  }

  subscribeAccount() {
    this.accountSubscription = this._walletService.onCurrentAccountChange.subscribe(currentAccount => {
      this.account = currentAccount;
      this.currentPath = PathUtil.getSerializedPath(this.account.lastUnusedAddress.path);
      this.setValidAddresses();
      this.refresh();
    });
  }

  subscribeFailure() {
    this.failureSubscription = this._deviceService.failureCallBack$.subscribe(failureType => {
      if (this.actionInForm && failureType && failureType.code == FailureType.Failure_InvalidSignature) {
        this.setAlert(AlertType.Warning, 'Invalid Signature');
      }
      if (this.actionInForm && failureType && failureType.code == FailureType.Failure_DataError) {
        this.setAlert(AlertType.Warning, 'Invalid Signature');
      }

      this._dialogService.closeActiveModal();
    });
  }

  async btnTabSignClick() {
    this.alertModel.show = false;
  }

  async btnSignClick() {
    if(this.isSignAddressFounded == false){
      return;
    }

    this.actionInForm = true;

    try {
      let signResult = await this._walletService.CurrentWallet.signMessage(this.signAddressPath, this.signModel.message);
      if (signResult) {
        this._dialogService.closeActiveModal();
        this.signModel.address = signResult.address;
        this.signModel.signature = signResult.signature;
        this.verifyModel.coin = this.coin.coinInfo.shortcut;
      } else {
        this._dialogService.closeActiveModal();
        this.setAlert(AlertType.Warning, `Error in sign message`);
      }
    } catch (e) {
      this._dialogService.closeActiveModal();
      this.setAlert(AlertType.Warning, `Error in sign message : ${e}`);
    }
  }

  async btnVerifyClick() {
    this.actionInForm = true;
    try {
      let verifyResult = await this._walletService.CurrentWallet.verifyMessage(this.verifyModel.address, this.verifyModel.message, this.verifyModel.signature);
      if (verifyResult) {
        this._dialogService.closeActiveModal();
        this.setAlert(AlertType.Success, 'Message successfully verified.');
      } else {
        this._dialogService.closeActiveModal();
        this.setAlert(AlertType.Warning, `Error in verify message`);
      }
    } catch (e) {
      this._dialogService.closeActiveModal();
      this.setAlert(AlertType.Warning, `Error in verify message`);
    }
  }

  btnClearSignFormClick(signForm: NgForm) {
    let model: SignAndVerifyMessageModel = <SignAndVerifyMessageModel>{ coin: this.coin.coinInfo.shortcut };
    signForm.resetForm(model);
  }

  txtSignAddressChange(address: string) {
    this.isSignAddressFounded = false;

    if(address.length <= 0){
      this.isSignAddressInvalid = false;
      return;
    }

    if(this.account == null) {
      return;
    }

    if (this.account.allAddresses) {
      this.account.allAddresses.forEach(element => {
        if(address == element.address) {
          this.isSignAddressFounded = true;
          this.signAddressPath = element.path;
        }
      });
  
      if (this.isSignAddressFounded) {
        return;
      }
    }
   
   if (this.validAddresses.length > 0) {
    this.validAddresses.forEach(validAddress => {
      if (address == validAddress.address) {
        this.isSignAddressFounded = true;
        this.signAddressPath = validAddress.path;
      }
    });
   }

    this.isSignAddressInvalid = !this.isSignAddressFounded;
  }

  clearVerify(veifyForm: NgForm) {
    let model: SignAndVerifyMessageModel = <SignAndVerifyMessageModel>{ coin: this.coin.coinInfo.shortcut };
    veifyForm.resetForm(model);
  }

  refresh() {
    if (this.signForm != undefined) {
      this.btnClearSignFormClick(this.signForm);
    }

    if (this.verifyForm != undefined) {
      this.clearVerify(this.verifyForm);
    }
  }

  setAlert(type: AlertType, content: string) {
    this.alertModel.type = type;
    this.alertModel.content = content;
    this.alertModel.show = true;
    this.alertModel.dismissing = true;
  }

  setValidAddresses() {
    if (this.account.hasAccountPerEachAddress === true) {
      this.validAddresses.push(this.account.lastUnusedAddress);
    } else {
      this._walletService.CurrentWallet.getAddresses(this.account).then((addresses) => {
        this.validAddresses = addresses;
      });
    }
  }

  ngOnDestroy() {
    this.coinSubscription.unsubscribe();
    this.accountSubscription.unsubscribe();
    this.failureSubscription.unsubscribe();
  }
}
