import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import {Helpers} from '../../services/helpers';
import {ScriptLoaderService} from '../../services/script-loader.service';
import { LayoutMode } from '@core/models/layout-mode';
import { LayoutService } from '@core/services/layout.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-wrapper',
  templateUrl: './wallet-layout.component.html',
  styleUrls: ['./wallet-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WalletLayoutComponent implements OnInit, OnDestroy {

  constructor(private _scriptService: ScriptLoaderService,
              private _layoutService: LayoutService,
              private _ref: ChangeDetectorRef) {
  }

  layoutMode: LayoutMode;
  changeLayoutSubscription: Subscription;

  async ngOnInit() {
    this._changeLayoutSubscribe();
    this._loadScripts();
  }

  private _loadScripts(): void {
    this._scriptService.load(
      './assets/js/wallet/chart.min.js',
      'https://cdn.datatables.net/1.10.11/js/jquery.dataTables.min.js',
      './assets/js/wallet/script.js')
      .then(() => {
        Helpers.initWalletEffects();
        Helpers.WalletLoading(true);
      }, error => {
        console.warn('Error on script loaded in wallet ', error);
      });
  }

  private _changeLayoutSubscribe() {
    this.changeLayoutSubscription = this._layoutService.onLayoutChange.subscribe((layout: LayoutMode) => {
          this.layoutMode = layout;
    });
  }

  ngOnDestroy() {
    this.changeLayoutSubscription.unsubscribe();
  }
}