import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DialogService} from "@shared/dialog/_services/dialog.service";

@Component({
  selector: 'app-wallet-no-backup',
  templateUrl: './wallet-no-backup.component.html',
  styleUrls: ['./wallet-no-backup.component.css']
})
export class WalletNoBackupComponent implements OnInit, OnDestroy {

  confirmationSubscription: Subscription;

  constructor(private _dialogService: DialogService) { }

  ngOnInit() {
    this.confirmationSubscribe();
  }

  confirmationSubscribe() {
    // this.confirmationSubscription = this.connectService.uiAlert$.subscribe(uiEvent => {
    //   if (uiEvent === UiEvent.Request_Confirmation) {
    //     let confirm = this.userSettingService.getNoBackupConfirmation();
    //     if (confirm === true) {
    //       this.connectService.receiveConfirmation(true);
    //     } else {
    //       TODO implement it with angular-material
    //       Helpers.showModal(true, 'no-backup-modal', true);
    //     }
    //   }
    // });
  }

  doBackup() {
    // this.connectService.receiveConfirmation(true);
    // this.userSettingService.setNoBackupConfirmation(false);
    // this.close();
    // this.router.navigate(['/wallet/backup']);
  }

  accept() {
    // this.connectService.receiveConfirmation(true);
    // this.userSettingService.setNoBackupConfirmation(true);
    // this.close();
  }

  close() {
    this._dialogService.closeAll();
  }

  ngOnDestroy() {
    if(this.confirmationSubscription){
      this.confirmationSubscription.unsubscribe();
    }
  }

}
