<div class="processing-dialog">
  <ng-container>
    <h5 class="body-title text-left">Cloud access has lost</h5>
    <p class="pt-1 pb-4">
      Wallet Access to your google drive has lost, Do you want to reconnect your account or use local storage as your main storage? 
    </p>
    <button type="button" class="btn btn-gradinet-green waves-effect waves-dark mr-2" style="top:-5px;" (click)="reconnect()">Reconnect</button>
    <button type="button" class="btn btn-gradinet-blue waves-effect waves-dark" style="top:-5px;"(click)="useLocalStorage()">Use local storage</button>
  </ng-container>
</div>
