import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unit'
})
export class CoinUnitPipe implements PipeTransform {

  transform(value: any, decimals?: number): number {

    if (value === undefined || value == null) {
      return 0.00;
    }
    if (value === 0) {
      return 0.00;
    }
    if (decimals == null)
      throw new Error("decimals for coin is null");
    return value / Math.pow(10, decimals);
  }

  ToSatoshi(value: any, decimals?: number): number {

    if (value === undefined || value == null) {
      return 0.00;
    }
    if (value === 0) {
      return 0.00;
    }
    if (decimals == null)
      throw new Error("decimals for coin is null");
    return value * Math.pow(10, decimals);
  }

}
