import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Exchange} from '../../models/exchange';

declare var $: any;

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.css']
})
export class OfferListComponent {
  @Output() selectedOfferEvent = new EventEmitter<Exchange>();
  @Output() timerEvent = new EventEmitter<boolean>();
  @Input() showSkeleton = false;
  @Input() set offers(offers: Array<Exchange>) {
    this._offers = offers;
    if (this.isOfferExist()) {
      this.startTimer();
      $(() => {
        $('[data-toggle="tooltip"]').tooltip()
      });
    } else {
      clearInterval(this._timer);
      $('[data-toggle="tooltip"]').tooltip("hide");
      this.clearOffers();
    }
  }
  get offers() {
    return this._offers;
  }
  _offers: Array<Exchange>;
  _timer;
  timeLeft = 30;
  constructor() { }

  selectOffer(offer: Exchange) {
    this.selectedOfferEvent.emit(offer);
  }

  startTimer() {
    if (this._timer) {
      return;
    }
    this._timer = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this._timer);
        this.clearOffers();
        this.timerEvent.emit(true);
      }
    }, 1000);


  }

  clearOffers() {
    this._offers = undefined;
    this.timeLeft = 30;
    this._timer = null;
  }

  isOfferExist(): boolean {
    return this._offers != null && this._offers[0] != null;
  }

  getOfferRate(offer: Exchange): string {
    return (offer.receiveCoinAmount / offer.sendAmount).toFixed(5);
  }
}
