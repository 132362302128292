import {AccountBrief} from "@core/models/connect/account-brief";
import {AccountInfo} from "@core/models/connect/account-info";
import {BaseOutput} from "@core/models/connect/base-output";
import {CoinModel} from "@core/models/connect/coin-model";
import {Transaction} from "@core/models/connect/transaction";
import {Wallet} from "./wallet";
import {Device} from '@webcore/device/Device';
import {RippleWallet} from '@webcore/wallet/RippleWallet';
import {AddressModel, RippleAddress, RippleSignedTx} from "@webcore/models/Prokey";
import * as PathUtil from "@webcore/utils/pathUtils";
import {CoinBaseType} from "@webcore/coins/CoinInfo";
import {RippleCoinInfoModel} from "@webcore/models/CoinInfoModel";
import {BitcoinFeeSelectionModel} from "@webcore/models/FeeSelectionModel";
import {TransactionType} from "@core/models/connect/transaction-type.enum";
import {ExtraId} from "@core/models/connect/extra-id";
import {MyConsole} from "@webcore/utils/console";
import { RippleAccountInfo, RippleWalletModel } from '@webcore/models/RippleWalletModel';
import {GenericSentTransactionResult} from "@webcore/models/GenericWalletModel";

export class Ripple extends Wallet {

  _accountInfo: Array<AccountInfo>;
  _rippleWallet: RippleWalletModel;
  _fee: BitcoinFeeSelectionModel;


  /**
   Ripple
   */
  constructor(device: Device, coinModel: CoinModel) {
    super(device, coinModel);
    this._webcoreBaseWallet = new RippleWallet(device, coinModel.coinInfo.name);
    this._accountInfo = new Array<AccountInfo>(1);
    this._accountInfo.push(
      {
        lastUnusedAddress: this.getPathOfFirstAddress(),
        balance: 0,
        id: 0,
        transactions: 0,
        xpub: 'test'
      }
    );
    this._rippleWallet = {
      totalBalance: 0,
      accounts: new Array<RippleAccountInfo>()
    }
  }

  //#region Wallet methods(inherited)

  /**
   * Get count of accounts, -1 means no account at all
   * @returns
   */
  get AccountsCount(): number {
    if (this._rippleWallet.accounts == null) {
      return -1;
    }
    return this._rippleWallet.accounts.length;
  }

  ClearCache(): void {
    this._currentAccountIndex = 0;
    this._isAccountDiscoveryFinished = false;
    this._rippleWallet = {
      totalBalance: 0,
      accounts: new Array<RippleAccountInfo>()
    };
    this.NeedToReSync = false;
  }

  StartAccountDiscovery(onNewAccountCallBack: (discoveredAccountNumber: number) => void): Promise<void> {
    return new Promise<void>((resolve, reject)=> {
      if (this._rippleWallet.accounts && this._rippleWallet.accounts.length > 0) {
        if (onNewAccountCallBack) {
          for (let accountCounter = 0; accountCounter < this._rippleWallet.accounts.length; accountCounter++) {
            onNewAccountCallBack(accountCounter);
          }
          this._isAccountDiscoveryFinished = true;
          return resolve();
        }
      } else {
        this._rippleWallet.accounts = new Array<RippleAccountInfo>();
      }
      try {
        let accountCounter = 0;
        (this._webcoreBaseWallet as RippleWallet).StartDiscovery(accountInfo => {
          this._rippleWallet.accounts.push(accountInfo);
          this._rippleWallet.totalBalance += +accountInfo.Balance!;
          if (onNewAccountCallBack) {
            onNewAccountCallBack(accountCounter++);
          }
        }).then(() => {
          this._isAccountDiscoveryFinished = true;
          this._totalBalance = this._rippleWallet.totalBalance;
          return resolve();
        });
      } catch (e) {
        this._isAccountDiscoveryFinished = false;
        return reject(e);
      }
    });
  }

  getAllAccountsInfo?(): AccountInfo[] {
    if (this._rippleWallet.accounts?.length > 0) {
      let accountCounter = 0;
      return this._rippleWallet.accounts.map((rippleAccountInfo: RippleAccountInfo) => {
        return this._ToAccountInfo(accountCounter++);
      })
    }
    return [];
  }

  getAccountsCount(): number {
    if(this._rippleWallet.accounts == null) {
      return -1;
    }
    return this._rippleWallet.accounts.length;
  }

  canAddNewAccount(): boolean {
    if(this._isAccountDiscoveryFinished == false || this._isNewAccountAlreadyAdded == true) {
      return false;
    }

    if(this._rippleWallet.accounts == null || this._rippleWallet.accounts.length == 0) {
      return false;
    }

    const lastAccount = this._rippleWallet.accounts[this._rippleWallet.accounts.length - 1];
    return +lastAccount.Balance != 0;
  }

  async _addNewAccount(): Promise<number> {
    if(this._rippleWallet.accounts == null) {
      return -1;
    }

    let addressModel = PathUtil.GetBipPath(
      CoinBaseType.Ripple,
      this._rippleWallet.accounts.length,
      this.CoinInfo
    );

    addressModel.address = await this.getAddress(addressModel.path, false);

    let newAccount: RippleAccountInfo = {
      Account: addressModel.address,
      Balance: "0",
      OwnerCount: undefined,
      PreviousTxnId: undefined,
      PreviousTxnLgrSeq: undefined,
      Sequence: undefined,
      TickSize: undefined,
      TransferRate: undefined,
      LedgerEntryType: undefined,
      Flags: undefined,

      isAccountFounded: false,
      addressModel: addressModel,
    }
    //TODO: Set the account address

    this._rippleWallet.accounts.push(newAccount);
    this._isNewAccountAlreadyAdded = true;

    return this._rippleWallet.accounts.length - 1;
  }

  getWebcoreAccountInfo(accountIndex: number) {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;
    return this._rippleWallet.accounts[ai];
  }

  getAccountBrief(accountIndex: number): AccountBrief {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;
    const accInfo = this._rippleWallet.accounts[ai];

    return <AccountBrief>{
      id: ai,
      balance: +accInfo.Balance,
      label: `Account #${ai + 1}`,
    }
  }

  getAccountInfo(accountIndex: number): AccountInfo {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;

    return this._ToAccountInfo(ai);
  }

  setCurrentAccount(accountIndex: number): void {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    this._currentAccountIndex = accountIndex;
  }

  getPublicKey(account: number, showOnProkey: boolean): Promise<any> {
    //! No public key
    return null;
  }

  async getAddress(path: number[], showOnProkey: boolean): Promise<any> {
    console.log("hmm");
    console.log(path);
    return await this._webcoreBaseWallet.GetAddress<RippleAddress>(path, showOnProkey);
  }

  getAddresses(accountInfo: AccountInfo): Promise<any> {
    return this._webcoreBaseWallet.GetAddresses<RippleAddress>([accountInfo.lastUnusedAddress.path]);
  }

  // async getAccountInfo(coin: CoinModel, changeDiscovery?: boolean): Promise<any> {
  //     this._rippleWallet.accounts = await (this._webcoreBaseWallet  as RippleWallet).StartDiscovery((accountInfo) => {
  //         MyConsole.Info("new ripple account", accountInfo);
  //     });

  //     let accounts = new Array<AccountBrief>();
  //     this._accountInfo.length = 0;
  //     if (this._rippleWallet.accounts.length > 0) {
  //         let i = 0;
  //         for (i = 0; i < this._rippleWallet.accounts.length; i++) {
  //             accounts.push({
  //                 id: i, label: `Account ${i + 1}`,
  //                 balance: this._rippleWallet.accounts[i].Balance == null ? 0 : +this._rippleWallet.accounts[i].Balance,
  //                 balancePrice: 0,
  //                 isNewAccount: false,
  //                 isDiscoveryFinished: true,
  //             });

  //             this._accountInfo.push({
  //                 addressModel: {
  //                     address: this._rippleWallet.accounts[i].Account,
  //                     path: this._rippleWallet.accounts[i].addressModel.path
  //                 },
  //                 balance: +this._rippleWallet.accounts[i].Balance,
  //                 id: i,
  //                 transactions: 0,
  //                 xpub: 'test',
  //                 hasAccountPerEachAddress: true
  //             });

  //             if (changeDiscovery && this._isCurrentWallet === true) {
  //                 this._changeDiscoveryAccount(accounts[i]);
  //             }
  //         }
  //         // Add a new empty account to enable add account button
  //         accounts.push({
  //             id: i, label: `Account ${i + 1}`,
  //             balance: 0,
  //             balancePrice: 0,
  //             isNewAccount: true,
  //             isDiscoveryFinished: true
  //         });

  //         this._accountInfo.push({
  //             addressModel: PathUtil.GetBipPath(CoinBaseType.Ripple, i, this._coinModel.coinInfo),
  //             balance: 0,
  //             id: i,
  //             transactions: 0,
  //             xpub: 'test',
  //             hasAccountPerEachAddress: true
  //         });
  //         if (changeDiscovery && this._isCurrentWallet === true) {
  //             this._changeDiscoveryAccount(accounts[i]);
  //         }
  //     }
  //     else {
  //         accounts = [{ id: 0, label: `Account 1`, balance: 0, balancePrice: 0, isNewAccount: true, isDiscoveryFinished: true }];
  //         this._accountInfo.push({
  //             addressModel: this.getPathOfFirstAddress(),
  //             balance: 0,
  //             id: 0,
  //             transactions: 0,
  //             xpub: 'test',
  //             hasAccountPerEachAddress: true
  //         });

  //         if (changeDiscovery && this._isCurrentWallet === true) {
  //             this._changeDiscoveryAccount(accounts[0]);
  //         }
  //     }

  //     return accounts;
  // }

  async signMessage(path: number[], message: string): Promise<any> {
    const coinInfo = this._webcoreBaseWallet.GetCoinInfo() as RippleCoinInfoModel;
    return await this._webcoreBaseWallet.SignMessage(path, message, coinInfo.on_device);
  }

  async verifyMessage(address: string, message: string, signature: string): Promise<any> {
    const coinInfo = this._webcoreBaseWallet.GetCoinInfo() as RippleCoinInfoModel;
    return await this._webcoreBaseWallet.VerifyMessage(address, message, signature, coinInfo.on_device);
  }

  prepareSendModel(output: BaseOutput, ...params: any[]) {
    const amount = +output.amount * Math.pow(10, this._coinModel.coinInfo.decimals);
    const fee = this._fee[params[1].toLowerCase()];
    const destinationTag = output.extraIdValue;
    return (this._webcoreBaseWallet as RippleWallet).GenerateTransaction(output.address, amount, params[0].id, fee, +destinationTag);
  }

  async sendTransaction(model: any): Promise<GenericSentTransactionResult> {
    let tx = await this._webcoreBaseWallet.SignTransaction(model) as RippleSignedTx;
    MyConsole.Info("tx", tx);
    let sendTxResponse = await (this._webcoreBaseWallet as RippleWallet).SendTransaction(tx);
    MyConsole.Info("ripple_send", sendTxResponse);
    let generalResponse = <GenericSentTransactionResult>{};
    generalResponse.isSuccess = sendTxResponse.broadcast;
    if (generalResponse.isSuccess) {
      generalResponse.txid = sendTxResponse.tx_json.hash;
    } else {
      generalResponse.error = sendTxResponse.engine_result_message;
    }
    return generalResponse;
  }

  handleSendResult(payload: any): Promise<any> {
    MyConsole.Info("payload", payload);
    return payload;
  }

  async calculateTransactionFee(outputValue: BaseOutput, fromAccount: number) {
    let ripple_fee = await (this._webcoreBaseWallet as RippleWallet).GetCurrentFee();
    this._fee = {
      economy: ripple_fee.drops.minimum_fee,
      normal: (+ripple_fee.drops.median_fee / 2).toString(),
      priority: ripple_fee.drops.median_fee,
      unit: this._coinModel.coinInfo.shortcut,
      decimal: this._coinModel.coinInfo.decimals
    };
    return this._fee;
  }

  async getAccountTransactionsBy(accountNumber: number, startIndex: number, numberOfTransactions: number): Promise<Transaction[]> {
    let txes = new Array<Transaction>();
    if (this._rippleWallet.accounts.length == 0 || this._rippleWallet.accounts.length <= accountNumber)
      return txes;

    let accountTxes = await (this._webcoreBaseWallet as RippleWallet).GetAccountTransactions(accountNumber);
    accountTxes.forEach(element => {
      if (element.tx.TransactionType == "Payment") {
        let transaction = <Transaction>{};
        transaction.targets = [];
        transaction.hash = element.tx.hash;
        //transaction.date = new Date(element.tx. * 1000 + 946684800000).toString();
        transaction.isValid = element.meta.TransactionResult == "tesSUCCESS";
        if (!transaction.isValid)
          transaction.invalidReason = element.meta.transactionResult;
        if (element.tx.Destination == this._rippleWallet.accounts[accountNumber].Account) {
          transaction.type = TransactionType.Received;
          transaction.targets.push({
            address: element.tx.Account,
            value: +element.tx.Amount,
            status: element.validated ? "Validated" : "NOT validated"
          });
        } else {
          transaction.type = TransactionType.Sent;
          transaction.targets.push({
            address: element.tx.Destination,
            value: +element.tx.Amount,
            status: element.validated ? "Validated" : "NOT validated"
          });
        }
        txes.push(transaction);
      }
    });
    return txes;
  }

//#endregion

  //#region Private method

  isAddressValid(address: string): boolean {
    return this._webcoreBaseWallet.IsAddressValid(address);
  }

  /**
   * This function will return the first path of the first account
   * @returns The path of Account 0 Path 0
   */
  getPathOfFirstAddress(): AddressModel {
    return PathUtil.GetBipPath(
      CoinBaseType.Ripple,
      0,
      this._coinModel.coinInfo
    );
  }

  getCoinExtraIdTypes(): Array<ExtraId> {
    return [{label: 'Destination tag', type: 'destinationTag', default: true}];
  }

  private _ToAccountInfo(rippleAccountIndex: number): AccountInfo {
    let defaultAccount: AccountInfo = <AccountInfo>{};
    let rippleAccount = this._rippleWallet.accounts[rippleAccountIndex];

    if (rippleAccount == null) {
      return defaultAccount;
    }

    defaultAccount = {
      lastUnusedAddress: rippleAccount.addressModel,
      balance: +rippleAccount.Balance,
      id: rippleAccountIndex,
      transactions: 0,
      xpub: 'test',
      hasAccountPerEachAddress: true
    }

    return defaultAccount;
  }

  private _ensureAccountDiscoveryIsDone(accountIndex?: number) {
    if (this._rippleWallet.accounts == null) {
      throw new Error("No wallet info, Call AccountDiscovery first");
    }

    if (accountIndex != null && (accountIndex < 0 || accountIndex >= this._rippleWallet.accounts.length)) {
      throw new Error("Account index is out of range");
    }
  }
  //#endregion

}
