import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

/* This component is responsible for displaying wipe device dialog. */
@Component({
  selector: 'app-wipe-device-dialog',
  templateUrl: './wipe-device-dialog.component.html',
  styleUrls: ['./wipe-device-dialog.component.css']
})
export class WipeDeviceDialogComponent {

  /** constructor */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
