import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {TransportInterface} from '@core/models/bootloader/transportInterface';
import {Features} from '@webcore/models/Prokey';
import {ChallengeMessage} from '@core/models/bootloader/ChallengeMessage';
import {ChallengeErrorCode} from '@core/models/bootloader/ChallengeErrors';
import {RestUrls} from "../../../_configs/rest-urls";
import { FirmwareSignatures } from '@models/bootloader/FirmwareSignatures';

declare const Buffer

@Injectable()
export class BootloaderService {
  constructor(private http: HttpClient) {
  }

  _transport: TransportInterface;

  public SetTransport(transport: TransportInterface): void {
    this._transport = transport;
  }


  public static ProtobufToFeatures(buf: string): Features {
    let f: Features = <Features>{};

    if (buf.length < 4) {
      return null;
    }

    while (buf.length > 0) {
      try {
        // CB for control byte
        var cb = Number.parseInt(buf.substring(0, 2), 16);
        var type = cb & 0x07;
        var field = (cb >> 3) & 0x0F;

        if ((cb & 0x80) == 0x80) {
          buf = buf.substring(2);
          cb = Number.parseInt(buf.substring(0, 2), 16);
          field |= (cb<<4);
        }

        var tmp: string;
        var num: number;

        if (type == 0) {  // Varint
          num = Number.parseInt(buf.substring(2, 4), 16);
          buf = buf.substring(4);
        }
        else if (type == 2) { // Length-delimited
          var len = Number.parseInt(buf.substring(2, 4), 16);
          tmp = buf.substring(4, 4 + len * 2);
          buf = buf.substring(4 + len * 2);
        }
        else {
          throw new Error("MSG type is not supported");
        }

        if (field == 1) {
          f.vendor = new Buffer(tmp, 'hex').toString();
        }
        else if (field == 2) {
          f.major_version = num;
        }
        else if (field == 3) {
          f.minor_version = num;
        }
        else if (field == 4) {
          f.patch_version = num;
        }
        else if (field == 5) {
          if (num == 1) {
            f.bootloader_mode = true;
          } else {
            f.bootloader_mode = false;
          }
        }
        else if (field == 18) {
          if (num == 1) {
            f.firmware_present = true;
          } else {
            f.firmware_present = false;
          }
        }

      } catch {
        return null;
      }
    }

    return f;
  }

  /** Set firmware signatures
   * ONLY WORKS WITH BOOTLOADER > 3.x.x
   */
  public async SetFirmwareSignatures(updateSignature?: (signature:FirmwareSignatures) => void): Promise<ChallengeMessage> {
    //! Get firmware signature data from server
    var res = await this.SendToServer(RestUrls.getOptimumFirmwareSignatures(), <ChallengeMessage>{});
    if(res.success == false) {
      return res;
    }

    //! Parse the model
    const signatures: FirmwareSignatures = JSON.parse(res.payload);
    if(signatures == null) {
      return { success: false, errorCode: ChallengeErrorCode.CANNOT_PARS_FIRMWARE_SIGNATURE_DATA };
    } 

    if(signatures.signatures.length != 3) {
      return { success: false, errorCode: ChallengeErrorCode.THREE_FIRMWARE_SIGNATURE_NEEDED };
    }

    if(signatures.signatures[0].signature.length != 128) {
      return { success: false, errorCode: ChallengeErrorCode.WRONG_FIRMWARE_SIGNATURE_LENGHT };
    }

    if(signatures.signatures[1].signature.length != 128) {
      return { success: false, errorCode: ChallengeErrorCode.WRONG_FIRMWARE_SIGNATURE_LENGHT };
    }

    if(signatures.signatures[2].signature.length != 128) {
      return { success: false, errorCode: ChallengeErrorCode.WRONG_FIRMWARE_SIGNATURE_LENGHT };
    }

    if(updateSignature) {
      updateSignature(signatures);
    }

    //! Prepare data to be sent to device
    //! MsgID
    let msg = "FFF9"

    //! 202 bytes of data
    msg += "000000CA";

    // message MsgSetFirmwareSignature
    // {
    //    required uint32 Version	    = 1;
    //    required bytes  Indexes     = 2;     
    //    required bytes  Signatures  = 3;  
    // }

    //! Version
    msg += `080${signatures.version}`;

    //! Indexes, 
    //! Field: 2, type: Length-delimited + len = 3
    msg += "1203";

    //! Each SignIndex is a number between 1 to 5, So, to make it simple :), no need to convert this number to hex
    //! sigIndex 1
    msg += `0${signatures.signatures[0].index}`;
    //! sigIndex 2
    msg += `0${signatures.signatures[1].index}`;
    //! sigIndex 3
    msg += `0${signatures.signatures[2].index}`;

    //! Field: 3, type: Length-delimited + len = 192
    msg += `1AC001`;

    //! 64 bytes of first signature, Already in hex string
    msg += signatures.signatures[0].signature;

    //! second signature, Already in hex string
    msg += signatures.signatures[1].signature;

    //! third signature, Already in hex string
    msg += signatures.signatures[2].signature;

    //! Send message to device
    res = await this._transport.Call(msg);
    
    //! Check device response
    res = this.CheckDeviceMsg(res.payload);

    return res;
  }

  public async UpdateFirmware(chgMessage: ChallengeMessage, ): Promise<ChallengeMessage> {
    if (chgMessage === undefined) {
      return { success: false, errorCode: ChallengeErrorCode.WRONG_PARAM };
    }

    var res = await this.SendToServer(RestUrls.getBootloaderLatestFirmware(), chgMessage);
    if (res.success == false) {
      return res;
    }

    res = await this._transport.Call(res.payload);
    if (res.success == false)
      return res;

    res = this.CheckDeviceMsg(res.payload);

    return res;
  }

  public async EraseFlash(): Promise<ChallengeMessage> {
    var res = await this._transport.Call("000600000000");
    if (res.success == false)
      return res;

    res = this.CheckDeviceMsg(res.payload);

    return res;
  }

  public async WipeDevice(): Promise<ChallengeMessage> {
    var res = await this._transport.Call("000500000000");
    if(res.success == false) {
      return res;
    }

    res = this.CheckDeviceMsg(res.payload);

    return res;
  }

  public async GetFeatures(): Promise<ChallengeMessage> {
    var res = await this._transport.Call("000000000000");
    if (res.success == false)
      return res;

    var err = this.CheckDeviceMsg(res.payload);
    if (err.success == false) {
      return err;
    }

    res.payload = res.payload.substring(12);
    var f = BootloaderService.ProtobufToFeatures(res.payload);

    return {
      success: true,
      payload: f
    };
  }

  public async ResetDevice(): Promise<ChallengeMessage> {
    var tmp = await this._transport.Call("FFF000000000");

    return { success: true, errorCode: ChallengeErrorCode.NO_ERR };
  }

  public async TestDevice(): Promise<ChallengeMessage> {
    var cmd = "0020000000370A3500FF55AA669933CC4142434445464748494A4B4C4D4E4F505152535455565758595A303132333435363738392100FF55AA669933CC";
    var res = await this._transport.Call(cmd);
    if (res.success == false)
      return res;

    return this.CheckDeviceMsg(res.payload);
  }

  public CheckDeviceMsg(msg: string): ChallengeMessage {
    if (msg == null || msg === undefined) {
      return { success: false, }
    }

    if (msg.length < 12) {
      return {
        success: false,
        errorCode: ChallengeErrorCode.PROTO_ERR,
      }
    }

    var msgId = msg.substring(0, 4);

    if (msgId == "0002") {
      return { success: true }
    }
    else if (msgId == "0003") {
      var errStr = msg.substring(12);
      var cErrCode: ChallengeErrorCode = ChallengeErrorCode.UNDEFINED;

      if (errStr == "0863")
        cErrCode = ChallengeErrorCode.DEVICE_RETURN_FIRMWARE_ERROR;
      else if (errStr == "0849")
        cErrCode = ChallengeErrorCode.SECURITY_ERR_1;
      else
        cErrCode = Number.parseInt(errStr.substring(2), 16);

      return { success: false, errorCode: cErrCode, }
    }

    return { success: true }
  }

  public SendToServer(url: string, toServer: ChallengeMessage): Promise<ChallengeMessage> {
    const header = new HttpHeaders({"Content-Type": "application/json"});

    return this.http.post<ChallengeMessage>(url, toServer, {headers: header}).toPromise();
  }

  public Reset(): void {
    this._transport.Reset();
  }
}
