import {Component} from '@angular/core';

@Component({
    selector: 'app-wallet-pin-on-device',
    templateUrl: './wallet-pin-on-device.component.html',
    styleUrls: ['./wallet-pin-on-device.component.css']
  })

  export class WalletPinOnDeviceComponent {

  }
