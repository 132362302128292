<ng-container *ngIf="headerMode === 'Portfolio'">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 float-right home-header-right">
    <div class="float-right p-4">
      <ul class="home-links">
        <li tourAnchor="header.storage">
          <img src="{{storageImagePath}}" class="mr-3" data-toggle="tooltip" data-trigger="hover"
               data-placement="bottom" title="Your wallet use {{storageRepresentName}} to store configurations">
        </li>
        <li tourAnchor="header.syncMode">
          <ng-container *ngIf="syncMode === 'Synchronized'">
            <a href="javascript:;" (click)="reDiscovery()" title="Synchoriezed">
              <i class="fa fa-refresh" style="color: #3fd18a;"></i>
              Synchronized
            </a>
          </ng-container>
          <ng-container *ngIf="syncMode === 'Synchronizing'">
            <a href="javascript:;" title="Synchronizing">
              <i class="fa fa-spinner fa-spin text-muted"></i>
              Synchronizing
            </a>
          </ng-container>
          <ng-container *ngIf="syncMode === 'Disconnected'">
            <a href="javascript:;" (click)="reDiscovery()" class="faildblockchain" title="Disconnected">
              <i class="fa fa-refresh"></i>
            Failed to connect blockchain
            </a>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="headerMode !== 'Portfolio'">
  <div id="dv-select-coin" class="col-xl-3 col-lg-3 col-md-12 col-sm-12 float-left p-3 text-center home-header-left"
  data-toggle="tooltip" data-trigger="hover" data-placement="bottom" title="Wallet is busy to discover your accounts, please wait a moment">
  <div class="dropdown">
    <button id="btn-select-coin" class="btn dropdown-toggle coin-nowrap" type="button" data-toggle="dropdown">
      <img src="{{selectedCoin?.getImageUrl()}}" alt="{{selectedCoin?.coinInfo.shortcut}}"
           onerror="if (this.src != './assets/img/coins/tk.png') this.src = './assets/img/coins/tk.png';" class="mr-2" />
      <span>{{selectedCoin?.coinInfo.name}}</span>
      <small>({{selectedCoin?.coinInfo.shortcut}})</small>
    </button>
    <div class="dropdown-menu">
      <a *ngFor="let coin of coins" href="javascript:;" (click)="coinsDropDownItem_OnClick(coin)" class="dropdown-item linebreak">
        <img src="{{coin.getImageUrl()}}" alt="{{coin.coinInfo.shortcut}}" loading="lazy" class="coinmenuimg"
             onerror="if (this.src != './assets/img/coins/tk.png') this.src = './assets/img/coins/tk.png';" />
             <label class="coinmenulbl">{{coin.coinInfo.name}} <small>({{coin.coinInfo.shortcut}})</small>
              <!--hardcode chain id for class-->
              <span *ngIf="IsThisCoinDuplicated(coin.coinInfo.name) && coin.coinInfo.chain_id==1" class="alert-primary erc20tag"> {{coin.coinInfo | tokenTypeToName}} </span>
              <span *ngIf="IsThisCoinDuplicated(coin.coinInfo.name) && coin.coinInfo.chain_id==56" class="alert-primary bep20tag"> {{coin.coinInfo | tokenTypeToName}} </span>
            </label>
      </a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item newcoin cursor-pointer" (click)="openModal()">
        <i class="fas fa-plus"></i>
        Add a new Coin / Token
      </a>
    </div>
  </div>
  </div>
  <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 float-right home-header-right">
    <div class="float-right p-4">
      <ul class="home-links">
        <li>
          <a routerLink="/wallet/settings" title="wallet setting">
            <i class="fas fa-cog"></i>
            Wallet Setting
          </a>
        </li>
        <li>
          <img src="{{storageImagePath}}" class="mr-3" data-toggle="tooltip" data-trigger="hover"
               data-placement="bottom" title="Your wallet use {{storageRepresentName}} to store configurations">
        </li>
        <li>
          <ng-container *ngIf="syncMode === 'Synchronized'">
            <a href="javascript:;" (click)="reDiscovery()" title="Synchoriezed">
              <i class="fa fa-refresh" style="color: #3fd18a;"></i>
              Synchronized
            </a>
          </ng-container>
          <ng-container *ngIf="syncMode === 'Synchronizing'">
            <a href="javascript:;" title="Synchronizing">
              <i class="fa fa-spinner fa-spin text-muted"></i>
              Synchronizing
            </a>
          </ng-container>
          <ng-container *ngIf="syncMode === 'Disconnected'">
            <a href="javascript:;" (click)="reDiscovery()" class="faildblockchain" title="Disconnected">
              <i class="fa fa-refresh"></i>
            Failed to connect blockchain
            </a>
          </ng-container>
        </li>
      </ul>
    </div>
    <div class="float-left p-3">
      <div class="bchaininfo" style="min-width: 180px;">
        <span *ngIf="SelectedCoinIsToken" class="alert-primary tokentag">{{selectedCoin?.coinInfo | tokenTypeToName: true}} <a href="https://support.prokey.io/help-center/articles/1/6/16/crypto-tokens,-erc-20-and-omni" target="_blank"><i class="fas fa-question-circle"></i></a></span>
      </div></div>
  </div>
</ng-container>

