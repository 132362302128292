<header>
  <nav class="nav">
    <div class="container container-nav p-0">
      <div class="logo pull-left">
        <a routerLink="/"><img src="https://statics.prokey.io/assets/img/logo-hr-blue.svg" title="Prokey" alt="Prokey Logo" width="132px" height="60px"/></a>
      </div>
      <div id="mainListDiv" class="main_list">
        <ul class="navlinks">
          <li><a href="https://prokey.io/" title="Home">Home</a></li>
          <li><a href="https://prokey.io/prokey-optimum" title="Shop">Shop</a></li>
          <li><a href="https://prokey.io/blog" title="Blog">Blog</a></li>
          <li class="dropdown">
            <a href="#" title="Wiki" class="dropdown-toggle" data-toggle="dropdown">Get Started</a>
            <div class="dropdown-menu">
              <span class="dropdown-menu-arrow"></span>
              <a class="dropdown-item" href="https://prokey.io/get-started">Setup your Prokey device</a>
              <a class="dropdown-item" href="https://prokey.io/restore-wallet">Restore your Prokey wallet</a>
            </div>
          </li>
          <li><a href="https://support.prokey.io" title="Support">Support</a></li>
          <li><a href="https://wallet.prokey.io" title="Wallet" class="btn-blue waves-effect waves-dark btn-rounded bshadow">Wallet</a></li>
        </ul>
      </div>
      <span class="navTrigger">
      <i></i>
      <i></i>
      <i></i>
    </span>
    </div>
  </nav>
</header>
