import {CoinUnitPipe} from '@shared/pipes/coin-unit.pipe';
import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DecimalPipe} from '@angular/common';
import jsQR from "jsqr";
import {WalletService} from '@core/services/wallet.service';
import {CoinModel} from '@core/models/connect/coin-model';
import {AccountInfo} from '@core/models/connect/account-info';
import {BaseOutput} from '@core/models/connect/base-output';
import {SelectFeeLevel} from '@core/models/connect/select-fee-level';
import {AlertModel} from '@shared/models/alert-model';
import {AlertType} from '@shared/models/alert-type.enum';
import {ButtonRequestType} from '@core/models/connect/button-request-type.enum';
import {SendStep} from '../models/send-step.enum';
import {CoinMarketService} from '@core/services/coin-market.service';
import {UserSettings} from '@core/models/user-settings';
import {BitcoinFeeSelectionModel} from '@webcore/models/FeeSelectionModel';
import {MyConsole} from '@webcore/utils/console';
import {CoinBaseType} from '@webcore/coins/CoinInfo';
import {RippleCoinInfoModel} from '@webcore/models/CoinInfoModel';
import {ProkeySettingsKey} from "@core/models/prokey-settings-key.enum";
import {ProkeySettingsService} from "@core/services/prokey-settings.service";
import {WalletDeviceService} from "@core/services/wallet-device.service";
import {ProcessingDialogComponent} from "../processing-dialog/processing-dialog.component";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import { LayoutMode } from '@core/models/layout-mode';

@Component({
  selector: "app-send",
  templateUrl: "./send.component.html",
  styleUrls: ["./send.component.css"],
})
export class SendComponent implements OnInit, OnDestroy {
  //Not used in HTML
  private _coinSubscription: Subscription;
  private _accountSubscription: Subscription;
  private _uiButtonSubscription: Subscription;
  private _previousCoin: string = "";
  private _previousAccountAddress: string = "";
  private _currentCurrencyRate: number = 1.0;
  private _ethBasedTransactionFee: number;

  //! Used in HTML
  coin: CoinModel;
  account: AccountInfo = <AccountInfo>{};
  output: BaseOutput = <BaseOutput>{};
  feeLevels: SelectFeeLevel[] = [];
  alertModel: AlertModel = <AlertModel>{ show: false };
  processingData = {
    sendStep: SendStep.ProcessingStep
  }
  currencies: string[] = [];
  coinPrice = 0.0;
  userSettingModel: UserSettings = <UserSettings>{};
  selectedFee: string = "";
  destinationTag?: number = null;
  isTransactionFeeComboBoxDisable = false;
  isMaxRequested = false;
  isFeeMoreThanBalanceErrorView = false;
  maxValueToBeSend = 0;
  needToReSync = false;

  get hasDestinationTag(): boolean {
    return this.coin.type == CoinBaseType.Ripple;
  }
  @ViewChild("sendForm", { static: true }) public sendForm: NgForm;

  //#region constructor and subscribes
  constructor(private _walletService: WalletService,
              private _deviceService: WalletDeviceService,
              private _settingsService: ProkeySettingsService,
              private coinMarketService: CoinMarketService,
              private _dialogService: DialogService,
              private decimalPipe: DecimalPipe,
              private unitPipe: CoinUnitPipe) {
  }

  @ViewChild('QRDialog') private _QRDialogRef: TemplateRef<any>;

  ngOnInit() {
    this.setUserSetting();
    this.loadCurrencies();
    this.subscribeCoin();
    this.subscribeAccount();
    this.updateCurrencyRate();
  }

  ngOnDestroy() {
    this._coinSubscription.unsubscribe();
    this._accountSubscription.unsubscribe();
    if (this._uiButtonSubscription) {
      this._uiButtonSubscription.unsubscribe();
    }
  }

  subscribeCoin() {
    this._coinSubscription = this._walletService.onCurrentWalletChange.subscribe(() => {
        this.coin = this._walletService.CurrentWalletCoin;
        this.needToReSync = this._walletService.CurrentWallet.NeedToReSync;
        this.clearForm(this.sendForm);
        
        if (this.coin.coinInfo.shortcut !== this._previousCoin) {
          this.alertModel = <AlertModel>{};
        }
      }
    );
  }

  subscribeAccount() {
    this._accountSubscription = this._walletService.onCurrentAccountChange.subscribe((currentAccount) => {

        this.account = currentAccount;
        this.clearForm(this.sendForm);
        this.calculateFee();
        if (this.account.lastUnusedAddress?.address !== this._previousAccountAddress) {
          this.alertModel = <AlertModel>{};
        }
      }
    );
  }

  subscribeButton() {
    this._uiButtonSubscription = this._deviceService.uiButton$.subscribe((code) => {
      if ( code === ButtonRequestType.ButtonRequest_ConfirmOutput
        || code === ButtonRequestType.ButtonRequest_ConfirmWord) {
        this.processingData.sendStep = SendStep.ConfirmOutputStep;
      } else if (code === ButtonRequestType.ButtonRequest_SignTx) {
        this.processingData.sendStep = SendStep.ConfirmActionStep;
      }
    });
  }

  //#endregion

  //#region Fee and amount
  async calculateFee() {
    //! Disable tx fee combobox
    this.isTransactionFeeComboBoxDisable = true;

    //make output for calculate transaction fee
    const newOutput: BaseOutput = {
      ...this.output, //! keep the address
      amount: `${this.unitPipe.ToSatoshi(this.output.amount, this.coin.coinInfo.decimals)}`, //! amount per satoshi
    };

    //! Calculate fee
    //! ETH based wallet returns a number
    //! ERC20 tokens wallet returns null
    //! Bitcoin based wallet returns BitcoinFeeSelectionModel
    const fees: BitcoinFeeSelectionModel | number = await this._walletService.CurrentWallet.calculateTransactionFee(newOutput, this.account.id);

    if(typeof(fees) === 'number'){
      this._ethBasedTransactionFee = fees;
    } else {
      //! Update UI
      this.showFees(fees);
    }
    //! Enable tx fee combobox
    this.isTransactionFeeComboBoxDisable = false;
  }

  /**
   * this function will set/update feeLevels and then the UI will be updated based on feeLevels
   * @param fees
   */
  showFees(fees: BitcoinFeeSelectionModel){
    //! Some coins like Ethereum does not have any options for calculating the fee and user does not need to select the tx fee, so the fees will be null
    if (fees) {
      if (fees.economy === fees.normal && fees.normal === fees.priority) {
        this.feeLevels = [{
          name: "Economy",
          fee: fees.economy,
          unit: fees.unit,
          decimal: fees.decimal,
          currency: (this._currentCurrencyRate) ? this.userSettingModel.displayCurrency :'',
          price: (this._currentCurrencyRate) ? (this.unitPipe.transform(fees.economy, this.coin.coinInfo.decimals) * this._currentCurrencyRate).toString() :'',
        }];
      } else { 
        //! If there is no fee values, Add them for the first time
        if(this.feeLevels == null || this.feeLevels.length != 3)
        {
          this.feeLevels = [
            {
              name: "Economy",
              fee: fees.economy,
              unit: fees.unit,
              decimal: fees.decimal,
              currency: (this._currentCurrencyRate) ? this.userSettingModel.displayCurrency :'',
              price: (this._currentCurrencyRate) ? (this.unitPipe.transform(fees.economy, this.coin.coinInfo.decimals) * this._currentCurrencyRate).toString() :'',
            },
            {
              name: "Normal",
              fee: fees.normal,
              unit: fees.unit,
              decimal: fees.decimal,
              currency: (this._currentCurrencyRate) ? this.userSettingModel.displayCurrency :'',
              price: (this._currentCurrencyRate) ? (this.unitPipe.transform(fees.normal, this.coin.coinInfo.decimals) * this._currentCurrencyRate).toString() :'',
            },
            {
              name: "Priority",
              fee: fees.priority,
              unit: fees.unit,
              decimal: fees.decimal,
              currency: (this._currentCurrencyRate) ? this.userSettingModel.displayCurrency :'',
              price: (this._currentCurrencyRate) ? (this.unitPipe.transform(fees.priority, this.coin.coinInfo.decimals) * this._currentCurrencyRate).toString() :'',
            },
          ];
        }
        //! Update the values only to keep the selected fee unchanged
        else
        {
          this.feeLevels[0].fee = fees.economy;
          this.feeLevels[0].price = (this._currentCurrencyRate) ? (this.unitPipe.transform(fees.economy, this.coin.coinInfo.decimals) * this._currentCurrencyRate).toString() :'';

          this.feeLevels[1].fee = fees.normal;
          this.feeLevels[1].price = (this._currentCurrencyRate) ? (this.unitPipe.transform(fees.normal, this.coin.coinInfo.decimals) * this._currentCurrencyRate).toString() :'';

          this.feeLevels[2].fee = fees.priority;
          this.feeLevels[2].price = (this._currentCurrencyRate) ? (this.unitPipe.transform(fees.priority, this.coin.coinInfo.decimals) * this._currentCurrencyRate).toString() :'';
        }
      }

      //! Select default fee to Economy
      if (!this.selectedFee) {
        this.selectedFee = "Normal";
      }
    } else { //! NO FEE
      this.feeLevels = [];
    }
  }

  async onChangeFee(name: string) {
    this.selectedFee = name;
    //! If user wants to send max, the max value should be re-calculated on fee changed
    if(this.isMaxRequested){
      await this.onGetMaxAccountAmount();
    }
  }

  /**
   * This function will be called when the input amount changed
   * The current price and the fee should be updated
   * Send max also will be cancelled if the input changed manually
   */
  onInputAmountChanged() {

    //! Update price
    this.setCoinPrice();

    //! Calculate fee based on the value to be sent
    this.calculateFee();

    //! hide the high fee error
    this.isFeeMoreThanBalanceErrorView = false;

    //! Once user edit the value manually, send max should be canceled
    this.isMaxRequested = false;
  }

  /**
   * This function will be called when the price value on the form changed
   * Based on the current value of the coin, the value of output.amount should be updated
   */
  onInputPriceChanged() {
    if (this.sendForm.controls["price"].valid) {
      this.updateCurrencyRate((currencyRate)=>{
        if (currencyRate) {
          let amount = this.coinPrice / currencyRate;
          this.output.amount = this.decimalPipe.transform(amount, "1.0-6");
          //! Re-calculate the fee based on amount
          this.calculateFee();
        }
      })
    }
  }

  updateCurrencyRate(onUpdateCurrencyCallBack?: (currencyRate:any) => void){
    if(this.coin.coinInfo.test){
      this._currentCurrencyRate = null;
      return;
    }

    let coin = this.coin.coinInfo.name.toLowerCase();
    let coinId = coin.toCoinId();
    this.coinMarketService
      .getSimplePrice(coinId, this.userSettingModel.displayCurrency.toLowerCase())
      .subscribe((result) => {
        if (result) {
          let currentCoin = result[coinId];
          if (currentCoin) {
            let currentCurrency = result[coinId][this.userSettingModel.displayCurrency.toLowerCase()];
            this._currentCurrencyRate = currentCurrency

            if(onUpdateCurrencyCallBack){
              onUpdateCurrencyCallBack(currentCurrency);
            }
          }
        }
      });
  }

  onChangeCurrency(event: any) {
    this.coinMarketService.ResetFetchTime();
    this.updateCurrencyRate((currencyRate)=>{
      this.calculateFee();
    })

    this.setCoinPrice();
  }

  setCoinPrice() {
    if (this.sendForm.controls["amount"].valid) {
      this.updateCurrencyRate((currencyRate)=>{
        if (currencyRate) {
          const value = +this.output.amount * currencyRate;
          this.coinPrice = +value.toFixed(2);
        }
      })
    }
  }

  async onGetMaxAccountAmount() {
    if (+this.account.balance <= 0) {
      this.output.amount = `0.00`;
      MyConsole.Info("Error on get max amount account:", this.account);
      return;
    }

    // Update MAX value if fee changed
    this.isMaxRequested = true;

    //make output for calculate transaction fee
    const newOutput: BaseOutput = { ...this.output, amount: `${this.account.balance}` };

    //calculate transaction fee for all Balance
    const fees: BitcoinFeeSelectionModel | number = await this._walletService.CurrentWallet.calculateTransactionFee(newOutput, this.account.id);

    let min_balance = 0;

    if(this.coin.coinInfo.coinBaseType == CoinBaseType.Ripple){
      min_balance = (this.coin.coinInfo as RippleCoinInfoModel).min_balance;
    }

    //! ETH wallet just returns a number
    if(typeof(fees) === 'number'){
      this._ethBasedTransactionFee = fees;
      let accBallance = this.account.balance - this._ethBasedTransactionFee;

      if(accBallance > 0){
        let maxBalance = this.unitPipe.transform(accBallance, this.coin.coinInfo.decimals);
        this.output.amount = this.decimalPipe.transform(maxBalance, "1.0-8");
        this.setCoinPrice();
      } else {
        this.output.amount = '0';
        this.coinPrice = 0;
        // Show error in UI
        this.isFeeMoreThanBalanceErrorView = true;
      }

    } else {
      //Update UI
      this.showFees(fees);

      if (fees) {
        const type = (this.selectedFee && this.selectedFee.toLowerCase()) || "normal";
        const maxBalance = this.unitPipe.transform(
          this.account.balance - fees[type] - min_balance,
          this.coin.coinInfo.decimals
        );

        if (maxBalance > 0) {
          this.output.amount = `${maxBalance}`;
          this.setCoinPrice();
          this.isFeeMoreThanBalanceErrorView = false;
        } else {
          this.output.amount = '0';
          this.coinPrice = 0;
          // Show error in UI
          this.isFeeMoreThanBalanceErrorView = true;
        }
      } else {
        // if fees is null, max = balance
        //! For ERC20 tokens, fees = null and the fee should not be deducted from token account balance because the fee will be paid by ETH main
        let allBalance = this.unitPipe.transform(this.account.balance, this.coin.coinInfo.decimals);
        this.output.amount = allBalance.toString();
        this.setCoinPrice();
      }
    }
  }

  //#endregion

  //#region SendTransaction
  onSendBtnClick() {
    this._dialogService.open(ProcessingDialogComponent, {disableClose: true, minWidth: 500, data: this.processingData});
    this.processingData.sendStep = SendStep.ProcessingStep;
    this.composeTransaction();
  }

  onReSyncClick(){
    this._walletService.reDiscovery(LayoutMode.Default, this.coin);
  }

  async composeTransaction() {
    this.subscribeButton();
    const result: AlertModel = <AlertModel>{};
    try {
      if (this.coin.type === CoinBaseType.Ripple) {
        if (this.destinationTag) {
          this.output.extraIdValue = this.destinationTag.toString();
        }
      }
      
      const sendModel = await this._walletService.CurrentWallet.prepareSendModel(
        this.output,
        this.account,
        this.selectedFee,
        this.destinationTag
      );

      const composeResult = await this._walletService.CurrentWallet.sendTransaction(sendModel);
      if (composeResult.isSuccess) {
        result.type = AlertType.Success;
        result.title = "Success";
        result.content = `Transaction <b>
                          <a href='${this._walletService.getTransactionLink(
                            composeResult.txid
                          )}'
                          class='tlink' target='_blank'>${
                            composeResult.txid
                          }</a></b> has just been sent, It may take several minutes for transactions to be appeared on the explorers`;
        
        this._previousCoin = this.coin.coinInfo.shortcut;
        this._previousAccountAddress = this.account.lastUnusedAddress.address;
        
        this._walletService.setNeedToDiscovery(true);
      } else {
        MyConsole.Info("Error on sending transaction:", composeResult.error);
        result.content = composeResult.error;
        result.type = AlertType.Danger;
        result.title = "Failed";
      }
    } catch (e) {
      MyConsole.Info("Exception on sending transaction:", e);
      result.content = e;
      result.type = AlertType.Danger;
      result.title = "Failed";
    }

    this.processingData.sendStep = SendStep.ProcessingStep;
    result.show = true;
    result.dismissing = true;
    this.alertModel = result;
    this.clearForm(this.sendForm);

    setTimeout(()=>{
      this._dialogService.closeAll();
    }, 1000);
  }

  clearForm(sendForm: NgForm) {
    this.destinationTag = null;
    let balance = this.account.balance;
    //! For Ripple like coins, There is a parameter called min_balance which should be always in the wallet and can not be send
    //! On the other words, this amount burns forever
    if (this.coin.coinInfo.coinBaseType == CoinBaseType.Ripple) {
      let minBalance = (this.coin.coinInfo as RippleCoinInfoModel).min_balance;
      //balance -= minBalance;
      if (balance < 0) {
        balance = 0;
      }
    }

    this.maxValueToBeSend = balance;

    sendForm.resetForm({
      symbol: this.coin.coinInfo.shortcut,
      decimals: this.coin.coinInfo.decimals,
      balance: balance,
      displayCurrency: this.userSettingModel.displayCurrency,
    });
  }

  getQR() {
    const dialogRef = this._dialogService.open(this._QRDialogRef, {maxWidth: 500});
    dialogRef.afterClosed().subscribe(() => {
      stopScan();
    });

    const video = document.createElement("video");
    video.width = 250;
    const canvasElement: any = document.getElementById("canvas");
    const canvas = canvasElement.getContext("2d");
    const outputContainer = document.getElementById("output");
    const outputMessage = document.getElementById("outputMessage");
    const outputData = document.getElementById("outputData");
    const input: any = document.getElementById("address");
    let localStream: MediaStream;

    function drawLine(begin, end, color) {
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    }

    // Use facingMode: environment to attemt to get the front camera on phones
    navigator.mediaDevices
      .getUserMedia({video: {facingMode: "environment"}})
      .then(function (stream: MediaStream) {
        video.srcObject = stream;
        localStream = stream;
        video.play();
        requestAnimationFrame(tick);
      });

    function tick() {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.hidden = false;
        outputContainer.hidden = false;
        const loadingMessageEl = document.getElementById("loadingMessage");
        if (loadingMessageEl) {
          loadingMessageEl.hidden = true;
        }
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#009688");
          drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#009688");
          drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#009688");
          outputData.parentElement.hidden = false;
          outputData.innerText = code.data;
          input.value = code.data;
          if (code.data) {
            stopScan();
            return;
          }
        } else {
          outputMessage.hidden = false;
          outputData.parentElement.hidden = true;
        }
      }
      requestAnimationFrame(tick);
    }

    function stopScan() {
      video.pause();
      dialogRef?.close();
      localStream.getTracks()[0].stop();
      canvasElement.hidden = true;
    }
  }

  loadCurrencies() {
    this.coinMarketService.getCurrencies().subscribe((result) => {
      if (result.length > 0) {
        let usdIndex = result.findIndex((x) => x == 'usd');
        let currencyCollection: string[] = result.slice(usdIndex);
        this.currencies = currencyCollection.map((x) => {
          return x.toUpperCase();
        });
      }
    });
  }

  setUserSetting() {
    const settings = this._settingsService.getAll();
    this.userSettingModel = {
      displayCurrency: settings[ProkeySettingsKey.DISPLAY_CURRENCY],
      displayCoin: settings[ProkeySettingsKey.DISPLAY_COIN],
      bitcoreServers: settings[ProkeySettingsKey.BITCOIN_SERVERS],
      explorerUrl: settings[ProkeySettingsKey.EXPLORER_URL]
    }
  }
}
