import {Injectable, TemplateRef} from '@angular/core';
import {Dialog} from "../_models/dialog";
import {DialogComponent} from "../dialog.component";
import {DialogConfig} from "../_models/dialog-config";
import {MatDialog} from "@angular/material/dialog";
import {MatDialogRef} from "@angular/material/dialog/dialog-ref";

/**
 * a service for create and show new dialog instanceof bootstrap dialog
 */
@Injectable({
  providedIn: 'root'
})

export class DialogService {
  /** use in dialog component for set buttons color and label */
  dialogConfig: DialogConfig;

  constructor(private _matDialog: MatDialog) {
  }

  /** it opens new dialog instance of ngb dialog */
  open(template: TemplateRef<DialogComponent> | any, dialogConfig?: DialogConfig): MatDialogRef<any> {
    const modalObj: Dialog = {template, dialogConfig};
    this.dialogConfig = modalObj.dialogConfig;
    return this._matDialog.open(template, modalObj.dialogConfig);
  }

  /* It will return last open dialog. */
  getLastActiveDialog(): MatDialogRef<any> {
    return this._matDialog.openDialogs[this._matDialog.openDialogs?.length - 1];
  }

  /** it close last active dialog */
  closeActiveModal(): void {
    this.getLastActiveDialog()?.close();
  }

  /** it close all active modals */
  closeAll(): void {
    this._matDialog.closeAll();
  }
}
