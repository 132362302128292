import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import {ProkeySettingsService} from "../../core/services/prokey-settings.service";

@Injectable({
  providedIn: 'root'
})
export class StorageGuard implements CanActivate {

  constructor(private settingService: ProkeySettingsService) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    if (!this.settingService.isInitialized()) {
      await this.settingService.initialize();
    }
    return true;
  }
}
