<div class="setting-tabs">
  <ul class="nav nav-tabs">
    <li class="nav-item"><a data-toggle="tab" title="" href="#basic" class="nav-link active" (click)="setTab('basic')"><i class="fas fa-cog"></i> Basic Settings</a></li>
    <li class="nav-item"><a data-toggle="tab" title="" href="#advanced" class="nav-link" (click)="setTab('advanced')"><i class="fas fa-cogs"></i> Advanced Settings</a></li>
  </ul>
  <div class="tab-content" style="padding:30px 0px 0px 0px;">
    <div id="basic" class="tab-pane fade active show">
      <app-basic-settings [active]="activeTab"></app-basic-settings>
    </div>
    <div id="advanced" class="tab-pane fade">
      <app-advance-settings [active]="activeTab"></app-advance-settings>
    </div>
  </div>
</div>
