<!--Some coins like Ethereum does not have any option to select the fee, feeLevels.length = 0, so we don't need to show fee list box here-->
<ng-container *ngIf="fees">
  <label for="selectedFee" class="mb-1 mt-0 d-flex">
            <span class="text-danger ml-auto" *ngIf="isFeeMoreThanBalanceErrorView"><i class="fas fa-info-circle"></i>
              Selected fee is more than account balance
            </span>
  </label>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Transaction Fee</span>
      </div>
      <select class="form-control" name="selectedFee" id="selectedFee" [(ngModel)]="selectedFee" (ngModelChange)="onChangeFee($event)">
        <option [ngValue]="null" disabled>Select Fee</option>
        <option *ngFor="let level of feeLevels" [ngValue]="level.name">
          {{level.name | titlecase}}
          <button type="button" class="btn btn-submit-amount">
            {{level.fee | unit: level.decimal | number: '1.6-8'}} {{level.unit}}
            <div *ngIf="coin.coinInfo.test==false && level.price && level.currency">({{level.price | number: '1.0-3'}} {{level.currency}})</div>
          </button>
        </option>
      </select>
    </div>
  </div>
  <div class="clear"></div>
</ng-container>
