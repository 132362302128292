import {Component} from '@angular/core';
import {Helpers} from '../../services/helpers';
import {WalletDeviceService} from "../../services/wallet-device.service";
import {DialogService} from "@shared/dialog/_services/dialog.service";

@Component({
  selector: 'app-wallet-passphrase-request',
  templateUrl: './wallet-passphrase-request.component.html',
  styleUrls: ['./wallet-passphrase-request.component.css']
})
export class WalletPassphraseRequestComponent {

  constructor(private _deviceService: WalletDeviceService, private _dialogService: DialogService) {
  }

  model: any = {passphrase: '', confirmPassphrase: ''};
  showPass: boolean = false;

  enterPassphrase() {
    this._deviceService.receivePassphrase(this.model.passphrase);
    this.model.passphrase = this.model.confirmPassphrase = '';
    this._dialogService.closeAll();
  }

  changeType() {
    this.showPass = !this.showPass;
    Helpers.changeInputType(this.showPass ? 'text' : 'password', 'passphrase', 'confirmPassphrase');
  }
}
