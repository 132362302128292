import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {Address} from '@core/models/connect/address';
import {ButtonRequestType} from '@core/models/connect/button-request-type.enum';
import {WalletService} from '@core/services/wallet.service';
import {CoinModel} from '@core/models/connect/coin-model';
import {AccountInfo} from '@core/models/connect/account-info';
import {CoinBaseType} from '@webcore/coins/CoinInfo'
import * as PathUtil from '@webcore/utils/pathUtils';
import {WalletDeviceService} from "@core/services/wallet-device.service";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import { MyConsole } from '@webcore/utils/console';

enum NewAddressAction {
  Show = 'Show',
  Copy = 'Copy'
}

interface ReceiveAddressModel {
  isShowFull: boolean,
  address: Address,
  viewAddress: string,
}

@Component({
  selector: 'app-receive',
  templateUrl: './receive.component.html',
  styleUrls: ['./receive.component.css']
})
export class ReceiveComponent implements OnInit, OnDestroy {

  //! Not used in HTML
  _previousPath: Array<Array<number>> = [];
  _currentElementId: string = '';
  _newPath: Array<number> = [];
  _uiButtonSubscription: Subscription;
  _coinSubscription: Subscription;
  _accountSubscription: Subscription;
  _coin: CoinModel;
  _newAddresses: Address[] = [];

  //! Used in HTML
  showActionMessage: boolean = false;
  isShowQrCode: boolean = false;
  hasPreviousAddress: boolean = false;
  qrAddressModel: Address = <Address>{};
  account: AccountInfo;
  previousAddresses: Address[] = [];
  newAddressAction: NewAddressAction = NewAddressAction.Show;
  isShowingAddressWithoutAccount = false;

  addressModel: ReceiveAddressModel = {
    isShowFull: false,
    address: <Address>{},
    viewAddress: "",
  };

  newAddressModel:ReceiveAddressModel = {
    isShowFull: false,
    address: <Address>{},
    viewAddress: "",
  };

  @ViewChild('newAddressDialog') private _newAddressDialogRef: TemplateRef<any>;
  @ViewChild('receiveDialog') private _receiveDialogRef: TemplateRef<any>;

  get showXrpAccountNote():boolean {
    return (this._coin.type == CoinBaseType.Ripple && this.account.balance == 0)
  }

  get CurrentCoinName(): string {
    return this._coin.coinInfo.name;
  }

  get serializedPathForQrCode(): string {
    return PathUtil.getSerializedPath(this.qrAddressModel.path);
  }

  constructor(private _dialogService: DialogService,
              private _deviceService: WalletDeviceService,
              private _walletService: WalletService) {
  }

  ngOnInit() {
    this.subscribeCoin();
    this.subscribeAccount();
  }

  subscribeCoin() {
    this._coinSubscription = this._walletService.onCurrentWalletChange.subscribe(() => {
      this.account = null;
      this.afterChangeWallet();
      this._coin = this._walletService.CurrentWalletCoin;
    });
  }

  subscribeAccount() {
    this._accountSubscription = this._walletService.onCurrentAccountChange.subscribe(currentAccount => {
      this.afterChangeWallet();
      this.account = currentAccount;
      //if (this.account.addressModel.path) {
        this.getAddress(false, 'input-address');
      //}
    });
  }

  subscribeButton() {
    if (!this._uiButtonSubscription) {
      this._uiButtonSubscription = this._deviceService.uiButton$.subscribe(code => {
        if (code == ButtonRequestType.ButtonRequest_Address) {
          this.showActionMessage = true;
          this._changeDialogClose(true);
        }
      });
    }
  }

  resetReceiveModal(showOnProkey: boolean, currentElementId: string) {
    this._dialogService.open(this._receiveDialogRef, {disableClose: true});
    this.getAddress(showOnProkey, currentElementId);
  }

  async getAddress(showOnProkey: boolean, currentElementId: string) {
    this.isShowQrCode = false;
    if (showOnProkey) {
      if (this.addressModel.address.address.length > 0) {
        this.addressModel = this.setShowAddress(this.addressModel.address, showOnProkey);
      }
    }
    this._currentElementId = currentElementId;
    this.subscribeButton();


    try {
      let path : Array<number>;
      //! If the account discovery is not finish sucessfully, the account.addressModel wont be set.
      //! So, In this case, we should use the first address path of the coin
      if(this.account == null || this.account.lastUnusedAddress == null || this.account.lastUnusedAddress.path == null) {
        //! Show warning to the user that this is the first address of the wallet because we don't know how many address
        //! already used.
        if(this._coin.coinInfo.coinBaseType == CoinBaseType.BitcoinBase) {
          this.isShowingAddressWithoutAccount = true;
        }

        path = this._walletService.CurrentWallet.getPathOfFirstAddress(0).path;
      } else {
        path = this.account.lastUnusedAddress.path;
      }

      const resultAddressModel: Address = await this._walletService.CurrentWallet.getAddress(path, showOnProkey);
      MyConsole.Info("Receive.component->getAddress:", resultAddressModel);
      
      if (resultAddressModel) {
        this.addressModel = this.setShowAddress(resultAddressModel, showOnProkey);
        if (showOnProkey) {
          this.qrAddressModel = this.addressModel.address;
          this.isShowQrCode = true;
          this.showActionMessage = false;
          this._changeDialogClose(false);
        } else {
          this.getPreviousAddress();
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

  async getPreviousAddress() {

    // ! Ethereum or other coins like that has no previous address
    // ! Because each address assumes as an account
    if (this.account.hasAccountPerEachAddress != undefined && this.account.hasAccountPerEachAddress == true) {
      this.hasPreviousAddress = false;
      return;
    }

    this.setPreviousPath(this.account.lastUnusedAddress.path);

    for(let i=0; i<this._previousPath.length; i++) {
      await this._walletService.CurrentWallet.getAddress(this._previousPath[i], false).then(result => {
        if (result) {
          this.previousAddresses.push(result);
        } else {
          console.warn('Something went wrong. Please, try again. ', result);
        }
      }).catch(e => {
        console.warn('Something went wrong. Please, try again. ', e);
      });
    }
  }

  setPreviousPath(path: Array<number>) {
    const currentIndex: number = path[path.length - 1];
    if (currentIndex > 0) {
      this.hasPreviousAddress = true;

      for (let i = 0; i < currentIndex; i++) {
        let currentPath = Object.assign([], path);
        currentPath[path.length - 1] = i;
        this._previousPath.push(currentPath);
      }
    } else {
      this.hasPreviousAddress = false;
    }
  }

  getNewAddress() {
    this.newAddressAction = NewAddressAction.Show;
    this.isShowQrCode = false;

    let lastPath = this._newAddresses.length > 0 ? this._newAddresses[this._newAddresses.length - 1].path : this.account.lastUnusedAddress.path;
    let currentIndex: number = lastPath[lastPath.length - 1];
    lastPath[lastPath.length - 1] = currentIndex + 1;
    this._newPath = lastPath;

    this._walletService.CurrentWallet.getAddress(this._newPath, false).then(result => {
      if (result) {
        let newAddress: Address = result;
        this.newAddressModel = this.setShowAddress(newAddress, false);
        this._newAddresses.push(result);
        this._dialogService.open(this._newAddressDialogRef, {disableClose: false});
      } else {
        console.warn(result);
      }
    }).catch(e => {
      console.warn(e);
    });
  }

  showNewAddress() {
    if (this.newAddressModel.address.address.length > 0) {
      this.newAddressModel = this.setShowAddress(this.newAddressModel.address, true);
    }

    this.subscribeButton();
    this._walletService.CurrentWallet.getAddress(this._newPath, true).then(result => {
      if (result) {
        let address: Address = result;
        this.newAddressModel = this.setShowAddress(address, true);
        this.qrAddressModel = this.newAddressModel.address;
        this.isShowQrCode = true;
        this.showActionMessage = false;
        this.newAddressAction = NewAddressAction.Copy;
        this._changeDialogClose(false);
      }
    });
  }

  afterChangeWallet() {
    this.isShowQrCode = false;
    this.showActionMessage = false;
    this._newPath = [];
    this.addressModel = {
      isShowFull: false,
      address: <Address>{ address: '' },
      viewAddress: "",
    };
    this.newAddressModel = {
      isShowFull: false,
      address: <Address>{ address: '' },
      viewAddress: "",
    };
    this.qrAddressModel = <Address>{};
    this._newAddresses = [];
    this.previousAddresses = [];
    this._previousPath = [];
    this._changeDialogClose(false);
  }

  setShowAddress(address: Address, isShowOnProkey: boolean) {
    const addMod: {
      isShowFull: boolean,
      address: Address,
      viewAddress: string,
    } = {
      address: address,
      isShowFull: isShowOnProkey,
      viewAddress: address.address,
    };

    if (isShowOnProkey == false) {
      addMod.viewAddress = address.address.substring(0, 17) + ' ...';
    }

    return addMod;
  }

  ngOnDestroy() {
    this._coinSubscription.unsubscribe();
    this._accountSubscription.unsubscribe();
    if (this._uiButtonSubscription) {
      this._uiButtonSubscription.unsubscribe();
    }
  }

  /* It will enable dialog backdrop close. */
  private _changeDialogClose(enable: boolean): void {
    const dialogRef = this._dialogService.getLastActiveDialog();
    if (dialogRef) {
      dialogRef.disableClose = enable;
    }
  }
}
