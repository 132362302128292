import {Injectable} from '@angular/core';
import {BaseRepository} from './base-repository';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements BaseRepository {

  getRepositoryRepresentName(): string {
      return 'Local Storage';
  }

  async init(): Promise<boolean> {
      return true;
  }

  async save<T>(key: string, object: T): Promise<void> {
    const objectString = JSON.stringify(object);
    localStorage.setItem(key, objectString);
  }

  normalSave<T>(key: string, object: T): void {
    const objectString = JSON.stringify(object);
    localStorage.setItem(key, objectString);
  }

  async get<T>(key: string): Promise<T> {
    const item = localStorage.getItem(key);
    return JSON.parse(item) as T;
  }

  normalGet<T>(key: string): T {
    const item = localStorage.getItem(key);
    return JSON.parse(item) as T;
  }

  async delete(key: string): Promise<boolean> {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  async deleteAll(): Promise<void> {
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        this.delete(key);
      }
    }
  }

  initial() {
  }

  terminate() {
  }

  getRepositoryImagePath(): string {
    return 'assets/img/hard-drive.png';
  }
}
