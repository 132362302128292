import {AfterViewInit, Component, ViewEncapsulation} from '@angular/core';
import {ScriptLoaderService} from '../core/services/script-loader.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['../../assets/css/errors/css/style-flat.css'],
  encapsulation: ViewEncapsulation.None
})
export class PageNotFoundComponent implements AfterViewInit {

  constructor(private scriptService: ScriptLoaderService) { }

  ngAfterViewInit() {
    this.scriptService.load(
      './assets/js/wallet/main-flat.js')
      .then(data => {
      }, error => {
        console.warn('Error on not found script loaded in wallet ', error);
      });
  }

}
