import {Component} from '@angular/core';
import {WalletDeviceService} from "../../services/wallet-device.service";
import {DialogService} from "@shared/dialog/_services/dialog.service";


@Component({
  selector: 'app-wallet-device-reboot',
  templateUrl: './wallet-device-reboot.component.html',
  styleUrls: ['./wallet-device-reboot.component.css']
})
export class WalletDeviceRebootComponent {
  constructor(private _deviceService: WalletDeviceService, private _dialogService: DialogService) {
  }

  btnReboot_OnClick() {
    this._dialogService.closeAll();
    this._deviceService.RebootDevice();
  }
}
