 /// <reference path="../../../../../node_modules/@types/w3c-web-usb/index.d.ts"/>
import { Injectable } from '@angular/core';


import { TransportInterface } from '../../models/bootloader/transportInterface';
import { ChallengeMessage } from '../../models/bootloader/ChallengeMessage';
import { ChallengeErrorCode } from '../../models/bootloader/ChallengeErrors';

@Injectable()
export class WebusbService implements TransportInterface {

  kind: string = "webusb";

  prokeyFilters = [
    { vendorId: 0x1364, productId: 0x0001 },
    { vendorId: 0x1209, productId: 0xAAAA }
  ];

  _usb: any;
  _port: any;
  constructor() {
    this._usb = navigator.usb;
  }

  public async Connect(): Promise<ChallengeMessage> {
    if (this._usb != undefined && this._usb.opened) {
        return { success: true };
    }

    var chg = await this._enumerate();
    if (chg.success == false)
      return chg;

    return await this._acquire();
  }

  public async Call(data: string): Promise<ChallengeMessage> {
    if (this._port === undefined || this._port == null) {
      return { success: false, errorCode: ChallengeErrorCode.USER_DID_NOT_SELECT_A_DEVICE };
    }

    var bMsg = this.HexStringToByteArray(data);
    let l = bMsg.length;
    let bDataToSend = new Uint8Array(64);
    var firstPacket = true;
    var n = 0;
    var t = 0;

    while (t < l) {
      n = 0;
      if (firstPacket) {
        firstPacket = false;
        bDataToSend[n++] = 0x3F;
        bDataToSend[n++] = 0x23;
        bDataToSend[n++] = 0x23;
      }
      else {
        bDataToSend[n++] = 0x3F;
      }

      while (n < 64) {
        if (t < l) {
          bDataToSend[n++] = bMsg[t++];
        }
        else
          bDataToSend[n++] = 0x00; // Padding
      }

      await this._port.transferOut(1, bDataToSend);
    }

    let recStr: string = "";
    var len = 0;
    firstPacket = true;
    var isValid = true;

    while (true) {
      const res = await this._port.transferIn(1, 64);

      if (res.status == "stall") {
        this._port.clearHalt("in", 1);
        continue;
      }

      if (res.data && res.data.byteLength === 0) {
        await this.delay(10);
        continue;
      }

      // TODO: Handle babble status
      //if (res.status == "babble") //! still have more data
        //continue;

      if (firstPacket) {
        firstPacket = false;

        var uint8View = new Uint8Array(res.data.buffer);
        len = uint8View[5] << 24;
        len |= uint8View[6] << 16;
        len |= uint8View[7] << 8;
        len |= uint8View[8];

        var rawStr: string = Buffer.from(res.data.buffer).toString('hex');

        // validate start bytes
        if (rawStr.substring(0, 6).toLowerCase() != '3f2323')
          isValid = false; //! Can not return err now because, maybe there are IN data in USB buffer

        //! Remove '?##" characters from data
        recStr = rawStr.substring(6);

        //! In the first packet, the lenght of data can be up to 55 bytes
        len -= 55;
      }
      else {

        var rawStr: string = Buffer.from(res.data.buffer).toString('hex');

        // validate start byte
        if (rawStr.substring(0, 2).toLowerCase() != '3f')
          isValid = false;

        //! Remove '?' characted from data
        recStr += rawStr.substring(2);

        //! In the other packets, the lenght of data can be up to 63 bytes
        len -= 63;
      }

      if (len > 0) //! still have more data
        continue;

      if (isValid)
        return { success: true, payload: recStr };
      else
        return { success: false, errorCode: ChallengeErrorCode.PROTO_ERR }
    }

  }

  async delay(ms: number): Promise<void> {
    await new Promise(resolve => setTimeout(() => resolve(null), ms)).then(() => console.log("fired"));
  }

  public async Release(): Promise<ChallengeMessage> {
    //if (this._port === undefined || this._port == null) {
    //  return { success: false, errorCode: ChallengeErrorCode.USER_DID_NOT_SELECT_A_DEVICE };
    //}

    //await this._port.close();

    return { success: true };
  }

  public async Reset(): Promise<ChallengeMessage> {
    return this.Release();
  }

  private HexStringToByteArray(data: string): Uint8Array {
    var b = new Uint8Array(data.length / 2);
    var n = 0;
    while (data.length > 0) {
      b[n] = Number.parseInt(data.substring(0, 2), 16);
      n++;
      data = data.substring(2);
    }

    return b;
  }

  public async GetVersion(): Promise<ChallengeMessage> {
    if (this._usb === undefined || this._usb == null) {
      return { success: false, errorCode: ChallengeErrorCode.NO_WEB_USB };
    }

    return { success: true, payload: "1.0.0" }
  }

  private async _enumerate(): Promise<ChallengeMessage> {
    if (this._usb === undefined || this._usb == null) {
      return { success: false, errorCode: ChallengeErrorCode.NO_WEB_USB };
    }

    // TODO: Handle more than one device 
    let devices = await this._usb.getDevices();
    if (devices != undefined && devices.length > 0) {
      for (let i = 0; i < devices.length; i++) {
        var device = devices[i];

        this.prokeyFilters.forEach(filter => {
          if(device.vendorId == filter.vendorId && device.productId == filter.productId){
            this._port = device;
            return { success: true, payload: device }
          }
        });
      }
    }

    try {
      let device = await this._usb.requestDevice({ filters: this.prokeyFilters });
      if (device == null) {
        return { success: false, errorCode: ChallengeErrorCode.USER_DID_NOT_SELECT_A_DEVICE };
      }

      this._port = device;

      return { success: true, payload: device }
    }
    catch { // To handle DOMException
      return { success: false, errorCode: ChallengeErrorCode.USER_DID_NOT_SELECT_A_DEVICE };
    }


    /*await this._usb.requestDevice({ filters: this.filters }).then(
      (device) => {
        this._port = device;
        console.log(this._port);

        return { success: true, payload: this._port };
      }
    ).catch((reason) => {
      console.log("Catch on request device: " + reason);
      return { success: false, errorCode: ChallengeErrorCode.NO_DEVICE };
    });*/
  }

  private async _acquire(): Promise<ChallengeMessage> {
    if (this._port === undefined || this._port == null) {
      return { success: false, errorCode: ChallengeErrorCode.USER_DID_NOT_SELECT_A_DEVICE };
    }

    try {
      await this._port.open();

      if (this._port.configuration === null)
        await this._port.selectConfiguration(1);

      await this._port.claimInterface(0);

      return { success: true };
    }
    catch {
      return { success: false, errorCode: ChallengeErrorCode.CAN_NOT_OPEN_USB };
    }
  }



}
