<div class="form-group mb-2">
  <label class="m-b-5">SEND</label>
  <div class="dropdown exchange-coin-search-one">
    <button class="btn bitcoin-btn dropdown-toggle relative waves waves-effect"
            type="button" data-toggle="dropdown" aria-expanded="false">
      <div class="d-flex">
        <div class="col-button right-border">
          <img src="{{selectedSendCoin.getImageUrl()}}" width="36px"
               height="36px"/>
        </div>
        <div class="col-button ml-2 pl-2 border-left-exchange">
          <span class="coin-unit d-block">{{selectedSendCoin.coinInfo.shortcut}}</span>
          <small class="account-number d-block">Account #{{account.id + 1}}</small>
          <small class="balance d-block">
            balance {{account.balance | unit: selectedSendCoin.coinInfo.decimals | number: '1.6-8'}}
          </small>
        </div>
      </div>
    </button>
  </div>
</div>
<form #sendCoinForm="ngForm" class="form-group">
  <input type="hidden" name="decimals" [(ngModel)]="selectedSendCoin.coinInfo.decimals" #decimals="ngModel" />
  <input type="hidden" name="balance" [(ngModel)]="account.balance" #balance="ngModel" />
  <div class="input-group" [class.c-is-invalid]="amount.invalid && amount.touched">
    <input id="sendAmount" style="z-index: 99" autocomplete="off" type="text" class="form-control" placeholder="Enter Amount"
           name="amount" required pattern="^\d+(\.\d{1,8})?$" appAmountValidator min-amount="0.000001"
           max-amount="balance" coin-name="decimals" [(ngModel)]="sendAmount"  #amount="ngModel" (keyup)="setAmount()">
    <div class="input-group-prepend">
      <span class="input-group-text shadow-none">$ {{sendUSDAmount | number: '1.0-3'}}</span>
    </div>
  </div>
  <ng-container *ngIf="amount.invalid && amount.touched">
    <div *ngIf="amount.errors.required" class="c-text-invalid">
      Amount is required
    </div>
    <div *ngIf="amount.errors.pattern" class="c-text-invalid">
      Amount is not valid
    </div>
    <div *ngIf="amount.errors.appAmountValidator" class="c-text-invalid">
      {{amount.errors.message}}
    </div>
  </ng-container>

</form>


