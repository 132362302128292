<app-alert [(model)]="alertModel"></app-alert>
<div class="card" [class.mt-4]="alertModel.show">
  <div class="card-body">
    <div class="form-group mb-0">
      <div class="f-inner">
        <label class="mb-0 mr-4">Device Label</label>
        <a href="javascript:void(0)" class="device-label mr-4" (click)="setLabelMode('Change')">{{deviceLabel}}</a>
      </div>
      <ng-container *ngIf="labelMode == 'View'">
        <button type="button" class="btn change-btn waves waves-effect changeit" (click)="setLabelMode('Change')"><i class="far fa-edit"></i> Change</button>
      </ng-container>
      <ng-container *ngIf="labelMode == 'Change'">
        <div class="editable-container editable-inline">
          <div class="form-inline editableform">
            <div class="control-group">
              <div class="editable-input" style="position: relative;">
                <input type="text" class="input-medium" style="padding-right: 24px;" autocomplete="off" required maxlength="16"
                       [(ngModel)]="model.label" #label="ngModel" name="label"/><span class="editable-clear-x"></span>
              </div>
              <div class="editable-buttons">
                <button type="button" class="btn btn-primary editable-submit" [disabled]="label.invalid" (click)="changeLabel()">
                  <i class="fas fa-check icon-white"></i>
                </button>
                <button type="button" class="btn editable-cancel fas fa-ban" (click)="setLabelMode('View')">
                  <i class="icon-remove"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="card mt-3">
  <div class="card-body">
    <div class="form-group mb-0">
      <div class="f-inner">
        <label class="mb-4 mr-5">PIN Protection</label>
        <div class="d-inline-block relative">
          <label class="switch-iPhone mb-0" for="switch">
            <input class="switch" id="switch" type="checkbox" [(ngModel)]="isPinProtection" (change)="changePin(isPinProtection)" /><span class="switchStyle"></span>
          </label>
        </div>
      </div>
      <button type="button" class="btn change-btn waves waves-effect relative" style="top:-5px;"
              *ngIf="isPinProtection" (click)="changePin(true)"><i class="far fa-edit"></i> Change</button>
      <p>Using PIN protection is highly recommended, PIN prevents unauthorised persons to access your fund even if they had access to the device physically.</p>
    </div>
  </div>
</div>
<div *ngIf="isShowPublicKey" class="card mt-3">
  <div class="card-body">
    <div class="form-group mb-0">
      <div class="f-inner">
        <label class="mb-4 mr-5">Public keys (XPUBS)</label>
      </div>
      <button type="button" class="btn change-btn waves waves-effect res-change-btn" data-toggle="collapse" data-target="#xpubs" (click)="showXpub()"><i class="far fa-eye"></i> {{xpubLabel}}</button>
    </div>
    <div id="xpubs" class="collapse">
      <div class="qr-scan relative">
        <small>Standard account</small>
        <span>{{xpub}}</span>
        <div class="qr-img">
          <qrcode [qrdata]="xpub" [size]="180" [level]="'M'"></qrcode>
        </div>
      </div>
      <p>
        Please notice by sharing your extended public key (xpub), the recipient can obtain all of your current and future wallet addresses, and view all of your historical transactions. This can have privacy implications, if you are trying to remain anonymous with your cryptocurrency transactions.
      </p>
    </div>
  </div>
</div>

