import {NgModule, Optional, SkipSelf} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS} from "@angular/common/http";

// import RxJs needed operators only once
import "rxjs/operators";

import {XsrfInterceptor} from "./services/xsrf.interceptor";
import {CustomErrorHandlerService} from "./services/custom-error-handler.service";
import {ScriptLoaderService} from "./services/script-loader.service";
import {WalletLayoutComponent} from './component/wallet-layout/wallet-layout.component';
import {WalletHeaderLayoutComponent} from './component/wallet-header-layout/wallet-header-layout.component';
import {WalletFooterLayoutComponent} from './component/wallet-footer-layout/wallet-footer-layout.component';
import {WalletSidebarLayoutComponent} from './component/wallet-sidebar-layout/wallet-sidebar-layout.component';
import {WalletDeviceInfoComponent} from './component/wallet-device-info/wallet-device-info.component';
import {WalletSidemenuLayoutComponent} from './component/wallet-sidemenu-layout/wallet-sidemenu-layout.component';
import {WalletPinRequestComponent} from './component/wallet-pin-request/wallet-pin-request.component';
import {WalletService} from "./services/wallet.service";
import {SharedModule} from "@shared/shared.module";
import {WalletPassphraseRequestComponent} from './component/wallet-passphrase-request/wallet-passphrase-request.component';
import {WindowService} from "./services/window.service";
import {AppHeaderComponent} from './component/app-header/app-header.component';
import {WalletNotifyLayoutComponent} from './component/wallet-notify-layout/wallet-notify-layout.component';
import {CoinMarketService} from "./services/coin-market.service";
import {CoinUnitPipe} from "@shared/pipes/coin-unit.pipe";
import {ChallengeService} from "./services/bootloader/challenge.service";
import {BridgeService} from "./services/bootloader/bridge.service";
import {BootloaderService} from "./services/bootloader/bootloader.service";
import {WebusbService} from "./services/bootloader/webusb.service";
import {TransportService} from "./services/bootloader/transport.service";
import {WalletAddCoinComponent} from './component/wallet-add-coin/wallet-add-coin.component';
import {WalletForgetDeviceComponent} from './component/wallet-forget-device/wallet-forget-device.component';
import {WalletNoBackupComponent} from './component/wallet-no-backup/wallet-no-backup.component';
import {WalletPinOnDeviceComponent} from "./component/wallet-pin-on-device/wallet-pin-on-device.component";
import {WalletDeviceRebootComponent} from "./component/wallet-device-reboot/wallet-device-reboot.component"
import {CoinTokenTypeToNamePipe} from "@shared/pipes/coin-type-to-name.pipe";
import {SpinnerLoadingComponent} from './component/spinner-loading/spinner-loading.component';
import {WalletAddCustomTokenComponent} from "./component/wallet-add-coin/wallet-add-custom-token/wallet-add-custom-token.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCommonModule} from "@angular/material/core";
import {ExchangeLayoutComponent} from "@core/component/exchange-layout/exchange-layout.component";
import { LayoutService } from "./services/layout.service";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { WalletTourService } from "./services/wallet-tour.service";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    MatCommonModule,
    MatDialogModule,
    MatMenuModule,
    MatCardModule,
    MatIconModule,
    TourMatMenuModule,
  ],
  exports: [
    // components that are used in app.component.ts will be listed here.
    AppHeaderComponent,
    WalletLayoutComponent,
    WalletHeaderLayoutComponent,
    WalletFooterLayoutComponent,
    WalletSidebarLayoutComponent,
    WalletNotifyLayoutComponent,
    SpinnerLoadingComponent
  ],
  declarations: [
    // components that are used in app.component.ts will be listed here.
    AppHeaderComponent,
    WalletLayoutComponent,
    WalletHeaderLayoutComponent,
    WalletFooterLayoutComponent,
    WalletSidebarLayoutComponent,
    WalletDeviceInfoComponent,
    WalletSidemenuLayoutComponent,
    WalletPinRequestComponent,
    WalletPassphraseRequestComponent,
    WalletNotifyLayoutComponent,
    WalletAddCoinComponent,
    WalletForgetDeviceComponent,
    WalletNoBackupComponent,
    WalletPinOnDeviceComponent,
    WalletDeviceRebootComponent,
    SpinnerLoadingComponent,
    SpinnerLoadingComponent,
    WalletAddCustomTokenComponent,
    ExchangeLayoutComponent
  ],
  providers: [
    // global singleton services of the whole app will be listed here.
    CustomErrorHandlerService,
    ScriptLoaderService,
    WalletService,
    WindowService,
    CoinMarketService,
    CoinUnitPipe,
    CoinTokenTypeToNamePipe,
    ChallengeService,
    BridgeService,
    BootloaderService,
    WebusbService,
    TransportService,
    LayoutService,
    WalletTourService,
    {provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true}
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error("CoreModule should be imported ONLY in AppModule.");
    }
  }
}
