export enum ChallengeErrorCode {
  NO_ERR = 0,

  //! Client Side Errors
  NO_RESPONSE_FROM_BRIDGE,
  NO_DEVICE,
  CAN_NOT_ACQUIRE_DEVICE,
  NO_RESPONSE_FROM_DEVICE,
  NOT_IN_BOOTLOADER,
  PROTO_ERR,
  ERR_CHG1,
  ERR_CHG2,
  ERR_CHG3,
  ERR_CHG4,
  DEVICE_RETURN_FIRMWARE_ERROR,
  NO_WEB_USB,
  NO_TRANSPORT,
  USER_DID_NOT_SELECT_A_DEVICE,
  CAN_NOT_OPEN_USB,
  FAILED_WITH_REASON,
  INVALID_HEADER,

  //! Server Side Errors
  WRONG_PARAM = 0x80,
  NO_SN,
  WRONG_SN,
  DEV_NOT_FOUNDED,
  SECURITY_ERR_1,
  NO_FIRMWARE,
  WRONG_FIRMWARE,
  DEVICE_ALREADY_EXIST,
  WRONG_KEY_VERSION,
  CANNOT_PARS_FIRMWARE_SIGNATURE_DATA,
  WRONG_FIRMWARE_SIGNATURE_DATA,
  THREE_FIRMWARE_SIGNATURE_NEEDED,
  WRONG_FIRMWARE_SIGNATURE_LENGHT,

  UNDEFINED = 0xFF,
}

export function GetChallengeErrorCodeViewMessage(errCode: ChallengeErrorCode, reason?:any) : string  {
  switch(errCode) {
    case ChallengeErrorCode.NO_ERR:
      return "No error";
    case ChallengeErrorCode.NO_RESPONSE_FROM_BRIDGE:
      return "No response from bridge";
    case ChallengeErrorCode.NO_DEVICE:
      return "No device";
    case ChallengeErrorCode.CAN_NOT_ACQUIRE_DEVICE:
      return "Cannot acquire device";
    case ChallengeErrorCode.NO_RESPONSE_FROM_DEVICE:
      return "No reponse from device";
    case ChallengeErrorCode.NOT_IN_BOOTLOADER:
      return "Device is not in bootloader mode";
    case ChallengeErrorCode.PROTO_ERR:
      return "Protobuf error";
    case ChallengeErrorCode.ERR_CHG1:
      return "Challenge err 1";
    case ChallengeErrorCode.ERR_CHG2:
      return "Challenge err 2";
    case ChallengeErrorCode.ERR_CHG3:
      return "Challenge err 3";
    case ChallengeErrorCode.ERR_CHG4:
      return "Challenge err 4";
    case ChallengeErrorCode.DEVICE_RETURN_FIRMWARE_ERROR:
      return "Firmware error";
    case ChallengeErrorCode.NO_WEB_USB:
      return "No webUSB, Check your browser";
    case ChallengeErrorCode.NO_TRANSPORT:
      return "No transport layer";
    case ChallengeErrorCode.USER_DID_NOT_SELECT_A_DEVICE:
      return "No device selected";
    case ChallengeErrorCode.CAN_NOT_OPEN_USB:
      return "USB can not be opened";
    case ChallengeErrorCode.FAILED_WITH_REASON:
      return `Device returned failed, reason:${reason}`;
    case ChallengeErrorCode.INVALID_HEADER:
      return "Invalid message header";
  
    //! Server Side Errors
    case ChallengeErrorCode.WRONG_PARAM:
      return "Server:Wrong parameters";
    case ChallengeErrorCode.NO_SN:
      return "Server:No serial number provided";
    case ChallengeErrorCode.WRONG_SN:
      return "Server:Wrong serial number provided";
    case ChallengeErrorCode.DEV_NOT_FOUNDED:
      return "Server:Device not found in DB";
    case ChallengeErrorCode.SECURITY_ERR_1:
      return "Server:Security err 1";
    case ChallengeErrorCode.NO_FIRMWARE:
      return "Server:No firmware file";
    case ChallengeErrorCode.WRONG_FIRMWARE:
      return "Server:Wrong firmware file";
    case ChallengeErrorCode.DEVICE_ALREADY_EXIST:
      return "Server:Device already exist in DB";
    case ChallengeErrorCode.WRONG_KEY_VERSION:
      return "Server:Wrong AuthKey";
    default:
      return `Unknown error:${errCode}`;
  }
}
