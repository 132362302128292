<app-header class="header-white"></app-header>
<section class="device-error stickytop">
  <div class="container">
    <div class="row error-body">
      <ng-container *ngIf="uiMode === 'Loading'">
        <div class="container" style="height: 100vh"></div>
      </ng-container>
      <ng-container *ngIf="uiMode === 'Transport'">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <div class="container">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 float-right error-right">
              <img src="assets/img/danger.svg" width="70%" height="auto" title="connect prokey to browser" />
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 float-left error-left">
              <h3>Your browser doesn't support WebUSB.</h3>
              <br>
              <h5 style="line-height:26px">
                Please download latest version of <img src="assets/img/google-chrome-icon.png" alt="google chrome" /> Google Chrome browser
                or Microsoft Edge <img src="assets/img/edge.png" alt="Microsoft Edge" />
                for connecting to prokey device through WebUSB.
              </h5>
              <div class="row mt-5">
                <div class="col-lg-12 col-md-12 col-sm-12 p-0 download-bridge">
                  <a href="https://www.google.com/chrome/" title="" class="btn waves waves-effect text-center" target="_blank">
                    <i class="fas fa-external-link-alt"></i>
                    Download Chrome
                  </a>
                  <a *ngIf="isWin10" href="microsoft-edge:https://wallet.prokey.io" title="" class="btn waves waves-effect text-center" target="_blank">
                    <i class="fas fa-external-link-alt"></i>
                    Open Microsoft Edge
                  </a>
                  <a *ngIf="!isWin10" href="https://www.microsoft.com/en-us/edge" title="" class="btn waves waves-effect text-center" target="_blank">
                    <i class="fas fa-external-link-alt"></i>
                    Download Microsoft Edge
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="uiMode === 'Failed'">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <div class="errconnect">
            <h3>Connection Error</h3>
            <p>
              Your connection between device and browser failed, first unplug your Prokey and reconnect it<br>
              to your PC/mobile, then click on try again
              <ng-container *ngIf="chromiumVersion >= 87">, Also make sure experimental USB backend in your chromium settings is disabled.<br></ng-container>
            </p>
            <a href="/" alt="">Try Again</a>
            <ng-container *ngIf="chromiumVersion >= 87"><a class="ml-2" href="https://support.prokey.io/help-center/articles/1/6/15/disable-experimental-usb-backend-on-chrome-based-browsers" target="_blank">Disable Experimental WebUSB</a></ng-container>
            <div style="position: relative;width: 640px;margin: 40px auto;">
              <video id=0 controls width=640 height=360>
              <source src="https://statics.prokey.io/assets/video/WebUSB_GuideVideo.ogv" type='video/ogg; codecs="theora, vorbis"'/>
              <source src="https://statics.prokey.io/assets/video/WebUSB_GuideVideo.webm" type='video/webm' >
              <source src="https://statics.prokey.io/assets/video/WebUSB_GuideVideo.mp4" type='video/mp4'>
              <p>Video is not visible, most likely your browser does not support HTML5 video</p>
              </video>
              </div>
          </div>
        </div>
      </ng-container>
      <div [class.hide-connect]="uiMode !== 'Ready'">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <div class="container">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 float-right error-right">
              <img src="assets/img/entry.png" alt="connect your wallet" width="100%" height="auto" title=""><br><br>

              <div class="text-center">
                <p>Don't have a Prokey? Get it <a href="https://prokey.io/prokey-optimum">here</a></p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 float-left error-left">
              <h3>Connect your Prokey</h3>
              <br>
              <h5> Please <i class="fa fa-plug"></i> connect your prokey to your computer/mobile, press "Check Device" button to connect it to browser.</h5>
              <div class="connectpk">
                <button type="button" class="prokey-webusb-button btn checkbutton" (click)="connect()">+ Check Device</button><br />
                <a href="https://support.prokey.io" target="_blank" class="nhelp">Need help?</a>
              </div>
              <ng-container *ngIf="isLinux">
                <div class="alert alert-primary linuxmsg">
                  <p>Please notice that WebUSB is not enable on Linux OS by default, checkout the following guide to enable it.</p>
                  <a href="https://support.prokey.io/help-center/articles/1/6/11/how-to-enable-webusb-on-linux-os" target="_blank">How to enable WebUSB on linux?</a>
                </div>
              </ng-container>
              <ng-container *ngIf="chromiumVersion >= 87">
                <div class="alert alert-primary linuxmsg">
                  <p>
                    Please make sure experimental USB backend in your chromium settings is disabled. <a href="https://support.prokey.io/help-center/articles/1/6/15/disable-experimental-usb-backend-on-chrome-based-browsers" target="_blank">Read more</a></p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
