import { Injectable } from '@angular/core';
import { ProkeySettingsKey } from '@core/models/prokey-settings-key.enum';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { ProkeySettingsService } from './prokey-settings.service';

@Injectable({
  providedIn: 'root'
})
export class WalletTourService {

  private _steps: IStepOption[] = [];

  constructor(
    private _tourService: TourService,
    private _settingService: ProkeySettingsService
  ) { }

  initialize(hasChart: boolean) {
    this._setSteps(hasChart);
    this._tourService.initialize(this._steps);
  };

  start() {
    this._tourService.start();
    this._tourService.end$.subscribe(() => {
      this._settingService.save(ProkeySettingsKey.IS_VIEWED_TOUR, true);
    });
  }

  private _setSteps(hasChart: boolean){
    let finalSteps: IStepOption[] = [
      {
        anchorId: 'header.storage',
        title: 'Setting Storage',
        content: 'You can store your wallet configuration on cloud storage to access same view on different browsers, check out wallet advanced settings to activate it.',
        isAsync: true,
        //enableBackdrop: true,
      },
      {
        anchorId: 'header.syncMode',
        title: 'Sync status',
        content: 'Re-Sync your wallet with blockchain to get new transactions',
        isAsync: true,
      }
    ];

    let chartSteps : IStepOption[] = [{
      anchorId: 'chart.totalBalance',
      title: 'Total Balance',
      content: 'Estimated balance of all cryptocurrencies in USD.',
      isAsync: true,
    }, {
      anchorId: 'chart.periodTime',
      title: 'Period time',
      content: 'Choose a time range for chart',
      isAsync: true,
    }];

    let mainSteps: IStepOption[] = [{
      anchorId: 'assets',
      title: 'Assets',
      content: 'List of assets added to your portfolio.',
      isAsync: true,
    }, {
      anchorId: 'balance',
      title: 'Asset balance',
      content: 'View balance of each asset, by clicking on each row you can manage that asset.',
      isAsync: true,
    }, {
      anchorId: 'gridView',
      title: 'Grid view mode',
      content: 'Please click on grid view mode',
      isAsync: true,
      goToNextOnAnchorClick: true,
    }, {
      anchorId: 'addOrRemoveAsset',
      title: 'Add/Remove asset',
      content: 'Add or remove any assets you would like to see on Portfolio.',
      isAsync: true,
    }];

    if (hasChart) {
      finalSteps.push(...chartSteps);
    }

    finalSteps.push(...mainSteps);
    this._steps = finalSteps;
  }
}
