import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription, timer} from 'rxjs';

import {WalletService} from '@core/services/wallet.service';
import {CoinModel} from '@core/models/connect/coin-model';
import {AccountInfo} from '@core/models/connect/account-info';
import {CoinMarketService} from '@core/services/coin-market.service';
import {Helpers} from '@core/services/helpers';
import {CurrencyPipe} from '@angular/common';
import {UiEvent} from '@core/models/connect/ui-event.enum';
import {WalletDeviceService} from "@core/services/wallet-device.service";

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit, OnDestroy {

  changeWalletSubscription: Subscription;
  accountSubscription: Subscription;
  uiAlertSubscription: Subscription;

  isErc20 = false;
  isOmni = false;
  coin: CoinModel;
  account: AccountInfo = <AccountInfo>{};
  currency: string;
  currencySymbol: string = '';
  minFrom: number;
  maxTo: number;
  readonly maxDays: number = 30;
  currentPricePercentage: number;
  currentPercentageTypeClass: string;
  defaultPeriodTime: string = 'Month';
  currnetPeriodTime: string;
  chartPeriodTiems: any = [
    { type: 'Month', days: 30 },
    { type: 'Week', days: 7 },
    { type: 'Day', days: 1 },
  ];


  constructor(private coinMarketService: CoinMarketService,
              private _deviceService: WalletDeviceService,
              private _walletService: WalletService,
              private currencyPipe: CurrencyPipe,
              private router: Router) {
  }

  ngOnInit() {
    this.setDefaultPeriodTime();
    this.setUserCurrency();
    this.subscribeUiAlert();
    this.changeWalletSubscribe();
    this.subscribeAccount();
  }

  changeWalletSubscribe() {
    this.changeWalletSubscription = this._walletService.onCurrentWalletChange.subscribe(() => {
      //! coinInfo can be null when this subscribe calls for the first time
      if(this._walletService.CurrentWalletCoin == null) {
        return;
      }

      this.coin = this._walletService.CurrentWalletCoin;
      this.setDefaultPeriodTime();
      this.renderChart();
      this.checkMarketData();
    });
  }

  subscribeAccount() {
    this.accountSubscription = this._walletService.onCurrentAccountChange.subscribe(currentAccount => {
      this.account = currentAccount;
    });
  }

  subscribeUiAlert() {
    this.uiAlertSubscription = this._deviceService.uiAlert$.subscribe(uiEvent => {
      if (uiEvent === UiEvent.Blockchain_Disconnected) {
        this.account = <AccountInfo>{};
      }
    });
  }

  send() {
    this.router.navigate(['/wallet/send']);
  }

  receive() {
    this.router.navigate(['/wallet/receive']);
  }

  setDefaultPeriodTime() {
    this.currnetPeriodTime = this.defaultPeriodTime;
  }

  setUserCurrency() {
    this.currency = this.coinMarketService.getCurrentCurrency();
    const formatedCurrency: string = this.currencyPipe.transform(42, this.currency, 'symbol');
    if (formatedCurrency) {
      this.currencySymbol = formatedCurrency.substr(0, 1);
    }
  }

  checkMarketData() {
    let checkMarketTimer$ = timer(3000);
    checkMarketTimer$.subscribe(value => {
      this.setPricePercentage();
    });
  }

  setPricePercentage() {
    if (this.coin.marketData) {
      switch (this.currnetPeriodTime) {
        case 'Month':
          if (this.coin.marketData.price_change_percentage_30d_in_currency) {
            this.currentPricePercentage = this.coin.marketData.price_change_percentage_30d_in_currency;
          }
          break;
        case 'Week':
          if (this.coin.marketData.price_change_percentage_7d_in_currency) {
            this.currentPricePercentage = this.coin.marketData.price_change_percentage_7d_in_currency;
          }
          break;
        case 'Day':
          if (this.coin.marketData.price_change_percentage_24h_in_currency) {
            this.currentPricePercentage = this.coin.marketData.price_change_percentage_24h_in_currency;
          }
          break;
        default:
          break;
      }
      if (this.currentPricePercentage) {
        this.currentPercentageTypeClass = this.currentPricePercentage < 0.00 ? 'fas fa-chevron-circle-down' : 'fas fa-chevron-circle-up';
      }
    }
  }

  renderChart() {
    if (this.coin.coinInfo.name !== undefined) {
      Helpers.destroyMarketPriceChart();
      let coin = this.coin.coinInfo.name.toLowerCase();
      if (coin === 'testnet') {
        coin = 'bitcoin';
      }
      this.coinMarketService.getMarketChart(coin.toCoinId(), this.maxDays).subscribe({
        next: (result) => {
          if (result) {
            const prices: any[] = result['prices'];
            this.minFrom = prices[0][0];
            this.maxTo = prices[prices.length - 1][0];
            Helpers.renderMarketPriceChart('timeline-chart', prices, this.minFrom, this.maxTo, this.currency, this.coin.coinInfo.name);
            this.coin.isAvailableMarketData = true;
          } else {
            this.coin.isAvailableMarketData = false;
          }
        },
        error: () => {
          this.coin.isAvailableMarketData = false;
        }
      });
    }
  }

  updateChart(periodTime: any) {
    this.currnetPeriodTime = periodTime.type;
    let numberOfDays: number;
    let maxToDate = new Date(this.maxTo);
    let newFrom: number;
    if (periodTime.days == this.maxDays) {
      newFrom = this.minFrom;
    } else {
      newFrom = maxToDate.setDate(maxToDate.getDate() - periodTime.days);
    }
    Helpers.updateMarketPriceChart(newFrom, this.maxTo);
    this.setPricePercentage();
  }

  ngOnDestroy() {
    this.changeWalletSubscription.unsubscribe();
    this.accountSubscription.unsubscribe();
    this.uiAlertSubscription.unsubscribe();
  }
}
