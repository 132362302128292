<div *ngIf="!offers && showSkeleton" class="row">
  <div class="col-md-4">
    <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '1rem',
         'background-color': '#e9e9e9'
         }">
    </ngx-skeleton-loader>
  </div>

  <div class="col-md-5"></div>

  <div class="col-md-3">
    <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '1rem',
         'background-color': '#e9e9e9'
         }">
    </ngx-skeleton-loader>
  </div>
  <div class="row"
       style="background: white;border: 1px solid #E5E5E5;min-height: 13rem;padding-top: 1rem;margin-right: 1rem;margin-left: 1rem;width: 100%">
    <div class="col-md-3" style="max-height: 2rem">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '2rem',
         'background-color': '#e9e9e9'
         }">
      </ngx-skeleton-loader>
    </div>
    <div class="col-md-1" style="max-height: 2rem">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '2rem',
         'background-color': '#e9e9e9'
         }">
      </ngx-skeleton-loader>
    </div>
    <div class="col-md-2" style="max-height: 2rem">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '2rem',
         'background-color': '#e9e9e9'
         }">
      </ngx-skeleton-loader>
    </div>
    <div class="col-md-1" style="max-height: 2rem">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '2rem',
         'background-color': '#e9e9e9'
         }">
      </ngx-skeleton-loader>
    </div>
    <div class="col-md-3" style="max-height: 2rem">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '2rem',
         'background-color': '#e9e9e9'
         }">
      </ngx-skeleton-loader>
    </div>
    <div class="col-md-2" style="max-height: 2rem">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '3rem',
         'background-color': '#e9e9e9'
         }">
      </ngx-skeleton-loader>
    </div>

    <div class="col-md-12" style="max-height: 1rem">
      <hr style="text-align:center">
    </div>

    <div class="row col-md-12">
      <div class="col-md-3">
        <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '1rem',
         'background-color': '#e9e9e9'
         }">
        </ngx-skeleton-loader>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-3">
        <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '1rem',
         'background-color': '#e9e9e9'
         }">
        </ngx-skeleton-loader>
      </div>
    </div>

    <div class="col-md-3">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '2rem',
         'background-color': '#e9e9e9'
         }">
      </ngx-skeleton-loader>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-8">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{
         height: '1rem',
         'background-color': '#e9e9e9'
         }">
      </ngx-skeleton-loader>
    </div>
  </div>
</div>
<div *ngIf="isOfferExist()" class="compare-offers-head d-flex align-items-center">
  <h6>{{_offers.length}} offers founded</h6>
  <span class="ml-auto">Offers refresh in 00:{{timeLeft}}</span>
</div>
<ul *ngIf="isOfferExist()">
  <li *ngFor="let offer of _offers">
    <div class="item-head d-flex">
      <div class="col-150px">
        <h4 class="d-block">{{offer.receiveCoinAmount}} {{offer.receiverCoin.coinInfo.shortcut}}</h4>
        <div class="d-flex" style="font-size: 0.77em;margin-top: 5px;" *ngIf="offer.rateId">
Fixed Rate <i class="fas fa-question-circle p-1" id="standardrate" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" title="A fixed rate exchange is an exchange performed with the same rate which is
displayed to the client in the begining of an exchange, regardless of the
subsequent rate fluctuations."></i>
        </div>
        <div class="d-flex" style="font-size: 0.77em;margin-top: 5px;" *ngIf="!offer.rateId">
        Standard Rate <i class="fas fa-question-circle p-1" id="standardrate" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" title="During the standard flow, each transaction may have its own unique exchange rate because of rate fluctuations, market conditions, and network fees. The rate might change at any moment; as a result, you might receive more or less than you thought you would"></i>
      </div>

        </div>
      <div class="col-full d-flex">
        <div>
          <img class="mr-1" src="/assets/img/up-arrow.svg" width="19px"
               height="19px"/>
          <span>{{offer.sendAmount}} {{offer.senderCoin.coinInfo.shortcut}}</span>
        </div>
        <div>
            <span class="color-blue">
              {{offer.senderCoin.coinInfo.shortcut}}/{{offer.receiverCoin.coinInfo.shortcut}} : {{getOfferRate(offer)}}</span>
          <img src="/assets/img/right-arrow.svg" width="19px" height="19px"/>
          <img class="mr-1 resImg" src="/assets/img/down-arrow.svg" width="19px"
               height="19px"/>
          <span class="resSpan">{{offer.receiveCoinAmount}} {{offer.receiverCoin.coinInfo.shortcut}}</span>
        </div>
        <div class="ml-auto" style="min-width: 135px;">
          <button type="button" class="btn-gradinet-green waves waves-effect"
                  data-toggle="modal" data-target="#Keep-your-security" (click)="selectOffer(offer)">
            Trade Now
          </button>
        </div>
      </div>
    </div>
    <div class="item-body d-flex">
      <div class="col-150px">
        <h4 class="d-block">PROVIDER</h4>
        <div class="d-flex">
          <img src='{{offer.providerImagePath}}' width="26px" height="26px">
          <span class="ml-2 color-blue">
              {{offer.providerName}}
          </span>
        </div>
      </div>
      <div class="col-full">
        <h4>KYC</h4>
        <p>{{offer.providerName}} enforce KYC only on suspicious transactions, Refunds without KYC
        </p>
      </div>
    </div>
  </li>
</ul>
