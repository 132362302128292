<div class="device-reboot-container">
  <div id="pin" style="width:100%" class="d-block">
    <div class="fade show">
      <div class="on-device-inner text-center">
        <h5>Device must be restarted</h5>
      </div>
      <div>
        <p class="text-left">Session has expired and your prokey device must be restarted. By pressing the below button device will be restarted automatically and the wallet will be reloaded.</p>
      </div>
      <div class="container" style="width:350px">
          <button type="submit" class="btn btn-gradinet-blue pnup waves-effect waves-dark d-block" style="width:100%" (click)="btnReboot_OnClick()">Restart</button>
      </div>
    </div>
  </div>
</div>
