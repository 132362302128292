<footer class="footer">
  <div class="container p-0">
    <div class="col-lg-12 col-12 p-0 pt-3">
      <div class="float-right d-flex">
        <span>Follow us in  Social Networks</span>
        <ul class="socilas d-flex pl-3">
          <li><a href="https://www.instagram.com/prokey.io/" target="_blank" title="our page on instagram"><i class="fab fa-instagram"></i></a>
          <li><a href="https://www.facebook.com/prokey.io" target="_blank" title="our page on facebook"><i class="fab fa-facebook-f"></i></a>
          <li><a href="https://twitter.com/prokey_io" target="_blank" title="our page on twitter"><i class="fab fa-twitter"></i></a>
        </ul>
      </div>
      <div class="float-left d-flex">
        <small>V2.0.0 Prokey © {{nowYear}}</small>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</footer>
