import {Directive} from '@angular/core';

/** a dialog footer directive used in footer content of dialog component */
@Directive({
  selector: '[appDialogFooter]',
  exportAs: 'footer'
})

export class ModalFooterDirective {
}
