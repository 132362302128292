<div class="wipe-modal">
  <div class="modal-content">
    <div class="modal-body text-center">
      <img class="prokey-device" src="./assets/img/device.svg" alt="prokey"/>
      <ng-container
        *ngIf="data.isDeviceWipeSuccessfully then wipeCompletedTemplate else confirmWipeTemplate"></ng-container>
      <ng-template #confirmWipeTemplate>
        <div><h6>Confirm Wipe from Device</h6></div>
        <div class="modal-red-footer">
          <h6>All data on your device will be deleted.</h6>
          <span>This action cannot be undone. Never do this action with Prokey<br>holding coins unless you have your recovery seed at hand.</span>
        </div>
      </ng-template>
      <ng-template #wipeCompletedTemplate>
        <h6>Wipe completed</h6>
        <span>Your Prokey has been successfully wiped.<br>Please <span class="text-danger">disconnect</span> your device now.</span>
      </ng-template>
    </div>
  </div>
</div>
