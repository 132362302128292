import {BaseProvider} from './baseProvider';
import {ExchangeOffer} from '../../models/exchange-offer';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AvailableCurrencyResponse} from '../../models/change-now/available-currency-response';
import {ExchangeRangeResponse} from '../../models/change-now/exchange-range-response';
import {EstimatedExchangeAmountResponse} from '../../models/change-now/estimated-exchange-amount-response';
import {ExchangeTransactionRequest} from '../../models/change-now/exchange-transaction-request';
import {ExchangeTransactionResponse} from '../../models/change-now/exchange-transaction-response';
import {TransactionStatusResponse} from '../../models/change-now/transaction-status-response';
import {ExchangeStatus} from '../../models/exchange-status';
import {ExchangeTransaction} from '../../models/exchange-transaction';
import {Exchange} from '../../models/exchange';
import {ExchangeException} from '../../errors/exchange.exception';
import {CoinModel} from '@core/models/connect/coin-model';
import {GetNetworkByChainId} from '@webcore/utils/ethereum-networks';
import {StatusType} from '../../models/status-type';
import {AmountRange} from '../../models/amount-range';

@Injectable({
  providedIn: 'root'
})
export class ChangeNowProvider extends BaseProvider {
  private availableCurrencies: AvailableCurrencyResponse[];

  constructor(private http: HttpClient) {
    super(http);
  }

  getProviderName(): string {
    return 'ChangeNow';
  }

  getProviderImagePath(): string {
    return '/assets/img/exchange/changenow.svg';
  }

  getProviderTransactionUrl(): string {
    return 'https://changenow.io/exchange/txs/';
  }

  async isExchangeCoinsSupported(sendCoin: CoinModel, receiveCoin: CoinModel, fixRate: boolean): Promise<boolean> {
    try {
      if (!this.availableCurrencies) {
        this.availableCurrencies = await this.getToServer<AvailableCurrencyResponse[]>(
          `/exchange/currencies?active=true&flow=${fixRate ? 'fixed-rate' : 'standard'}&buy=true&sell=true`
        );
      }
      return this.isCoinSupported(sendCoin) && this.isCoinSupported(receiveCoin);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        throw new ExchangeException(error.error.message);
      }
      throw error;
    }
  }

  async getAmountRange(sendCoin: CoinModel, receiveCoin: CoinModel, fixRate: boolean): Promise<AmountRange> {
    try {
      const sendCoinShortcut = sendCoin.coinInfo.shortcut.toLowerCase().trim();
      const receiveCoinShortcut = receiveCoin.coinInfo.shortcut.toLowerCase().trim();
      const range = await this.getToServer<ExchangeRangeResponse>(
        `/exchange/range?fromCurrency=${sendCoinShortcut}&fromNetwork=${this.getNetwork(sendCoin)}&toCurrency=${receiveCoinShortcut}&toNetwork=${this.getNetwork(receiveCoin)}&flow=${fixRate ? 'fixed-rate' : 'standard'}`
      );
      return {minimum: range.minAmount, maximum: range.maxAmount};
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        throw new ExchangeException(error.error.message);
      }
      throw error;
    }
  }

  async getOffers(sendCoin: CoinModel, receiveCoin: CoinModel, sendCoinAmount: number, fixRate: boolean): Promise<ExchangeOffer> {
    try {
      const sendCoinShortcut = sendCoin.coinInfo.shortcut.toLowerCase().trim();
      const receiveCoinShortcut = receiveCoin.coinInfo.shortcut.toLowerCase().trim();
      const exchangeAmountResponse = await this.getToServer<EstimatedExchangeAmountResponse>(
        `/exchange/estimated-amount?fromCurrency=${sendCoinShortcut}&toCurrency=${receiveCoinShortcut}&fromAmount=${sendCoinAmount}&fromNetwork=${this.getNetwork(sendCoin)}&toNetwork=${this.getNetwork(receiveCoin)}&flow=${fixRate ? 'fixed-rate' : 'standard'}&type=direct`
      );
      return {
        providerName: this.getProviderName(),
        providerImagePath: this.getProviderImagePath(),
        providerTransactionUrl: this.getProviderTransactionUrl(),
        receiveCoinAmount: exchangeAmountResponse.toAmount,
        fee: 0,
        rateId: exchangeAmountResponse.rateId
      };
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        throw new ExchangeException(error.error.message);
      }
      throw error;
    }
  }

  async createExchangeTransaction(exchange: Exchange, payoutAddress: string, payinAddress: string): Promise<ExchangeTransaction> {
    const body: ExchangeTransactionRequest = {
      fromCurrency: exchange.senderCoin.coinInfo.shortcut.toLowerCase().trim(),
      fromNetwork: this.getNetwork(exchange.senderCoin),
      toCurrency: exchange.receiverCoin.coinInfo.shortcut.toLowerCase().trim(),
      toNetwork: this.getNetwork(exchange.receiverCoin),
      fromAmount: exchange.sendAmount.toString(),
      rateId: exchange.rateId ? exchange.rateId : '',
      address: payoutAddress,
      toAmount: '',
      extraId: '',
      refundAddress: '',
      refundExtraId: '',
      userId: '',
      payload: '',
      contactEmail: '',
      source: '',
      flow: exchange.rateId ? 'fixed-rate' : 'standard',
      type: ''
    };
    try {
      const exchangeTransactionResponse = await this.postToServer<ExchangeTransactionResponse>(
        `/exchange`, body
      );
      return {
        payinAddress: exchangeTransactionResponse.payinAddress,
        payoutAddress: exchangeTransactionResponse.payoutAddress,
        id: exchangeTransactionResponse.id,
        amount: exchangeTransactionResponse.fromAmount,
        extraId: exchangeTransactionResponse.payinExtraId
      };
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        throw new ExchangeException(error.error.message);
      }
      throw error;
    }
  }

  async getExchangeStatus(rateId: string): Promise<ExchangeStatus> {
    try {
      const transactionStatusResponse = await this.getToServer<TransactionStatusResponse>(
        `/exchange/by-id?id=${rateId}`
      );
      let status: StatusType = StatusType.WAITING;
      if (transactionStatusResponse) {
        if (transactionStatusResponse.status === 'finished') {
          status = StatusType.FINISHED;
        }
        if (transactionStatusResponse.status === 'failed') {
          status = StatusType.FAILED;
        }
      }
      return {
        id: transactionStatusResponse.id,
        status: status,
        payinAddress: transactionStatusResponse.payinAddress,
        payoutAddress: transactionStatusResponse.payoutAddress,
        createdAt: transactionStatusResponse.createdAt,
        updatedAt: transactionStatusResponse.updatedAt
      };
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        throw new ExchangeException(error.error.message);
      }
      throw error;
    }
  }

  isCoinSupported(requestedCoin: CoinModel): boolean {
    for (const availableCurrency of this.availableCurrencies) {
      if (requestedCoin.coinInfo.shortcut.toLowerCase().trim() === availableCurrency.ticker.toLowerCase().trim() &&
        this.getNetwork(requestedCoin) === availableCurrency.network) {
        return true;
      }
    }
    return false;
  }

  getNetwork(coin: CoinModel) {
    if (coin.coinInfo['chain_id']) {
      return GetNetworkByChainId(coin.coinInfo['chain_id']).toLowerCase().trim();
    }
    if (coin.coinInfo['blockchain']) {
      return GetNetworkByChainId(coin.coinInfo['blockchain']).toLowerCase().trim();
    }
    return coin.coinInfo.shortcut.toLowerCase();
  }
}
