<form #userForm="ngForm" novalidate class="mt-0 fresh bitcoin-form mb-0">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-0 mb-2">
      <div class="card">
        <div class="card-body">
          <div class="form-group mb-0">
            <label style="padding-top: 13px !important;">Currency to display</label>
            <select class="form-control d-inline-block float-right" style="width:100px"
                    [(ngModel)]="model.displayCurrency" name="displayCurrency" #displayCurrency="ngModel">
              <option *ngFor="let currency of currencies" [value]="currency">
                {{currency}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-0 mb-2">
      <div class="card">
        <div class="card-body">
          <div class="form-group mb-0">
            <label style="padding-top: 13px !important;">Default Currency</label>
            <select class="form-control d-inline-block float-right" style="width:177px"
                    [(ngModel)]="model.displayCoin" name="displayCoin" #displayCoin="ngModel">
              <option value="BTC">Prokey wallet - Bitcoin</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
    <div class="">
      <div class="card-body">
        <!--<div class="col-lg-8 col-md-10 col-sm-12 col-12 center p-0">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-0 float-left btc-col">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Bitcore Servers</span>
                </div>
                <input autocomplete="off" type="text" class="form-control"
                       [(ngModel)]="model.bitcoreServers" name="bitcoreServers" #bitcoreServers="ngModel">
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-0 float-left btc-col">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Explorer URL</span>
                </div>
                <input autocomplete="off" type="text" class="form-control"
                       [(ngModel)]="model.explorerUrl" name="explorerUrl" #explorerUrl="ngModel">
              </div>
            </div>
          </div>
        </div>-->
        <div class="clearfix"></div>
        <div class="d-block text-right">
          <button type="button" class="btn btn-gradinet-blue pnup waves-effect waves-dark" (click)="save()">
            Save & Reload
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
