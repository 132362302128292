<div class="forget-device-container">
  <div class="pt-2 pr-2">
  </div>
  <div class="modal-body">
    <h6 class="body-title text-center">
      Forget "{{data?.deviceLabel}}"?
    </h6>
    <p class="text-center" style="font-size: 13px">
      Would you like Prokey Wallet to forget your device, or to remember it?
    </p>
    <div class="container" style="width:350px">
      <button type="submit" class="btn btn-gradinet-blue pnup waves-effect waves-dark d-block mt-3" style="width:100%" (click)="forget()">
        Forget
      </button>
      <button type="submit" class="btn btn-outline-secondary pnup waves-effect waves-dark d-block mt-2" style="width:100%" (click)="remember()">
        {{timerValue}} Remember device
      </button>
    </div>
  </div>
</div>
