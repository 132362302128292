import { Directive, Attribute } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";
import { CoinUnitPipe } from '../pipes/coin-unit.pipe';

@Directive({
  selector: '[appAmountValidator][formControlName],[appAmountValidator][formControl],[appAmountValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: AmountValidatorDirective,
      multi: true
    }
  ]
})
export class AmountValidatorDirective implements Validator {

  constructor(
    @Attribute("min-amount") public minAmount: string,
    @Attribute("max-amount") public maxAmount: string,
    @Attribute("coin-name") public coinName: string,
    private unitPipe: CoinUnitPipe
  ) { }

  validate(element: AbstractControl): { [key: string]: any } {

    if (!element.value || element.value == '') {
      return null;
    }
    
    let balanceControl = element.root.get(this.maxAmount);
    if (!balanceControl) {
      return null;
    }

    let coinControl = element.root.get(this.coinName);
    if (!coinControl || !coinControl.value) {
      return null;
    }

    let result = { appAmountValidator: true, message: '' };
    let balance = +this.unitPipe.transform(balanceControl.value, coinControl.value);
    if (+element.value < +this.minAmount) {
      result.message = 'Amount too low.';
      return result;
    } else if (+element.value > balance) {
      result.message = 'Not enough funds.'
      return result;
    } else {
      return null;
    }
  }

}
