import {Injectable} from '@angular/core';
import {BaseProvider} from './providers/baseProvider';
import {ChangeNowProvider} from './providers/changeNow-provider';
import {BehaviorSubject} from 'rxjs';
import {Exchange} from '../models/exchange';
import {CoinModel} from '@core/models/connect/coin-model';
import {CoinBaseType, CoinInfo} from '@webcore/coins/CoinInfo';
import {BitcoinBaseCoinInfoModel} from '@webcore/models/CoinInfoModel';
import {ExchangeTransaction} from '../models/exchange-transaction';
import {ExchangeStatus} from '../models/exchange-status';
import {ExchangeException} from '../errors/exchange.exception';
import {ChangellyProvider} from "./providers/changelly-provider";

/**
 * @author <a href="mailto:purianajaflu@gmail.com">pourianajafluy</a>
 */
@Injectable({
  providedIn: 'root'
})
export class ExchangeService {
  private providers = new Array<BaseProvider>();
  private exchangeSource = new BehaviorSubject<Exchange>({
    senderCoin: this.getTestCoinModel(),
    receiverCoin: this.getTestCoinModel(),
    providerName: 'test',
    providerImagePath: 'test',
    providerTransactionUrl: 'test',
    sendAmount: 0,
    receiveCoinAmount: 0,
    fee: 0
  });
  currentOffer = this.exchangeSource.asObservable();

  constructor(private changeNowProvider: ChangeNowProvider, private changellyProvider: ChangellyProvider) {
    this.providers.push(changeNowProvider);
    this.providers.push(changellyProvider);
  }

  /**
   * broadcast selected exchange object
   * @param exchange
   */
  changeSelectedExchange(exchange: Exchange) {
    this.exchangeSource.next(exchange);
  }

  /**
   * get all offers for a exchange between two coins and a certain amount
   * @param sendCoin
   * @param receiveCoin
   * @param sendCoinAmount
   */
  async getOffers(
    sendCoin: CoinModel, receiveCoin: CoinModel, sendCoinAmount: number
  ): Promise<Array<Exchange>> {
    const offers = new Array<Exchange>();
    let isSendAmountSupported = false;
    let isExchangeSupported = false;
    let minimumSendAmount;
    let maximumSendAmount;
    // @ts-ignore
    for (const provider of this.providers) {
      for (let i = 0; i < 2; i++) {
        let isFixRate = true;
        if (i === 0) {
          isFixRate = false;
        }
        if (await provider.isExchangeCoinsSupported(sendCoin, receiveCoin, isFixRate)) {
          isExchangeSupported = true;
        } else {
          continue;
        }

        const amountRange = await provider.getAmountRange(sendCoin, receiveCoin, isFixRate);
        minimumSendAmount = minimumSendAmount && (minimumSendAmount < amountRange.minimum) ? minimumSendAmount : amountRange.minimum;
        if (amountRange.maximum) {
          maximumSendAmount = maximumSendAmount && (maximumSendAmount > amountRange.maximum) ? maximumSendAmount : amountRange.maximum;
        }

        if (amountRange.minimum && amountRange.minimum > sendCoinAmount) {
          continue;
        }
        if (amountRange.maximum && amountRange.maximum < sendCoinAmount) {
          continue;
        }
        const offer = await provider.getOffers(sendCoin, receiveCoin, sendCoinAmount, isFixRate);
        offers.push(
          {
            senderCoin: sendCoin,
            receiverCoin: receiveCoin,
            providerName: offer.providerName,
            providerImagePath: offer.providerImagePath,
            providerTransactionUrl: offer.providerTransactionUrl,
            sendAmount: sendCoinAmount,
            receiveCoinAmount: offer.receiveCoinAmount,
            fee: offer.fee,
            rateId: offer.rateId
          }
        );
        isSendAmountSupported = true;
      }
    }
    if (!isExchangeSupported) {
      throw new ExchangeException('Exchange for this currency pair not supported');
    }
    if (!isSendAmountSupported) {
      if (sendCoinAmount < minimumSendAmount) {
        throw new ExchangeException('minimum exchange amount is: ' + minimumSendAmount);
      }
      if (sendCoinAmount > maximumSendAmount) {
        throw new ExchangeException('maximum exchange amount is: ' + maximumSendAmount);
      }
    }
    return offers;
  }

  /**
   * create exchange in selected exchange provider and then return its information
   * @param exchange
   * @param payoutAddress
   * @param payinAddress
   */
  async getExchangeTransactionInfo(exchange: Exchange, payoutAddress: string, payinAddress: string): Promise<ExchangeTransaction> {
    return await this.getProvider(exchange.providerName).createExchangeTransaction(exchange, payoutAddress, payinAddress);
  }

  /**
   * get exchange transaction status
   * @param exchange
   */
  async getExchangeStatus(exchange: Exchange): Promise<ExchangeStatus> {
    return await this.getProvider(exchange.providerName).getExchangeStatus(exchange.exchangeId);
  }

  getTestCoinModel() {
    const btcCoinInfo = CoinInfo.Get<BitcoinBaseCoinInfoModel>('Test', CoinBaseType.BitcoinBase);
    return new CoinModel(btcCoinInfo);
  }

  private getProvider(providerName: string): BaseProvider {
    return this.providers.find(provider => provider.getProviderName().toLowerCase() === providerName.toLowerCase());
  }
}
