import { Injectable } from '@angular/core';
import { LayoutMode } from '@core/models/layout-mode';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor() { }

  //#region Private members
  
  private _layout: LayoutMode = LayoutMode.Portfolio;
  private _layoutSource = new BehaviorSubject<LayoutMode>(LayoutMode.Portfolio);

  //#endregion

  //#region Events

  onLayoutChange = this._layoutSource.asObservable();

  //#endregion

  public get Layout(): LayoutMode {
    return this._layout;
  }

  public set Layout(layoutMode: LayoutMode) {
    this._layout = layoutMode;
    this._layoutSource.next(this._layout);
  }
}
