import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TransportInterface} from '@core/models/bootloader/transportInterface';
import {AuthKeyMessage, ChallengeMessage} from '@core/models/bootloader/ChallengeMessage';
import {ChallengeErrorCode} from '@core/models/bootloader/ChallengeErrors';
import {Features} from '@webcore/models/Prokey';
import {BootloaderService} from './bootloader.service';
import {MyConsole} from '@webcore/utils/console';
import {RestUrls} from "../../../_configs/rest-urls";
import { Version } from '@models/bootloader/Version';

@Injectable()
export class ChallengeService {
  constructor(private http: HttpClient) {
  }

  _transport: TransportInterface;

  public SetTransport(transport: TransportInterface): void {
    this._transport = transport;
  }

  public async Challenge(features: Features, updateCallBack?: (msg: string) => void): Promise<ChallengeMessage> {
    var chg: ChallengeMessage;

    try {


      

      //! Check bootloader version
      if(features == null) {
        return Promise.reject({
          success: false,
          errorCode: ChallengeErrorCode.NO_RESPONSE_FROM_DEVICE,
        });
      }

      //! To set the key, device should be in bootloader mode
      if(features.bootloader_mode == undefined || features.bootloader_mode == false) {
        if(updateCallBack != null)
          updateCallBack("Not in bootloader mode");

        return Promise.reject({
          success: false,
          errorCode: ChallengeErrorCode.NOT_IN_BOOTLOADER,
        });
      }


      const bootloaderVersion = `${features.major_version}.${features.minor_version}.${features.patch_version}`;

      if(updateCallBack != null)
        updateCallBack(`Bootloader version: ${bootloaderVersion}`);

      //! Step 1
      //! First Step is getting RNDD from Device
      chg = await this.GetRndDevice();
      MyConsole.Info("ChallengeService::Challenge->Device RNDD", chg);
      if (chg.success == false) {
        return Promise.reject(chg);
      }

      //! Let's send this RNDD to server and get its response
      //! fs means 'From Server'
      var fs: ChallengeMessage = await this.SendToServerChallenge({
        ...chg,
        bootloaderVersion: bootloaderVersion,
      });

      if (fs.success == false)
        return fs;

      //! Step 2
      //! Now we got Enc(RndD) as Challenge and RndS from server
      chg = await this.ChallengeWithDevice(fs);
      if (chg.success == false)
        return Promise.reject(chg);

      //! Now device has sent the Enc(RndS), Now it should be sent to server to make sure that device is genuine
      fs = await this.SendToServerChallenge({
        ...chg,
        bootloaderVersion: bootloaderVersion
      });
      if (fs.success == false)
        return Promise.reject(fs);

      //! Step 3
      //! Now we got Hash(Hash(SessionKey)) from server
      chg = await this.CheckHashWithDevice(fs);
      if (chg.success == false)
        return Promise.reject(chg);

        fs = await this.SendToServerChallenge({
          ...chg,
          bootloaderVersion: bootloaderVersion
        });
      if (fs.success == false)
        return Promise.reject(fs);

      return { 
        success: true, 
        payload: chg, 
        bootloaderVersion: bootloaderVersion
      }
    }
    catch
    {
      await this._transport.Release();
      this._transport.Reset();
      return {
        success: false,
        errorCode: ChallengeErrorCode.UNDEFINED,
      }
    }
  }

  public Reset(): void {
    this._transport.Reset();
  }

  /**
   * Get device features
   * @returns Device Features
   */
  private async GetDeviceFeatures(): Promise<Features> {
    var res = await this._transport.Call("003700000000");
    if(res.success == false){
      return Promise.reject({ success: false, errorCode: ChallengeErrorCode.NO_RESPONSE_FROM_DEVICE });
    }

    const err = this.CheckIfFailure(res.payload);
    if(err.success == false){
      MyConsole.Info("ChallengeService::GetDeviceFeatures->FAILED", err);
      return Promise.reject(err);
    }

    if(res.payload.substring(0,4).toLowerCase().trim() != "0011") {
      MyConsole.Info("ChallengeService::GetDeviceFeatures-> Bad message type");
      return Promise.reject({ success: false, errorCode: ChallengeErrorCode.INVALID_HEADER });
    }

    //! Remove MsgId + Lenght
    res.payload = res.payload.substring(12)

    return BootloaderService.ProtobufToFeatures(res.payload);
  }

  /**
   * Check if AuthKey already set
   * @returns success: true if key was set
   */
  private async GetIsKeySet(): Promise<ChallengeMessage> {
    var res = await this._transport.Call("FFF300000000");
    if(res.success == false) {
      return res;
    }

    const err = this.CheckIfFailure(res.payload);
    if(err.success == false){
      MyConsole.Info("ChallengeService::GetIsKeySet->FAILED", err);
      return err;
    }

    if (res.payload.substring(0, 16).toLowerCase().trim() != "fff4000000260801") {
      MyConsole.Info("ChallengeService::GetIsKeySet->Bad command or lenght")
      return { success: false, errorCode: ChallengeErrorCode.INVALID_HEADER };
    }


    let data: string = res.payload;
    if(data == null || data.length < 38*2){
      MyConsole.Info("ChallengeService::GetIsKeySet->Bad lenght")
      return { success: false, errorCode: ChallengeErrorCode.PROTO_ERR };
    }

    //! Remove CMD + Lenght + KeyVersion + SN + Field3
    data = data.substr(4 + 8 + 4 + (34*2), 4);

    if(data == "1801")
      return { success: true, payload: true };
    else if( data == "1800") {
      return { success: true, payload: false };
    }

    return { success: false, errorCode: ChallengeErrorCode.ERR_CHG4 };
  }

  /**
   * Ask device to set the AuthKey and return it, The key won't save unless WriteOtpKey calls
   * @returns AuthKey if success
   */
  private async SetOptKey(): Promise<AuthKeyMessage> {
    var res = await this._transport.Call("FFF500000000");
    if(res.success == false) {
      return res;
    }

    const err = this.CheckIfFailure(res.payload);
    if(err.success == false){
      MyConsole.Info("ChallengeService::SetOptKey->FAILED", err);
      return err;
    }

    //! Remove MsgId + Lenght
    res.payload = res.payload.substring(12);

    const authKeyMsg = this.ProtobufParsAuthKey(res.payload);
    if(authKeyMsg.success == false){
      MyConsole.Info("ChallengeService::SetOptKey->Proto ERROR")
      return authKeyMsg;
    }

    //! If the device can not set the key, it will return failur message, so this won't happen
    //! But still we can check the result
    if(authKeyMsg.isOptSet == false) {
      return {
        success: false,
        errorCode: ChallengeErrorCode.SECURITY_ERR_1
      };
    }

    if(authKeyMsg.authKey == null || authKeyMsg.authKey.length != 64 || authKeyMsg.sn == null || authKeyMsg.sn.length != 64){
      MyConsole.Info("ChallengeService::SetOptKey->Auth key or serial number data length is invalid");
      return {
        success: false,
        errorCode: ChallengeErrorCode.WRONG_PARAM,
      };
    }

    //! The message contains AuthKey, The message should be sent to server and server should return back the hash of AuthKey
    return authKeyMsg;
  }

  /**
   * Write AuthKey to OPT permanently
   * @param serverMessage
   * @returns
   */
   private async WriteOtpKey(serverMessage: AuthKeyMessage): Promise<AuthKeyMessage> {
    //! Send Hash(AuthKey) to device
    var res = await this._transport.Call("FFF7000000220A20" + serverMessage.authKey);
    if(res.success == false) {
      return res;
    }

    const err = this.CheckIfFailure(res.payload);
    if(err.success == false){
      MyConsole.Info("ChallengeService::WriteOtpKey->FAILED", err);
      return err;
    }

    return this.ProtobufParsAuthKey(res.payload);
  }

  /**
   * Step 3: Check Session key hash with device
   * @param chg1 Session key hash
   * @returns
   */
  private async CheckHashWithDevice(chg1: ChallengeMessage): Promise<ChallengeMessage> {
    var cmd = "FFF10000002408032A20" + chg1.checkHash;

    var res = await this._transport.Call(cmd);
    if (res.success == false)
      return res;

    const err = this.CheckIfFailure(res.payload);
    if(err.success == false){
      MyConsole.Info("ChallengeService::CheckHashWithDevice->FAILED", err);
      return err;
    }

    if (res.payload.substring(0, 16).toLowerCase().trim() != "fff1000000240804") {
      MyConsole.Info("ChallengeService::CheckHashWithDevice->Bad command or lenght")
      return { success: false, errorCode: ChallengeErrorCode.ERR_CHG4 };
    }

    //! Remove CMD + Lenght
    res.payload = res.payload.substring(12);

    var chg = this.ProtobufParsChallenge(res.payload);

    return (chg.success == true) ? chg : { success: false, errorCode: ChallengeErrorCode.PROTO_ERR };
  }

  /**
   * Step 2: Challenging device with RndS
   * @param chg1 RndS+Enc(RndD)
   * @returns Enc(RndS)
   */
  private async ChallengeWithDevice(chg1: ChallengeMessage): Promise<ChallengeMessage> {
    var cmd = "FFF10000002608021A10" + chg1.rnd + "2210" + chg1.challenge;

    var res = await this._transport.Call(cmd);
    if (res.success == false)
      return res;

    const err = this.CheckIfFailure(res.payload);
    if(err.success == false){
      MyConsole.Info("ChallengeService::ChallengeWithDevice->FAILED", err);
      return err;
    }

    if (res.payload.substring(0, 16).toLowerCase().trim() != "fff1000000360802") {
      MyConsole.Info("ChallengeService::ChallengeWithDevice->Bad command or lenght")
      return { success: false, errorCode: ChallengeErrorCode.ERR_CHG3 };
    }

    //! Remove CMD + Lenght
    res.payload = res.payload.substring(12);

    return this.ProtobufParsChallenge(res.payload);
  }

  /**
   * Step 1: Get RNDD to start Mutual Authentication
   * @returns RNDD
   */
  private async GetRndDevice(): Promise<ChallengeMessage> {
    var res = await this._transport.Call("FFF1000000020801");
    if (res.success == false)
      return { success: false, errorCode: ChallengeErrorCode.ERR_CHG1 };

    const err = this.CheckIfFailure(res.payload);
    if(err.success == false){
      MyConsole.Info("ChallengeService::GetRndDevice->FAILED", err);
      return err;
    }

    if (res.payload.substring(0, 16).toLowerCase().trim() != "fff1000000360801") {
      MyConsole.Info("ChallengeService::GetRndDevice->Bad command or lenght")
      return { success: false, errorCode: ChallengeErrorCode.ERR_CHG1 };
    }

    //! Remove CMD + Lenght
    res.payload = res.payload.substring(12);

    return this.ProtobufParsChallenge(res.payload);
  }

  /**
   * Check if it is failure message
   * @param buf message from device
   * @returns success: true if no error
   */
  private CheckIfFailure(buf: string): ChallengeMessage {
    if(buf == null || buf.length < 12){
      return {
        success: false,
        errorCode: ChallengeErrorCode.INVALID_HEADER,
      }
    }

    if(buf.substring(0,4) == "0003") {
      let reason = buf.substr(14,2);
      return {
        success: false,
        errorCode: ChallengeErrorCode.FAILED_WITH_REASON,
        payload: reason,
      }
    }

    return {
      success: true,
      errorCode: ChallengeErrorCode.NO_ERR,
    }
  }

  /**
   * Send Challenge data to server
   * @param toServer Data to be sent
   * @param isProduction Is Production API should be called
   * @returns Server response
   */
  private SendToServerChallenge(toServer: ChallengeMessage): Promise<ChallengeMessage> {
    const header = new HttpHeaders({"Content-Type": "application/json"});

    return this.http.post<ChallengeMessage>(RestUrls.getServerChallenge(),
      toServer,
      {headers: header}
    ).toPromise();
  }

  /**
   * Protobuf decode helper function
   * @param buf protobuf raw data
   * @returns ChallengeMessage
   */
  private ProtobufParsChallenge(buf: string): ChallengeMessage {
    let msg: ChallengeMessage = <ChallengeMessage>{};

    if (buf.length < 4) {
      return {
        success: false,
        errorCode: ChallengeErrorCode.PROTO_ERR
      };
    }

    while (buf.length > 0) {
      try {
        // CB for control byte
        var cb = Number.parseInt(buf.substring(0, 2), 16);
        var type = cb & 0x07;
        var field = cb >> 3;

        var tmp: string;
        var num: number;

        if (type == 0) {  // Varint
          num = Number.parseInt(buf.substring(2, 4), 16);
          buf = buf.substring(4);
        }
        else if (type == 2) { // Length-delimited
          var len = Number.parseInt(buf.substring(2, 4), 16);
          tmp = buf.substring(4, 4 + len * 2);
          buf = buf.substring(4 + len * 2);
        }
        else {
          return {
            success: false,
            errorCode: ChallengeErrorCode.PROTO_ERR
          };
        }

        if (field == 1) {
          msg.regTyp = num;
        }
        else if (field == 2) {
          msg.sn = tmp;
        }
        else if (field == 3) {
          msg.rnd = tmp;
        }
        else if (field == 4) {
          msg.challenge = tmp;
        }
        else if (field == 5) {
          msg.checkHash = tmp;
        }

      } catch {
        return {
          success: false,
          errorCode: ChallengeErrorCode.PROTO_ERR
        };
      }

    }

    msg.success = true;

    return msg;
  }

  /**
   * Protobuf decode helper function
   * @param buf protobuf raw data
   * @returns AuthKeyMessage
   */
  private ProtobufParsAuthKey(buf: string): AuthKeyMessage {
    let msg: AuthKeyMessage = <AuthKeyMessage>{};

    if (buf.length < 4) {
      return {
        success: false,
        errorCode: ChallengeErrorCode.PROTO_ERR
      };
    }

    while (buf.length > 0) {
      try {
        // CB for control byte
        var cb = Number.parseInt(buf.substring(0, 2), 16);
        var type = cb & 0x07;
        var field = cb >> 3;

        var tmp: string;
        var num: number;

        if (type == 0) {  // Varint
          num = Number.parseInt(buf.substring(2, 4), 16);
          buf = buf.substring(4);
        }
        else if (type == 2) { // Length-delimited
          var len = Number.parseInt(buf.substring(2, 4), 16);
          tmp = buf.substring(4, 4 + len * 2);
          buf = buf.substring(4 + len * 2);
        }
        else {
          return {
            success: false,
            errorCode: ChallengeErrorCode.PROTO_ERR
          };
        }

        if (field == 1) {
          msg.keyVersion = num;
        }
        else if (field == 2) {
          msg.sn = tmp;
        }
        else if (field == 3) {
          msg.isOptSet = num == 0 ? false : true;
        }
        else if (field == 4) {
          msg.authKey = tmp;
        }
      } catch {
        return {
          success: false,
          errorCode: ChallengeErrorCode.PROTO_ERR
        };
      }

    }

    msg.success = true;

    return msg;
  }
}
