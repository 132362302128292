
<h3 class="body-title float-left">Account Balance</h3>
<div class="btns float-right">
  <button type="button" (click)="send()" class="btn btn-gradinet-blue waves-effect waves-dark mr-1"><i class="fa fa-upload"></i>Send</button>
  <button type="button" (click)="receive()" class="btn btn-gradinet-green waves-effect waves-dark"><i class="fa fa-download"></i>Receive</button>
</div>
<div class="clear"></div>
<div class="chart">
  <div class="chart-header">
    <div class="row p-3">
      <div class="col-lg-4 col-md-4 b-r align-self-center acc-balance">
        <h4 class="d-block m-b-3"> {{account.balance | unit: coin.coinInfo.decimals | number: '1.6-8'}} {{coin.coinInfo.shortcut}} </h4>
        <h6 *ngIf="account.balancePrice != null" class="text-muted d-block"> {{account.balancePrice | number:'1.0-2'}} {{currency}} </h6>
      </div>
      <div *ngIf="coin.isAvailableMarketData" class="col-lg-4 col-md-4 align-self-center">
        <h4 *ngIf="currentPricePercentage" class="d-block m-b-3">{{currentPricePercentage | number:'1.0-2'}}% <i [class]="currentPercentageTypeClass"></i></h4>
        <h6 class="text-muted d-block">{{currnetPeriodTime}}</h6>
      </div>
      <div *ngIf="coin.isAvailableMarketData" class="col-lg-4 col-md-4">
        <div class="dropdown">
          <button type="button" class="btn btn-outline-light text-dark dropdown-toggle float-right" data-toggle="dropdown">
            {{currnetPeriodTime}}
          </button>
          <div class="dropdown-menu">
            <a *ngFor="let periodTime of chartPeriodTiems" href="javascript:;" (click)="updateChart(periodTime)" class="dropdown-item">{{periodTime.type}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
  <div class="chart-body">
    <div [hidden]="!coin.isAvailableMarketData" id="timeline-chart"></div>
    <div *ngIf="!coin.isAvailableMarketData" class="text-center">Chart is not available. But you still can send or receive {{coin.coinInfo.name}}.</div>
  </div>
</div>
<div class="clear"></div>
<app-transactions [viewMode]="'Dashboard'"></app-transactions>
