<h3 class="body-title float-left">Send {{coin.coinInfo.name}}</h3>
<div class="clear"></div>
<div class="card">
  <div class="card-body">

    <div *ngIf="needToReSync" class="alert alert-warning alerthead">
      <div class="container">
        <div class="float-left">
          <i class="fas fa-refresh"></i>
          <div class="msghead">
            <strong>Resync is required</strong><br />
            <span>You need to resync before send {{coin.coinInfo.name}} again.</span>
          </div>
        </div>
        <div class="float-right">
          <button type="button" class="btn btn-gradinet-yellow pnup waves-effect waves-dark" (click)="onReSyncClick()">Resync</button>
        </div>
      </div>
    </div>

    <app-alert [(model)]="alertModel"></app-alert>
    <div class="col-lg-8 col-md-10 col-sm-12 col-12 center p-0">
      <form #sendForm="ngForm" novalidate class="fresh bitcoin-form">
        <input type="hidden" name="symbol" [(ngModel)]="coin.coinInfo.shortcut" #symbol="ngModel" />
        <input type="hidden" name="decimals" [(ngModel)]="coin.coinInfo.decimals" #decimals="ngModel" />
        <input type="hidden" name="balance" [(ngModel)]="maxValueToBeSend" #balance="ngModel" />
        <div class="form-group">
          <label for="address" class="m-b-5">
            Recipient address
            <ng-container *ngIf="address.invalid && address.touched">
              <span class="text-danger ml-auto" *ngIf="address.errors.required"><i class="fas fa-info-circle"></i>
                Address is required</span>
              <span class="text-danger ml-auto" *ngIf="address.errors.appAddressValidator"><i
                  class="fas fa-info-circle"></i> Invalid Address
              </span>
            </ng-container>
            <span class="text-success ml-auto" *ngIf="address.valid && address.touched"><i class="fas fa-check"></i>
              Valid Address</span>
          </label>
          <div class="input-group">
            <input type="text" id="address" class="form-control" autocomplete="off" [(ngModel)]="output.address"
              name="address" #address="ngModel" required appAddressValidator coin-name="symbol" />
            <div class="input-group-append">
              <button class="btn btn-light" type="button" data-original-title="Generate" (click)="getQR()">
                <i class="fas fa-qrcode"></i>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="hasDestinationTag" class="form-group">
          <label for="trtag" class="mb-1 mt-2">
            Transaction tag (optional)
          </label>
          <div class="input-group">
            <input [(ngModel)]="destinationTag" autocomplete="off" type="number" class="form-control" id="trtag"
              placeholder="Transaction tag" name="trtag" value="" min="0" max="4294967295" step="1">
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="col-lg-8 col-md-12 col-sm-12 col-12 p-0 float-left btc-col">
            <div class="form-group" [class.error]="amount.invalid && amount.touched">
              <label for="amount" class="mb-1 mt-0 d-flex">
                <ng-container *ngIf="amount.invalid && amount.touched">
                  <span class="text-danger ml-auto" *ngIf="amount.errors.required"><i class="fas fa-info-circle"></i>
                    Amount is required</span>
                  <span class="text-danger ml-auto" *ngIf="amount.errors.pattern"><i class="fas fa-info-circle"></i>
                    Amount is not valid</span>
                  <span class="text-danger ml-auto" *ngIf="amount.errors.appAmountValidator"><i
                      class="fas fa-info-circle"></i> {{amount.errors.message}}</span>
                </ng-container>
              </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Amount</span>
                </div>
                <input type="text" class="form-control" autocomplete="off" placeholder="Enter Amount" value="output.amount"
                  [(ngModel)]="output.amount" name="amount" #amount="ngModel" required pattern="^\d+(\.\d{1,8})?$"
                  appAmountValidator min-amount="0.000001" max-amount="balance" coin-name="decimals"
                  (keyup)="onInputAmountChanged()"/>
                <a (click)="onGetMaxAccountAmount()" class="maxsend">Max</a>
                <div class="input-group-append">
                  <button class="btn btn-light" type="button" title="" style="cursor:unset;">{{coin.coinInfo.shortcut}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 col-12 float-right usd-col d-flex">
            <div class="form-group mt-auto">
              <div class="input-group">
                <input autocomplete="off" type="text" class="form-control" pattern="^\d+(\.\d{1,8})?$" name="price"
                  #price="ngModel" [(ngModel)]="coinPrice" (keyup)="onInputPriceChanged()">
                <div class="input-group-append">
                  <select class="form-control" name="displayCurrency" #displayCurrency="ngModel"
                    [(ngModel)]="userSettingModel.displayCurrency" (ngModelChange)="onChangeCurrency($event)">
                    <option *ngFor="let currency of currencies" [value]="currency">
                      {{currency}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Some coins like Ethereum does not have any option to select the fee, feeLevels.length = 0, so we don't need to show fee list box here-->
        <ng-container *ngIf="feeLevels.length > 0">
          <label for="selectedFee" class="mb-1 mt-0 d-flex">
            <span class="text-danger ml-auto" *ngIf="isFeeMoreThanBalanceErrorView"><i class="fas fa-info-circle"></i>
              Selected fee is more than account balance
            </span>
          </label>
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Transaction Fee</span>
              </div>
              <select class="form-control" name="selectedFee" #displayCurrency="ngModel" [(ngModel)]="selectedFee"
                (ngModelChange)="onChangeFee($event)"
                [disabled]="isTransactionFeeComboBoxDisable">
                <option *ngFor="let level of feeLevels" [value]="level.name">
                  {{level.name | titlecase}}
                  <button type="button" class="btn btn-submit-amount">
                    {{level.fee | unit: level.decimal | number: '1.6-8'}} {{level.unit}}
                    <div *ngIf="coin.coinInfo.test==false">({{level.price | number: '1.0-3'}} {{level.currency}})</div>
                  </button>
                </option>
              </select>
            </div>
          </div>
          <div class="clear"></div>
        </ng-container>
        <div class="d-block">
          <button type="button" class="btn btn-gradinet-blue pnup waves-effect waves-dark"
            [disabled]="sendForm.invalid || needToReSync" (click)="onSendBtnClick()">
            <i class="fa fa-upload"></i>
            Send
          </button>
          <div class="float-right">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<ng-template #QRDialog>
  <app-dialog headerTitle="Scan QR Code">
    <div class="container">
      <div>
        <canvas id="canvas" hidden style="width:100%; height:100%"></canvas>
      </div>
      <div class="fresh" style="margin:10px 0 20px 0;">
        <div class="form-group m-b-0">
          <div id="loadingMessage" class="alert2 alert2-warning">
            <i class="fas fa-exclamation-circle"></i>
            <span>Unable to access video stream (please make sure you have a webcam enabled)</span>
          </div>
        </div>
        <div class="form-group">
          <div class="label" id="output" hidden>
            <div id="outputMessage">No QR code detected.</div>
            <div hidden><b>Data:</b> <span id="outputData"></span></div>
          </div>
        </div>
      </div>
    </div>
  </app-dialog>
</ng-template>
