import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

import {DeviceMode} from '@core/models/connect/device-mode.enum';
import {UiEvent} from '@core/models/connect/ui-event.enum';
import {DeviceFirmwareStatus} from '@core/models/connect/device-firmware-status.enum';
import {Helpers} from '../core/services/helpers';
import {WindowService} from '../core/services/window.service';
import {WalletDeviceService} from "../core/services/wallet-device.service";

enum StartMode {
  Loading = 'Loading',
  Ready = 'Ready',
  Transport = 'Transport',
  Failed = 'Failed',
}

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit, OnDestroy, AfterViewInit {

  //#region Private members

  private _deviceSubscription: Subscription;
  private _uiAlertSubscription: Subscription;

  //#endregion

  //#region Public members

  uiMode: StartMode = StartMode.Loading;
  isWebUsb = false;
  isLinux = false;
  isWin10 = false;
  chromiumVersion: number;

  //#endregion

  constructor(private _deviceService: WalletDeviceService,
              private _windowService: WindowService,
              private _router: Router) {
  }

  ngOnInit() {
    this._uiAlertSubscribe();
    this._deviceSubscribe();
    this._userAgentDetect();

    if (!this._deviceService.isInitial()) {
      this._deviceService.init().finally(() => {
        this.connect();
      });
    } else {
      this.uiMode = StartMode.Ready;
    }
  }

  async ngAfterViewInit() {
    if (this.uiMode === StartMode.Loading) {
      Helpers.StartLoading(true);
    } else {
      Helpers.StartLoading(false);
    }
  }

  async connect() {
    try {
      const res = await this._deviceService.connect();
      if (!res.success) {
        if (res.errorCode === 12) {
          this.uiMode = StartMode.Transport;
          Helpers.StartLoading(false);
        } else if (res.errorCode !== 14) {
          this.uiMode = StartMode.Failed;
          Helpers.StartLoading(false);
        }
      }
    } catch (e) {
      this.uiMode = StartMode.Failed;
      Helpers.StartLoading(false);
      console.log('error in connect : ', e);
    }
  }

  ngOnDestroy() {
    this._deviceSubscription.unsubscribe();
    this._uiAlertSubscription.unsubscribe();
  }

  //#region Private members

  private _deviceSubscribe() {
    this._deviceSubscription = this._deviceService.device$.subscribe(device => {
      if (device.isConnected === true) {
        switch (device.mode) {
          case DeviceMode.Bootloader:
            if (device.firmware === DeviceFirmwareStatus.Required) {
              this._router.navigate(['/firmware/install']);
            } else {
              this._router.navigate(['/firmware/update']);
            }
            break;
          case DeviceMode.Initialize:
            this._router.navigate(['/welcome']);
            break;
          case DeviceMode.Normal:
          case DeviceMode.Seedless:
            this._router.navigate(['/wallet/portfolio']);
            break;
          default:
            break;
        }
      }
    }, error => {
      console.log('error in device subscribe : ', error);
      this.uiMode = StartMode.Failed;
      Helpers.StartLoading(false);
    });
  }

  private _uiAlertSubscribe() {
    this._uiAlertSubscription = this._deviceService.uiAlert$.subscribe(uiEvent => {
      if (uiEvent === UiEvent.Device_Initilaized) {
        this.uiMode = StartMode.Ready;
        Helpers.StartLoading(false);
      } else if (uiEvent === UiEvent.Device_NotInitilaized) {
        this.uiMode = StartMode.Failed;
        Helpers.StartLoading(false);
      }
    }, () => {
      this.uiMode = StartMode.Failed;
      Helpers.StartLoading(false);
    });
  }

  private _userAgentDetect() {
    let os;
    let osVersion;
    const nAgt = this._windowService.nativeWindow.navigator.userAgent;
    const clientStrings = [
        {s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/},
        {s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/},
        {s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/},
        {s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/},
        {s: 'Windows Vista', r: /Windows NT 6.0/},
        {s: 'Windows Server 2003', r: /Windows NT 5.2/},
        {s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/},
        {s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/},
        {s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/},
        {s: 'Windows 98', r: /(Windows 98|Win98)/},
        {s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/},
        {s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
        {s: 'Windows CE', r: /Windows CE/},
        {s: 'Windows 3.11', r: /Win16/},
        {s: 'Android', r: /Android/},
        {s: 'Open BSD', r: /OpenBSD/},
        {s: 'Sun OS', r: /SunOS/},
        {s: 'Chrome OS', r: /CrOS/},
        {s: 'Linux', r: /(Linux|X11(?!.*CrOS))/},
        {s: 'iOS', r: /(iPhone|iPad|iPod)/},
        {s: 'Mac OS X', r: /Mac OS X/},
        {s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
        {s: 'QNX', r: /QNX/},
        {s: 'UNIX', r: /UNIX/},
        {s: 'BeOS', r: /BeOS/},
        {s: 'OS/2', r: /OS\/2/},
        {s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
    ];

    for (const cs of clientStrings) {
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
      if (osVersion === '10' || osVersion === '10.0') {
        this.isWin10 = true;
      }
    }

    if (os === 'Linux') {
      this.isLinux = true;
    }

    const chromeRaw = nAgt.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (chromeRaw) {
      this.chromiumVersion = parseInt(chromeRaw[2], 10);
    }
  }

  //#endregion
}
