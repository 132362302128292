import {AccountBrief} from "@core/models/connect/account-brief";
import {AccountInfo} from "@core/models/connect/account-info";
import {BaseOutput} from "@core/models/connect/base-output";
import {CoinModel} from "@core/models/connect/coin-model";
import {Transaction} from "@core/models/connect/transaction";
import {Device} from "@webcore/device/Device";
import {Wallet} from "./wallet";
import {OmniWallet} from '@webcore/wallet/OmniWallet';
import {OmniCoinInfoModel} from "@webcore/models/CoinInfoModel";
import {AddressModel, SignedTx} from "@webcore/models/Prokey";
import {OmniAccountInfo, OmniTransactionView, OmniWalletModel} from "@webcore/models/OmniWalletModel";
import {MyConsole} from "@webcore/utils/console";
import {TransactionType} from "@core/models/connect/transaction-type.enum";
import {TransactionTarget} from "@core/models/connect/transaction-target";
import * as PathUtil from "@webcore/utils/pathUtils";
import {CoinBaseType} from "@webcore/coins/CoinInfo";
import {ExtraId} from "@core/models/connect/extra-id";

export class Omni extends Wallet {
  _fullDiscoveredWallet: OmniWalletModel;
  _defaultAccount: OmniAccountInfo;

  constructor(device: Device, coinModel: CoinModel) {
    super(device, coinModel);
    this._webcoreBaseWallet = new OmniWallet(device, coinModel.coinInfo.name, (coinModel.coinInfo as OmniCoinInfoModel).proparty_id);
  }

  /**
   * Get count of accounts, -1 means no account at all
   * @returns
   */
  get AccountsCount(): number {
    if (this._fullDiscoveredWallet == null) {
      return -1;
    }
    return this._fullDiscoveredWallet.accounts.length;
  }

  ClearCache(): void {
    this._currentAccountIndex = 0;
    this._isAccountDiscoveryFinished = false;
    this._fullDiscoveredWallet = null;
    this._isNewAccountAlreadyAdded = false;
    this.NeedToReSync = false;
  }

  StartAccountDiscovery(onNewAccountCallBack: (discoveredAccountNumber: number) => void): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this._fullDiscoveredWallet) {
        if (onNewAccountCallBack) {
          this._fullDiscoveredWallet.accounts.forEach(acc => {
            onNewAccountCallBack(acc.accountIndex);
          });
        }

        this._isAccountDiscoveryFinished = true;
        return resolve();
      }
      this._fullDiscoveredWallet = <OmniWalletModel>{
        totalBalance: 0,
        accounts: [],
      };
      try {
        (this._webcoreBaseWallet as OmniWallet).StartDiscovery(accountInfo => {
          this._fullDiscoveredWallet.accounts.push(accountInfo);

          if (onNewAccountCallBack) {
            onNewAccountCallBack(accountInfo.accountIndex);
          }
        }).then((wallet: OmniWalletModel) => {
          this._fullDiscoveredWallet.totalBalance = wallet.totalBalance;
          this._totalBalance = wallet.totalBalance;
          this._isAccountDiscoveryFinished = true;
          return resolve();
        });
      } catch (e) {
        this._isAccountDiscoveryFinished = false;
        return reject(e);
      }
    });
  }

  canAddNewAccount(): boolean {
    if (this._isAccountDiscoveryFinished == false || this._isNewAccountAlreadyAdded == true) {
      return false;
    }

    if (this._fullDiscoveredWallet == null || this._fullDiscoveredWallet.accounts == null) {
      return false;
    }

    if (this._fullDiscoveredWallet.accounts.length == 0) {
      return false;
    }

    const lastAccount = this._fullDiscoveredWallet.accounts[this._fullDiscoveredWallet.accounts.length - 1];
    return lastAccount.transactions && lastAccount.transactions.length > 0;
  }

  async _addNewAccount(): Promise<number> {
    if (this._fullDiscoveredWallet == null || this._fullDiscoveredWallet.accounts == null) {
      return -1;
    }

    let newAccount: OmniAccountInfo = {
      accountIndex: this._fullDiscoveredWallet.accounts.length,
      balance: 0,
      transactions: [],
    }

    const pathToFirstAddressOfThisNewAccount = PathUtil.GetBipPath(
      CoinBaseType.OMNI,
      newAccount.accountIndex,
      this.CoinInfo
    )

    // set the account address
    pathToFirstAddressOfThisNewAccount.address = await this.getAddress(pathToFirstAddressOfThisNewAccount.path, false);
    newAccount.addressModel = pathToFirstAddressOfThisNewAccount;

    this._fullDiscoveredWallet.accounts.push(newAccount);
    this._isNewAccountAlreadyAdded = true;


    return newAccount.accountIndex;
  }

  getWebcoreAccountInfo(accountIndex: number) {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;
    return this._fullDiscoveredWallet.accounts[ai];
  }

  getAccountBrief(accountIndex: number): AccountBrief {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;
    const accInfo = this._fullDiscoveredWallet.accounts[ai];

    return <AccountBrief>{
      id: accInfo.accountIndex,
      balance: accInfo.balance,
      label: `Account #${accInfo.accountIndex + 1}`,
    }
  }

  getAccountInfo(accountIndex: number): AccountInfo {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;

    return this._ToAccountInfo(this._fullDiscoveredWallet.accounts[ai]);
  }

  setCurrentAccount(accountIndex: number): void {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    this._currentAccountIndex = accountIndex;
  }

  getPublicKey(account: number, showOnProkey: boolean): Promise<any> {
    //! No public key
    return null;
  }

  async getAddress(path: number[], showOnProkey: boolean): Promise<any> {
    return await this._webcoreBaseWallet.GetAddress<AddressModel>(path, showOnProkey);
  }

  async getAddresses(accountInfo: AccountInfo): Promise<any> {
    return this._webcoreBaseWallet.GetAddress<AddressModel>(accountInfo.lastUnusedAddress.path);
  }

  getAllAccountsInfo(): AccountInfo[] {
    if (this._fullDiscoveredWallet?.accounts?.length > 0) {
      return this._fullDiscoveredWallet.accounts.map((account: OmniAccountInfo) => {
        this._ensureAccountDiscoveryIsDone(account.accountIndex);
        return this._ToAccountInfo(account);
      });
    }
    else {
      return [];
    }
  }

  async getAccountInfoByParams(accountBrief: AccountBrief): Promise<any> {
    const omniAccount = await (this._webcoreBaseWallet as OmniWallet).AccountDiscovery(accountBrief.id);
    return this._ToAccountInfo(omniAccount);
  }

  getDefaultAccount(): AccountInfo {
    return this._ToAccountInfo(this._defaultAccount);
  }

  async signMessage(path: number[], message: string): Promise<any> {
    return await this._webcoreBaseWallet.SignMessage(path, message);
  }

  async verifyMessage(address: string, message: string, signature: string): Promise<any> {
    return await this._webcoreBaseWallet.VerifyMessage(address, message, signature);
  }

  async prepareSendModel(output: BaseOutput, ...params: any[]) {
    let accountNumber = params[0].id;
    let selectedFee = params[1];

    // Decimal Factor
    // for divisible coins or tokens, the value in this field is to be divided by 100,000,000
    // for indivisible coins or tokens, the value in this field is the integer number of Omni Protocol coins or tokens (e.g. 1 represents 1 indivisible token)
    const decimalFactor = ((this._webcoreBaseWallet.GetCoinInfo() as OmniCoinInfoModel).divisible == true) ? 100000000 : 1;

    return await (this._webcoreBaseWallet as OmniWallet).GenerateTransaction(output.address, +output.amount * decimalFactor, accountNumber, selectedFee);

  }

  async sendTransaction(model: any): Promise<any> {
    let signedTx: any = await this._webcoreBaseWallet.SignTransaction(model);
    return await (this._webcoreBaseWallet as OmniWallet).SendTransaction(signedTx.serialized_tx);
  }

  async handleSendResult(payload: any): Promise<any> {
    let signedTx: SignedTx = payload;
    return signedTx;
  }

  async calculateTransactionFee(outputValue: BaseOutput, fromAccount: number) {
    return await (this._webcoreBaseWallet as OmniWallet).CalculateTransactionFee();
  }

  async getAccountTransactionsBy(accountNumber: number, startIndex: number, numberOfTransactions: number): Promise<Transaction[]> {
    let transactions: Transaction[] = [];
    let omniTransactions: OmniTransactionView[] = [];
    try {
      omniTransactions = await (this._webcoreBaseWallet as OmniWallet).GetTransactionViewList(accountNumber, startIndex, numberOfTransactions);
      MyConsole.Info("Loaded transaction:", omniTransactions);

      if (omniTransactions && omniTransactions.length > 0) {
        for (let omniTran of omniTransactions) {
          let transaction = <Transaction>{};
          transaction.targets = [];
          transaction.hash = omniTran.hash;
          transaction.date = omniTran.date;
          transaction.isValid = omniTran.isValid;
          transaction.invalidReason = omniTran.invalidReason;

          if (omniTran.status == 'RECEIVED') {
            transaction.type = TransactionType.Received;
            transaction.targets.push((<TransactionTarget>{
              address: omniTran.toAddress,
              value: omniTran.amount,
              status: omniTran.status,
            }));
          } else if (omniTran.status == 'SENT') {
            transaction.type = TransactionType.Sent;
            transaction.targets.push((<TransactionTarget>{
              address: omniTran.toAddress,
              value: omniTran.amount,
              status: omniTran.status,
            }));
          }

          transactions.push(transaction);
        }
      }
      return transactions;
    } catch (e) {
      console.log(e);
    }
  }

//#region Private Methods
  private _ToAccountInfo(omniAccount: OmniAccountInfo): AccountInfo {
    const defaultAccount: AccountInfo = <AccountInfo>{};

    if (omniAccount == null) {
      return defaultAccount;
    }

    defaultAccount.id = omniAccount.accountIndex;
    defaultAccount.balance = omniAccount.balance;
    defaultAccount.lastUnusedAddress = omniAccount.addressModel;
    defaultAccount.hasAccountPerEachAddress = true;
    defaultAccount.allAddresses = [omniAccount.addressModel];

    if (omniAccount.transactions) {
      defaultAccount.transactions = omniAccount.transactions.length;
    }

    return defaultAccount;
  }

  isAddressValid(address: string): boolean {
    return this._webcoreBaseWallet.IsAddressValid(address);
  }

  /**
   * This function will return the first path of the first account
   * @returns The path of Account 0 Path 0
   */
  getPathOfFirstAddress(): AddressModel {
    return PathUtil.GetBipPath(
      CoinBaseType.OMNI,
      0 // Account 0
    );
  }

  getCoinExtraIdTypes(): Array<ExtraId> {
    return null;
  }

  private _ensureAccountDiscoveryIsDone(accountIndex?: number) {
    if(this._fullDiscoveredWallet == null) {
      throw new Error("No wallet info, Call AccountDiscovery first");
    }

    if(accountIndex != null && (accountIndex < 0 || accountIndex >= this._fullDiscoveredWallet.accounts.length)) {
      throw new Error("Account index is out of range");
    }
  }

//#endregion
}
