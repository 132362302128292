import { CoinModel } from "@core/models/connect/coin-model";
import { CoinBaseType, CoinInfo } from "@webcore/coins/CoinInfo";
import { BitcoinBaseCoinInfoModel, Erc20BaseCoinInfoModel } from "@webcore/models/CoinInfoModel";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { CustomTokenService } from "../custom-token.service";

export class WalletCoins {

    private _coins: CoinModel[] = [];
    private _famousCoins: CoinModel[] = [];
    private _otherCoins: CoinModel[] = [];
    private _myCoins: CoinModel[] = [];
    private _defaultCoin: CoinModel;
    private readonly _priorityNumber: number = 10;

    private _otherCoinsSource = new BehaviorSubject<CoinModel[]>(this._otherCoins);
    private _assetCoinsSource = new BehaviorSubject<boolean>(false);

    // number of wallet supported coins
    count: number = 0;

    onOtherCoinsChange = this._otherCoinsSource.asObservable();
    onAssetCoinsChange = this._assetCoinsSource.asObservable();

    /**
     * Initialize wallet coins by device version.
     */
    constructor(
        private _deviceVersion: string,
        private _customTokenService: CustomTokenService) {
            this._initialize(_deviceVersion);
    }

    getCoins(): CoinModel[] {
        return this._coins;
    }

    getFamousCoins(): CoinModel[] {
        return this._famousCoins;
    }

    getOtherCoins(): CoinModel[] {
        return this._otherCoins;
    }

    getMyCoins(myFamous: string[]): CoinModel[] {
        if (myFamous?.length > 0) {
            this._myCoins = this._famousCoins.concat(this._otherCoins.filter(c => myFamous.includes(c.coinInfo.id)));
        } else {
            this._myCoins = this._famousCoins;
        }
        
        return this._myCoins;
    }

    addOtherCoins(otherCoins: CoinModel[], withChangeSource: boolean) {
        if (otherCoins.length === 0) {
            this._otherCoins = otherCoins;
        } else {
            otherCoins.forEach(coin => this._otherCoins.push(coin));
        }

        if (withChangeSource) {
            this._changeOtherCoinsSource();
        }
    }

    changeAssetCoinsSource() {
        this._assetCoinsSource.next(true);
    }

    get defaultCoin(): CoinModel {
        return this._defaultCoin;
    }

    private _changeOtherCoinsSource() {
        this._otherCoinsSource.next(this._otherCoins);
    }

    private _initialize(version: string) {
        this._coins = [];
        const coins = CoinInfo.GetAllCoinsInfoByVersion(version);
        const customTokens = this._customTokenService.getCustomTokens();
        for (const coin of coins) {
          const model = new CoinModel(coin);
          model.isFamous = coin.priority < this._priorityNumber;
          model.isCustomToken = false;
          this._coins.push(model);
        }
        
        this._addCustomTokens(customTokens, this._coins);
        this.count = this._coins.length;

        if (this.count > 0) {
            this._famousCoins = this._coins.filter(c => c.isFamous === true);
            this._otherCoins = this._coins.filter(c => c.isFamous === false);
            this._changeOtherCoinsSource();
        }

        this._setDefaultCoin();
    }
    
    private _addCustomTokens(customTokens: Array<Erc20BaseCoinInfoModel>, coinModels: CoinModel[]) {
        if (customTokens) {
          for (const coin of customTokens) {
            const model = new CoinModel(coin);
            model.isFamous = false;
            model.isCustomToken = true;
            coinModels.push(model);
          }
        }
    }

    private _setDefaultCoin(){
        let btcCoinInfo = CoinInfo.Get<BitcoinBaseCoinInfoModel>("Bitcoin", CoinBaseType.BitcoinBase);
        this._defaultCoin = new CoinModel(btcCoinInfo);
    }
}
