export enum ButtonRequestType {
  ButtonRequest_Other = 'ButtonRequest_Other',
  ButtonRequest_FeeOverThreshold = 'ButtonRequest_FeeOverThreshold',
  ButtonRequest_ConfirmOutput = 'ButtonRequest_ConfirmOutput',
  ButtonRequest_ResetDevice = 'ButtonRequest_ResetDevice',
  ButtonRequest_ConfirmWord = 'ButtonRequest_ConfirmWord',
  ButtonRequest_WipeDevice = 'ButtonRequest_WipeDevice',
  ButtonRequest_ProtectCall = 'ButtonRequest_ProtectCall',
  ButtonRequest_SignTx = 'ButtonRequest_SignTx',
  ButtonRequest_FirmwareCheck = 'ButtonRequest_FirmwareCheck',
  ButtonRequest_Address = 'ButtonRequest_Address',
  ButtonRequest_PublicKey = 'ButtonRequest_PublicKey',
  ButtonRequest_MnemonicWordCount = 'ButtonRequest_MnemonicWordCount',
  ButtonRequest_MnemonicInput = 'ButtonRequest_MnemonicInput',
  ButtonRequest_PassphraseType = 'ButtonRequest_PassphraseType',
  ButtonRequest_UnknownDerivationPath = 'ButtonRequest_UnknownDerivationPath',
  ButtonRequest_EnterPinOnDevice = 'ButtonRequest_EnterPinOnDevice', 
  ButtonRequest_EnterNewPinOnDevice = 'ButtonRequest_EnterNewPinOnDevice',
  ButtonRequest_ReEnterNewPinOnDevice = 'ButtonRequest_ReEnterNewPinOnDevice',
  ButtonRequest_PinOnDeviceDone = 'ButtonRequest_PinOnDeviceDone',
}
