import {Component} from '@angular/core';
import {WalletDeviceService} from "../../services/wallet-device.service";
import {DialogService} from "@shared/dialog/_services/dialog.service";

@Component({
  selector: 'app-wallet-pin-request',
  templateUrl: './wallet-pin-request.component.html',
  styleUrls: ['./wallet-pin-request.component.css']
})
export class WalletPinRequestComponent {

  model: any = { pin: '' };
  submitDisabled: boolean = true;

  constructor(private _deviceService: WalletDeviceService, private _dialogService: DialogService) {
  }

  backspacePin() {
    let currentpin: string = this.model.pin;
    if (currentpin.length > 0) {
      currentpin = currentpin.substring(0, currentpin.length - 1);
    }
    if (currentpin.length === 0) {
      this.submitDisabled = true;
    }
    this.model.pin = currentpin;
  }

  addPin(value) {
    const maxInputLength = 9;
    let currentPin: string = this.model.pin;
    if (currentPin.length < maxInputLength) {
      currentPin += value;
      this.model.pin = currentPin;
    }
    if (currentPin.length > 0) {
      this.submitDisabled = false;
    }
  }

  submitPin() {
    this._dialogService.closeAll();
    this._deviceService.receivePin(this.model.pin);
    this.model.pin = '';
  }
}
