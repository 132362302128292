<div class="pin-on-device-container">
  <div class="easy-numpad-frame easy-numpad-frame2" id="easy-numpad-frame">
    <div id="pin" style="width:100%" class="d-block">
      <div class="easy-numpad-container fade show">
        <div class="on-device-inner text-center">
          <h5>Enter Prokey PIN</h5>
          <div class="alert alert-primary" style="text-align: left;font-size: 15px;">
            Click <i class="fa fa-sort-up"></i> or <i class="fa fa-sort-down"></i> to choose a number and press OK to select it. Once you done, Long press OK button to enter.
          </div>
          <div class="pin-on-device">
            <img src="./assets/img/long-press-ok.gif" width="300px" height="340px">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
