import { Injectable } from '@angular/core';
import { ErrorCode } from '../models/error-code-type';

@Injectable()
export class CustomErrorHandlerService {
  code: ErrorCode;

  constructor() {
  }

  getErrorMessage(errorCode: string): string {
    let message = this.code[errorCode];
    if (message) {
      return message;
    }
    return '';
  }
}
