import { CoinBaseType } from "@webcore/coins/CoinInfo";
import { BitcoinBaseCoinInfoModel,
  EthereumBaseCoinInfoModel,
  Erc20BaseCoinInfoModel,
  MiscCoinInfoModel,
  OmniCoinInfoModel,
  RippleCoinInfoModel,
  NemCoinInfoModel
 } from "@webcore/models/CoinInfoModel"

export class CoinModel {
  public coinInfo: BitcoinBaseCoinInfoModel | EthereumBaseCoinInfoModel | Erc20BaseCoinInfoModel | MiscCoinInfoModel | OmniCoinInfoModel | RippleCoinInfoModel | NemCoinInfoModel;

  public isOneAccountPerAddress: boolean;
  public isFamous: boolean;
  public type: CoinBaseType;
  public marketData: any;
  public simplePrice: any;
  public isAvailableMarketData: boolean;
  public isCustomToken: boolean;

  //! This flag is used to show this coin in drop down list, true means show. it is set in WalletAddCoinComponent::subscribeOtherCoins
  public isSelected: boolean;

  constructor(coinInfo: BitcoinBaseCoinInfoModel | EthereumBaseCoinInfoModel | Erc20BaseCoinInfoModel | MiscCoinInfoModel | OmniCoinInfoModel | RippleCoinInfoModel | NemCoinInfoModel, coinBaseType?: CoinBaseType) {
    this.coinInfo = coinInfo;
    if(coinInfo.coinBaseType != null) {
      this.type = coinInfo.coinBaseType;
    } else if(coinBaseType != null) {
      this.type = coinBaseType;
    } else {
      throw new Error("Coin Base Type is not set");
    }
  }

  getImageUrl(): string {
    if (this.coinInfo.coinBaseType === CoinBaseType.ERC20) {
      return `./assets/img/coins/${(this.coinInfo as Erc20BaseCoinInfoModel).address}.png`;
    }
    return `./assets/img/coins/${this.coinInfo.shortcut.toLowerCase()}.png`;
  }
}
