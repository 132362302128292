import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { GoogleOath2ProviderService } from '@core/services/oauth2/google-oath2-provider.service';
import { first } from 'rxjs/operators';
import { WalletDeviceService } from '@core/services/wallet-device.service';
import { StorageService } from '@core/services/storage/storage.service';

@Component({
  selector: 'app-google-auth-insuffient-scope-dialig',
  templateUrl: './google-auth-insufficient-scope-dialog.component.html',
  styleUrls: ['./google-auth-insufficient-scope-dialog.component.css']
})
export class GoogleAuthInsufficientScopeDialogComponent implements OnInit {
  private _deviceId: string;
  constructor(private _storageService: StorageService, private _deviceService: WalletDeviceService) { }

  async ngOnInit(): Promise<void> {
    let device = await this._deviceService.device$
        .pipe(first()).toPromise(); // it will unsubscribe device$ after first value is emitted.
    this._deviceId = device.features.device_id;
  }

  reconnect() {
    this._storageService.reconnect(this._deviceId);
  }

  public useLocalStorage() {
    this._storageService.changeToLocalStorage(this._deviceId);
  }
}
