<div class="d-block exchange fill-white">
  <div class="alert alert-success p-2 w-100 py-3 d-block" style="color:#07563B;border-radius:10px">
    Your exchange executed successfully, You can leave the page and wait for payment
  </div>
  <div class="d-block wallet-details-receiving w-100">
    <ul>
      <li>
        <span class="fw500">Transaction ID</span>
        <b>{{exchange.transactionId}}</b>
      </li>
      <li>
        <span class="fw500">Exchange</span>
        <b>
          {{exchange.sendAmount}} {{exchange.senderCoin.coinInfo.shortcut.toUpperCase()}} to
          {{exchange.receiveCoinAmount}} {{exchange.receiverCoin.coinInfo.shortcut.toUpperCase()}}
        </b>
      </li>
      <li>
        <span class="fw500">{{exchange.providerName}} link</span>
        <b><a class="tlink" target="a_blank" href="{{exchange.providerTransactionUrl}}{{exchange.exchangeId}}">View transaction on {{exchange.providerName}}</a></b>
      </li>
      <li>
        <span class="fw500">Status</span>
        <div *ngIf="isExchangeWaiting()">
          <svg class="spinner2" width="22px" height="22px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
          </svg>
          <b> Waiting for confirm </b>
        </div>
        <div *ngIf="isExchangeFailed()">
          <i class="fas fa-exclamation-triangle" style="color: #ed4747;"></i>
          <b> Failed </b>
        </div>
        <div *ngIf="isExchangeFinished()">
          <i class="fas fa-check" style="color: #0ec74f;"></i>
          <b> Finished </b>
        </div>
      </li>
    </ul>
  </div>
  <div class="return-wallet text-right">
    <button class="btn btn-return-wallet waves waves-effect" (click)="returnToWallet()">
      <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8.746" viewBox="0 0 5 8.746">
        <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back"
              d="M12.758,10.565l3.309-3.307a.625.625,0,0,0-.885-.883l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
              transform="translate(-11.251 -6.194)" fill="#145e9c"/>
      </svg>
      <span>Return to Wallet</span>
    </button>
  </div>
  <p class="mt-5 receiving-p">
    To ask any question regarding your exchange, please refer to Provider website at <br>
    https://changenow.com
  </p>
</div>
