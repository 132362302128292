import {Device} from "@webcore/device/Device";
import {CoinModel} from "@core/models/connect/coin-model";
import {Wallet} from "./wallet";
import {EthereumWallet} from "@webcore/wallet/EthereumWallet";
import {CoinBaseType} from "@webcore/coins/CoinInfo";
import {Erc20BaseCoinInfoModel, EthereumBaseCoinInfoModel,} from "@webcore/models/CoinInfoModel";
import {AccountInfo} from "@core/models/connect/account-info";
import {BaseOutput} from "@core/models/connect/base-output";
import {AccountBrief} from "@core/models/connect/account-brief";
import {Transaction} from "@core/models/connect/transaction";
import {AddressModel, EthereumAddress, EthereumSignedTx, PublicKey,} from "@webcore/models/Prokey";
import {MyConsole} from "@webcore/utils/console";
import {EthereumAccountInfo, EthereumTransactionView, EthereumWalletModel,} from "@webcore/models/EthereumWalletModel";
import BigNumber from "bignumber.js";
import {TransactionType} from "@core/models/connect/transaction-type.enum";
import {TransactionTarget} from "@core/models/connect/transaction-target";
import * as PathUtil from "@webcore/utils/pathUtils";

export class Ethereum extends Wallet {

  private _fullDiscoveredWallet: EthereumWalletModel;
  private _defaultAccount: EthereumAccountInfo;

  //Etherem wallet
  constructor(
    device: Device,
    coinModel: CoinModel
  ) {
    super(device, coinModel);

    //! coinInfo which is the last parameter of new EthereumWallet(..) cointains coinNameOrContractAddress, so coinName/contractAddress is redundant and  passed as empty.
    this._webcoreBaseWallet  = new EthereumWallet(
      this._device,
      "",
      coinModel.type == CoinBaseType.ERC20,
      coinModel.type == CoinBaseType.ERC20
        ? (coinModel.coinInfo as Erc20BaseCoinInfoModel)
        : (coinModel.coinInfo as EthereumBaseCoinInfoModel)
    );
  }

  /**
   * Get count of accounts, -1 means no account at all
   * @returns
   */
  get AccountsCount(): number {
    if(this._fullDiscoveredWallet == null) {
      return -1;
    }
    return this._fullDiscoveredWallet.accounts.length;
  }

  //#region implementation of Wallet methods

  ClearCache(): void {
    this._currentAccountIndex = 0;
    this._isAccountDiscoveryFinished = false;
    this._fullDiscoveredWallet = null;
    this._isNewAccountAlreadyAdded = false;
    this.NeedToReSync = false;
  }

  StartAccountDiscovery(onNewAccountCallBack: (discoveredAccountNumber: number) => void): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      //! Return previous discovered data if any
      if(this._fullDiscoveredWallet) {
        if(onNewAccountCallBack) {
          this._fullDiscoveredWallet.accounts.forEach(acc => {
            onNewAccountCallBack(acc.accountIndex);
          });
        }

        this._isAccountDiscoveryFinished = true;
        return resolve();
      } else {
        this._fullDiscoveredWallet = <EthereumWalletModel>
          {
            totalBalance: 0,
            accounts: [],
          };
      }

      //! Start discovery
      try {
        (this._webcoreBaseWallet as EthereumWallet).StartDiscovery((accInfo) => {

          //! Add discovered account
          this._fullDiscoveredWallet.accounts.push(accInfo);

          if (onNewAccountCallBack) {
            onNewAccountCallBack(accInfo.accountIndex);
          }
        }).then((ethWalletModel: EthereumWalletModel) => {
          //! Account discovery is done
          this._fullDiscoveredWallet.totalBalance = ethWalletModel.totalBalance;
          this._totalBalance = this._fullDiscoveredWallet.totalBalance;
          this._isAccountDiscoveryFinished = true;

          return resolve();
        }).catch((error) => {
          return reject(error);
        });
      } catch(e) {
        this._isAccountDiscoveryFinished = false;
        return reject(e);
      }
    })
  }

  canAddNewAccount(): boolean {
    if(this._isAccountDiscoveryFinished == false || this._isNewAccountAlreadyAdded == true) {
      return false;
    }

    if(this._fullDiscoveredWallet == null || this._fullDiscoveredWallet.accounts == null) {
      return false;
    }

    if(this._fullDiscoveredWallet.accounts.length == 0) {
      return false;
    }
    const lastAccount = this._fullDiscoveredWallet.accounts[this._fullDiscoveredWallet.accounts.length - 1];
    if(lastAccount.isDirectQueryFromGeth && +lastAccount.balance > 0)
      return true;
      
    return lastAccount.transactions && lastAccount.transactions.length > 0;
  }

  async _addNewAccount(): Promise<number> {
    if (this._fullDiscoveredWallet == null || this._fullDiscoveredWallet.accounts == null) {
      return -1;
    }

    const newAccount: EthereumAccountInfo = {
      txs: undefined,
      nonce: undefined,
      address: undefined,
      nonTokenTxs: undefined,
      unconfirmedTxs: undefined,
      unconfirmedBalance: undefined,
      balance: '0',
      transactions: [],
      accountIndex: this._fullDiscoveredWallet.accounts.length
    };

    const pathToFirstAddressOfThisNewAccount = PathUtil.GetBipPath(
      CoinBaseType.EthereumBase,
      newAccount.accountIndex,
      this.CoinInfo,
    )

    // set the account address
    pathToFirstAddressOfThisNewAccount.address = await this.getAddress(pathToFirstAddressOfThisNewAccount.path, false);
    newAccount.addressModel = pathToFirstAddressOfThisNewAccount;

    this._fullDiscoveredWallet.accounts.push(newAccount);
    this._isNewAccountAlreadyAdded = true;

    return newAccount.accountIndex;
  }

  getWebcoreAccountInfo(accountIndex: number) {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;
    return this._fullDiscoveredWallet.accounts[ai];
  }

  getAccountBrief(accountIndex: number): AccountBrief {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;
    const accInfo = this._fullDiscoveredWallet.accounts[ai];

    return <AccountBrief>{
      id: accInfo.accountIndex,
      balance: +accInfo.balance,
      label: `Account #${accInfo.accountIndex + 1}`,
    }
  }

  setCurrentAccount(accountIndex: number): void {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    this._currentAccountIndex = accountIndex;
  }

  getAccountInfo(accountIndex: number): AccountInfo {
    const accInfo = this.getWebcoreAccountInfo(accountIndex);
    return this._ToAccountInfo(accInfo);
  }

  getAllAccountsInfo(): AccountInfo[] {
    if (this._fullDiscoveredWallet?.accounts?.length > 0) {
      return this._fullDiscoveredWallet.accounts.map((account: EthereumAccountInfo) => {
        this._ensureAccountDiscoveryIsDone(account.accountIndex);
        return this._ToAccountInfo(account);
      });
    } else {
      return [];
    }
  }

  /**
   * This function will return the first path of the first account
   * @returns The path of Account 0 Path 0
   */
  getPathOfFirstAddress(account: number): AddressModel {
    return PathUtil.GetBipPath(
      CoinBaseType.EthereumBase,
      account,
      this._coinModel.coinInfo,
    )
  }

  async getPublicKey(account: number, showOnProkey: boolean): Promise<any> {
    //? Does'nt ERC20 have public key
    if (this._coinModel.type == CoinBaseType.ERC20) {
      return null;
    }
    const coinInfo = this._coinModel.coinInfo;

    //! For getting public key, the account should not be passed
    //! to getting ethereum path account have to be 0' in GetBipPath(..), so account is passed as undefinded
    const path = PathUtil.GetBipPath(
      CoinBaseType.EthereumBase,
      undefined,
      coinInfo
    );

    return await this._webcoreBaseWallet .GetPublicKey<PublicKey>(path.path, showOnProkey);
  }

  async getAddress(path: number[], showOnProkey: boolean): Promise<any> {
    return await this._webcoreBaseWallet.GetAddress<EthereumAddress>(path, showOnProkey);
  }

  getAddresses(accountInfo: AccountInfo): Promise<any> {
    return this._webcoreBaseWallet.GetAddresses<EthereumAddress>([accountInfo.lastUnusedAddress.path]);
  }

  async getAccountInfoByParams(accountBrief: AccountBrief): Promise<any> {
    const ethAccount = await (this._webcoreBaseWallet  as EthereumWallet).AccountDiscovery(
      accountBrief.id
    );

    return this._ToAccountInfo(ethAccount);
  }

  getDefaultAccount(): AccountInfo {
    return this._ToAccountInfo(this._defaultAccount);
  }

  signMessage(path: number[], message: string): Promise<any> {
    return this._webcoreBaseWallet.SignMessage(path, message);
  }

  async verifyMessage(
    address: string,
    message: string,
    signature: string
  ): Promise<any> {
    return await this._webcoreBaseWallet .VerifyMessage(address, message, signature);
  }

  async prepareSendModel(output: BaseOutput, ...params: any[]) {
    let amount: BigNumber = new BigNumber(+output.amount);
    //! Amount to wei
    amount = amount.multipliedBy(
      Math.pow(10, this._coinModel.coinInfo.decimals)
    );

    return await (this._webcoreBaseWallet  as EthereumWallet).GenerateTransaction(
      output.address,
      amount,
      params[0].id
    );
  }

  async sendTransaction(tx: any): Promise<any> {
    let signedTx = (await this._webcoreBaseWallet .SignTransaction(tx)) as EthereumSignedTx;
    return await (this._webcoreBaseWallet  as EthereumWallet).PrepareAndSendTransaction(
      tx,
      signedTx
    );
  }

  async calculateTransactionFee(outputValue: BaseOutput, fromAccount: number) {
    if (this._coinModel.type == CoinBaseType.ERC20) {
      return null;
    } else {
      return await (this._webcoreBaseWallet  as EthereumWallet).CalculateTransactionFee();
    }
  }

  async getAccountTransactionsBy(
    accountNumber: number,
    startIndex: number,
    numberOfTransactions: number
  ): Promise<Transaction[]> {
    let transactions: Transaction[] = [];
    let ethTransactions: EthereumTransactionView[] = [];
    try {
      ethTransactions = await (
        this._webcoreBaseWallet  as EthereumWallet
      ).GetTransactionViewList(accountNumber, startIndex, numberOfTransactions);
      MyConsole.Info("Loaded transaction:", ethTransactions);

      if (ethTransactions) {
        if (ethTransactions.length > 0) {
          for (let ethTran of ethTransactions) {
            let transaction = <Transaction>{};
            transaction.targets = [];
            transaction.hash = ethTran.hash;
            transaction.date = ethTran.date;
            transaction.blockNumber = ethTran.blockNumber;
            transaction.fee = ethTran.fee;
            transaction.isValid = true;

            transaction.value = ethTran.amount;

            if (ethTran.status == "RECEIVED") {
              transaction.type = TransactionType.Received;
              transaction.targets.push(<TransactionTarget>{
                address: ethTran.received,
                value: ethTran.amount,
                status: ethTran.status,
              });
            } else if (ethTran.status == "SENT") {
              transaction.type = TransactionType.Sent;
              transaction.targets.push(<TransactionTarget>{
                address: ethTran.sent,
                value: ethTran.amount,
                status: ethTran.status,
              });
            }

            transactions.push(transaction);
          }
        }
      }
      MyConsole.Info("TRANSACTIONS : ", transactions);
      return transactions;
    } catch (e) {
      MyConsole.Exception("Ethereum::getAccountTransactionsBy", e);
    }
  }
  //#endregion

  //#region Private Mehtods
  private _ToAccountInfo(ethAccount: EthereumAccountInfo): AccountInfo {
    const defaultAccount: AccountInfo = <AccountInfo>{};

    if (ethAccount == null) {
      return defaultAccount;
    }

    defaultAccount.id = ethAccount.accountIndex;
    defaultAccount.balance = +ethAccount.balance;
    defaultAccount.lastUnusedAddress = ethAccount.addressModel;

    defaultAccount.hasAccountPerEachAddress = true;
    defaultAccount.isDirectQueryFromGeth = ethAccount.isDirectQueryFromGeth;
    defaultAccount.allAddresses = [ethAccount.addressModel];
    defaultAccount.transactions = ethAccount.txs

    return defaultAccount;
  }

  private _ensureAccountDiscoveryIsDone(accountIndex?: number) {
    if(this._fullDiscoveredWallet == null) {
      throw new Error("No wallet info, Call AccountDiscovery first");
    }

    if(accountIndex != null && (accountIndex < 0 || accountIndex >= this._fullDiscoveredWallet.accounts.length)) {
      throw new Error("Account index is out of range");
    }
  }
  //#endregion
}
