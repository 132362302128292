import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CoinModel} from '@core/models/connect/coin-model';
import {WalletService} from '@core/services/wallet.service';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-receive-coin',
  templateUrl: './receive-coin.component.html',
  styleUrls: ['./receive-coin.component.css']
})
export class ReceiveCoinComponent implements OnInit {
  @Output() selectCoinEvent = new EventEmitter<CoinModel>();
  @Input() selectedSendCoin: CoinModel;
  selectedReceiveCoin: CoinModel;
  famousCoins: CoinModel[];
  allCoins: CoinModel[];
  searchText: string;
  searchSubject: Subject<string> = new Subject<string>();
  searchCoins: Array<CoinModel>;

  @ViewChild('receiveExchangeForm') public receiveExchangeForm: NgForm;

  constructor(private _walletService: WalletService) {
  }

  ngOnInit() {
    this.setCoins();
    this.searchSubject.pipe(debounceTime(500)).subscribe(searchValue => {
      if (searchValue) {
        this.searchCoins = this.allCoins.filter(
          coin => coin.coinInfo.shortcut.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            coin.coinInfo.name.toLowerCase().startsWith(searchValue.toLowerCase())
        ).slice(0, 5);
      } else {
        this.searchCoins = undefined;
      }
    });
  }

  onSearchKeyUp() {
    this.searchSubject.next(this.searchText);
  }

  async setCoins() {
    this.allCoins = this._walletService.WalletCoins.getCoins();
    this.famousCoins = this._walletService.WalletCoins.getFamousCoins();
    this.setReceiveCoinOnInit();
  }

  private setReceiveCoinOnInit() {
    for (const famousCoin of this.famousCoins) {
      if (famousCoin.coinInfo.shortcut !== this.selectedSendCoin.coinInfo.shortcut) {
        this.setReceiveCoin(famousCoin);
        break;
      }
    }
  }

  setReceiveCoin(coin: CoinModel) {
    this.selectedReceiveCoin = coin;
    this.selectCoinEvent.emit(coin);
  }
}

