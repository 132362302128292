import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {WalletService} from '@core/services/wallet.service';
import {AccountInfo} from '@core/models/connect/account-info';
import {AlertModel} from '@shared/models/alert-model';
import {AlertType} from '@shared/models/alert-type.enum';
import {FailureType} from '@core/models/connect/failure-type.enum';
import {WalletDeviceService} from "@core/services/wallet-device.service";
import {DialogService} from "@shared/dialog/_services/dialog.service";

enum LabelMode {
  View = 'View',
  Change = 'Change',
}

@Component({
  selector: 'app-basic-settings',
  templateUrl: './basic-settings.component.html',
  styleUrls: ['./basic-settings.component.css']
})
export class BasicSettingsComponent implements OnInit, OnDestroy {

  deviceSubscription: Subscription;
  accountSubscription: Subscription;
  failureSubscription: Subscription;

  labelMode: LabelMode = LabelMode.View;
  model: any = { label: '' };
  deviceLabel: string = '';
  isPinProtection: boolean = false;
  xpub: string = 'xpubs';
  xpubLabel: string = 'Show XPUBS';
  account: AccountInfo = <AccountInfo>{};
  alertModel: AlertModel = <AlertModel>{ show: false };
  actionInForm: boolean = false;
  isShowPublicKey: boolean = true;


  @Input('active') activeTab: string;

  constructor(private _walletService: WalletService,
              private _dialogService: DialogService,
              private _deviceService: WalletDeviceService) {
  }

  ngOnInit() {
    this.deviceSubscribe();
    this.accountSubscribe();
    this.failureSubscribe();
  }

  deviceSubscribe() {
    this.deviceSubscription = this._deviceService.device$.subscribe(device => {
      this.deviceLabel = device.label;
      this.model.label = this.deviceLabel;
      if (device.features) {
          this.isPinProtection = device.features.pin_protection;
      }
    });
  }

  accountSubscribe() {
    this.accountSubscription = this._walletService.onCurrentAccountChange.subscribe(accountInfo => {
      this.account = accountInfo;

      this.isShowPublicKey = true;

      //! The account may have public key itself
      if(accountInfo.xpub != null && accountInfo.xpub != ""){
        this.xpub = accountInfo.xpub;
        return;
      }

      // Bitcoin, Ethereum and Cardano support PublicKey
      // CoinFactory should return null if publicKey is not supported
      this._walletService.CurrentWallet.getPublicKey(this.account.id, false).then(publicKey => {
        if(publicKey == null) {
          this.isShowPublicKey = false;
          return;
        }
        this.xpub = publicKey.xpub;
      }).catch(e => {
        console.log(e);
      });

    });
  }

  failureSubscribe() {
    this.failureSubscription = this._deviceService.failureCallBack$.subscribe(failureType => {
      if (failureType && this.actionInForm) {
        this._deviceService.initializeDevice();
        if (failureType.code === FailureType.Failure_PinMismatch) {
          // TODO this kind of alerts can be changing to angular material snackbars https://material.angular.io/components/snack-bar/overview
          this.setAlert(AlertType.Warning, 'PIN is mismatch.');
        }
      }
    });
  }

  setLabelMode(newMode: string) {
    this.labelMode = newMode as LabelMode;
  }

  changeLabel() {
    this.actionInForm = true;
    this._deviceService.changeLabel(this.model.label).then(() => {
      this._dialogService.closeActiveModal();
      //this._deviceService.refresh();
      this.setAlert(AlertType.Success, 'Change device label successful');
      this.setLabelMode(LabelMode.View.toString());
    }).catch(() => {
      this._dialogService.closeActiveModal();
      this.setAlert(AlertType.Warning, 'Try again to change label.');
      this.setLabelMode(LabelMode.View.toString());
    });
  }

  changePin(remove: boolean) {
    this.actionInForm = true;
    this._deviceService.changePin(!remove).then(res => {
      this._deviceService.initializeDevice();

      this.setAlert(AlertType.Success, res.message);
    }).catch(() => {
      this.setAlert(AlertType.Warning, 'Try again later');
    });
  }

  setAlert(type: AlertType, content: string) {
    this.alertModel.type = type;
    this.alertModel.content = content;
    this.alertModel.show = true;
    this.alertModel.dismissing = true;
  }

  showXpub() {
    if (this.xpubLabel === 'Show XPUBS') {
      this.xpubLabel = 'Hide XPUBS';
    } else {
      this.xpubLabel = 'Show XPUBS';
    }
  }

  isCurrentTab() {
    return this.activeTab === 'basic';
  }

  ngOnDestroy() {
    this.deviceSubscription.unsubscribe();
    this.accountSubscription.unsubscribe();
    this.failureSubscription.unsubscribe();
  }

}
