import { Component, OnDestroy, OnInit } from '@angular/core';
import { WalletService } from '@core/services/wallet.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wallet-sidemenu',
  templateUrl: './wallet-sidemenu-layout.component.html',
  styleUrls: ['./wallet-sidemenu-layout.component.css']
})
export class WalletSidemenuLayoutComponent implements OnInit, OnDestroy {

  disableSideMenu: boolean = true;
  discoveryFinishedSubscription: Subscription;

  constructor(private _walletService: WalletService) { }

  ngOnInit() {
    this.discoveryFinishedSubscribe();
  }

  private discoveryFinishedSubscribe() {
    this.discoveryFinishedSubscription = this._walletService.onCurrentWalletDiscoveryFinished.subscribe(isFinished => {
      if (isFinished) {
        this.disableSideMenu = false;
      }
    });
  }

  ngOnDestroy() {
    this.discoveryFinishedSubscription.unsubscribe();
  }

}
