import {Injectable} from '@angular/core';
import {CustomToken} from '@core/models/custom-token';
import {NetworkModel} from '@core/models/network-model';
import {Erc20BaseCoinInfoModel} from '@webcore/models/CoinInfoModel';
import {CoinBaseType, CoinInfo} from '@webcore/coins/CoinInfo';
import * as EthereumNetworks from '@webcore/utils/ethereum-networks';
import {BaseRepository} from './storage/repository/base-repository';
import {WalletDeviceService} from "./wallet-device.service";
import {ProkeySettingsService} from "@core/services/prokey-settings.service";
import {ProkeySettingsKey} from "@core/models/prokey-settings-key.enum";
import {ProkeySettings} from "@core/models/prokey-settings";

@Injectable({
  providedIn: 'root'
})
export class CustomTokenService {
  private customTokenKey = 'custom-tokens';
  networks: Array<NetworkModel> = [
    {
      name: 'Ethereum',
      chainId: 1,
      supportVersion: '1.8.1',
      type: CoinBaseType.ERC20,
      isTest: false
    },
    {
      name: 'Binance Smart Chain',
      chainId: 56,
      supportVersion: '1.8.1',
      type: CoinBaseType.ERC20,
      isTest: false
    },
    {
      name: 'Rinkeby',
      chainId: 4,
      supportVersion: '1.8.1',
      type: CoinBaseType.ERC20,
      isTest: true
    }
  ];
  private repository: BaseRepository;

  constructor(private _deviceService: WalletDeviceService, private _prokeySettingsService: ProkeySettingsService) {
  }

  getCustomTokens(): Array<Erc20BaseCoinInfoModel> {
    return  this._prokeySettingsService.get(ProkeySettingsKey.CUSTOM_TOKENS);
  }

  async addCustomToken(coinModel: CustomToken): Promise<Erc20BaseCoinInfoModel> {
    const coin: Erc20BaseCoinInfoModel = {
      name: coinModel.tokenName,
      shortcut: coinModel.symbol,
      support: {
        optimum: coinModel.network.supportVersion
      },
      test: coinModel.network.isTest,
      decimals: coinModel.decimal,
      slip44: this.getSlip44(coinModel),
      coinBaseType: CoinBaseType.ERC20,
      id: this.getId(coinModel),
      chain_id: coinModel.network.chainId,
      type: 'erc20',
      address: coinModel.contractAddress,
      tx_url: this.GetTransactionUrl(coinModel),
      priority: 11,
    };
    let setting: ProkeySettings = await this._prokeySettingsService.getAll();
    let customTokenArray = setting.customTokens;
    if (!customTokenArray) {
      customTokenArray = new Array<Erc20BaseCoinInfoModel>();
    }
    customTokenArray.push(coin);
    setting.customTokens = customTokenArray;
    await this._prokeySettingsService.patch(setting);
    return coin;
  }

  async deleteCustomToken(coin: Erc20BaseCoinInfoModel) {
    let customTokenArray: Array<Erc20BaseCoinInfoModel> = await this.repository.get<Array<Erc20BaseCoinInfoModel>>(this.customTokenKey);
    if (customTokenArray) {
      customTokenArray = customTokenArray.filter(token => token.id !== coin.id);
    }
    await this.repository.save(this.customTokenKey, customTokenArray);
  }

  getSlip44(coinModel: CustomToken) {
    if (coinModel.network.type === CoinBaseType.ERC20) {
      return EthereumNetworks.GetSlip44ByChainId(coinModel.network.chainId);
    }
    throw new Error('unsupported coin type');
  }

  getId(coinModel: CustomToken) {
    if (coinModel.network.type === CoinBaseType.ERC20) {
      return `erc20_${EthereumNetworks.GetNetworkByChainId(coinModel.network.chainId)}_${coinModel.symbol}`;
    }
    throw new Error('unsupported coin type');
  }

  GetTransactionUrl(coinModel: CustomToken) {
    if (coinModel.network.type === CoinBaseType.ERC20) {
      return this.GetTransactionUrlBaseOnChainId(coinModel.network.chainId);
    }
    throw new Error('unsupported coin type');
  }

  async getAvailableNetworks(): Promise<Array<NetworkModel>> {
    const deviceCurrentVersion = await this._deviceService.getVersion();
    console.log(deviceCurrentVersion);
    return this.networks.filter(network => CoinInfo.compareVersions(deviceCurrentVersion, network.supportVersion) >= 0);
  }

  GetTransactionUrlBaseOnChainId(chainId: number): string {
    switch (chainId) {
      // ETH tokens
      case 1:
        return `https://etherscan.io/tx/{hash}`;
      // trin tokens
      case 4:
        return `https://rinkeby.etherscan.io/tx/{hash}`;
      // RSK tokens
      case 30:
        return `https://explorer.rsk.co/tx/{hash}`;
      // tRSK tokens
      case 31:
        return `https://explorer.testnet.rsk.co/tx/{hash}`;
      // BSC tokens
      case 56:
        return `https://bscscan.com/tx/{hash}`;
    }
  }
}
