<div class="modal-dialog">
  <div class="modal-content">
    <div class="pt-2 pr-2">
    </div>
    <div class="modal-body">
      <h5 class="body-title text-center">
        Your Prokey is not backed up!
      </h5>
      <div class="container" style="width:350px">
        <div class="alert alert-info">
          <span style="font-size:13px">If you device is ever lost or damaged, your funds will be lost. Backup your device first, to protect your coins against such events.</span>
        </div>
        <button type="button" class="btn btn-gradinet-blue btn-block pnup waves-effect waves-dark d-block mt-3" (click)="doBackup()">
          Backup my Prokey
        </button>
        <button type="button" class="btn btn-outline-info btn-block pnup waves-effect waves-dark d-block mt-2" (click)="accept()">
          I will take the risk
        </button>
      </div>
    </div>
  </div>
</div>
