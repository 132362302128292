import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

import {UiEvent} from '@core/models/connect/ui-event.enum';
import {WalletService} from '../../services/wallet.service';
import {WalletDeviceService} from "../../services/wallet-device.service";


@Component({
  selector: 'app-wallet-notify-layout',
  templateUrl: './wallet-notify-layout.component.html',
  styleUrls: ['./wallet-notify-layout.component.css']
})
export class WalletNotifyLayoutComponent implements OnInit, OnDestroy {

  deviceSubscription: Subscription;
  uiAlertSubscription: Subscription;
  changeWalletSubscription: Subscription;

  showFirwareOutdated: boolean = false;
  showNeedsBackup: boolean = false;
  showIsTestCoin: boolean = false;

  constructor(private _walletService: WalletService, private _deviceService: WalletDeviceService, private router: Router) {
  }

  ngOnInit() {
    this.deviceSubscribe();
    this.uiAlertSubscribe();
    this.changeWalletSubscribe();
  }

  deviceSubscribe() {
    this.deviceSubscription = this._deviceService.device$.subscribe(device => {
      if (device.features) {
        this.showNeedsBackup = device.features.needs_backup === true;
      }
    });
  }

  uiAlertSubscribe() {
    this.uiAlertSubscription = this._deviceService.uiAlert$.subscribe(uiEvent => {
      switch (uiEvent) {
        case UiEvent.Firmware_Outdated:
          this.showFirwareOutdated = true;
          break;
        default:
          break;
      }
    });
  }

  changeWalletSubscribe() {
    this.changeWalletSubscription = this._walletService.onCurrentWalletChange.subscribe(() => {
      this.showIsTestCoin = this._walletService.CurrentWalletCoin.coinInfo.test;
    });
  }

  doBackup() {
    if (this._walletService.CurrentWallet == null) {
      let defaultCoin = this._walletService.WalletCoins.defaultCoin;
      this._walletService.changeCurrentWallet(defaultCoin);
    }

    this.router.navigate(['/wallet/backup']);
  }

  doUpdate() {
    this.router.navigate(['/firmware/update']);
  }

  ngOnDestroy() {
    this.deviceSubscription.unsubscribe();
    this.uiAlertSubscription.unsubscribe();
    this.changeWalletSubscription.unsubscribe();
  }

}
