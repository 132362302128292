export enum UiEvent {
  None = 'none',
  Device_Initilaized = 'device_initialized',
  Device_NotInitilaized = 'device_not_initialized',
  Blockchain_Disconnected = 'blockchain_disconnected',
  Blockchain_Synchronizing = 'blockchain_Synchronizing',
  Blockchain_Synchronized = 'blockchain_Synchronized',
  Close_Window = 'ui-close_window',
  Browser_Not_Supported = 'ui-browser_not_supported',
  Browser_Outdated = 'ui-browser_outdated',
  Device_Firmware_Outdated = 'ui-device_firmware_outdated',
  Firmware_Outdated = 'ui-device_firmware_outdated',
  Change_Settings = 'ui-change_settings',
  Request_Confirmation = 'ui-request_confirmation',
  Request_Button = 'ui-button',
  Request_Pin = 'ui-request_pin',
  Invalid_Pin = 'ui-invalid_pin',
  Request_Passphrase = 'ui-request_passphrase',
  Request_Passphrase_On_Device = 'ui-request_passphrase_on_device',
  Invalid_Passphrase = 'ui-invalid_passphrase',
  Invalid_Passphrase_Action = 'ui-invalid_passphrase_action',
}
