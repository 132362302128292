import { Injectable } from '@angular/core';
import {LocalStorageService} from './repository/local-storage-service';
import {BaseRepository} from './repository/base-repository';
import {GoogleStorageRepositoryService} from "@core/services/storage/repository/google-drive/google-storage-repository.service";
import {Router} from "@angular/router";
import {DialogService} from "@shared/dialog/_services/dialog.service";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly _storageField = 'storage-field';
  private _storages = new Array<BaseRepository>();
  private _repositories = new Map<string, BaseRepository>();

  constructor(private _localStorageRepository: LocalStorageService,
              private _googleDriveStorageRepository: GoogleStorageRepositoryService,
              private _route: Router,
              private _dialogService: DialogService) {
    this._repositories.set(_localStorageRepository.getRepositoryRepresentName(), _localStorageRepository);
    this._repositories.set(_googleDriveStorageRepository.getRepositoryRepresentName(), _googleDriveStorageRepository);

    this._repositories.forEach(((repo, key) => this._storages.push(repo)))
  }

  changeStorage(deviceId: string, storage: string) {
    this.getCurrentStorage(deviceId).terminate();
    this._repositories.get(storage).initial();
    this._localStorageRepository.save(this._storageField + '-' + deviceId, {currentStorage: storage});
    this._route.navigate(['/']);
  }

  getCurrentStorage(deviceId: string): BaseRepository {
    let currentStorageId: string;
    try {
      const {currentStorage: currentStorageId} = this._localStorageRepository.normalGet(this._storageField + '-' + deviceId);
      if (currentStorageId) {
        let baseRepository = this._repositories.get(currentStorageId);
        if (baseRepository) {
          return baseRepository;
        }
      }
    } catch (e) {
      return this._localStorageRepository;
    }
    return this._localStorageRepository;
  }

  reconnect(deviceId: string) {
    this.getCurrentStorage(deviceId).initial();
  }

  changeToLocalStorage(deviceId: string) {
    this.changeStorage(deviceId, this._localStorageRepository.getRepositoryRepresentName());
    this._dialogService.closeAll();
  }

  getAllStoragesDetails(): Array<BaseRepository> {
    return this._storages;
  }
}
