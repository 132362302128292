<div class="processing-dialog">
    <ng-container>
        <h5 class="body-title text-left">Insufficient Permission on Google Drive</h5>
        <p class="pt-1 pb-4">
            Please allow Prokey to see, create and delete wallet configuration on Google drive.
        </p>
        <button type="button" class="btn btn-gradinet-green waves-effect waves-dark mr-2" style="top:-5px;" (click)="reconnect()">Reconnect again</button>
        <button type="button" class="btn btn-gradinet-green waves-effect waves-dark mr-2" style="top:-5px;" (click)="useLocalStorage()">Continue With Local Storage</button>
    </ng-container>
</div>
