import { Pipe, PipeTransform, Type } from '@angular/core';
import { CoinBaseType, CoinInfo } from '@webcore/coins/CoinInfo';
import {
  BitcoinBaseCoinInfoModel,
  EthereumBaseCoinInfoModel,
  Erc20BaseCoinInfoModel,
  MiscCoinInfoModel,
  OmniCoinInfoModel,
  RippleCoinInfoModel, NemCoinInfoModel
} from '@webcore/models/CoinInfoModel';

@Pipe({
    name: 'tokenTypeToName'
})
export class CoinTokenTypeToNamePipe implements PipeTransform {
    transform(coinInfo: BitcoinBaseCoinInfoModel | EthereumBaseCoinInfoModel | Erc20BaseCoinInfoModel | OmniCoinInfoModel | RippleCoinInfoModel | MiscCoinInfoModel | NemCoinInfoModel, isShowChain = false): string {
        if (coinInfo.coinBaseType == undefined)
            return "";

        switch (coinInfo.coinBaseType) {
            case CoinBaseType.ERC20:
                let chainid = (coinInfo as Erc20BaseCoinInfoModel).chain_id;
                switch(chainid){
                    case 1:
                        if(isShowChain)
                            return "ERC20 - Ethereum";
                        else
                            return "ERC20";
                    case 3:
                    case 4:
                    case 5:
                    case 42:
                        return "ERC20 - Testnet";
                    case 56:
                        if(isShowChain)
                            return "BEP20 - Binance"
                        else
                            return "BEP20";
                    default:
                        if(isShowChain)
                            return "ERC20 - Unknown Chain Id";
                        else
                            return "ERC20";
                }
            case CoinBaseType.OMNI:
                return "OMNI";
            default:
                return "";
        }
    }
}
