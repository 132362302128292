import {Component, Inject, OnInit, Optional} from '@angular/core';
import {timer} from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import {WalletDeviceService} from "../../services/wallet-device.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {LocalStorageService} from "@core/services/storage/repository/local-storage-service";

/* this component is responsible for displaying wallet-forget-device dialog. */
@Component({
  selector: 'app-wallet-forget-device',
  templateUrl: './wallet-forget-device.component.html',
  styleUrls: ['./wallet-forget-device.component.css']
})
export class WalletForgetDeviceComponent implements OnInit {

  constructor(private _deviceService: WalletDeviceService,
              private _localStorageService: LocalStorageService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  /* timer countdown value. */
  timerValue: number = 10;

  /* on component initialize. */
  ngOnInit() {
    this._startTimer();
  }

  /* on forget device handle. */
  forget() {
    this._localStorageService.deleteAll();
    this._deviceService.handleDisconnectedDevice(true);
  }

  /* on remember device handle. */
  remember() {
    this._deviceService.handleDisconnectedDevice(true);
  }

  /* start timer */
  private _startTimer(): void {
    let logoutTimer = timer(1000, 1000).pipe(take(10), finalize(() => {
      this.remember();
    }));
    logoutTimer.subscribe(() => {
      this.timerValue--;
    });
  }
}
