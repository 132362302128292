import {AccountInfo} from "@core/models/connect/account-info";
import {BaseOutput} from "@core/models/connect/base-output";
import {CoinModel} from "@core/models/connect/coin-model";
import {AccountBrief} from "@core/models/connect/account-brief";
import {Transaction} from "@core/models/connect/transaction";
import {CoinBaseType} from "@webcore/coins/CoinInfo";
import {Device} from "@webcore/device/Device";
import {AddressModel, PublicKey} from "@webcore/models/Prokey";
import {BitcoinWallet} from "@webcore/wallet/BitcoinWallet";
import {Wallet} from "./wallet";
import * as PathUtil from "@webcore/utils/pathUtils";
import {BitcoinBaseCoinInfoModel} from "@webcore/models/CoinInfoModel";
import {BitcoinAccountInfo, BitcoinTransactionView, BitcoinWalletModel} from "@webcore/models/BitcoinWalletModel";
import {TransactionType} from "@core/models/connect/transaction-type.enum";
import {TransactionTarget} from "@core/models/connect/transaction-target";
import * as GenericWalletModel from '@webcore/models/GenericWalletModel';

export class Bitcoin extends Wallet {

  private _fullDiscoveredWallet: BitcoinWalletModel = null;

  /**
   * Bitcoin Wallet.
   */
  constructor(device: Device, coinModel: CoinModel) {
    super(device, coinModel);
    this._webcoreBaseWallet  = new BitcoinWallet(this._device, coinModel.coinInfo.name);
  }

  /**
   * Get count of accounts, -1 means no account at all
   * @returns
   */
  get AccountsCount(): number {
    if(this._fullDiscoveredWallet == null) {
      return -1;
    }
    return this._fullDiscoveredWallet.accounts.length;
  }

  /**
   * Delete previous account data and ...
   * This function should be called when user wants to refresh the wallet
   */
  ClearCache(): void {
    this._currentAccountIndex = 0;
    this._isAccountDiscoveryFinished = false;
    this._fullDiscoveredWallet = null;
    this._isNewAccountAlreadyAdded = false;
    this.NeedToReSync = false;
  }

  /**
   * Start discovering wallet accounts
   * To get account and wallet info, you need to call getAccountInfo
   * If all accounts have been already discovered, this function will resolve using previous data immadiatly
   * To have a refresh account discovery, Call ClearCache first
   * @param updateCallBack Inform upper later that an account has discovered.
   * @returns
   */
  async StartAccountDiscovery(onNewAccountCallBack: (discoveredAccountNumber: number) => void): Promise<void> {
    return new Promise<void>((resolve, reject)=>{

      //! Return previous discovered data if any
      if(this._fullDiscoveredWallet) {
        if(onNewAccountCallBack) {
          this._fullDiscoveredWallet.accounts.forEach(acc => {
            onNewAccountCallBack(acc.accountIndex);
          });
        }

        this._isAccountDiscoveryFinished = true;
        return resolve();
      } else {
        this._fullDiscoveredWallet = <BitcoinWalletModel>
          {
            totalBalance: 0,
            accounts: [],
          };
      }

      //! Start discovery
      try {
        (this._webcoreBaseWallet as BitcoinWallet).StartDiscovery((accInfo) => {

          //! Add discovered account
          this._fullDiscoveredWallet.accounts.push(accInfo);

          if (onNewAccountCallBack) {
            onNewAccountCallBack(accInfo.accountIndex);
          }
        }).then((bitcoinWalletModel: BitcoinWalletModel) => {
          // Account discovery is done
          this._fullDiscoveredWallet.totalBalance = bitcoinWalletModel.totalBalance;
          this._totalBalance = this._fullDiscoveredWallet.totalBalance;
          this._isAccountDiscoveryFinished = true;

          return resolve();
        }).catch(reason => {
          this._isAccountDiscoveryFinished = false;
          return reject(reason);
        });
      } catch(e) {
        this._isAccountDiscoveryFinished = false;
        return reject(e);
      }
    })
  }


  /**
   * To ask if a new account can be added to list of current accounts
   * IMPORTANT: To get the currect response, Call this function after account discovery finished
   * @returns True means the "addNewAccount" func can be called in order to add a new (virtual) account
   */
  canAddNewAccount(): boolean {
    if(this._isAccountDiscoveryFinished == false || this._isNewAccountAlreadyAdded == true) {
      return false;
    }

    if(this._fullDiscoveredWallet == null || this._fullDiscoveredWallet.accounts == null) {
      return false;
    }

    if(this._fullDiscoveredWallet.accounts.length == 0) {
      return false;
    }

    const lastAccount = this._fullDiscoveredWallet.accounts[this._fullDiscoveredWallet.accounts.length - 1];

    return lastAccount.txs > 0;
  }

  /**
   * Add new account, Call "canAddNewAccount" first
   * DO NOT CALL THIS FUNCTION DIRECTLY IF YOU WANT EVENTS RISE AFTER ADDING NEW ACCOUNT, So call it through wallet service
   * @returns New account index. -1 means fail
   */
  async _addNewAccount(): Promise<number> {
    if(this._fullDiscoveredWallet == null || this._fullDiscoveredWallet.accounts == null) {
      return -1;
    }

    let newAcc = <BitcoinAccountInfo>{
      accountIndex: this._fullDiscoveredWallet.accounts.length,
      totalReceived: '0',
      totalSent:'0',
      balance: '0',
      txs: 0,
      transactions: [],
      itemsOnPage: 0,
      totalPages: 0,
    };

    const pathToFirstAddressOfThisNewAccount = PathUtil.GetBipPath(
      CoinBaseType.BitcoinBase,
      newAcc.accountIndex,
      this.CoinInfo,
      false,
      0
    )

    // get the first address from device
    pathToFirstAddressOfThisNewAccount.address = await this.getAddress(pathToFirstAddressOfThisNewAccount.path, false);

    newAcc.lastUnusedAddress = pathToFirstAddressOfThisNewAccount;

    this._fullDiscoveredWallet.accounts.push(newAcc);
    this._isNewAccountAlreadyAdded = true;

    return newAcc.accountIndex;
  }

  getWebcoreAccountInfo(accountIndex?: number): BitcoinAccountInfo {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;
    return this._fullDiscoveredWallet.accounts[ai];
  }

  getAccountBrief(accountIndex?: number): AccountBrief {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;
    const accInfo = this._fullDiscoveredWallet.accounts[ai];

    return <AccountBrief>{
      id: accInfo.accountIndex,
      balance: +accInfo.balance,
      label: `Account #${accInfo.accountIndex + 1}`,
    }
  }

  getAccountInfo(accountIndex?: number): AccountInfo {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    const ai = accountIndex ?? this._currentAccountIndex;

    return this._ToAccountInfo(this._fullDiscoveredWallet.accounts[ai]);
  }

  getAllAccountsInfo(): AccountInfo[] {
    if (this._fullDiscoveredWallet?.accounts?.length > 0) {
      return this._fullDiscoveredWallet.accounts.map((account: BitcoinAccountInfo) => {
        this._ensureAccountDiscoveryIsDone(account.accountIndex);
        return this._ToAccountInfo(account);
      });
    } else {
      return [];
    }
  }

  setCurrentAccount(accountIndex: number): void {
    this._ensureAccountDiscoveryIsDone(accountIndex);

    this._currentAccountIndex = accountIndex;
  }

  /**
   * This function will return the first path of the first account
   * @returns The path of Account 0 Path 0
   */
  getPathOfFirstAddress(account: number): AddressModel {
    return PathUtil.GetBipPath(
      CoinBaseType.BitcoinBase,
      account,
      this._coinModel.coinInfo,
      false,
      0
    )
  }

  async getPublicKey(account: number, showOnProkey: boolean): Promise<any> {
    const coinInfo = this._coinModel.coinInfo;

    //! For getting public key, both isChange & addressIndex should not be passed
    const path = PathUtil.GetBipPath(CoinBaseType.BitcoinBase, account, coinInfo);

    return await this._webcoreBaseWallet .GetPublicKey<PublicKey>(path.path, showOnProkey);
  }

  async getAddress(path: number[], showOnProkey: boolean): Promise<any> {
    return await this._webcoreBaseWallet .GetAddress<AddressModel>(path, showOnProkey);
  }

  async getAddresses(accountInfo: AccountInfo): Promise<any> {
    let startIndex = 0;
    let justPaths : Array<Array<number>> = [];

    // Makinging a list of paths
    for(let i=0; i<20; i++) {
        let path = PathUtil.GetBipPath(
            CoinBaseType.BitcoinBase,   // Coin Type
            accountInfo.id,              // Account Number
            this.CoinInfo,                   // CoinInfo
            false,                      // External chain address
            startIndex + i,             // address index
        );
        justPaths.push(path.path);
    }
    
    return this._webcoreBaseWallet.GetAddresses(justPaths);
  }

  async signMessage(path: number[], message: string): Promise<any> {
    const coinInfo = this._webcoreBaseWallet .GetCoinInfo() as BitcoinBaseCoinInfoModel;
    return await this._webcoreBaseWallet .SignMessage(path, message, coinInfo.on_device);
  }

  async verifyMessage(address: string, message: string, signature: string): Promise<any> {
    const coinInfo = this._webcoreBaseWallet .GetCoinInfo() as BitcoinBaseCoinInfoModel;
    return await this._webcoreBaseWallet .VerifyMessage(address, message, signature, coinInfo.on_device);
  }

  async prepareSendModel(output: BaseOutput, ...params: any[]) {
    return await (this._webcoreBaseWallet  as BitcoinWallet).GenerateTransaction([{ Address: output.address, value: +output.amount * 100000000 }], params[0].id, params[1]);
  }

  async sendTransaction(model: any): Promise<GenericWalletModel.GenericSentTransactionResult> {
    let signedTx: any = await this._webcoreBaseWallet.SignTransaction(model);
    return await (this._webcoreBaseWallet  as BitcoinWallet).SendTransaction(signedTx.serialized_tx);
  }

  async calculateTransactionFee(outputValue: BaseOutput, fromAccount: number) {
    let txValue = +outputValue.amount;
    if (outputValue.amount == undefined || txValue == 0) {
      txValue = 546; // Dust
    }

    return await (this._webcoreBaseWallet  as BitcoinWallet).CalculateTransactionFee([{ Address: outputValue.address, value: txValue }], fromAccount);
  }

  async getAccountTransactionsBy(accountNumber: number, startIndex: number, numberOfTransactions: number): Promise<Transaction[]> {
    const transactions: Transaction[] = [];

    let btcTransactions: BitcoinTransactionView[] = [];
    try {
      btcTransactions = await (this._webcoreBaseWallet  as BitcoinWallet).GetTransactionViewList(accountNumber, startIndex, numberOfTransactions);

      if(btcTransactions == null)
        return transactions;

      for (const btcTran of btcTransactions) {
        const transaction = <Transaction>{};
        transaction.targets = [];
        transaction.hash = btcTran.hash;
        transaction.date = btcTran.date;
        transaction.blockNumber = btcTran.blockNumber;
        transaction.fee = btcTran.fee;
        transaction.isOmni = btcTran.isOmni;
        transaction.isValid = true;
        if (btcTran.received && btcTran.received.length > 0) {
          transaction.type = TransactionType.Received;
          for (const rec of btcTran.received) {
            transaction.targets.push(<TransactionTarget>{ address: rec.address, value: rec.value, status: rec.status });
          }
        }

        if (btcTran.sent && btcTran.sent.length > 0) {
          transaction.type = TransactionType.Sent;
          for (const sent of btcTran.sent) {
            transaction.targets.push(<TransactionTarget>{ address: sent.address, value: sent.value, status: sent.status });
          }
        }

        transactions.push(transaction);
      }
      return transactions;
    } catch (e) {
      console.log(e);
    }
  }

  private _ToAccountInfo(bitcoinAccount: BitcoinAccountInfo): AccountInfo {
    const defaultAccount: AccountInfo = <AccountInfo>{};

    if (bitcoinAccount == null) {
      return defaultAccount;
    }

    defaultAccount.id = bitcoinAccount.accountIndex;
    defaultAccount.balance = +bitcoinAccount.balance;
    defaultAccount.lastUnusedAddress = bitcoinAccount.lastUnusedAddress;

    //! We need all addresses for Sign & Verify because we have to check if user is entering a valid wallet address
    defaultAccount.allAddresses = bitcoinAccount.addresses;

    defaultAccount.transactions = bitcoinAccount.txs;
    defaultAccount.xpub = bitcoinAccount.publicKey?.address ?? "";

    return defaultAccount;
  }

  private _ensureAccountDiscoveryIsDone(accountIndex?: number) {
    if(this._fullDiscoveredWallet == null) {
      throw new Error("No wallet info, Call AccountDiscovery first");
    }

    if(accountIndex != null && (accountIndex < 0 || accountIndex >= this._fullDiscoveredWallet.accounts.length)) {
      throw new Error("Account index is out of range");
    }
  }
}
