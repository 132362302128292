import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UserSettings} from '@core/models/user-settings';
import {CoinMarketService} from '@core/services/coin-market.service';
import {WindowService} from '@core/services/window.service';
import {Helpers} from '@core/services/helpers';
import {ProkeySettingsService} from "@core/services/prokey-settings.service";
import {ProkeySettingsKey} from "@core/models/prokey-settings-key.enum";


@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly defaultCurrency: string = "USD";

  model: UserSettings = <UserSettings>{};
  currencies: string[] = [this.defaultCurrency];

  constructor(private windowService: WindowService,
              private coinMarketService: CoinMarketService,
              private _settingsService: ProkeySettingsService) {
  }

  ngOnInit() {
    this.getCurrencies();
    const settings = this._settingsService.getAll();
    this.model = {
      displayCurrency: settings[ProkeySettingsKey.DISPLAY_CURRENCY],
      displayCoin: settings[ProkeySettingsKey.DISPLAY_COIN],
      bitcoreServers: settings[ProkeySettingsKey.BITCOIN_SERVERS],
      explorerUrl: settings[ProkeySettingsKey.EXPLORER_URL]
    }
  }

  ngAfterViewInit() {
    Helpers.setSettingFormClass();
  }

  getCurrencies() {
    this.coinMarketService.getCurrencies().subscribe(result => {
      if (result.length > 0) {
        let usdIndex = result.findIndex(x => x == this.defaultCurrency.toLowerCase());
        let currencyCollection: string[] = result.slice(usdIndex);
        this.currencies = currencyCollection.map((x) => { return x.toUpperCase() });
      }
    });
  }

  save() {
    if (!this.model.displayCurrency) {
      this.model.displayCurrency = this.defaultCurrency;
    }
    this._settingsService.patch(this.model);
    this.windowService.nativeWindow.location.href = '/wallet/device';
  }

  ngOnDestroy() {
    Helpers.setSettingFormClass();
  }
}
