import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wallet-settings',
  templateUrl: './wallet-settings.component.html',
  styleUrls: ['./wallet-settings.component.css']
})
export class WalletSettingsComponent implements OnInit, OnDestroy {

  activeTab: string = 'basic';

  constructor() { }

  ngOnInit() {
  }

  setTab(tabName: string) {
    this.activeTab = tabName;
  }

  ngOnDestroy() {
  }

}
