import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

/* This component is responsible for displaying reset passphrase dialog. */
@Component({
  selector: 'app-reset-passphrase-dialog',
  templateUrl: './reset-passphrase-dialog.component.html',
  styleUrls: ['./reset-passphrase-dialog.component.css']
})
export class ResetPassphraseDialogComponent {

  /* constructor */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

}
