import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddressPathIndexPipe } from './pipes/address-path-index.pipe';
import { AlertComponent } from './components/alert/alert.component';
import { SafePipe } from './pipes/safe.pipe';
import { AmountValidatorDirective } from './directives/amount-validator.directive';
import { AddressValidatorDirective } from './directives/address-validator.directive';
import { EqualValidatorDirective } from './directives/equal-validator.directive';
import { CoinUnitPipe } from './pipes/coin-unit.pipe';
import { CoinTokenTypeToNamePipe } from './pipes/coin-type-to-name.pipe';
import { NumToOrdinaryPipe } from './pipes/num-to-ordinary.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        // common and shared components/directives/pipes between more than one module and components will be listed here.
        AddressPathIndexPipe,
        AlertComponent,
        SafePipe,
        AmountValidatorDirective,
        AddressValidatorDirective,
        EqualValidatorDirective,
        CoinUnitPipe,
        CoinTokenTypeToNamePipe,
        NumToOrdinaryPipe,
        ConfirmationDialogComponent,
    ],
    exports: [
        // common and shared components/directives/pipes between more than one module and components will be listed here.
        CommonModule,
        AlertComponent,
        AddressPathIndexPipe,
        SafePipe,
        CoinUnitPipe,
        AmountValidatorDirective,
        AddressValidatorDirective,
        EqualValidatorDirective,
        CoinTokenTypeToNamePipe,
        NumToOrdinaryPipe,
    ]
    /* No providers here! Since they’ll be already provided in AppModule. */
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    // Forcing the whole app to use the returned providers from the AppModule only.
    return {
      ngModule: SharedModule,
      providers: [ /* All of your services here. It will hold the services needed by `itself`. */]
    };
  }
}
