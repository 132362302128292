<a routerLink="/wallet/user-settings" title="Prokey Wallet" class="sidebar-link waves-effect">
  <i class="fas fa-tv bg-gradinet-blue"></i>
  <i class="fas fa-caret-up"></i>
  <h4>{{label}}</h4><br>
  <ng-container *ngIf="isConnected then ConnectedTemplate else DisconnectedTemplate"></ng-container>
  <ng-template #ConnectedTemplate>
    <small>Connected Device</small>
  </ng-template>
  <ng-template #DisconnectedTemplate>
    <small>Disconnected Device</small>
  </ng-template>
</a>
