import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {CoinModel} from '@core/models/connect/coin-model';
import {AccountInfo} from '@core/models/connect/account-info';
import {Helpers} from './helpers';
import {CoinBaseType} from '@webcore/coins/CoinInfo'
import {Erc20BaseCoinInfoModel} from '@webcore/models/CoinInfoModel'
import {CustomTokenService} from "./custom-token.service";
import {RestUrls} from "../../_configs/rest-urls";
import {Wallet} from "./coins/wallet";
import {Bitcoin} from "./coins/bitcoin";
import {UiEvent} from '@core/models/connect/ui-event.enum';
import {Ethereum} from './coins/ethereum';
import {WalletDeviceService} from './wallet-device.service';
import { WalletCoins } from './coins/wallet-coins';
import {Ripple} from "@core/services/coins/ripple";
import { LayoutMode } from '@core/models/layout-mode';
import {Omni} from "@core/services/coins/omni";
import {ProkeySettingsService} from './prokey-settings.service'
import { ProkeySettingsKey } from '@core/models/prokey-settings-key.enum';
import { WalletDeviceEventsService } from './wallet-device-events.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private _http: HttpClient,
              private _deviceService: WalletDeviceService,
              private _customTokenService: CustomTokenService,
              private _settingsService: ProkeySettingsService,
              private _walletDeviceEventsService: WalletDeviceEventsService) {
    this._initialize();
  }

  //#region Private members

  private _currentAccountInfo: AccountInfo = <AccountInfo>{};
  private _currentWalletIndex: number = 0;
  private _currentWallet: Wallet;
  private _userWallets: Wallet[] = [];
  private _walletCoins: WalletCoins = null;

  private _myFamousCoinsSource = new BehaviorSubject<boolean>(false);
  private _currentAccountSource = new BehaviorSubject<AccountInfo>(this._currentAccountInfo);
  private _discoveryFinishedSource = new BehaviorSubject<boolean>(false);

  //#endregion

  //#region Public Events

  onCurrentWalletChange = new ReplaySubject(1);
  onReDiscovery = new ReplaySubject(1);
  onCurrentAccountChange = this._currentAccountSource.asObservable();
  onMyFavCoinsChange = this._myFamousCoinsSource.asObservable();
  onNewAccountDiscovered =  new ReplaySubject<number>();
  onCurrentWalletDiscoveryFinished = this._discoveryFinishedSource.asObservable();

  //#endregion

  //#region Public properties

  public get device(): WalletDeviceService{
    return this._deviceService;
  }

  public get CurrentWallet(): Wallet {
    return this._currentWallet;
  }

  public get CurrentWalletCoin(): CoinModel {
    return this._currentWallet == null ? null : this._currentWallet.CoinModel;
  }

  public get WalletCoins(): WalletCoins {
    return this._walletCoins;
  }

  //#endregion

  //#region Public methods

  /**
   * Request to change the current wallet
   * @param coin
   * @returns
   */
  changeCurrentWallet(coin: CoinModel): Promise<boolean> {
    return new Promise(async (resolve) => {
      const existingWallet = this.getWallet(coin);
      const newWallet = existingWallet ?? this.CreateNewWallet(coin);
      if (newWallet) {
        Helpers.canSelectCoin(false);

        this._currentWallet = await newWallet;
        this._currentWalletIndex = this._userWallets.indexOf(this._currentWallet);

        //! Rising event
        this.onCurrentWalletChange.next();

        //! start account discovery
        this.StartCurrentWalletAccountsDiscovery().then(() => {
          //Rise an event to inform account discovery finished to stop showing "Waiting" and show "Add Account" button

          //Automatically add coin if have balance to assets.
          this._autoAddAsset();
          this.nextDiscoveryFinished();
          return resolve(true);
          
        }).catch(() => {
          console.warn('account discovery failed.');
          Helpers.showToastrNotification('warning', `Wallet can't discovery ${coin.coinInfo.shortcut}`, {timeout: 5000});
          return resolve(false);
        });

      } else {
        Helpers.WalletLoading(false);
        Helpers.showToastrNotification('warning', `Wallet can't switch to ${coin.coinInfo.shortcut}`, {timeout: 5000});
        return resolve(false);
      }
    });
  }

  setCurrentAccountOfCurrentWallet(accountIndex: number) {
    //! Set the account in current wallet
    this.CurrentWallet.setCurrentAccount(accountIndex);

    //! Inform all that a new account selected
    //? It is really new account? What happen if user click on previous one again?
    this._currentAccountSource.next(this.CurrentWallet.getAccountInfo(accountIndex));
  }

  changeMyFamousCoinsSource() {
    this._myFamousCoinsSource.next(true);
  }

  getPublicProviderAddressLink(address : string) {
    let link = '';
    const coin = this.CurrentWalletCoin;
    if(coin == null)
      return "";

      if (coin.type == CoinBaseType.ERC20 || coin.type == CoinBaseType.EthereumBase) {
        let chainId = (coin.coinInfo as Erc20BaseCoinInfoModel).chain_id;
        switch (chainId) {
          //ETH
          case 1:
            return `https://etherscan.io/address/${address}`;

          //BSC
          case 56:
            return `https://bscscan.com/address/${address}`;
          //OKX
          case 66:
            return `https://www.oklink.com/en/okc/address/${address}`;  
          //ETC
          case 61:
            return `https://blockscout.com/etc/mainnet/address/${address}`;
  
        }
      } else if (coin.type == CoinBaseType.Ripple) {
       //TODO
      }
      return link;
  }

  getTransactionLink(hash: string) {
    let link = '';
    const coin = this.CurrentWalletCoin;
    if(coin == null)
      return "";

    if (coin.type == CoinBaseType.ERC20) {
      let chainId = (coin.coinInfo as Erc20BaseCoinInfoModel).chain_id;
      switch (chainId) {
        //ETH tokens
        case 1:
          return `https://etherscan.io/tx/${hash}`;

        //trin tokens
        case 4:
          return `https://rinkeby.etherscan.io/tx/${hash}`;

        //RSK tokens
        case 30:
          return `https://explorer.rsk.co/tx/${hash}`;

        //tRSK tokens
        case 31:
          return `https://explorer.testnet.rsk.co/tx/${hash}`;

        //BSC tokens
        case 56:
          return `https://bscscan.com/tx/${hash}`;

      }
    } else if (coin.coinInfo.tx_url) {
      const templateUrl = coin.coinInfo.tx_url;
      link = templateUrl
        .replace('{coin-name}', coin.coinInfo.name.toLowerCase())
        .replace('{coin-symbol}', coin.coinInfo.shortcut.toLowerCase())
        .replace('{hash}', hash);
    }
    return link;
  }

  getWallet(coin: CoinModel): Wallet {
    if (this._userWallets && this._userWallets.length) {
      return this._userWallets.find((wallet: Wallet) => wallet._coinModel.coinInfo.id === coin.coinInfo.id);
    }
  }

  async CreateNewWallet(coin: CoinModel, doStartAccountDiscovery?: boolean, onNewAccountCallBack?: (discoveredAccountNumber: number) => void): Promise<Wallet> {
    const wallet = this._createWallet(coin);

    if (doStartAccountDiscovery) {
      const callbackFn = onNewAccountCallBack ?? (() => {});
      await wallet.StartAccountDiscovery(callbackFn);
    }

    this._userWallets.push(wallet);
    return wallet;
  }

  async StartCurrentWalletAccountsDiscovery(): Promise<void> {
    return await this._userWallets[this._currentWalletIndex].StartAccountDiscovery((accIndex) => {
      if(accIndex === 0) { 
        this._currentAccountSource.next(this.CurrentWallet.getAccountInfo(0));
      }

      if (this.onNewAccountDiscovered.closed) {
        this.onNewAccountDiscovered = new ReplaySubject<number>();
      }

      this.onNewAccountDiscovered.next(accIndex);
    });
  }

  /**
   * Add new account
   * call CurrentWallet.canAddNewAccount and check the result in prior to call this function.
   */
  async AddNewAccount(): Promise<void> {
    const newAccIndex = await this._currentWallet._addNewAccount();
    if(newAccIndex != -1) {
      this.onNewAccountDiscovered.next(newAccIndex);
    }
  }

  nextDiscoveryFinished(){
    this._discoveryFinishedSource.next(true);
  }

  reDiscovery(layout: LayoutMode, coin?: CoinModel) {
    if (layout == LayoutMode.Portfolio) {
      this._clearUserWallets();
      this.onReDiscovery.next();
    } else {
      Helpers.WalletLoading(true);
      this.CurrentWallet.ClearCache();
      this.changeCurrentWallet(coin);
    }
  }

  setNeedToDiscovery(needToReSync: boolean){
    this.CurrentWallet.NeedToReSync = needToReSync;
    this._userWallets[this._currentWalletIndex].NeedToReSync = needToReSync;
  }

  //#endregion

  //#region Private methods

  private _createWallet(coin: CoinModel): Wallet {
    switch (coin.type) {
      case CoinBaseType.ERC20:
      case CoinBaseType.EthereumBase:
        return new Ethereum(this._deviceService.WebcoreDevice, coin);

      case CoinBaseType.BitcoinBase:
        return new Bitcoin(this._deviceService.WebcoreDevice, coin);

      case CoinBaseType.OMNI:
        return new Omni(this._deviceService.WebcoreDevice, coin);

      case CoinBaseType.Ripple:
        return new Ripple(this._deviceService.WebcoreDevice, coin);

      // case CoinBaseType.NEM:
      //   return new Nem(this._deviceService.WebcoreDevice, coin);

      default:
        throw new Error(`Unknown coin type: ${coin.type}`);
    }
  }

  private _initialize() {
    if (!this._deviceService.isInitial()) {
      window.location.href = '/start';
    }
    
    this._deviceService.getVersion().then((version) => {
      this._walletCoins = new WalletCoins(version, this._customTokenService);
      this._checkFirmwareVersion(version);
    });
  }

  private _clearUserWallets() {
    this._userWallets = [];
  }

  private _autoAddAsset() {
    let hasBalance = this.CurrentWallet.TotalBalance > 0;
    if (!hasBalance) {
      return;
    }

    let assets: string[] = this._settingsService.get(ProkeySettingsKey.ASSET_COINS) ?? [];
    let currentAsset = assets.filter(a => a == this.CurrentWallet.CoinInfo.id);
    
    if (currentAsset.length == 0) {
      assets.push(this.CurrentWallet.CoinInfo.id);
      this._settingsService.save(ProkeySettingsKey.ASSET_COINS, assets);
    }
  }

  private _checkFirmwareVersion(version: string) {
    this._isLatestVersion(version).subscribe(isLatest => {
      if (isLatest === false) {
        this._deviceService.setUiAlertSource(UiEvent.Firmware_Outdated);
      }
    });
  }

  private _isLatestVersion(version: string): Observable<boolean> {
    return this._http.get<boolean>(RestUrls.getBootloaderOptimumVersion(version));
  }

  //#endregion

}
