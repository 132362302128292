import {BaseProvider} from './baseProvider';
import {ExchangeOffer} from '../../models/exchange-offer';
import {ExchangeStatus} from '../../models/exchange-status';
import {ExchangeTransaction} from '../../models/exchange-transaction';
import {Exchange} from '../../models/exchange';
import {CoinModel} from '@core/models/connect/coin-model';
import {AmountRange} from '../../models/amount-range';
import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {RequestModel} from "../../models/changelly/request-model";
import {ResponseModel} from "../../models/changelly/response-model";
import {ExchangeException} from "../../errors/exchange.exception";
import {FixRateRangeModel} from "../../models/changelly/fix-rate-range-model";
import {ExchangeAmountModel} from "../../models/changelly/exchange-amount-model";
import {FixRateExchangeAmountModel} from "../../models/changelly/fix-rate-exchange-amount-model";
import {CreateTransactionResponse} from "../../models/changelly/create-transaction-response";
import {StatusType} from "../../models/status-type";

@Injectable({
  providedIn: 'root'
})
export class ChangellyProvider extends BaseProvider {
  private availableCurrencies: string[];

  constructor(private http: HttpClient) {
    super(http);
  }

  getProviderName(): string {
    return 'Changelly';
  }

  getProviderImagePath(): string {
    return "/assets/img/exchange/changelly.png";
  }

  getProviderTransactionUrl(): string {
    return "https://changelly.com/transaction/";
  }

  async isExchangeCoinsSupported(sendCoin: CoinModel, receiveCoin: CoinModel, fixRate: boolean): Promise<boolean> {
    try {
      if (!this.availableCurrencies) {
        const message: RequestModel = {
          method: 'getCurrencies',
          jsonrpc: "2.0",
          id: "prokey",
        }
        const responseModel = await this.postToChangelly<ResponseModel<string[]>>(message);
        this.availableCurrencies = responseModel.result;
      }
      return this.isCoinSupported(sendCoin) && this.isCoinSupported(receiveCoin);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        throw new ExchangeException(error.error.message);
      }
      throw error;
    }
  }

  async getAmountRange(sendCoin: CoinModel, receiveCoin: CoinModel, fixRate: boolean): Promise<AmountRange> {
    if (fixRate) {
      const message: RequestModel = {
        method: 'getFixRate',
        jsonrpc: "2.0",
        id: "prokey",
        params: {
          'from': sendCoin.coinInfo.shortcut.toLowerCase(),
          'to': receiveCoin.coinInfo.shortcut.toLowerCase(),
        }
      }
      const responseModel = await this.postToChangelly<ResponseModel<FixRateRangeModel>>(message);
      return {
        minimum: +responseModel.result.minFrom,
        maximum: +responseModel.result.maxFrom
      };
    } else {
      const message: RequestModel = {
        method: 'getMinAmount',
        jsonrpc: "2.0",
        id: "prokey",
        params: {
          'from': sendCoin.coinInfo.shortcut.toLowerCase(),
          'to': receiveCoin.coinInfo.shortcut.toLowerCase(),
        }
      }
      const responseModel = await this.postToChangelly<ResponseModel<string>>(message);
      return {
        minimum: +responseModel.result
      };
    }
  }

  async getOffers(sendCoin: CoinModel, receiveCoin: CoinModel, sendCoinAmount: number, isFixRate: boolean): Promise<ExchangeOffer> {
    if (isFixRate) {
      const message: RequestModel = {
        method: 'getFixRateForAmount',
        jsonrpc: "2.0",
        id: "prokey",
        params: [{
          'from': sendCoin.coinInfo.shortcut.toLowerCase(),
          'to': receiveCoin.coinInfo.shortcut.toLowerCase(),
          'amountFrom': sendCoinAmount.toString()
        }],
      }
      const responseModel = await this.postToChangelly<ResponseModel<FixRateExchangeAmountModel[]>>(message);
      return {
        providerName: this.getProviderName(),
        providerImagePath: this.getProviderImagePath(),
        providerTransactionUrl: this.getProviderTransactionUrl(),
        receiveCoinAmount: +responseModel.result[0].amountTo,
        fee: 0,
        rateId: responseModel.result[0].id,
      }
    } else {
      const message: RequestModel = {
        method: 'getExchangeAmount',
        jsonrpc: "2.0",
        id: "prokey",
        params: [{
          'from': sendCoin.coinInfo.shortcut.toLowerCase(),
          'to': receiveCoin.coinInfo.shortcut.toLowerCase(),
          'amount': sendCoinAmount.toString()
        }],
      }
      const responseModel = await this.postToChangelly<ResponseModel<ExchangeAmountModel[]>>(message);
      return {
        providerName: this.getProviderName(),
        providerImagePath: this.getProviderImagePath(),
        providerTransactionUrl: this.getProviderTransactionUrl(),
        receiveCoinAmount: +responseModel.result[0].result,
        fee: +responseModel.result[0].fee,
      }
    }
  }

  async createExchangeTransaction(exchange: Exchange, payoutAddress: string, payinAddress: string): Promise<ExchangeTransaction> {
    let message: RequestModel;
    if (exchange.rateId) {
      message = {
        method: 'createFixTransaction',
        jsonrpc: "2.0",
        id: "prokey",
        params: {
          "from": exchange.senderCoin.coinInfo.shortcut.toLowerCase(),
          "to": exchange.receiverCoin.coinInfo.shortcut.toLowerCase(),
          "address": payoutAddress,
          "refundAddress": payinAddress,
          "extraId": null,
          "rateId": exchange.rateId,
          "amountFrom": exchange.sendAmount
        },
      }
    } else {
      message = {
        method: 'createTransaction',
        jsonrpc: "2.0",
        id: "prokey",
        params: {
          "from": exchange.senderCoin.coinInfo.shortcut.toLowerCase(),
          "to": exchange.receiverCoin.coinInfo.shortcut.toLowerCase(),
          "address": payoutAddress,
          "extraId": null,
          "amount": exchange.sendAmount
        },
      }
    }
    const responseModel = await this.postToChangelly<ResponseModel<CreateTransactionResponse>>(message);
    return {
      payinAddress: responseModel.result.payinAddress,
      payoutAddress: responseModel.result.payoutAddress,
      id: responseModel.result.id,
      amount: +responseModel.result.amountExpectedFrom,
      extraId: responseModel.result.payinExtraId
    }
  }

  async getExchangeStatus(transactionId: string): Promise<ExchangeStatus> {
    const message: RequestModel = {
      method: 'getStatus',
      jsonrpc: "2.0",
      id: "prokey",
      params: {
        "id": transactionId
      }
    }
    const responseModel = await this.postToChangelly<ResponseModel<string>>(message);
    let status : StatusType = StatusType.WAITING;
    switch (responseModel.result) {
      case 'finished':
        status = StatusType.FINISHED;
        break;
      case 'failed':
        status = StatusType.FAILED;
        break;
    }
    return {
      id: transactionId,
      status: status,
      payinAddress: '',
      payoutAddress: '',
      createdAt: '',
      updatedAt: ''
    }
  }

  private isCoinSupported(requestedCoin: CoinModel): boolean {
    for (const availableCurrency of this.availableCurrencies) {
      if (requestedCoin.coinInfo.shortcut.toLowerCase() === availableCurrency) {
        return true;
      }
    }
    return false;
  }

  private postToChangelly<T>(message: RequestModel): Promise<T> {
    return this.postToServer<T>('/', message);
  }
}
