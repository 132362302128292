import { Component, OnInit } from '@angular/core';
import {StorageService} from "../../../core/services/storage/storage.service";
import {first} from "rxjs/operators";
import {WalletDeviceService} from "@core/services/wallet-device.service";

@Component({
  selector: 'app-storage-auth-dialog',
  templateUrl: './storage-auth-dialog.component.html',
  styleUrls: ['./storage-auth-dialog.component.css']
})
export class StorageAuthDialogComponent implements OnInit {
  private _deviceId: string;

  constructor(private _storageService: StorageService, private _deviceService: WalletDeviceService) { }

  async ngOnInit(): Promise<void> {
    let device = await this._deviceService.device$
      .pipe(first()).toPromise(); // it will unsubscribe device$ after first value is emitted.
    this._deviceId = device.features.device_id;
  }

  reconnect() {
    this._storageService.reconnect(this._deviceId);
  }

  useLocalStorage() {
    this._storageService.changeToLocalStorage(this._deviceId);
  }
}
