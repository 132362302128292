import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ChallengeMessage} from '@core/models/bootloader/ChallengeMessage';
import {TransportInterface} from '@core/models/bootloader/transportInterface';
import {ChallengeErrorCode} from '@core/models/bootloader/ChallengeErrors';

class BridgeInstance {
  version: string;
  path: string;
  vendor: number;
  product: number;
  prevSession: number;
  session: number;
}

@Injectable()
export class BridgeService implements TransportInterface {

  kind: string = "bridge";

  _bridge: BridgeInstance;
  _httpRegHeader = new HttpHeaders({ "Content-Type": "text/plain" });

  bridgeUrl = "http://127.0.0.1:21325/";

  constructor(private http: HttpClient) {
    this._bridge = new BridgeInstance();
    this._bridge.prevSession = -1;
    this._bridge.session = -1;
    this._bridge.version = "";
  }

  public GetBridgeStatus(): BridgeInstance {
    return this._bridge;
  }

  public async Connect(): Promise<ChallengeMessage> {
    //! Already Connected
    if (this._bridge.session > 0)
      return { success: true };

    var res: ChallengeMessage = await this.GetVersion();
    if (res.success == false)
      return res;

    res = await this._enumerate();
    if (res.success == false)
      return res;

    res = await this._acquire();
    if (res.success == false)
      return res;

    return { success: true }
  }

  public async Release(): Promise<ChallengeMessage> {
    if (this._bridge.session < 0)
      return <ChallengeMessage>{ success: true };

    var tmp: string;
    tmp = await this._post("release/" + this._bridge.session.toString());

    tmp = this.SmartJsonGetValue(tmp, "session");
    if (tmp == null)
      return { success: false, errorCode: ChallengeErrorCode.NO_RESPONSE_FROM_BRIDGE };

    //! now the session should be closed
    this._bridge.session = -1;
    this._bridge.prevSession = Number.parseInt(tmp);

    return { success: true };
  }

  public async Call(data: string): Promise<ChallengeMessage> {
    if (this._bridge.session < 0) {
      console.error("The session cannot be negative, connect function should be called first")
      return { success: false };
    }

    var str: string = await this._post("call/" + this._bridge.session.toString(), data);
    return { success: true, payload: str };
  }

  private async _acquire(): Promise<ChallengeMessage> {
    var tmp: string;

    tmp = "acquire/" + this._bridge.path;
    if (this._bridge.prevSession != -1)
      tmp += "/" + this._bridge.prevSession.toString();

    return await this._post(tmp).then((result) => {
      tmp = this.SmartJsonGetValue(result, "session");
      if (tmp == null)
        return <ChallengeMessage>{ success: false, errorCode: ChallengeErrorCode.CAN_NOT_ACQUIRE_DEVICE };

      this._bridge.session = Number.parseInt(tmp);

      return <ChallengeMessage>{ success: true }
    }).catch((err) => {
      return <ChallengeMessage>{ success: false, errorCode: ChallengeErrorCode.NO_RESPONSE_FROM_BRIDGE, payload: err };
    });
  }

  private async _enumerate(): Promise<ChallengeMessage> {
    var tmp: string;

    return await this._post("enumerate").then((result) => {
      //! Path
      tmp = this.SmartJsonGetValue(result, "path");
      if (tmp == null)
        return <ChallengeMessage>{ success: false, errorCode: ChallengeErrorCode.NO_DEVICE };

      this._bridge.path = tmp;

      //! vendor
      tmp = this.SmartJsonGetValue(result, "vendor");
      if (tmp == null)
        return <ChallengeMessage>{ success: false, errorCode: ChallengeErrorCode.NO_DEVICE };

      this._bridge.vendor = Number.parseInt(tmp);

      //! product
      tmp = this.SmartJsonGetValue(result, "product");
      if (tmp == null)
        return <ChallengeMessage>{ success: false, errorCode: ChallengeErrorCode.NO_DEVICE };

      this._bridge.product = Number.parseInt(tmp);

      //! session
      tmp = this.SmartJsonGetValue(result, "session")
      if (tmp == null)
        return <ChallengeMessage>{ success: false, errorCode: ChallengeErrorCode.NO_DEVICE };

      this._bridge.prevSession = (tmp == "null") ? -1 : Number.parseInt(tmp);

      return <ChallengeMessage>{ success: true, payload: this._bridge };

    }).catch((err) => {
      return <ChallengeMessage>{ success: false, errorCode: ChallengeErrorCode.NO_RESPONSE_FROM_BRIDGE, payload: err };
    });
  }

  public async GetVersion(): Promise<ChallengeMessage> {

    try {
      var verJson = await this._post("");

      var ver = this.SmartJsonGetValue(verJson, "version");
      if (ver == null)
        return { success: false, errorCode: ChallengeErrorCode.NO_RESPONSE_FROM_BRIDGE };

      this._bridge.version = ver;

      return { success: true, payload: ver };
    }
    catch {
      return { success: false, errorCode: ChallengeErrorCode.UNDEFINED };
    }


    /*var ver: string;
    var res: ChallengeMessage = <ChallengeMessage>{ success: false, errorCode: ChallengeErrorCode.UNDEFINED };
    await this._post("").then((result) => {
      ver = this.SmartJsonGetValue(result, "version");
      if (ver == null)
        res = { success: false, errorCode: ChallengeErrorCode.NO_RESPONSE_FROM_BRIDGE };

      this._bridge.version = ver;

      res = { success: true, payload: ver };
    }).catch((err) => {
      res = { success: false, errorCode: ChallengeErrorCode.NO_RESPONSE_FROM_BRIDGE, payload: err };
    });

    return res;*/
  }

  public async Reset(): Promise<ChallengeMessage> {
    this._bridge.prevSession = -1;
    this._bridge.session = -1;
    this._bridge.version = "";

    return { success: true };
  }

  private SmartJsonGetValue(inp: string, wts: string): string {
    wts = wts.toLowerCase();
    inp = inp.toLowerCase();

    if (inp.includes(wts) == false)
      return null;

    let wtsPos = inp.indexOf(wts);
    let sp = inp.indexOf(":", wtsPos) + 1;
    let ep = inp.indexOf(",", sp);
    if (ep < 0)
      ep = inp.indexOf("}", sp);

    var res = inp.slice(sp, ep);

    if (res.charAt(0) === "\"")
      res = res.substr(1);

    if (res.charAt(res.length - 1) === "\"")
      res = res.substring(0, res.length - 1);

    return res;
  }

  private _post(url: string, body?: string): Promise<string> {
    if (body != null) {
      return this.http.post(this.bridgeUrl + url, body, { headers: this._httpRegHeader, responseType: 'text' })
        .pipe(map(response => response || ""), catchError((error: HttpErrorResponse) => throwError(error)))
        .toPromise();
    } else {
      return this.http.post(this.bridgeUrl + url, "", { headers: this._httpRegHeader, responseType: 'text' })
        .pipe(map(response => response || ""), catchError((error: HttpErrorResponse) => throwError(error)))
        .toPromise();
    }
  }
}
