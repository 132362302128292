<div class="mt-4">
  <h3 class="body-title float-left col-lg-8 col-md-6 col-sm-6 col-12">Transactions</h3>
  <div class="input-group mb-3 col-lg-4 col-md-6 col-sm-6 col-12 p-0 table-search">
    <input type="text" class="form-control" id="filter-search" placeholder="Search Amount and ....">
    <div class="input-group-append">
      <button class="btn" type="button" style="z-index:1"><i class="fa fa-search"></i></button>
    </div>
  </div>
</div>
<ng-container *ngIf="uiMode === 'Loading'">
  <div class="row">
    <div class="col-12 d-flex d-flex justify-content-center align-items-center mt-3 mb-3">
      <div>
        <i class="fa fa-spinner fa-spin fa-2x"></i>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="uiMode === 'NoTransactions'">
  <div class="notransaction">
    <i class="far fa-list-alt"></i>
    No transaction found in blockchain.
  </div>
</ng-container>
<ng-container *ngIf="uiMode === 'DisconnectedBlockchain'">
  <div class="faildload">
    <i class="far fa-list-alt"></i>
    Failed to connect blockchain at this moment, Please try again later.
  </div>
</ng-container>
<ng-container *ngIf="uiMode === 'PublicProvider'">
  <div class="alert alert-primary">
    <i class="fas fa-external-link-alt"></i>
    <a target="_blank" href="{{publicProviderLink}}"> Click here</a> to find out transactions list on blockchain explorer .
  </div>
</ng-container>
<ng-container *ngIf="uiMode === 'TransactionHistory'">
  <ng-container *ngIf="viewModeProperty === 'Dashboard'">
    <div style="min-height:245px;">
      <table class="table table-borderless {{tableClassName}} table-responsive text-center">
        <thead *ngIf="hasTableHeader">
          <tr>
            <td width="60px"></td>
            <td width="200px"></td>
            <td width="80px" class="font-weight-bold"></td>
            <td class="font-weight-bold"></td>
            <td class="font-weight-bold text-green"></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let transaction of transactions" (click)="openTransaction(transaction.hash)" style="cursor: pointer" [ngStyle]="{'background': (transaction.isValid == false) ? 'seashell' : 'none' }">
            <td width="60px">
              <i *ngIf="transaction.isValid && transaction.type == 'sent'" class="fa fa-upload bg-red td-icon"></i>
              <i *ngIf="transaction.isValid && transaction.type == 'recv'" class="fa fa-download bg-green td-icon"></i>
              <i *ngIf="!transaction.isValid" class="fa fa-ban bg-red td-icon"></i>
            </td>
            <td width="200px">
              <div *ngIf="isETH && transaction.value === 0">
                <span class="erc-fee">ERC20 fee</span><span class="text-light ml-2"><i class="far fa-clock"></i> {{transaction.date | date:'mediumDate'}}</span>
              </div>
              <div *ngIf="isBTC && transaction.isOmni">
                <span class="erc-fee">OMNI</span><span class="text-light ml-2"><i class="far fa-clock"></i> {{transaction.date | date:'mediumDate'}}</span>
              </div>
              <div *ngIf="!(isETH && transaction.value === 0) && !(isBTC && transaction.isOmni)">
                <span class="text-light"><i class="far fa-clock"></i> {{transaction.date | date:'mediumDate'}}</span>
              </div>
            </td>
            <td width="80px" class="font-weight-bold">
              <span *ngIf="transaction.isValid && transaction.type == 'sent'">Sent</span>
              <span *ngIf="transaction.isValid && transaction.type == 'recv'">Received</span>
              <span *ngIf="!transaction.isValid">Invalid</span>
            </td>
            <td class="font-weight-bold">
              {{transaction.targets[0].address}}
            </td>
            <td class="font-weight-bold text-green">
              {{transaction.targets[0].value | unit: coin.coinInfo.decimals}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="viewModeProperty !== 'Dashboard'">
    <div style="min-height:245px;">
      <table class="table table-borderless {{tableClassName}} table-responsive text-center">
        <tbody>
          <tr *ngFor="let transaction of transactions | paginate: { id: 'server', itemsPerPage: itemsPerPage, currentPage: page, totalItems: total }" (click)="openTransaction(transaction.hash)" style="cursor: pointer" [ngStyle]="{'background': (transaction.isValid == false) ? 'seashell' : 'none' }">
            <td width="60px">
              <i *ngIf="transaction.isValid && transaction.type == 'sent'" class="fa fa-upload bg-red td-icon"></i>
              <i *ngIf="transaction.isValid && transaction.type == 'recv'" class="fa fa-download bg-green td-icon"></i>
              <i *ngIf="!transaction.isValid" class="fa fa-ban bg-red td-icon"></i>
            </td>
            <td width="150px">
              <div *ngIf="isETH && transaction.value === 0">
                <span class="erc-fee">ERC20 fee</span>
              </div>
              <div *ngIf="isBTC && transaction.isOmni">
                <span class="erc-fee">OMNI</span>
              </div>
              <div *ngIf="!(isETH && transaction.value === 0) && !(isBTC && transaction.isOmni)">
                <span class="text-light"><i class="far fa-clock"></i> {{transaction.date | date:'mediumDate'}}</span>
              </div>
            </td>
            <td width="80px" class="font-weight-bold">
              <span *ngIf="transaction.isValid && transaction.type == 'sent'">Sent</span>
              <span *ngIf="transaction.isValid && transaction.type == 'recv'">Received</span>
              <span *ngIf="!transaction.isValid">Invalid</span>
            </td>
            <td class="font-weight-bold">
              {{transaction.targets[0].address}}
            </td>
            <td class="font-weight-bold text-green">
              {{transaction.targets[0].value | unit: coin.coinInfo.decimals}}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="has-text-centered">
        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
        <pagination-controls (pageChange)="getTransactionPage($event)" id="server" nextLabel="" previousLabel=""></pagination-controls>
      </div>
    </div>
  </ng-container>
</ng-container>
