import {environment} from "../../environments/environment";

/** All REST urls should be put here and all services should use this class to get their desired REST url. */
export class RestUrls {
  static readonly baseURL = environment.baseURL;

  /** url for getting latest device firmware. */
  static getBootloaderLatestFirmware(): string {
    return this.baseURL + "api/bootloader/getLatestFirmware";
  }

  /** url for getting the device firmware's signatures */
  static getOptimumFirmwareSignatures(): string {
    return this.baseURL + "api/bootloader/getOptimumFirmwareSignatures";
  }

  /** url for setting server challenge. */
  static getServerChallenge(): string {
    return this.baseURL + 'api/challenges/challenge';
  }

  /** url for checking if this is the latest version of device firmware */
  static getBootloaderOptimumVersion(version: string): string {
    return this.baseURL + "api/bootloader/isOptimumLatestVersion?version=" + version;
  }

  /** url for add authentication headers to exchange http request */
  static getExchangeAuth(): string {
    return this.baseURL + "api/exchange/auth"
  }
}
