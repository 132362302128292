import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-wallet-footer',
  templateUrl: './wallet-footer-layout.component.html'
})
export class WalletFooterLayoutComponent implements OnInit {

  nowYear: number;

  /**
   *
   */
  constructor() {
  }

  ngOnInit() {
    this.nowYear = new Date().getFullYear();
  }
}
