import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {WalletService} from '@core/services/wallet.service';
import {CoinModel} from '@core/models/connect/coin-model';
import {AccountInfo} from '@core/models/connect/account-info';
import {Transaction} from '@core/models/connect/transaction';
import {Helpers} from '@core/services/helpers';
import {WindowService} from '@core/services/window.service';
import {UiEvent} from '@core/models/connect/ui-event.enum';
import {CoinBaseType} from '@webcore/coins/CoinInfo';
import {WalletDeviceService} from "@core/services/wallet-device.service";

enum TransactionsUiMode {
  Loading = 'Loading',
  NoTransactions = 'NoTransactions',
  TransactionHistory = 'TransactionHistory',
  DisconnectedBlockchain = 'DisconnectedBlockchain',
  PublicProvider = 'PublicProvider',
}

enum ViewMode {
  Dashboard = 'Dashboard',
  TransactionsPage = 'TransactionsPage'
}

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
})
export class TransactionsComponent implements OnInit, AfterViewInit, OnDestroy {

  coinSubscription: Subscription;
  accountSubscription: Subscription;
  uiAlertSubscription: Subscription;

  uiMode: TransactionsUiMode;
  coin: CoinModel;
  account: AccountInfo = <AccountInfo>{};
  transactions: Transaction[] = [];
  tableClassName: string = 'table-transactions';
  hasTableHeader: boolean = false;
  isETH: boolean = false;
  isBTC = false;
  isBlockchainDisconnected = false;
  publicProviderLink = '';

  page = 1;
  itemsPerPage = 10;
  dashboardItemsCount = 3;
  total: number;
  loading: boolean;

  @Input('viewMode') viewModeProperty: string;

  constructor(
    private _windowService: WindowService,
    private _walletService: WalletService,
    private _deviceService: WalletDeviceService
  ) {
  }

  ngOnInit() {
    this.initLayoutMode();
    this.coinSubscribe();
    this.uiAlertSubscribe();
    this.accountSubscribe();
  }

  ngAfterViewInit() {
    this.initDataTables();
  }

  initDataTables() {
    Helpers.initLastTransactions(this.tableClassName);
  }

  initLayoutMode() {
    this.uiMode = TransactionsUiMode.Loading;
    if (this.viewModeProperty === ViewMode.Dashboard) {
      this.tableClassName = 'table-transactions';
      this.hasTableHeader = false;
    } else {
      this.tableClassName = 'table-transactions2';
      this.hasTableHeader = true;
    }
  }

  coinSubscribe() {
    this.coinSubscription = this._walletService.onCurrentWalletChange.subscribe(() => {
      this.uiMode = TransactionsUiMode.Loading;
      this.coin = this._walletService.CurrentWalletCoin;
      this.isETH = this.coin.type === CoinBaseType.EthereumBase;
      this.isBTC = this.coin.type === CoinBaseType.BitcoinBase && this.coin.coinInfo.name === 'Bitcoin';
    });
  }

  accountSubscribe() {
    this.accountSubscription = this._walletService.onCurrentAccountChange.subscribe(currentAccount => {
      
      this.account = currentAccount;

      if (!this.isBlockchainDisconnected) {
        if (this.account.isDirectQueryFromGeth) {
          let currentAddress = this.account.lastUnusedAddress?.address;
          this.publicProviderLink = this._walletService.getPublicProviderAddressLink(currentAddress);
          this.uiMode = TransactionsUiMode.PublicProvider;
        } else {
          this.uiMode = TransactionsUiMode.Loading;
          if (this._walletService.CurrentWallet) {
            this.getTransactionPage(1);
          }
        }
      }
    });
  }

  uiAlertSubscribe() {
    this.uiAlertSubscription = this._deviceService.uiAlert$.subscribe(uiEvent => {
      switch (uiEvent) {
        case UiEvent.Blockchain_Disconnected:
          this.isBlockchainDisconnected = true;
          this.uiMode = TransactionsUiMode.DisconnectedBlockchain;
          break;
        case UiEvent.Blockchain_Synchronizing:
        case UiEvent.Blockchain_Synchronized:
          this.isBlockchainDisconnected = false;
          break;
        default:
          break;
      }
    });
  }

  openTransaction(hash: string) {
    const url = this._walletService.getTransactionLink(hash);
    this._windowService.nativeWindow.open(url, '_blank');
  }

  getTransactionPage(page: number) {
    this.loading = true;
    const perPage = this.viewModeProperty === ViewMode.Dashboard.toString() ? this.dashboardItemsCount : this.itemsPerPage;
    const start = (page - 1) * perPage;
    const end = start + perPage;
    this._walletService.CurrentWallet.getAccountTransactionsBy(this.account.id, start, end).then(trans => {
      if (trans) {
        this.page = page;
        this.total = this.account.transactions;

        this.transactions = [];

        //! Remove the transaction with value 0 from ETH tx
        trans.forEach(t => {
          //! Only EthereumBase is important now
          //! show only tx with value > 0
          if(this.coin.type != CoinBaseType.EthereumBase || t.value > 0)
            this.transactions.push(t);
        });

        if (this.transactions.length > 0) {
          this.uiMode = TransactionsUiMode.TransactionHistory;
          this.initDataTables();
        } else {
          this.uiMode = TransactionsUiMode.NoTransactions;
        }
      } else {
        this.uiMode = TransactionsUiMode.NoTransactions;
      }
    }).catch(e => console.log(e));
  }

  ngOnDestroy() {
    if (this.coinSubscription) {
      this.coinSubscription.unsubscribe();
    }

    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }

    if (this.uiAlertSubscription) {
      this.uiAlertSubscription.unsubscribe();
    }
  }
}
