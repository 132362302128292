import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DialogComponent} from './dialog.component';
import {ModalFooterDirective} from "./_directive/dialog-footer-directive";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";


@NgModule({
  declarations: [DialogComponent, ModalFooterDirective],
  exports: [DialogComponent, ModalFooterDirective],
  imports: [CommonModule, MatDialogModule, MatButtonModule]
})
export class DialogModule {
}
