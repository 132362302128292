import { Injectable } from '@angular/core';
import * as $ from 'jquery';

declare let document: any;

interface Script {
  src: string;
  loaded: boolean;
}

interface Style {
  href: string;
  media: string;
  loaded: boolean;
}

@Injectable()
export class ScriptLoaderService {

  constructor() { }

  private _scripts: Script[] = [];
  private _styles: Style[] = [];

  load(...scripts: string[]) {
    scripts.forEach((script: string) => this._scripts[script] = { src: script, loaded: false });

    let promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(src: string) {
    return new Promise((resolve, reject) => {

      //resolve if already loaded
      if (this._scripts[src].loaded) {
        resolve({ script: src, loaded: true, status: 'Already Loaded' });
      }
      else {
        //load script
        let script = $('<script/>')
          .attr('type', 'text/javascript')
          .attr('src', this._scripts[src].src);

        $('head').append(script);
        resolve({ script: src, loaded: true, status: 'Loaded' });
      }
    });
  }

  loadStyle(style: Style) {
    var currentStyle = this._styles.filter(x => x.href == style.href);
    if (currentStyle.length > 0) {
      return;
    } else {
      this._styles.push(style);
      let link = $('<link/>')
        .attr('rel', 'stylesheet')
        .attr('type', 'text/css')
        .attr('href', style.href)
        .attr('media', style.media);
      $('base').after(link);
    }
  }

  removeStyle(style: Style) {
    let link = $(`link[href='${style.href}']`);
    if (link) {
      $(link).remove();
      delete this._styles[this._styles.indexOf(style)];
    }
  }

}
