import {Component, Input} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-spinner-loading',
  templateUrl: './spinner-loading.component.html',
  styleUrls: ['./spinner-loading.component.css']
})
export class SpinnerLoadingComponent {
  @Input() set showSpinner(value: boolean) {
    if (value === true) {
     this.loadSpinner();
    } else {
      this.hideSpinner();
    }
  }

  loadSpinner() {
    $('#loading').remove();
    $('body').append('' +
      '<div id=\'loading\' style=\'top:80px\'><div class=\'dotiner\'>' +
      '<div class=\'dot dot-1\'></div><div class=\'dot dot-2\'>' +
      '</div><div class=\'dot dot-3\'>' +
      '</div>' +
      '</div>' +
      '<span>LOADING</span>' +
      '<svg xmlns=\'http://www.w3.org/2000/svg\' version=\'1.1\'>' +
      '<defs>' +
      '<filter id=\'goo\'>' +
      '<feGaussianBlur in=\'SourceGraphic\' stdDeviation=\'10\' result=\'blur\' />' +
      '<feColorMatrix in=\'blur\' mode=\'matrix\' values=\'1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7\'/>' +
      '</filter>' +
      '</defs>' +
      '</svg>' +
      '</div>');
    $('.acc-balance').hide();
  }

  hideSpinner() {
    $('#loading').fadeOut(500);
    setTimeout(function () {
      $('#loading').remove();
    }, 1000);
    $('.syncing').remove();
    $('ul.home-links li:nth-child(3) a').show();
    $('.acc-balance').show();
  }
}
