import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ButtonRequestType} from '@core/models/connect/button-request-type.enum';
import {AlertModel} from '@shared/models/alert-model';
import {AlertType} from '@shared/models/alert-type.enum';
import {FailureType} from '@core/models/connect/failure-type.enum';
import {MyConsole} from '@webcore/utils/console';
import {ProkeySettingsService} from "@core/services/prokey-settings.service";
import {ProkeySettingsKey} from "@core/models/prokey-settings-key.enum";
import {LocalStorageService} from "@core/services/storage/repository/local-storage-service";
import {WalletDeviceService} from "@core/services/wallet-device.service";
import {ResetPassphraseDialogComponent} from "../reset-passphrase-dialog/reset-passphrase-dialog.component";
import {WipeDeviceDialogComponent} from "../wipe-device-dialog/wipe-device-dialog.component";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import {StorageService} from "@core/services/storage/storage.service";
import {first} from "rxjs/operators";
import {BaseRepository} from "@core/services/storage/repository/base-repository";
import {StorageSyncDialogComponent} from "../storage-sync-dialog/storage-sync-dialog.component";

enum PassphraseAction {
  Enable = 'Enable',
  Disable = 'Disable',
}

@Component({
  selector: 'app-advance-settings',
  templateUrl: './advance-settings.component.html',
  styleUrls: ['./advance-settings.component.css']
})
export class AdvanceSettingsComponent implements OnInit, OnDestroy {

  deviceSubscription: Subscription;
  uiButtonSubscription: Subscription;
  failureSubscription: Subscription;

  // storage
  availableStorages: Array<BaseRepository>;
  currentStorage?: BaseRepository;
  private _deviceId?: string;

  alertModel: AlertModel = <AlertModel>{show: false};
  fmvVersion: string;
  isPinProtection?: boolean;
  isPassphraseProtection?: boolean;
  saveLogs?: boolean;
  actionInForm = false;
  protectionAction = false;
  wipeAction = false;
  /* 2-way binded data for wipe-device dialog. */
  private _wipeDeviceData = {
    isDeviceWipeSuccessfully: false
  }
  /* 2-way binded data for reset-passphrase dialog. */
  private _passphraseData = {
    isSetPassphraseSuccessfully: false,
    passphraseAction: PassphraseAction.Enable
  }

  readonly wipeDialog: string = 'wipe-dialog';
  @Input('active') activeTab: string;

  constructor(private _deviceService: WalletDeviceService,
              private _settingsService: ProkeySettingsService,
              private _localStorageService: LocalStorageService,
              private _dialogService: DialogService,
              private _storageService: StorageService) {
  }

  async ngOnInit() {
    this.deviceSubscribe();
    this.buttonSubscribe();
    this.failureSubscribe();
    this.checkSaveLog();

    // load all storages
    this.availableStorages = this._storageService.getAllStoragesDetails();

    // load current storage and set it in option

    await this._getCurrentStorage();
  }

  deviceSubscribe() {
    this.deviceSubscription = this._deviceService.device$.subscribe(device => {
      if (device.features) {
        this.fmvVersion = `${device.features.major_version}.${device.features.minor_version}.${device.features.patch_version}`;
        this.isPinProtection = device.features.pin_protection;
        this.isPassphraseProtection = device.features.passphrase_protection;
      }
    });
  }

  buttonSubscribe() {
    this.uiButtonSubscription = this._deviceService.uiButton$.subscribe(code => {
      if (this.actionInForm && ButtonRequestType.ButtonRequest_WipeDevice == code) {
        this._openDialog(WipeDeviceDialogComponent, this._wipeDeviceData);
      } else if (this.actionInForm && ButtonRequestType.ButtonRequest_ProtectCall == code && this.isCurrentTab()) {
        this._openDialog(ResetPassphraseDialogComponent, this._passphraseData);
      }
    });
  }

  failureSubscribe() {
    this.failureSubscription = this._deviceService.failureCallBack$.subscribe(failureType => {
      if (failureType && this.actionInForm) {
        if (failureType.code == FailureType.Failure_ActionCancelled) {
          if (this.protectionAction) {
            this._closeDialogs();
            this.isPassphraseProtection = !this.isPassphraseProtection;
          } else if (this.wipeAction) {
            this._closeDialogs();
          }
        }
      }
    });
  }

  async setProtection() {
    this.actionInForm = true;
    this.protectionAction = true;
    this._passphraseData.passphraseAction = this.isPassphraseProtection ? PassphraseAction.Enable : PassphraseAction.Disable;

    try {
      const success = await this._deviceService.setPassphrase(this.isPassphraseProtection);
      if (success) {
        this._passphraseData.isSetPassphraseSuccessfully = true;
        this._deviceService.isForceDisconnect = true;
        this.setAlert(AlertType.Success, 'Set passphrase successfully.');
      } else {
        this._closeDialogs();
        this.setAlert(AlertType.Warning, 'Try again later.');
      }
    } catch (e) {
      this.protectionAction = false;
      this._closeDialogs();
      this.setAlert(AlertType.Warning, `Try again later. ${e}`);
    }
  }


  async wipeDevice() {
    this.actionInForm = true;
    this.wipeAction = true;
    try {
      const success = await this._deviceService.wipeDevice();
      if (success) {
        this._wipeDeviceData.isDeviceWipeSuccessfully = true;
        this._deviceService.isForceDisconnect = true;
      } else {
        this._closeDialogs();
        this.setAlert(AlertType.Warning, 'Try again later.');
      }
    } catch (e) {
      this.wipeAction = false;
      this._closeDialogs();
      this.setAlert(AlertType.Warning, `Try again later. ${e}`);
    }
  }

  setAlert(type: AlertType, content: string) {
    this.alertModel.type = type;
    this.alertModel.content = content;
    this.alertModel.show = true;
    this.alertModel.dismissing = true;
  }

  isCurrentTab() {
    return this.activeTab === 'advanced';
  }

  ngOnDestroy() {
    this._closeDialogs();
    this.deviceSubscription.unsubscribe();
    this.uiButtonSubscription.unsubscribe();
    this.failureSubscription.unsubscribe();
  }

  async setLogs(saveLogs: boolean) {
    await this._settingsService.save(ProkeySettingsKey.ENABLE_LOGS, saveLogs);
    MyConsole.setSaveLogs(saveLogs);
  }


  async downloadLogs(filename = 'data') {
    const localStorageData = await this._localStorageService.get<BlobPart>('logs');
    const data = JSON.stringify(localStorageData, null, " ");
    const blob = new Blob([data], {type: 'application/json'});

    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);

    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }

    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.json');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  clearLogs() {
    this._localStorageService.delete('logs');
  }


  checkSaveLog() {
    this.saveLogs = this._settingsService.get(ProkeySettingsKey.ENABLE_LOGS);
  }

  syncStorage() {
    this._dialogService.open(StorageSyncDialogComponent, { data: {
        deviceId: this._deviceId
      }
    })
  }

  /* this method will open related dialog. */
  _openDialog(dialogComponent: any, data) {
    this._dialogService.open(dialogComponent, {
      data,
      maxWidth: 500,
      disableClose: true,
      panelClass: 'custom-dialog-class'
    });
  }

  /* this method will close all active dialogs. */
  private _closeDialogs() {
    this._dialogService.closeAll();
  }

  changeStorage(storage: string) {
    this._storageService.changeStorage(this._deviceId, storage);
  }

  private async _getCurrentStorage() {
    let device = await this._deviceService.device$.pipe(first()).toPromise();
    this._deviceId = device.features.device_id;
    this.currentStorage = this._storageService.getCurrentStorage(this._deviceId);
  }
}
