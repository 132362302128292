<app-alert [(model)]="alertModel"></app-alert>
<div class="card" [class.mt-4]="alertModel.show">
  <div class="card-body">
    <div class="form-group">
      <div class="f-inner">
        <label class="mr-4">Firmware version</label>
      </div>
      <span>{{fmvVersion}}</span>
    </div>
    <div class="form-group mb-0">
      <div class="f-inner">
        <label class="mb-4 mr-5">Device Passphrase</label>
      </div>
      <div class="d-inline-block relative">
        <label class="switch-iPhone mb-0" for="passphrase">
          <input name="passphrase" class="switch" id="passphrase" type="checkbox"
                 [(ngModel)]="isPassphraseProtection" (change)="setProtection()"/><span
          class="switchStyle"></span>
        </label>
      </div>
    </div>
    <p>Passphrase encryption adds an extra custom word to your recovery seed. This allows you to access new wallets,
      each hidden behind a particular passphrase. Your old accounts will be accessible with an empty passphrase</p>
  </div>
</div>
<div class="card mt-3">
  <div class="card-body">
    <div class="form-group mb-0 row">
      <div class="col-lg-4 col-md-3 col-sm-12 col-12">
        <label class="mb-4 mr-5">Check recovery seed</label>
      </div>
      <div class="col-lg-8 col-md-9 col-sm-12 col-12">
        <p>Due to security reasons, you can check your seed on device only.</p>
        <a href="https://support.prokey.io/help-center/articles/3/10/10/checking-your-recovery-seed"
           class="btn change-btn waves waves-effect" target="_blank"><i class="far fa-edit"></i> How to check recovery
          seed?</a>
      </div>
    </div>
  </div>
</div>
<div class="card mt-3">
  <div class="card-body">
    <div class="form-group mb-0 row">
      <div class="col-lg-4 col-md-3 col-sm-12 col-12">
        <label class="mb-4 mr-5">Wipe device</label>
      </div>
      <div class="col-lg-8 col-md-9 col-sm-12 col-12 pr-0">
        <p>Wiping the device removes all its information.</p>
        <p>Only wipe your device if you have your recovery seed at hand or there<br>are no funds stored on this device.
        </p>
        <button type="button" class="btn ban-btn waves waves-effect" (click)="wipeDevice()"><i class="fas fa-ban"></i>
          Wipe Device
        </button>
      </div>
    </div>
  </div>
</div>
<div class="card mt-3">
  <div class="card-body">
    <div class="form-group mb-0 row">
      <div class="col-lg-4 col-md-3 col-sm-12 col-12">
        <label class="mb-4 mr-5">Logs</label>
      </div>
      <div class="col-lg-8 col-md-9 col-sm-12 col-12 pr-0">
        <div class="form-group mb-0">
          <div class="f-inner">
            <label class="mb-4 mr-5">Save Logs</label>
          </div>
          <div class="d-inline-block relative">
            <label class="switch-iPhone mb-0" for="save_logs">
              <input name="log_save_switch" class="switch" id="save_logs" type="checkbox" value="saveLogs"
                     [(ngModel)]="saveLogs" (change)="setLogs(saveLogs)"/><span class="switchStyle"></span>
            </label>
          </div>

        </div>
        <div class="form-group mb-0">
          <div class="f-inner">
            <label class="mb-4 mr-5">Download Logs</label>
          </div>
          <div class="d-inline-block relative">
            <button class="btn change-btn waves waves-effect changeit" type="button" id="download_logs"
                    (click)="downloadLogs()"><i
              class="fa fa-download"></i> Download
            </button>
          </div>
        </div>
        <div class="form-group mb-0">
          <div class="f-inner">
            <label class="mb-4 mr-5">Clear Logs</label>
          </div>
          <div class="d-inline-block relative">
            <button class="btn change-btn waves waves-effect " type="button" id="clear_logs"
                    (click)="clearLogs()"><i
              class="fa fa-trash"></i> Clear
            </button>
          </div>
        </div>
      </div>
    </div>
    <p>You can find out what data between blockchain
      and device has been transferred by getting logs from messages between the device and platform.</p>
  </div>
</div>
<div class="card mt-3">
  <div class="card-body">
    <div class="form-group mb-0 row">
      <div class="col-lg-4 col-md-3 col-sm-12 col-12">
        <label class="mb-4 mr-5">Cloud Storage</label>
      </div>
      <div class="col-lg-8 col-md-9 col-sm-12 col-12 pr-0">
        <div class="form-group mb-0">
          <div class="d-inline-block relative">
            <select class="form-control d-inline-block float-right" #select style="width:200px" (change)="changeStorage(select.value)">
              <option *ngFor="let storage of availableStorages"
                      [selected]="storage.getRepositoryRepresentName() == currentStorage.getRepositoryRepresentName()">
                      {{storage.getImageUrl}} 
                 {{storage.getRepositoryRepresentName()}}
              </option>
            </select>
          </div>
          <div class="d-inline-block form-group mb-0 ng-star-inserted align-top ml-3" *ngIf="currentStorage.getRepositoryRepresentName() != 'Local Storage'">
            <div class="d-inline-block relative">
              <button class="btn change-btn waves waves-effect " type="button" id="clear_logs"
                      (click)="syncStorage()"><i
                class="fa fa-sync"></i> Sync
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <p class="mt-3">By enabling cloud storage, your wallet configuration which doesn't inlcude any critical information, will be saved on your cloud storage account to have access to same configuration on different browsers and machines.
    </p>
  </div>
</div>
