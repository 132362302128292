<div mat-dialog-title>
  <h5>{{headerTitle}}</h5>
</div>
<div mat-dialog-content>
  <ng-content></ng-content>
</div>
<div mat-dialog-actions *ngIf="_footer">
  <ng-content select="[appDialogFooter]"></ng-content>
  <button mat-button (click)="closeModal()">{{_modal.dialogConfig?.cancelBtnLabel}}</button>
  <button mat-button (click)="submit()" cdkFocusInitial>{{_modal.dialogConfig?.submitBtnLabel}}</button>
</div>
