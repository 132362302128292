<div class="alert alert-danger" *ngIf="hasError">
  <i class="fas fa-info-circle"></i>
  {{errorMessage}}
</div>

<form action="" method="POST">
  <div class="d-flex exchange">
    <app-send-coin
      class="exchange-flex-col"
      (selectAmountEvent)="setAmount($event)"
      (selectCoinEvent)="setSendCoin($event)">
    </app-send-coin>
    <div class="exchange-flex-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="15.484"
           viewBox="0 0 31.5 15.484">
        <path id="Icon_awesome-long-arrow-alt-right"
              data-name="Icon awesome-long-arrow-alt-right"
              d="M22.074,15.188H.844A.844.844,0,0,0,0,16.031v3.938a.844.844,0,0,0,.844.844h21.23v3.239a1.688,1.688,0,0,0,2.881,1.193l6.051-6.051a1.687,1.687,0,0,0,0-2.386l-6.051-6.051a1.688,1.688,0,0,0-2.881,1.193Z"
              transform="translate(0 -10.258)" fill="#52a5ea"/>
      </svg>
    </div>
    <div class="exchange-flex-col">
      <app-receive-coin
        class="form-group mb-2"
        [selectedSendCoin]="selectedSendCoin"
        (selectCoinEvent)="setReceiveCoin($event)">
      </app-receive-coin>
      <div class="d-block text-left">
        <button [disabled]="isOfferButtonDisabled" type="button" (click)="getOffers()"
                class="btn btn-gradinet-blue pnup waves-effect waves-dark exchange-search mt-1">
          Compare Offers
        </button>
      </div>
    </div>
  </div>
</form>
<div class="compare-offers">
  <app-offer-list (selectedOfferEvent)="setSelectedOffer($event)"
                  [offers]="offers"
                  [showSkeleton]="showSkeleton"
                  (timerEvent)="refreshOffersEvent($event)">
  </app-offer-list>
</div>
<div class="modal moda-reponsive fade wipe-modal" id="Keep-your-security">
  <div class="modal-dialog modal-xl" style="width:700px;">

    <div class="modal-content">
      <div class="modal-header">
        <div class="pt-2 pr-2 d-flex align-items-center w-100">
          <h5 class="mr-auto mb-0">Exchange Information</h5>
          <button type="button" class="close ml-auto p-0" data-dismiss="modal" (click)="cancelExchange()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 29.25 29.25">
              <g id="Icon_ionic-ios-close-circle-outline"
                 data-name="Icon ionic-ios-close-circle-outline"
                 transform="translate(-3.375 -3.375)">
                <path id="Path_8" data-name="Path 8"
                      d="M23.295,21.705,19.589,18l3.705-3.705a1.124,1.124,0,0,0-1.589-1.589L18,16.411l-3.705-3.705a1.124,1.124,0,0,0-1.589,1.589L16.411,18l-3.705,3.705a1.086,1.086,0,0,0,0,1.589,1.116,1.116,0,0,0,1.589,0L18,19.589l3.705,3.705a1.129,1.129,0,0,0,1.589,0A1.116,1.116,0,0,0,23.295,21.705Z"
                      fill="#33323a"/>
                <path id="Path_9" data-name="Path 9"
                      d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z"
                      fill="#33323a"/>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <ul class="text-left smallerfont exchangetos">
          <li>
            <p>This exchange is handling by ChangeNow and doesn’t perform by Prokey.</p>
          </li>
          <li>
            <p>This is starting of exchange between cryptocurrencies, if you had any inquiry about your previous exchange transactions, please contact ChangeNow support before proceeding.</p>
          </li>
          <li>
            <p>You're using this feature to exchange funds that will be sent to an account under your direct personal control.</p>
          </li>
          <li>
            <p>You're not using this feature for gambling or any other violation of the provider's terms of service.</p>
          </li>
          <li>
            <p>Cryptocurrency transactions are irreversible and you won't be able to receive a refund for your purchase.</p>
          </li>
        </ul>

      </div>
      <div class="modal-footer">
        <div class="d-flex w-100">
          <div class="checkbox mr-auto">
            <div class="custom-control custom-checkbox mb-1">
              <input type="checkbox" class="custom-control-input" id="agree" [checked]="privacy"
                     (click)="checkPrivacy()">
              <label class="custom-control-label cursor-pointer" for="agree">I have read, understand and agree to the above information</label>
            </div>
          </div>
          <button type="button" (click)="confirmExchange()" [disabled]="!privacy"
                  class="btn btn-gradinet-green pnup waves-effect waves-dark exchange-button ml-auto">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
