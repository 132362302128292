import {Injectable} from '@angular/core';
import {Subscription, timer} from "rxjs";
import {WalletDeviceService} from "./wallet-device.service";
import {WalletForgetDeviceComponent} from "@core/component/wallet-forget-device/wallet-forget-device.component";
import {ProkeyDevice} from "@core/models/connect/prokey-device";
import {WalletPinRequestComponent} from "@core/component/wallet-pin-request/wallet-pin-request.component";
import {WalletPinOnDeviceComponent} from "@core/component/wallet-pin-on-device/wallet-pin-on-device.component";
import {FailureType} from "@core/models/connect/failure-type.enum";
import {ButtonRequestType} from "@core/models/connect/button-request-type.enum";
import {WalletDeviceRebootComponent} from "@core/component/wallet-device-reboot/wallet-device-reboot.component";
import {WalletPassphraseRequestComponent} from "@core/component/wallet-passphrase-request/wallet-passphrase-request.component";
import {DialogService} from "@shared/dialog/_services/dialog.service";
import {ConfirmationDialogComponent} from "@shared/components/confirmation-dialog/confirmation-dialog.component";

/* this service is responsible for handling device events. */
@Injectable({
  providedIn: 'root'
})
export class WalletDeviceEventsService {

  /* constructor */
  constructor(private _dialogService: DialogService, private _deviceService: WalletDeviceService) {
    this._listenToDeviceSubscribe();
    this._listenToPinRequest();
    this._listenToUiButtons();
    this._listToFailureCallback();
    this._listenToPassphraseRequest();
  }

  /* device subscription. */
  private _deviceSubscription: Subscription;
  /* pin-request subscription. */
  private _pinRequestSubscription: Subscription;
  /* pin-on-device subscription */
  private _uiButtonSubscription: Subscription;
  /* pin-on-device-cancelled subscription */
  private _failureCallBackSubscription: Subscription;
  /* passphrase-request subscription */
  private _passphraseRequestSubscription: Subscription;

  /* it will hook on to listen to device subscribe. */
  private _listenToDeviceSubscribe() {
    this._deviceSubscription = this._deviceService.device$.subscribe(
      (device: ProkeyDevice) => {
        this._handleDeviceDisconnected(device);
      });
  }

  /* it will hook on to listen to pin-request subscribe. */
  private _listenToPinRequest(): void {
    this._pinRequestSubscription = this._deviceService.pinRequest$.subscribe(pinRequestModel => {
      let pinTimer$ = timer(1700);
      pinTimer$.subscribe(() => {
        if (pinRequestModel.showPopup) {
          this._dialogService.open(WalletPinRequestComponent, {disableClose: true});
        } else if (pinRequestModel.showPopup) {
          this._dialogService.closeAll();
        }
      });
    });
  }

  /* it will hook on to listen to ui-button subscribe. */
  private _listenToUiButtons(): void {
    this._uiButtonSubscription = this._deviceService.uiButton$.subscribe(
      (type: ButtonRequestType) => {
        if (this._deviceService.DisableButtonRequestDialog === false) {
          if (type === ButtonRequestType.ButtonRequest_EnterPinOnDevice ||
            type === ButtonRequestType.ButtonRequest_EnterNewPinOnDevice ||
            type === ButtonRequestType.ButtonRequest_ReEnterNewPinOnDevice) {
            this._dialogService.open(WalletPinOnDeviceComponent, {disableClose: true});
          } else if (type === ButtonRequestType.ButtonRequest_PinOnDeviceDone) {
            this._dialogService.closeAll();
          } else if (type == ButtonRequestType.ButtonRequest_ProtectCall || type == ButtonRequestType.ButtonRequest_Other) {
            this._dialogService.open(ConfirmationDialogComponent, {disableClose: true})
          }
        }
        else {
          this._deviceService.DisableButtonRequestDialog = false;
        }
      });
  }

  /* it will hook on to listen to failure-callback subscribe. */
  private _listToFailureCallback(): void {
    this._failureCallBackSubscription = this._deviceService.failureCallBack$.subscribe(
      (type: any) => {
        if (type?.code === FailureType.Failure_NotInitialized) {
          this._dialogService.open(WalletDeviceRebootComponent, {disableClose: true, maxWidth: 500});
        } else if (type?.code === FailureType.Failure_PinCancelled) {
          this._dialogService.closeActiveModal();
        } else if (type?.code === FailureType.Failure_ActionCancelled) {
          this._dialogService.closeActiveModal();
        }
      })
  }

  /* it will hook on to listen to passphrase-request subscribe. */
  private _listenToPassphraseRequest() {
    this._passphraseRequestSubscription = this._deviceService.passphraseRequest$.subscribe(model => {
      if (model.showPopup === true) {
        this._dialogService.open(WalletPassphraseRequestComponent, {disableClose: true, width: '350px'});
      }
    });
  }

  /* this method will handle device disconnected. */
  private _handleDeviceDisconnected(device: ProkeyDevice): void {
    if (device.isConnected === false) {
      if (this._deviceService.isForceDisconnect) {
        this._deviceService.isForceDisconnect = false;
        this._deviceService.handleDisconnectedDevice(true);
      } else {
        this._dialogService.open(WalletForgetDeviceComponent, {
          data: {deviceLabel: device.label},
          disableClose: true
        });
      }
    }
  }
}
