import {Component, OnInit} from '@angular/core';
import {ExchangeService} from '../../services/exchange.service';
import {Exchange} from '../../models/exchange';
import {Router} from '@angular/router';
import {ExchangeStatus} from '../../models/exchange-status';
import {StatusType} from '../../models/status-type';

@Component({
  selector: 'app-status-exchange',
  templateUrl: './status-exchange.component.html',
  styleUrls: ['./status-exchange.component.css']
})
export class StatusExchangeComponent implements OnInit {
  STATUS_REFRESH_TIME = 60000;
  exchange: Exchange;
  exchangeStatus: ExchangeStatus;
  _timer;

  constructor(
    private exchangeService: ExchangeService,
    private route: Router
  ) { }

  ngOnInit() {
    this.exchangeSubscribe();
    this.startTimer();
  }

  private async exchangeSubscribe() {
    this.exchangeService.currentOffer.subscribe(async exchange => {
      this.exchange = exchange;
    });
  }

  private async startTimer() {
    if (this._timer) {
      return;
    }
    this._timer = setInterval(() => {
      this.changeExchangeStatus();
    }, this.STATUS_REFRESH_TIME);
  }

  private async changeExchangeStatus() {
    this.exchangeStatus = await this.exchangeService.getExchangeStatus(this.exchange);
  }

  returnToWallet() {
    this.route.navigate(['/start']);
  }

  isExchangeFailed(): boolean {
    if (this.exchangeStatus) {
      return this.exchangeStatus.status === StatusType.FAILED;
    }
    return false;
  }

  isExchangeFinished(): boolean {
    if (this.exchangeStatus) {
      return this.exchangeStatus.status === StatusType.FINISHED;
    }
    return false;
  }

  isExchangeWaiting(): boolean {
    if (this.exchangeStatus) {
      return this.exchangeStatus.status === StatusType.WAITING;
    }
    return true;
  }
}
