import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {NetworkModel} from '@core/models/network-model';
import {CustomTokenService} from '../../../services/custom-token.service';
import {Erc20BaseCoinInfoModel} from '@webcore/models/CoinInfoModel';
import {CoinModel} from '@core/models/connect/coin-model';
import {IsAddressValid} from '@webcore/utils/address';
import {CoinBaseType} from '@webcore/coins/CoinInfo';

@Component({
  selector: 'app-wallet-add-custom-token',
  templateUrl: './wallet-add-custom-token.component.html',
  styleUrls: ['./wallet-add-custom-token.component.css']
})
export class WalletAddCustomTokenComponent implements OnInit {
  @Output() _cancelEvent = new EventEmitter<boolean>();
  @Output() _addedCustomTokenEvent = new EventEmitter<Erc20BaseCoinInfoModel>();
  @Input() _websiteCoinModels: CoinModel[];
  @ViewChild('addCustomTokenForm') public addCustomTokenForm: NgForm;
  networks: Array<NetworkModel>;

  selectedNetwork: string;
  name: string;
  contractAddress: string;
  contractDecimal: string;
  contractSymbol: string;

  addressValidationFlag = true;
  networkValidationFlag = true;
  contractAddressRepeatedValidationFlag = true;
  contractSymbolRepeatedValidationFlag = true;

  repeatedCoinContractAddress: string;
  repeatedCoinSymbol: string;

  constructor(
    private customTokenService: CustomTokenService
  ) { }

  async ngOnInit() {
    this.networks = await this.customTokenService.getAvailableNetworks();
  }

  cancel() {
    this._cancelEvent.emit(true);
  }

  async addToken() {
    // this method just work with erc20 coins
    if (!this.addCustomTokenForm.valid) {
      return;
    }
    const contractNetwork = this.networks.find(network => network.name === this.selectedNetwork);
    if (!this.selectedNetwork) {
      this.networkValidationFlag = false;
      return;
    }
    if (!IsAddressValid(this.contractAddress, contractNetwork.type, contractNetwork.isTest ? 'tETH' : 'ETH', contractNetwork.isTest)) {
      this.addressValidationFlag = false;
      return;
    }
    if (this._websiteCoinModels.some(coin => this.validateContractAddressAndSymbol(coin))) {
      return;
    }
    const customToken = await this.customTokenService.addCustomToken({
      network: contractNetwork,
      tokenName: this.name.trim(),
      contractAddress: this.contractAddress.trim(),
      decimal: +this.contractDecimal,
      symbol: this.contractSymbol.trim()
    });
    this._addedCustomTokenEvent.emit(customToken);
  }

  private validateContractAddressAndSymbol(coin: CoinModel) {
    if (coin.coinInfo.coinBaseType === CoinBaseType.ERC20) {
      const erc20Coin = coin.coinInfo as Erc20BaseCoinInfoModel;
      if (erc20Coin.address === this.contractAddress) {
        this.contractAddressRepeatedValidationFlag = false;
        this.repeatedCoinContractAddress = coin.coinInfo.name;
        return true;
      }
    }
    if (coin.coinInfo.shortcut === this.contractSymbol) {
      this.contractSymbolRepeatedValidationFlag = false;
      this.repeatedCoinSymbol = coin.coinInfo.name;
      return true;
    }
    return false;
  }

  changeNetwork(network: string) {
    this.selectedNetwork = network;
    this.networkValidationFlag = true;
  }

  changeContractAddress() {
    this.addressValidationFlag = true;
    this.contractAddressRepeatedValidationFlag = true;
  }
}
