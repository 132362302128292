export enum SendStep {
  ProcessingStep = 'ProcessingStep',
  ConfirmOutputStep = 'ConfirmOutputStep',
  ConfirmActionStep = 'ConfirmActionStep',
}

export enum ConfirmFeeMode {
  Single = 'Single',
  Multi = 'Multi',
}
