import { Component, OnInit, Input } from '@angular/core';
import { AlertModel } from '../../models/alert-model';
import { AlertType } from '../../models/alert-type.enum';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  @Input() model: AlertModel;

  constructor() { }

  ngOnInit() {
  }

  getIconClass(alertType: string): string {
    switch (alertType) {
      case AlertType.Success:
        return "far fa-check-circle";
      case AlertType.Warning:
        return "fas fa-exclamation-circle";
      default:
        return "";
    }
  }

}
