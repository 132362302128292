<h3 class="mb-3">Add A Custom Token</h3>
<div class="blue-box">
  Please note that your custom token will be shown as "Unknown" asset on your device because it doesn't exist on
  the firmware,
  however, you can send, receive and manage the token with your Prokey.
</div>

<div class="form-box col-md-7">
  <form #addCustomTokenForm="ngForm">
    <div class="input-group-append">
      <select class="form-control form-box-select network" name="displayNetwork" [(ngModel)]="selectedNetwork" (ngModelChange)="changeNetwork($event)">
        <option [ngValue]="null" disabled>Select Network</option>
        <option *ngFor="let network of networks" [ngValue]="network.name">
          {{network.name}}
        </option>
      </select>
    </div>
    <ng-container *ngIf="!networkValidationFlag">
      <div class="c-text-invalid">
        Network is required
      </div>
    </ng-container>

    <div class="input-group-append form-box-input" [class.c-is-invalid]="tokenName.invalid && tokenName.touched">
      <div class="form-box-input-label"><span style="margin-left: 1rem">Token Name</span></div>
      <input name="tokenName" [(ngModel)]="name" #tokenName="ngModel" class="form-control form-control-line" required type="text" style="border: 0;flex: 1;width: auto;">
    </div>
    <ng-container *ngIf="tokenName.invalid && tokenName.touched">
      <div *ngIf="tokenName.errors.required" class="c-text-invalid">
        Token name is required
      </div>
    </ng-container>

    <div class="input-group-append form-box-input" [class.c-is-invalid]="address.invalid && address.touched">
      <div class="form-box-input-label"><span style="margin-left: 1rem">Contract Address</span>
        <span style="margin-left: 5px" class="fas fa-question-circle"
              tooltip="This refers to the address location of the actual token contract that manages the logic for the tokens. It does NOT refer to the address that holds your own personal tokens!"></span>
      </div>
      <input name="address" class="form-control form-control-line" type="text" style="border: 0;flex: 1;width: auto;" required
             [(ngModel)]="contractAddress" #address="ngModel" (keyup)="changeContractAddress()">
    </div>
    <ng-container *ngIf="address.touched">
      <div *ngIf="address.invalid && address.errors.required" class="c-text-invalid">
        Contract Address is required
      </div>
      <div *ngIf="!address.errors && !addressValidationFlag" class="c-text-invalid">
        <i class="fas fa-info-circle"></i>
        Invalid Contract Address
      </div>
      <div *ngIf="!address.errors && !contractAddressRepeatedValidationFlag" class="c-text-invalid">
        <i class="fas fa-info-circle"></i>
        contract address already existed as {{repeatedCoinContractAddress}}
      </div>
    </ng-container>


    <div style="display: flex;width: 100%;flex-wrap: wrap">
      <div class="input-group-append form-box-input" [class.c-is-invalid]="decimal.invalid && decimal.touched">
        <div class="form-box-input-label"><span style="margin-left: 1rem">Decimal</span>
          <span style="margin-left: 5px" class="fas fa-question-circle"
                tooltip="the decimals value is the number of digits that come after the decimal place when displaying token values on-screen"></span>
        </div>
        <input name="decimal" class="form-control form-control-line" type="text" style="border: 0;flex: 1;width: auto;" required pattern="^\d+(\.\d{1,8})?$" [(ngModel)]="contractDecimal" #decimal="ngModel">
      </div>
      <ng-container *ngIf="decimal.invalid && decimal.touched">
        <div *ngIf="decimal.errors.required" class="c-text-invalid">
          Decimal is required
        </div>
        <div *ngIf="decimal.errors.pattern" class="c-text-invalid">
          Decimal is not valid
        </div>
      </ng-container>

      <div style="width: 5rem"></div>

      <div class="input-group-append form-box-input" [class.c-is-invalid]="symbol.invalid && symbol.touched">
        <div class="form-box-input-label"><span style="margin-left: 1rem">Symbol</span></div>
        <input name="symbol" class="form-control form-control-line" type="text" style="border: 0;flex: 1;width: auto;" required [(ngModel)]="contractSymbol" #symbol="ngModel">
      </div>
      <ng-container *ngIf="symbol.touched">
        <div *ngIf="symbol.invalid && symbol.errors.required" class="c-text-invalid">
          Symbol name is required
        </div>
        <div *ngIf="!symbol.errors && !contractSymbolRepeatedValidationFlag" class="c-text-invalid">
          <i class="fas fa-info-circle"></i>
          symbol already existed as {{repeatedCoinSymbol}}
        </div>
      </ng-container>
    </div>

    <div style="margin-top: 2rem;display: flex">
      <button style="margin: 0;padding-right: 1rem!important;padding-left: 1rem !important;" (click)="addToken()"
              class="btn-gradinet-blue waves-effect waves-dark text-center mr-3" type="button">
        Apply
      </button>
      <button style="margin: 0; padding-right: 1rem!important;padding-left: 1rem !important;" (click)="cancel()"
              class="btn-gradinet-blue waves-effect waves-dark text-center" type="button">
        Cancel
      </button>
    </div>
  </form>
</div>
