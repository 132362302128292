import {ExchangeOffer} from '../../models/exchange-offer';
import {ExchangeTransaction} from '../../models/exchange-transaction';
import {ExchangeStatus} from '../../models/exchange-status';
import {Exchange} from '../../models/exchange';
import {CoinModel} from '@core/models/connect/coin-model';
import {AmountRange} from '../../models/amount-range';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {RestUrls} from "../../../../../_configs/rest-urls";
import {ExchangeServerRequest} from "../../models/exchange-server-request";

/**
 * each exchange provider must implement this class
 * @author <a href="mailto:purianajaflu@gmail.com">pourianajafluy</a>
 */
export abstract class BaseProvider {
  protected constructor(private httpClient: HttpClient) {
  }

  /**
   * return provider name
   */
  abstract getProviderName(): string;

  /**
   * return provider logo image path
   */
  abstract getProviderImagePath(): string;

  /**
   *return provider transaction status url
   */
  abstract getProviderTransactionUrl(): string;

  /**
   * return amount range for validation exchange
   * @param sendCoin
   * @param receiveCoin
   * @param fixRate
   */
  abstract getAmountRange(sendCoin: CoinModel, receiveCoin: CoinModel, fixRate: boolean): Promise<AmountRange>;

  /**
   * check if exchange send and receive coin support in this provider
   * @param sendCoin
   * @param receiveCoin
   * @param fixRate
   */
  abstract isExchangeCoinsSupported(sendCoin: CoinModel, receiveCoin: CoinModel, fixRate: boolean): Promise<boolean>;

  /**
   * get fixed-rate and standard offer for exchange
   * @param sendCoin
   * @param receiveCoin
   * @param sendCoinAmount
   * @param isFixRate
   */
  abstract getOffers(sendCoin: CoinModel, receiveCoin: CoinModel, sendCoinAmount: number, isFixRate: boolean): Promise<ExchangeOffer>;

  /**
   * create transaction for exchange in provider and return its information
   * @param exchange
   * @param payoutAddress
   * @param payinAddress
   */
  abstract createExchangeTransaction(exchange: Exchange, payoutAddress: string, payinAddress: string): Promise<ExchangeTransaction>;

  /**
   * return exchange transaction for exchange id
   * @param transactionId
   */
  abstract getExchangeStatus(transactionId: string): Promise<ExchangeStatus>;

  getToServer<T>(url: string): Promise<T> {
    return this.sendRequestThroughServer(url, 'GET', null);
  }

  postToServer<T>(url: string, body: any): Promise<T> {
    return this.sendRequestThroughServer(url, 'POST', body);
  }

  /**
   * each provider must send its request with this method to add authorization through server
   * @param url
   * @param method
   * @param body
   */
  sendRequestThroughServer<T>(url: string, method: string, body: any): Promise<T> {
    const request: ExchangeServerRequest = {
      providerName: this.getProviderName(),
      url: url,
      body: body ? JSON.stringify(body) : undefined,
      method: method
    }

    return this.httpClient.post<T>(
      RestUrls.getExchangeAuth(),
      request,
      {headers: new HttpHeaders({'Content-Type': 'application/json'})}
    ).toPromise();
  }
}
