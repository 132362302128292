<h3 class="body-title float-left">Receive {{CurrentCoinName}}</h3>
<div class="clear"></div>
<div class="card">
  <div class="card-body">
    <div *ngIf="showXrpAccountNote" class="alert alert-primary mb-5 small">Please notice that to fund a new address, you must receive enough XRP to meet the reserve requirement. The current minimum reserve requirement is 20 XRP <a href="https://xrpl.org/reserves.html" target="a_blank">Read more</a></div>
    <div *ngIf="isShowingAddressWithoutAccount" class="alert alert-danger mb-5 small">
      Because the wallet synchronization failed, the first address of the wallet is being shown here, this address may have been used before. To get the latest unused address, check your internet connection and click on "Failed to connect blockchain" or reload the website. However, you can receive {{CurrentCoinName}} using this address and it does not make any technical issue.
    </div>
    <div class="col-lg-8 col-md-10 col-sm-12 col-12 center p-0">
      <div class="fresh">
        <div class="form-group m-b-0">
          <label class="m-b-5">Fresh address</label>
          <div class="input-group">
            <input id="input-address" type="text" class="form-control" readonly="readonly" [value]="addressModel.viewAddress">
            <div class="input-group-append">
              <button class="btn btn-light" type="button" data-backdrop="static" data-keyboard="false" (click)="resetReceiveModal(true, 'input-address')">
                <i class="fa fa-eye" data-toggle="tooltip" title="View"></i>
              </button>
            </div>
          </div>
          <div *ngIf="hasPreviousAddress" class="text-muted text-left mt-2 d-inline-block float-left">
            <a href="javascript:void(0)" data-toggle="collapse" data-target="#addresses" title="Previous address" class="text-muted clp collapsed">
              <i class="far fa-clock"></i>
              <i class="fas fa-level-down-alt"></i>
              Previous address
            </a>
          </div>
        </div>
        <ng-container *ngIf="account.hasAccountPerEachAddress !== true">
          <button type="button" class="btn btn-gradinet-blue pnup waves-effect waves-dark mt-2 float-right" (click)="getNewAddress()">
            <i class="fa fa-upload"></i>
            New
          </button>
        </ng-container>
        <div class="clear"></div>
        <div *ngIf="hasPreviousAddress" id="addresses" class="collapse mt-3">
          <table class="table table-address">
            <tbody>
              <tr *ngFor="let address of previousAddresses">
                <td>/{{address.path[address.path.length - 1]}}</td>
                <td>{{address.address}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #receiveDialog>
  <app-dialog headerTitle="New address">
    <div class="container" style="width:470px">
      <div *ngIf="isShowQrCode" class="img">
        <qrcode [qrdata]="qrAddressModel.address" [size]="160" [level]="'M'"></qrcode>
        <small class="text-center">{{serializedPathForQrCode}}</small>
      </div>
      <div class="fresh">
        <div class="form-group m-0">
          <div class="input-group">
            <input type="text" id="txtViewFullAddress" class="form-control" readonly="readonly" [value]="addressModel.viewAddress">
            <div class="input-group-append">
              <button class="btn btn-light" type="button"
                      data-toggle="tooltip" title="" data-original-title="Copy"
                      data-clipboard="" data-clipboard-target="#txtViewFullAddress">
                <i class="fa fa-copy"></i>
              </button>
            </div>
          </div>
          <div *ngIf="showActionMessage" class="body-title text-left mt-3">
            <img class="prokey-device" src="./assets/img/device.svg" alt="prokey"/>
            Check address on Prokey
          </div>
        </div>
      </div>
    </div>
  </app-dialog>
</ng-template>

<ng-template #newAddressDialog>
  <app-dialog headerTitle="New address">
    <div class="container" style="width: 470px">
      <div *ngIf="isShowQrCode" class="img">
        <qrcode [qrdata]="qrAddressModel.address" [size]="160" [level]="'M'"></qrcode>
        <small class="text-center">{{serializedPathForQrCode}}</small>
      </div>
      <div class="fresh">
        <div class="form-group m-0">
          <div class="input-group">
            <input type="text" id="txtViewFullNewAddress" class="form-control" readonly="readonly" [value]="newAddressModel.viewAddress">
            <div class="input-group-append">
              <button *ngIf="newAddressAction === 'Show'" class="btn btn-light" type="button" (click)="showNewAddress()"><i class="fa fa-eye"></i></button>
              <button *ngIf="newAddressAction === 'Copy'" class="btn btn-light" type="button"
                      data-toggle="tooltip" title="" data-original-title="Copy"
                      data-clipboard="" data-clipboard-target="#txtViewFullNewAddress">
                <i class="fa fa-copy"></i>
              </button>
            </div>
          </div>
          <div *ngIf="showActionMessage" class="body-title text-left mt-3">
            <img class="prokey-device" src="./assets/img/device.svg" alt="prokey"/>
            Check address on Prokey
          </div>
        </div>
      </div>
    </div>
  </app-dialog>
</ng-template>
