<div class="alert alert-danger" *ngIf="hasError">
  <i class="fas fa-info-circle"></i>
  {{errorMessage}}
</div>

<form #receiveExchangeForm="ngForm" action="" method="POST">
  <div class="d-flex exchange fill-white">
    <div class="exchange-flex-col col">
      <div class="form-group mb-2 col-lg-6 col-md-8 col-sm-12 col-12 p-0">
        <label class="m-b-5">Receiving {{exchange?.receiverCoin.coinInfo.name}} Address</label>
        <div class="dropdown exchange-coin-search-one">
          <button class="btn bitcoin-btn dropdown-toggle relative waves waves-effect"
                  type="button" data-toggle="dropdown" aria-expanded="false" [disabled]="!useOnlyProkeyAddressFlag">
            <div class="d-flex">
              <div class="col-button right-border">
                <img src="{{exchange?.receiverCoin.getImageUrl()}}" width="36px"
                     height="36px"/>
              </div>
              <div class="col-button ml-2 pl-2 border-left-exchange">
                <span class="coin-unit d-block">{{exchange?.receiverCoin.coinInfo.shortcut}}</span>
                <small class="account-number d-block">
                  Account #{{selectedReceiveAccount ? selectedReceiveAccount.id + 1 : '-'}}
                </small>
                <small class="balance d-block">
                  balance {{
                  selectedReceiveAccount ?
                    (selectedReceiveAccount.balance | unit: exchange?.receiverCoin.coinInfo.decimals | number: '1.6-8') :
                    '0'
                  }}
                </small>
              </div>
            </div>
            <svg class="abs-dropdown" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="8"
                 viewBox="0 0 14 8">
              <image id="left148" width="14" height="8"
                     xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAABHNCSVQICAgIfAhkiAAAAL5JREFUGJWNzrEuRGEYhOFnj1rIJm5BIqJUke1UFEyjUahcwboCrS0p9LJRnL+SlRCJQlyDQqngIuQoHMnZjchO903m/WZ6dV2vYD3JkzlUStnHS4Ul3JVSduaAjnGNfpXkDcMfv2z+A+3hCodJXitIcoERJqWU1T+gbdzgJMkt9Jqm6QYusYutJO+tt4FnnCU5/83OggsYYw0DLLfQOMmwu6LqHkm+cIRPTHCPB5zOzp9q7DQv4hEfOGgfTukbk51GdPCtwzYAAAAASUVORK5CYII="/>
            </svg>
          </button>
          <div class="dropdown-menu p-2" x-placement="bottom-start" style="width:100%">
            <div class="dropdown-search mb-2 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-1" width="22px"
                   height="22px" xmlns:xlink="http://www.w3.org/1999/xlink"
                   version="1.1" id="Capa_1" x="0px" y="0px"
                   viewBox="0 0 512.005 512.005"
                   style="enable-background:new 0 0 512.005 512.005;"
                   xml:space="preserve">
													<g>
														<g>
															<path
                                d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667    S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6    c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z     M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"/>
														</g>
													</g>
												</svg>
            </div>
            <div class="search-category">
              <h6 class="hide-on-search">Accounts</h6>
              <ul class="hide-on-search">
                <li *ngFor="let account of receiveAccounts" (click)="selectAccount(account)">
                  <a href="javascript:void(0)" class="waves waves-effect"
                     data-coin="ltc" data-icon="assets/img/coins/color/ltc.svg">
                    <div class="d-flex search-coin">
                      <div class="col align-items-center">
                        <img src="{{exchange?.receiverCoin.getImageUrl()}}"
                             class="mr-2" width="24px" height="24px"/>
                        <span>Account #{{account.id + 1}}</span>
                      </div>
                      <div class="col align-items-center">
                        <small>Balance {{account.balance}}</small>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="address" class="m-b-5">
          Recipient address
          <ng-container *ngIf="address.touched">
              <span class="text-danger ml-auto" *ngIf="address.invalid && address.errors.required"><i class="fas fa-info-circle"></i>
                Address is required</span>
              <span class="text-danger ml-auto" *ngIf="!address.errors.required && !isAddressValid">
                <i class="fas fa-info-circle"></i> Invalid Address
              </span>
          </ng-container>
          <span class="text-success ml-auto" *ngIf="address.valid && address.touched && isAddressValid"><i class="fas fa-check"></i>
              Valid Address</span>
        </label>
        <div class="input-group" id="confirmAddress" data-toggle="popover" title=""
             data-content="Confirm address on your Prokey" data-placement="bottom" data-trigger="manual">
          <input autocomplete="off" type="text" class="form-control" placeholder="Receiver Address" #address="ngModel"
                 required [(ngModel)]="receiverAddress" name="receiverAddress" (change)="checkAddress()"
                 value="{{receiverAddress}}" [disabled]="useOnlyProkeyAddressFlag" id="address" >
        </div>
      </div>
      <app-coin-fee *ngIf="fees" class="form-group" [coin]="currentCoin" [fees]="fees" (selectedFeeEvent)="selectFee($event)"></app-coin-fee>
      <div class="form-group">
        <div class="custom-control custom-checkbox mb-1">
          <input type="checkbox" class="custom-control-input" id="useonly" (change)="changeUseProkeyFlag()" [checked]="useOnlyProkeyAddressFlag">
          <label class="custom-control-label cursor-pointer" for="useonly">
            I Use only Prokey addresses
          </label>
        </div>
      </div>
      <div class="form-group text-center">
        <button type="button" (click)="sendTransaction()"
                class="waves waves btn btn-gradinet-green pnup waves-effect waves-dark showPopover mr-3">
          Send Transaction
        </button>
        <button type="button" (click)="cancelTransaction()"
                class="waves waves btn btn-light pnup waves-effect btn-sm">
          Cancel Transaction
        </button>
      </div>
    </div>
  </div>
</form>

<app-spinner-loading [showSpinner]="loading"></app-spinner-loading>
